import React from "react";
// import CommonSortBox from "../../../../../../component/fragments/CommonSortBox";
// import CommonNavLink from "../../../../../../component/pages/dashboard/actuals/expense/CommonNavLink";
// import ActionContext from "../../../../../../../context/ActionContext";
import "../../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeApprovedNavLink from "./approveNavLink";
import EmployeeCommonNavLink from "./CommonNavLink";
import EmployeePendingNavLink from "./pendingNavLink";
import EmployeeRejectNavLink from "./rejectNavLink";
import EmployeeRevenueNavLink from "./RevenueNavLink";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../../helper/Helpers";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import { Dna } from "react-loader-spinner";

const EmployeeCommonRevenueLayout = ({
  children,
  chartBox,
  revenueNavLink,
  commonNavLink,
  approvedLink,
  pendingLink,
  rejectLink,
  options,
  exportVal,
  onExport,
  exportDrop,
  onGetDept,
  exportSelect,
  onFileDownload,
  loading,
}) => {
  // const actionCtx = useContext(ActionContext);

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Actual Revenue`}> */}
      <div className="expense-projection-inde-wrap">
        {exportDrop && (
          <div className="header-submit-filter">
            <label>Export</label>
            <Select
              styles={reactSelectStyle}
              className="select-filter-by"
              options={options}
              placeholder="File Type"
              value={exportVal}
              onChange={(e) => onExport(e)}
            />
            {/* <ButtonBlue onClick={onGetDept} text="All Department" /> */}
          </div>
        )}
        <>
          {commonNavLink && <EmployeeCommonNavLink link={"/dashboard-home"} />}
          {revenueNavLink && (
            <EmployeeRevenueNavLink link={"/dashboard-home"} />
          )}
          {approvedLink && <EmployeeApprovedNavLink link={"/dashboard-home"} />}
          {pendingLink && <EmployeePendingNavLink link={"/dashboard-home"} />}
          {rejectLink && <EmployeeRejectNavLink link={"/dashboard-home"} />}
        </>
        {/* common links end */}
        {/* chart and table box start */}
        <>{chartBox}</>
        {/* chart  box end */}
        {/* children box start */}
        <>{children}</>
        {/* children box end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};

export default EmployeeCommonRevenueLayout;
