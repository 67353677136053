/* eslint-disable */
import React, { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
import "../../../../../styles/dashboard/actuals/expense/actualExpense.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import EmployeeAttachMoreFiles from "./addMoreFiles";
import moment from "moment";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/airbnb.css";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import { Dna } from "react-loader-spinner";
import {
  createEmployeeActualExpense,
  getSingleEmployeeActualExpensesDraft,
  updateEmployeeActualExpense,
} from "../../../../../../redux/employee/actual-expense";
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { NumericFormat } from "react-number-format";
import Modal from "react-awesome-modal";
import EmpSharedServiceModal from "../modals/sharedServiceModal";
import { getDepartments } from "../../../../../../redux/department/deparment";

const EmployeeActualNewExpenseUpdate = ({ text, name, button }) => {
  // const [startDate, setstartDate] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.actual_expense_employee);
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);
  const [singleActual, setSingleActual] = useState({});
  const { user } = useSelector((state) => state.user);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const { departmentDrop } = useSelector((state) => state.department);
  const [details, setDetails] = useState({
    expense_date: "",
    chart_of_account_id: "",
    unit_price: "",
    units: "",
    description: "",
    total_expense: "",
    receipt: [],
  });

  const status = localStorage.getItem("employee-actual-expense-status");

  const [imageToUpload, setImageToUpload] = useState([]);

  const history = useHistory();

  const [showSharedModal, setShowSharedModal] = useState(false);
  const [isApportion, setIsApportion] = useState([]);

  const editApportionToSubmit = (list, dept) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          subsidiary_id: chi?.subsidiary?.id,
          department_id: dept,
          apportion: chi?.budget_percentage,
        };
      });
      // console.log(newList, "newList");
      setIsApportion(newList);
    }
  };

  const formatApportionToSubmit = (obj, e) => {
    const newList = isApportion?.map((chi) =>
      obj?.id === chi?.id ? { ...chi, apportion: e } : chi
    );
    setIsApportion(newList);
  };

  useEffect(() => {
    handleApportionSum();
  }, [isApportion]);

  const [apportionSum, setApportionSum] = useState("");
  const handleApportionSum = () => {
    const totalApportion = isApportion
      ?.map((chi) => chi.apportion)
      .reduce((a, b) => Number(a) + Number(b), 0);
    setApportionSum(totalApportion);
  };

  const handleSubmit = async (e, param) => {
    e.preventDefault();
    // console.log("details-------", {...details, receipt: imageToUpload});
    // const status = localStorage.getItem("employee-actual-expense-status");
    const formData = new FormData();
    formData.append(
      "expense_date",
      moment(singleActual?.expense_date).format("YYYY-MM-DD")
    );
    formData.append(
      "chart_of_account_id",
      singleActual?.chart_of_account_id?.value
    );
    formData.append("is_shared_service", isApportion?.length > 0 ? 1 : 0);
    formData.append(
      "unit_price",
      formatNumberWithoutComma(String(singleActual?.unit_price))
    );
    formData.append(
      "units",
      formatNumberWithoutComma(String(singleActual?.units))
    );
    formData.append("description", singleActual?.description);
    // details?.total_expense
    //   ? formData.append(
    //       "total_expense",
    //       formatNumberWithoutComma(singleActual?.total_expense)
    //     )
    //   :
    formData.append("total_expense", singleActual?.total_expense);
    Array.from(imageToUpload).forEach((image) => {
      formData.append("receipt[]", image);
    });
    formData.append("status", singleActual?.status);
    formData.append("organization_id", user?.organizations[0]?.id);
    formData.append("is_submitted", param);
    formData.append("department_id", singleActual?.department_id?.value);
    // console.log(singleActual);
    // return
    const val = {
      id: singleActual?.id,
      obj: formData,
    };

    // console.log(val);
    const data = await dispatch(updateEmployeeActualExpense(val));
    if (data?.payload?.success) {
      history.push("/employee-dashboard-actual-expense");
      // setDetails({
      //   expense_date: "",
      //   chart_of_account_id: "",
      //   unit_price: "",
      //   units: "",
      //   description: "",
      //   total_expense: "",
      //   receipt: [],
      // });
      // setstartDate("");
      // setImageToUpload([]);
    }
  };

  useEffect(() => {
    // console.log("ertyuiuyt");
    const empObj = {
      reason: true,
      id: user?.organizations[0]?.id,
    };
    dispatch(getChatAccounts(empObj));
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    dispatch(getDepartments(user?.organizations[0]?.id));
    getEmployeeActualInfo();
    // eslint-disable-next-line
  }, []);

  const getEmployeeActualInfo = async () => {
    const id = localStorage.getItem("employee-actual-expense-id");
    const obj = {
      id: id,
    };
    const data = await dispatch(getSingleEmployeeActualExpensesDraft(obj));
    if (data?.payload?.success) {
      const expense = data?.payload?.data?.expense;
      //   console.log(expense);
      const newObj = {
        ...expense,
        chart_of_account_id: {
          ...expense?.chart_of_account,
          label: expense?.chart_of_account?.name,
          value: expense?.chart_of_account?.id,
        },
        department_id: {
          ...expense?.department,
          label: expense?.department?.name,
          value: expense?.department?.id,
        },
      };
      setSingleActual(newObj);
    }
  };

  useEffect(() => {
    const calcVal =
      formatNumberWithoutComma(String(singleActual?.unit_price)) *
      formatNumberWithoutComma(String(singleActual?.units));
    setSingleActual((prev) => {
      return { ...prev, total_expense: calcVal };
    });
    setDetails((prev) => {
      return { ...prev, total_expense: calcVal };
    });
  }, [singleActual?.unit_price, singleActual?.units]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...singleActual, [name]: value };
    setSingleActual(data);
  };

  const handleAddToImageList = (param) => {
    const obj = [param, ...singleActual?.receipts];
    setSingleActual((prev) => {
      return {
        ...prev,
        receipts: obj,
      };
    });
  };

  const handleApportionSubmit = () => {
    const alertText = "The sharing percentage is above 100%";
    if (isApportion && apportionSum > 100) {
      toast.error(alertText, {
        theme: "colored",
      });
    } else {
      setShowSharedModal(false);
      actionCtx?.setIsModalOut(false);
    }
  };

  const apportionData =
    departmentDrop?.branch?.subsidiary?.shared_service;

  // const { preference } = useSelector((state) => state.preference);
  // const isDarkTheme = useThemeDetector();
  // require(`flatpickr/dist/themes/${
  //   preference?.display === "Light"
  //               ? "airbnb"
  //               : preference?.display === "Dark"
  //               ? "dark"
  //               : isDarkTheme
  //               ? "dark"
  //               : "airbnb"
  // }.css`);

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Actual Expense`}> */}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container start */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">Update Actual Expense</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          <div
            className="btn-download-box"
            //   onClick={() => setShowModal(true)}
          >
            <button className={`btn-bulk `}>
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div>
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container end */}
        <div
          className="form-box"
          style={{
            width: "100%",
          }}
        >
          <form action="" className="form" onSubmit={handleSubmit}>
            {/* <div className=""> */}
            <div
              className="wrapper"
              style={{
                borderBottom: "unset",
                // paddingBottom: "-40rem",
                height: "unset",
                minHeight: "unset",
                // background: "green"
              }}
            >
              <p className="box-title">1.Expense Information</p>
              <div
                className="form-wrap"
                style={{
                  gridTemplateRows: "1fr",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <div className="employee-actual-expense-left-upload-wrap">
                  {/* select content wrap start */}
                  <div className="select-content">
                    {imageToUpload?.length < 1 && (
                      <>
                        <p className="title">Upload receipt </p>
                        <input
                          type="file"
                          name="upload-recipt-employee"
                          id="upload-recipt-employee"
                          className="upload-receipt"
                          onChange={(e) =>
                            handleAddToImageList(e.target.files[0])
                          }
                        />
                        <label
                          htmlFor="upload-recipt-employee"
                          className="label-upload"
                        >
                          <div className="wrap">
                            <FaPlus className="icon" />
                            <p className="text">Attach file</p>
                          </div>
                        </label>
                        <p className="info">
                          Supported format is jpg, xls, pdf. maximum of 5 files
                          and must not be less than 450kb
                        </p>
                      </>
                    )}
                    {/* less than end */}
                    {imageToUpload?.length > 0 && (
                      <>
                        <input
                          type="file"
                          name="upload-recipt-employee"
                          id="upload-recipt-employee"
                          className="upload-receipt"
                          onChange={(e) =>
                            handleAddToImageList(e.target.files[0])
                          }
                        />
                        <label
                          htmlFor="upload-recipt-employee"
                          className="label-upload-two"
                        >
                          {" "}
                          <div className="plus-box">
                            <FaPlus className="icon" />
                          </div>
                          <p className="text">Attach more files</p>
                        </label>
                      </>
                    )}
                  </div>
                  {/* select content wrap end */}
                  {/* slected content box start */}
                  <div className="selected-content-box">
                    <div
                      style={{
                        gridTemplateColumns: `repeat(${singleActual?.receipts?.length}, max-content)`,
                      }}
                      className="wrap"
                    >
                      {singleActual?.receipts?.map((chi, idx) => {
                        const { original_url, mime_type } = chi;
                        // console.log(original_url);
                        if (original_url && !mime_type?.includes("image/")) {
                          return (
                            <div className="box box-file" key={idx}>
                              {/* <p className="type">File type: <span>{formatApplicationType(chi?.type)}</span></p> */}
                              <p className="name">
                                File name: <span>{chi?.name}</span>
                              </p>
                            </div>
                          );
                        }
                        return (
                          <div
                            style={{
                              backgroundImage: original_url
                                ? `url(${original_url})`
                                : `url(${URL.createObjectURL(chi)})`,
                              width: "25rem",
                              //   backgroundColor: "red"
                            }}
                            key={idx}
                            className="box"
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                  {/* slected content box error */}
                </div>
                {/* form group-container start */}
                <div
                  // style={{ background: "green" }}
                  className="form-group form-group right-box-expense-actual-box-wrap"
                >
                  {/* form-group start */}
                  <div className="form-group">
                    <label htmlFor="expense_date" className="label">
                      Expense Date <span>*</span>
                    </label>
                    <Flatpickr
                      id="start_date"
                      name="start_date"
                      className={`input ${
                        singleActual?.expense_date && "input-active"
                      }`}
                      autoComplete="off"
                      value={moment(singleActual?.expense_date).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={(date) => {
                        setSingleActual((prev) => {
                          return {
                            ...prev,
                            expense_date: moment(date[0]).format("YYYY-MM-DD"),
                          };
                        });
                      }}
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="chart_of_account" className="label">
                      Chart of Account <span>*</span>
                    </label>
                    <Select
                      styles={reactSelectStyle}
                      className={`select-wrap ${
                        singleActual?.chart_of_account_id &&
                        "select-wrap-active"
                      }`}
                      placeholder="Select chart of account"
                      value={singleActual?.chart_of_account_id}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, chart_of_account_id: e };
                        });
                        setSingleActual((prev) => {
                          return {
                            ...prev,
                            chart_of_account_id: e,
                          };
                        });
                      }}
                      options={chatAccountDrop}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="department" className="label">
                      Department <span>*</span>
                    </label>
                    <Select
                      styles={reactSelectStyle}
                      className={`Select-wrap ${
                        singleActual?.department_id && "select-wrap-active"
                      }`}
                      placeholder="Select Department"
                      value={singleActual?.department_id}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, department_id: e };
                        });
                        setSingleActual((prev) => {
                          return {
                            ...prev,
                            department_id: e,
                          };
                        });
                      }}
                      options={departmentDrop}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="unit_price" className="label">
                      Unit Price <span>*</span>
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      id="unit_price"
                      name="unit_price"
                      placeholder="specify unit price"
                      value={singleActual?.unit_price}
                      // onChange={(e) =>
                      //   setDetails((prev) => {
                      //     return {
                      //       ...prev,
                      //       unit_price: handleNumberFormatInput(e),
                      //     };
                      //   })
                      // }
                      onChange={handleChange}
                      className={`input ${
                        singleActual.unit_price && "input-active"
                      }`}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="units" className="label">
                      Units <span>*</span>
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      id="units"
                      name="units"
                      placeholder="specify unit"
                      value={singleActual?.units}
                      // onChange={(e) =>
                      //   setDetails((prev) => {
                      //     return {
                      //       ...prev,
                      //       units: handleNumberFormatInput(e),
                      //     };
                      //   })
                      // }
                      onChange={handleChange}
                      className={`input ${
                        singleActual.units && "input-active"
                      }`}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="description" className="label">
                      Description <span>*</span>
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      onChange={(e) => {
                        handleChange(e);
                        setSingleActual((prev) => {
                          return {
                            ...prev,
                            description: e.target.value,
                          };
                        });
                      }}
                      style={{ resize: "none" }}
                      cols="30"
                      rows="7"
                      placeholder="Enter your description here..."
                      className={`input ${
                        singleActual?.description && "input-active"
                      }`}
                      value={singleActual?.description}
                    />
                  </div>
                  {/* form-group end */}
                  {/* sharing percent start */}
                  {apportionData?.length > 0 && (
                    <div>
                      <label className="label">
                        Set Sharing Percentage <span>*</span>
                      </label>
                      <ButtonBlue
                        onClick={() => {
                          setShowSharedModal(true);
                          actionCtx?.setIsModalOut(true);
                        }}
                        text={"Set Below"}
                      />
                    </div>
                  )}
                  {/* sharing percent end */}

                  {/* form-group start */}
                  <div>
                    <label htmlFor="total_expense" className="label">
                      Total Expense
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      disabled
                      style={{
                        backgroundColor: "#F1F2F3",
                        marginBottom: "-10rem",
                      }}
                      type="text"
                      onChange={handleChange}
                      id="total_expense"
                      name="total_expense"
                      placeholder="shows total value"
                      value={singleActual?.total_expense}
                      // onChange={handleChange}
                      className={`input`}
                    />
                  </div>
                  {/* form-group end */}
                </div>

                <div className="btn-box-wrap">
                  {!loading ? (
                    <input
                      onClick={(e) => {
                        handleSubmit(e, 0);
                      }}
                      type="submit"
                      value={"Update"}
                      className={`form-submit form-submit-active`}
                    />
                  ) : (
                    <div style={{ alignSelf: "center", marginLeft: "3rem" }}>
                      <Dna
                        visible={true}
                        height="80"
                        width="100"
                        ariaLabel="dna-loading"
                        // wrapperStyle={{color: "red", backgroundColor : "red"}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  )}
                  {/* btn-box-end */}
                </div>
                {/* form group-container end */}
              </div>
            </div>
            {/* </div> */}
            {/* modal start */}
            <Modal effect="fadeInLeft" visible={showSharedModal}>
              <EmpSharedServiceModal
                onCancel={() => {
                  setShowSharedModal(false);
                  actionCtx?.setIsModalOut(false);
                }}
                list={isApportion}
                listData={apportionData}
                deptVal={details?.department_id?.label}
                onSubmit={handleApportionSubmit}
                formatApportionToSubmit={formatApportionToSubmit}
              />
            </Modal>
            {/* modal end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default EmployeeActualNewExpenseUpdate;
