import React, { useContext, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import ButtonBlue from "../../../../buttons/ButtonBlue";
// import ToolLink from "./tools";
import Modal from "react-awesome-modal";
import ZohoModal from "./modal/connectModal";
import { FaCheck } from "react-icons/fa";
// import { useHistory, useLocation } from "react-router-dom";
import ZohoImg from "../../../../../assets/zohoImg.svg";
import SapImg from "../../../../../assets/sapImg.svg";
import FreshBookImg from "../../../../../assets/freshBookImg.svg";
import ActionContext from "../../../../../context/ActionContext";
import {
  createZohoIntegration,
  getZohoIntegration,
} from "../../../../../redux/integration/integration";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import { useEffect } from "react";

const CRMTools = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [checkbox, setCheckbox] = useState({
    one: false,
    two: false,
    three: false,
  });

  const [label, setLabel] = useState({
    one: false,
    two: false,
    three: false,
  });

  const { loading } = useSelector((state) => state.integration);
  const dispatch = useDispatch();

  const actionCtx = useContext(ActionContext);

  const list = [
    {
      imgTitle: ZohoImg,
      content:
        "Manage  and automate business processes across your organization",
      active: "one",
      name: "zoho expense",
    },
    {
      imgTitle: SapImg,
      content: "Easily manage and track business operations and activities",

      active: "two",
      name: "SAP",
    },
    {
      imgTitle: FreshBookImg,
      content:
        "Easily send invoices, track time, manage receipts, expenses, and accept credit cards with east",
      active: "three",
      name: "FreshBooks",
    },
  ];

  const [details, setDetails] = useState({
    // private_k
    private_key: "",
    client_label: "",
  });

  // const [displayPassCode, setDisplayPassCode] = useState("");
  // const [passCode, setPassCode] = useState("");

  // const handlePasswordChange = (e) => {
  //   e.preventDefault();
  //   const data = e.target.value;
  //   console.log(data, "val");
  //   setPassCode(data);
  //   // setDisplayPassCode("*".repeat(data.length));
  //   setDisplayPassCode(data.replace(/./g, "*"));
  // };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleZohoIntegration = async (e) => {
    e.preventDefault();
    const obj = {
      name: label.one || zohoDetails[0]?.name,
      organization_id: actionCtx?.activeOrg?.id,
      // status: true,
      client_id: details?.private_key,
      client_secret: details?.client_label,
    };
    // console.log(obj, "obj");
    const data = await dispatch(createZohoIntegration(obj));
    // console.log(data, "data");
    if (data?.payload?.success) {
      setShowModal(false);
      setDetails({ private_key: "", client_label: "" });
      const url = data?.payload?.data?.url;
      // history.push("/zoho-expense/callback");
      // console.log(url, "url");
      window.open(url, "_blank").focus();
    }
  };


  const [zohoDetails, setZohoDetails] = useState([]);

  const handleGetZohoData = async () => {
    const data = await dispatch(getZohoIntegration());
    if (data?.payload?.success) {
      const newData = data?.payload?.data?.crm_tools?.map((chi) => {
        return {
          status: chi?.status,
          name: chi?.name
        };
      });
      setZohoDetails(newData);
    }
  };

  // const [refreshTable] = false;
  useEffect(() => {
    handleGetZohoData();
  }, []);

  return (
    <>
      <div className="integration-wrap">
        <div className="zoho-box">
          {/*  left box start */}
          <div className="zoho-left-box">
            <div
              className="text"
              onClick={() => history.push("/dashboard-general-integration")}
            >
              <p>Integration</p>
            </div>
            <FaAngleRight className="icon" />
            <p className="active-text">CRM Tools</p>
          </div>
          {/* left box end */}
          {/* right box start */}
          <div className="zoho-right-box">
            {/* <button className="cancel-btn" onClick={() => history.goBack()}>
              Cancel
            </button> */}
            <ButtonBlue
              text={"Connect"}
              // btnClassName="connect-btn"
              onClick={() => {
                actionCtx.setIsModalOut(true);
                (label.one || zohoDetails[0]?.status) && setShowModal(true);
              }}
              bgColor={
                label.one || zohoDetails[0]?.status
                  ? "var(--blue-color)"
                  : "#ccc"
              }
              style={{
                cursor: (label.one || !zohoDetails[0]?.status) && "default",
              }}
            />
          </div>
          {/* right box end */}
        </div>
        <div className="wrapper" />

        {/* crm tools wrap start */}
        <div style={{ marginTop: "3rem" }}>
          {/* <ToolLink /> */}
          <div
            className="admin-approval-settings-wrap"
            style={{ paddingTop: "unset" }}
          >
            {/* <div className="title-btn-box"></div> */}
            {/* title wrap end */}

            {/* list box start */}
            <div className="list-wrap">
              {list.map((chi, idx) => {
                const { title, content, imgTitle, active, name } = chi;
                if (active === "one") {
                  return (
                    <div
                      key={idx}
                      className={`item   ${
                        active === "one" &&
                        (checkbox.one || zohoDetails[0]?.status) &&
                        "item-active"
                      }`}
                      // onClick={() => history.push(link)}
                      onClick={() => {
                        setCheckbox((prev) => {
                          return { ...prev, one: !checkbox.one };
                        });
                        setLabel((prev) => {
                          return { ...prev, one: !checkbox.one && name };
                        });
                      }}
                    >
                      <figure>
                        <img alt="" src={imgTitle} />
                      </figure>
                      <p className="title">{title}</p>
                      <p className="text">{content}</p>

                      <div className="check-box">
                        <FaCheck
                          className={`icon ${checkbox.one && "icon-active"}`}
                        />
                      </div>
                    </div>
                  );
                }
                if (active === "two") {
                  return (
                    <div
                      key={idx}
                      className={`item ${
                        active === "two" && checkbox.two && "item-active"
                      }`}
                      // onClick={() => history.push(link)}
                      onClick={() =>
                        setCheckbox((prev) => {
                          return { ...prev, two: !checkbox.two };
                        })
                      }
                    >
                      <figure>
                        <img alt="" src={imgTitle} />
                      </figure>
                      <p className="title">{title}</p>
                      <p className="text">{content}</p>

                      <div className="check-box">
                        <FaCheck
                          className={`icon ${checkbox.two && "icon-active"}`}
                        />
                      </div>
                    </div>
                  );
                }
                if (active === "three") {
                  return (
                    <div
                      key={idx}
                      className={`item ${
                        active === "three" && checkbox.three && "item-active"
                      }`}
                      // onClick={() => history.push(link)}
                      onClick={() =>
                        setCheckbox((prev) => {
                          return { ...prev, three: !checkbox.three };
                        })
                      }
                    >
                      <figure>
                        <img alt="" src={imgTitle} />
                      </figure>
                      <p className="title">{title}</p>
                      <p className="text">{content}</p>

                      <div className="check-box">
                        <FaCheck
                          className={`icon ${checkbox.three && "icon-active"}`}
                        />
                      </div>
                    </div>
                  );
                }
                return <React.Fragment key={idx}></React.Fragment>;
              })}
            </div>
            {/* list box end */}
          </div>
        </div>
        {/* crm tools wrap end */}
      </div>
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal}>
        <ZohoModal
          onCancel={() => {
            actionCtx.setIsModalOut(false);
            setShowModal(false);
            setDetails({ private_key: "", client_label: "" });
          }}
          loader={loading}
          handleChange={handleChange}
          private_key={details?.private_key}
          client_label={details?.client_label}
          onConnect={handleZohoIntegration}
          // handlePasswordChange={handlePasswordChange}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};
export default CRMTools;
