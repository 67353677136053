import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import ActionContext from "../../../../../../context/ActionContext";
import { useHistory } from "react-router-dom";
// import ExpenseChart from "../../../../../../component/pages/dashboard/actuals/expense/ExpenseChart";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
// import TableBodyRow from "../../../../../../component/tables/TableBodyRow";

import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
// import illustrationIcon from "../../../../assets/Illustration-icon.svg";
// import illustrationIcon from "../../../../../../assets/Illustration-icon.svg";
import EmployeeDraftProjection from "../expense/EmpDraftProjection";
import EmployeeCommonRevenueLayout from "./CommonLayout";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRevenueProjectionComment,
  // getRevenueProjection,
  getRevenueProjectionById,
  getRevenueProjectionSubmittedPagination,
  getRevenueSubmitProjectionBySearch,
} from "../../../../../../redux/employee/revenue-projection/revenue-projection";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import {
  checkCurrencyFormat,
  formatNumber,
  perPageOptions,
  trimLongString,
} from "../../../../../../helper/Helpers";
import useDebounce from "../../../../../../helper/UseDebounce";
import Modal from "react-awesome-modal";
import EmployeeRevenuePendingOverviewModal from "./modals/pendingModal";
import EmployeeRevenueOverviewRejectModal from "./modals/rejectedOverviewModal";
import EmployeeProjectionComentModal from "./modals/comment";
import EmpExpRevRejectModal from "./modals/rejectModal";
import EmployeeProjectionApprovalModal from "./modals/approve";
import EmployeeRevApproveOverview from "./modals/approveOverviewModal";
import {
  getEmployeeProjectionDownload,
  getExportToPdf,
  getUserDepartment,
  notifyNextApprover,
  recallProjections,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import FinalApprovalModal from "../../../../../../component/pages/dashboard/projection/revenue/modals/FinalApprovalModal";
import { getDepartments } from "../../../../../../redux/department/deparment";
import { getVerticals } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import { getProducts } from "../../../../../../redux/budget-setting/revenue-projection/product";
import RevenueRecallModal from "./modals/recallModal";
// import { useRef } from "react";
// import { useReactToPrint } from "react-to-print";
// import Papa from "papaparse";

const EmployeeRevenueSubmitProjection = ({ children }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [indexNo, setIndexNo] = useState("");
  const [refreshTable] = useState(false);
  const { loadingPage, revenueProjectionById } = useSelector(
    (state) => state.revenue_projection
  );
  const { loading, recallLoading } = useSelector(
    (state) => state.expenditure_projection
  );
  const { productDrop } = useSelector((state) => state.product);
  const { verticalDrop } = useSelector((state) => state.vertical);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  // const [sortByVal, setSortByVal] = useState({ label: "Sort by" });
  const [singleChild, setSingleChild] = useState({});
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const { departmentDrop } = useSelector((state) => state.department);
  const [filterByVal, setFilterByVal] = useState("");
  const [productVal, setProductVal] = useState("");
  const [verticalVal, setVerticalVal] = useState("");

  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Product", value: "product_id" },
    { label: "Vertical", value: "vertical_id" },
  ];
  const [activeStat, setActiveStat] = useState({
    one: true,
    two: false,
    three: false,
    four: false,
    five: false,
  });

  const statusDropdown = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    { label: "Pending My Approval", value: "pending_my_current_approver" },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const statusDropDownTwo = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const [dept, setDept] = useState("");
  const [selectStat, setSelectStat] = useState("");

  const debouncedSearchTerm = useDebounce(search, 1000);

  const handleSearch = (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      search: search,
      dept_id: dept?.id,
      filter: filterByVal?.value,
      status: selectStat?.value || param,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      // sort_by: sortByVal?.value,
    };
    setIndexNo(false);
    dispatch(getRevenueSubmitProjectionBySearch(obj));
  };

  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      (activeStat?.one && handleSearch("")) ||
        (activeStat?.two && handleSearch("approved")) ||
        (activeStat?.three && handleSearch("pending")) ||
        (activeStat?.five && handleSearch("pending_my_current_approver")) ||
        (activeStat?.four && handleSearch("rejected"));
      // const obj = {
    } else {
      handleGetRevenueData();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const obj = {
      reason: true,
      id: user?.organizations[0]?.id,
    };
    if (user?.organizations[0]?.id) {
      dispatch(getUserDepartment(user?.organizations[0]?.id));
      dispatch(getDepartments(user?.organizations[0]?.id));
      dispatch(getVerticals(obj));
      dispatch(getProducts(obj));
    }
  }, [user?.organizations[0]?.id]);

  useEffect(() => {
    if (actionCtx?.perPage || actionCtx?.currencyValue) {
      handleGetRevenueData();
    }
  }, [actionCtx?.perPage, actionCtx?.currencyValue]);

  const handleRevnueProjectionUpdate = (param) => {
    actionCtx?.setIsModalOut(false);
    localStorage.setItem("single-revenue-projection-id", param.id);
    history.push("/employee-dashboard-projection-revenue-update");
  };
  const [exportLoader, setExportLoader] = useState(false);
  const handleExport = async (param) => {
    const obj = {
      id: param?.id,
      type: "revenue_projection",
      currency_id: actionCtx?.currencyValue?.id,
    };
    // console.log(obj?.id, "id");
    setExportLoader(true);
    const data = await dispatch(getExportToPdf(obj));
    if (data?.payload?.url) {
      setIndexNo(false);
      setExportLoader(false);
      actionCtx?.setIsModalOut(false);
      window.open(data?.payload?.url, "_blank").focus();
      setShowModal((prev) => {
        return {
          ...prev,
          approveOverview: false,
          pendingOverview: false,
          rejectOverview: false,
        };
      });
    } else {
      setExportLoader(false);
    }
  };

  const handlePagination = async (param, statusParam, num) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo || num,
      status: statusParam || selectStat?.value || "",
      dept_id: dept?.id || "",
      filter: filterByVal?.value || "",
      search: search,
      // sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    setIndexNo(false);
    const data = await dispatch(getRevenueProjectionSubmittedPagination(obj));
    if (data?.payload?.success) {
      // setIndexNo(false);
      setSingleItem(data?.payload?.data);
    }
  };
  const handlePaginationNum = async (num, statusParam) => {
    // const splitLink = param.split("/api");
    // const splitLink = param.split("?page=");
    // const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: num,
      status: statusParam || selectStat?.value || "",
      dept_id: dept?.id || "",
      filter: filterByVal?.value || "",
      search: search,
      // sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    setIndexNo(false);
    const data = await dispatch(getRevenueProjectionSubmittedPagination(obj));
    if (data?.payload?.success) {
      // setIndexNo(false);
      setSingleItem(data?.payload?.data);
    }
  };

  const [showModal, setShowModal] = useState({
    pendingOverview: false,
    approveOverview: false,
    rejectOverview: false,
    approve: false,
    reject: false,
    comment: false,
    approve_all: false,
    recall: false,
  });
  const [modalView, setModalView] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const tableHeadList = [
    "Product",
    "Description",
    "Portfolio",
    "Unit",
    `Unit Price (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Revenue (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    "Gp",
    "GP %",
    "Status",
    // "Action",
  ];

  const cardHeader = [
    "Revenue Projected",
    "Approved",
    "Pending",
    "Rejected",
    "Pending My Approval",
    "Total Gp",
  ];

  const link = [
    "/employee-dashboard-projection-revenue-new",
    "/employee-dashboard-projection-revenue-submitted",
    "/employee-dashboard-projection-revenue-submitted-approved",
    "/employee-dashboard-projection-revenue-submitted-pending",
    "employee-dashboard-projection-revenue-submitted-rejected",
  ];

  // console.log("revenueProjectionById", revenueProjectionById);

  const [singleItem, setSingleItem] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterLoad, setFilterLoad] = useState(false);

  const handleGetRevenueData = async (param, deptParam) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id:
        deptParam ||
        dept?.id ||
        localStorage.getItem("revenue-projection-report") ||
        "",
      status: param || selectStat?.value || "",
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      product_id: productVal?.value,
      vertical_id: verticalVal?.value,
    };
    const data = await dispatch(getRevenueProjectionById(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
    }
  };
  const [activeBell, setActiveBell] = useState(true);
  const handleFilterSubmit = async (param, deptParam) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: deptParam || dept?.id || "",
      status: param || selectStat?.value || "",
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      product_id: productVal?.value,
      vertical_id: verticalVal?.value,
    };
    setFilterLoad(true);
    const data = await dispatch(getRevenueProjectionById(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      setFilterLoad(false);
      setShowFilterModal(false);
      actionCtx?.setIsModalOut(false);
      // setSelectStat("");
      localStorage.setItem("revenue-notify-dept", dept?.id);
      // setVerticalVal("");
      // setProductVal("");
      // setPdfExport("");
      // setFilterByVal("");
      dept?.id && setActiveBell(false);
      // setDept("");
      setActiveStat((prev) => {
        return {
          ...prev,
          one: obj?.status === "",
          two: obj?.status === "approved" && true,
          three: obj?.status === "pending" && true,
          five: obj?.status === "pending_my_current_approver" && true,
          four: obj?.status === "rejected" && true,
        };
      });
    } else {
      setFilterLoad(false);
    }
  };
  const cardValue = [
    singleItem?.summary?.total_projection || 0,
    singleItem?.summary?.approved || 0,
    singleItem?.summary?.awaiting_approval || 0,
    singleItem?.summary?.rejected || 0,
    singleItem?.summary?.pending_my_approver || 0,
    singleItem?.summary?.total_gp || 0,
  ];

  const handleGetCommentData = (param) => {
    // setSingleChild(param);
    dispatch(getAllRevenueProjectionComment(param));
  };

  const handleSingleCommentData = (param) => {
    setSingleChild(param);
    if (param) {
      dispatch(getAllRevenueProjectionComment(param?.id));
    }
  };

  // export dropdown
  const [pdfExport, setPdfExport] = useState("");
  const exportOption = [
    { label: "CSV", value: "excel" },
    { label: "PDF", value: "pdf" },
  ];
  const handleFileDownload = async (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: dept?.id,
      type: "revenue_projection",
      format: pdfExport?.value,
      user_id: user?.id,
      currency: actionCtx?.currencyValue?.id || "",
      status: param || selectStat?.value,
    };
    setDownloading(true);
    const data = await dispatch(getEmployeeProjectionDownload(obj));
    if (data?.payload?.status === 200) {
      setDownloading(false);
      setIndexNo(false);
      window.open(data?.payload?.data?.url, "_blank").focus();
      setIndexNo(false);
      setShowFilterModal(false);
      setSelectStat("");
      setDept("");
      setVerticalVal("");
      setProductVal("");
      setPdfExport("");
      setFilterByVal("");
      actionCtx?.setIsModalOut(false);
    } else {
      setDownloading(false);
    }
  };

  const handleEditListCheck = (id) => {
    const newList = singleItem?.submitted_revenueprojection?.data?.map((chi) =>
      chi?.id === id
        ? { ...chi, current_approver: !chi?.current_approver }
        : chi
    );
    const newObj = {
      ...singleItem,
      submitted_revenueprojection: {
        ...singleItem?.submitted_revenueprojection,
        data: newList,
      },
    };
    setSingleItem(newObj);
  };

  const [allApproveId, setAllAppproveId] = useState([]);
  const handleApproveAll = () => {
    const list = singleItem?.submitted_revenueprojection?.data?.filter(
      (chi) => chi?.status === "pending" && chi?.current_approver
    );
    const newList = list.map((chi) => chi?.id);
    setAllAppproveId(newList);
  };

  const [allApprovalStatus, setAllApprovalStatus] = useState("");
  const [headerCheck, setHeaderCheck] = useState(true);

  const [selectedRecall, setSelectedRecall] = useState([]);
  const [checkHeader, setCheckHeader] = useState(!true);
  const [recallLoader, setRecallLoader] = useState(false);

  const handleRecallProjection = async (param, singleParam) => {
    const obj = {
      id: param || [singleParam],
      type: "revenue_projection",
    };
    // console.log(obj);
    // return
    setRecallLoader(true);
    const data = await dispatch(recallProjections(obj));
    // console.log(obj, "obj");
    if (data?.payload?.success) {
      setRecallLoader(false);
      actionCtx?.setIsModalOut(false);
      history.push("/employee-dashboard-projection-revenue");
    } else {
      setRecallLoader(false);
    }
  };

  const handleCheckRecall = (id) => {
    const checkedList = selectedRecall.indexOf(id);
    if (checkedList > -1) {
      setSelectedRecall(selectedRecall.filter((chi) => chi !== id));
    } else {
      setSelectedRecall([...selectedRecall, id]);
    }
  };

  const handleAllRecallCheck = (e) => {
    if (e.target.checked) {
      setSelectedRecall(
        singleItem?.submitted_revenueprojection?.data?.map((chi) => chi.id)
      );
    } else {
      setSelectedRecall([]);
    }
    setCheckHeader(e.target.checked);
  };

  const [notifyLoader, setNotifyLoader] = useState(false);
  const deptId = localStorage.getItem("revenue-notify-dept");
  const handleNotifyApprover = async () => {
    const obj = {
      department_id: deptId,
      projection_type: "revenue_projection",
    };
    setNotifyLoader(true);
    const data = await dispatch(notifyNextApprover(obj));
    if (data.payload?.success) {
      setNotifyLoader(false);
      setDept("");
      setActiveBell(true);
    } else {
      setNotifyLoader(false);
    }
  };

  return (
    <>
      <EmployeeCommonRevenueLayout
        exportDrop
        exportVal={pdfExport}
        options={exportOption}
        onExport={(e) => {
          setPdfExport(e);
          setIndexNo(false);
        }}
      >
        <EmployeeReusableTable
          downloading={downloading}
          onDownload={() => {
            handleFileDownload(
              (activeStat?.one && "") ||
                (activeStat?.two && "approved") ||
                (activeStat?.three && "pending") ||
                (activeStat?.four && "rejected")
            );
          }}
          exportDrop
          exportVal={pdfExport}
          options={exportOption}
          onExport={(e) => {
            setPdfExport(e);
            setIndexNo(false);
          }}
          onOpenFilter={() => {
            setIndexNo(false);
            actionCtx?.setIsModalOut(true);
            setShowFilterModal(true);
          }}
          showFilter={showFilterModal}
          onCloseFilter={() => {
            actionCtx?.setIsModalOut(false);
            setShowFilterModal(false);
            setSelectStat("");
            setVerticalVal("");
            setProductVal("");
            setPdfExport("");
            setFilterByVal("");
            setDept("");
          }}
          filterLoading={filterLoad}
          onFilterSubmit={() => {
            handleFilterSubmit();
          }}
          filterIcon
          vertical
          verticalVal={verticalVal}
          onVerticalChange={(e) => {
            setVerticalVal(e);
          }}
          verticalDrop={verticalDrop}
          product
          productVal={productVal}
          onProductChange={(e) => {
            setProductVal(e);
          }}
          productDrop={productDrop}
          fileDownload
          onRejectAll={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, approve_all: true };
            });
            setAllApprovalStatus("rejected");
            handleApproveAll();
          }}
          onApproveAll={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, approve_all: true };
            });
            setAllApprovalStatus("approved");
            handleApproveAll();
          }}
          rejectAll={activeStat?.five}
          approveAll={activeStat?.five}
          allActionBtn
          downloadLoader={downloading}
          onFileDownload={() => {
            pdfExport?.value &&
              handleFileDownload(
                (activeStat?.one && "") ||
                  (activeStat?.two && "approved") ||
                  (activeStat?.three && "pending") ||
                  (activeStat?.four && "rejected")
              );
            setIndexNo(false);
          }}
          exportSelect={pdfExport?.label || "File"}
          ApprovalBell={!activeStat?.four}
          disableBell={activeBell}
          onNotify={handleNotifyApprover}
          totalTitle={"Revenue Projected"}
          totalValue={formatNumber(String(cardValue[0] || 0))}
          totalTitleTwo={"Total GP"}
          totalValueTwo={formatNumber(String(cardValue[5] || 0))}
          perPage
          optionPerPage={perPageOptions}
          setPerPage={(e) => {
            actionCtx?.setPerpage(e);
          }}
          recallAllBtn={
            activeStat?.three || activeStat?.one || activeStat?.five
          }
          loading={loadingPage}
          recallLoader={recallLoading}
          notifyLoader={notifyLoader}
          onRecallAll={() => {
            selectedRecall?.length > 0 &&
              handleRecallProjection(selectedRecall, "");
          }}
          recallBgColor={selectedRecall?.length < 1 && "#cccccc"}
          perPageVal={actionCtx?.perPage}
          statusDrop
          statusDropdown={
            user?.role !== "budget rep" ? statusDropdown : statusDropDownTwo
          }
          setStatusVal={(e) => {
            setSelectStat(e);
          }}
          statusVal={selectStat}
          dept
          departmentDrop={
            user?.user_type === "ADMIN" ? departmentDrop : userDepartmentDrop
          }
          deptVal={dept}
          setDepartmentVal={(e) => {
            setDept(e);
          }}
          filter
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => {
            setFilterByVal(e);
            setIndexNo(false);
          }}
          onSearchTable={(e) => {
            setSearch(e);
            setIndexNo(false);
          }}
          showSearch
          fifthCard={user?.role !== "budget rep"}
          addButton
          BtnTitle={`New Revenue`}
          onPage={() => history.push(link[0])}
          secondCard
          thirdCard={true}
          fourthCard
          // sixthCard
          // cardTitleOne={cardHeader[0]}
          cardTitleTwo={cardHeader[1]}
          cardTitleThree={cardHeader[2]}
          cardTitleFour={cardHeader[3]}
          cardTitleFive={cardHeader[4]}
          // cardTitleSix={cardHeader[5]}
          // cardBodyOne={formatNumber(String(cardValue[0] || 0))}
          cardBodyTwo={formatNumber(String(cardValue[1] || 0))}
          cardBodyThree={formatNumber(String(cardValue[2] || 0))}
          cardBodyFour={formatNumber(String(cardValue[3] || 0))}
          cardBodyFive={formatNumber(String(cardValue[4] || 0))}
          // cardBodySix={formatNumber(String(cardValue[5] || 0))}
          onFirstCard={() => {
            setIndexNo(false);
            handleGetRevenueData("");
            setSelectStat({
              label: "All Projection",
              value: "",
            });
            setActiveStat((prev) => {
              return {
                ...prev,
                one: true,
                two: false,
                three: false,
                four: false,
                five: false,
              };
            });
          }}
          onSecondCard={() => {
            setIndexNo(false);
            setSelectStat({
              label: "Approved",
              value: "approved",
            });
            handleGetRevenueData("approved");
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: true,
                three: false,
                four: false,
                five: false,
              };
            });
          }}
          onThirdCard={() => {
            setIndexNo(false);
            setSelectStat({
              label: "Pending",
              value: "Pending",
            });
            handleGetRevenueData("pending");
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: false,
                three: true,
                four: false,
                five: false,
              };
            });
          }}
          onFifthCard={() => {
            setIndexNo(false);
            setSelectStat({
              label: "Pending My Approval",
              value: "pending_my_current_approver",
            });
            handleGetRevenueData("pending_my_current_approver");
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: false,
                three: false,
                four: false,
                five: true,
              };
            });
          }}
          onFourthCard={() => {
            setIndexNo(false);
            handleGetRevenueData("rejected");
            setSelectStat({
              label: "Rejected",
              value: "rejected",
            });
            setActiveStat((prev) => {
              return {
                ...prev,
                five: false,
                one: false,
                two: false,
                three: false,
                four: true,
              };
            });
          }}
          linkCardOne={activeStat?.one}
          linkCardTwo={activeStat?.two}
          linkCardThree={activeStat?.three}
          linkCardFour={activeStat?.four}
          linkCardFive={activeStat?.five}
        />

        {children}

        <div className="table-bo">
          {/* table box start */}

          <ActualReuseableTable
            key={`dffgjsh`}
            massSelectable={
              (activeStat?.five &&
                singleItem?.submitted_revenueprojection?.data?.length > 0) ||
              (activeStat?.three &&
                singleItem?.submitted_revenueprojection?.data?.length > 0)
            }
            // checkValue={headerCheck}
            checkValue={
              (activeStat?.five && headerCheck) ||
              (activeStat?.three && checkHeader)
            }
            onMassCheck={(e) => {
              setHeaderCheck(!headerCheck);
              const newList =
                singleItem?.submitted_revenueprojection?.data?.map((chi) => {
                  return { ...chi, current_approver: e.target.checked };
                });
              const newObj = {
                ...singleItem,
                submitted_revenueprojection: {
                  ...singleItem?.submitted_revenueprojection,
                  data: newList,
                },
              };
              setSingleItem(newObj);
              // pending
              activeStat?.three && handleAllRecallCheck(e);
            }}
            useNumPagination
            currentPage={singleItem?.submitted_revenueprojection?.current_page}
            totalTableLength={singleItem?.submitted_revenueprojection?.total}
            onNumPage={(e) => {
              handlePaginationNum(
                e,
                (activeStat?.one && "") ||
                  (activeStat?.two && "approved") ||
                  (activeStat?.three && "pending") ||
                  (activeStat?.four && "rejected")
              );
            }}
            dontShowPagination={
              singleItem?.submitted_revenueprojection?.data?.length < 1
            }
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            eight={tableHeadList[7]}
            nine={tableHeadList[8]}
            prevPage={singleItem?.submitted_revenueprojection?.prev_page_url}
            nextPage={singleItem?.submitted_revenueprojection?.next_page_url}
            fromPage={singleItem?.submitted_revenueprojection?.from}
            toPage={singleItem?.submitted_revenueprojection?.to}
            totalPage={singleItem?.submitted_revenueprojection?.total}
            onNextPage={() => {
              (activeStat?.one &&
                handlePagination(
                  singleItem?.submitted_revenueprojection?.next_page_url,
                  ""
                )) ||
                (activeStat?.two &&
                  handlePagination(
                    singleItem?.submitted_revenueprojection?.next_page_url,
                    "approved"
                  )) ||
                (activeStat?.three &&
                  handlePagination(
                    singleItem?.submitted_revenueprojection?.next_page_url,
                    "pending"
                  )) ||
                (activeStat?.five &&
                  handlePagination(
                    singleItem?.submitted_revenueprojection?.next_page_url,
                    "pending_my_current_approver"
                  )) ||
                (activeStat?.one &&
                  handlePagination(
                    singleItem?.submitted_revenueprojection?.next_page_url,
                    "rejected"
                  ));
            }}
            onPrevPage={() => {
              (activeStat?.one &&
                handlePagination(
                  singleItem?.submitted_revenueprojection?.prev_page_url,
                  ""
                )) ||
                (activeStat?.two &&
                  handlePagination(
                    singleItem?.submitted_revenueprojection?.prev_page_url,
                    "approved"
                  )) ||
                (activeStat?.five &&
                  handlePagination(
                    singleItem?.submitted_revenueprojection?.prev_page_url,
                    "pending_my_current_approver"
                  )) ||
                (activeStat?.three &&
                  handlePagination(
                    singleItem?.submitted_revenueprojection?.prev_page_url,
                    "pending"
                  )) ||
                (activeStat?.one &&
                  handlePagination(
                    singleItem?.submitted_revenueprojection?.prev_page_url,
                    "rejected"
                  ));
            }}
            action
          >
            {singleItem?.submitted_revenueprojection?.data?.length > 0 ? (
              <>
                {" "}
                {singleItem?.submitted_revenueprojection?.data?.map(
                  (chi, idx) => {
                    const {
                      product,
                      unit,
                      unit_price,
                      total_revenue_project,
                      total_gross_profit,
                      gpp,
                      status,
                      current_approver,
                      description,
                      portfolio,
                      id,
                    } = chi;
                    return (
                      <EmpTableBodyRow
                        checkBoxType={
                          activeStat?.five ||
                          activeStat?.three ||
                          status === "pending"
                        }
                        onCheck={(e) => {
                          handleEditListCheck(id);
                          handleCheckRecall(id);
                        }}
                        // checkValue={current_approver}
                        checkValue={
                          (activeStat?.five && current_approver) ||
                          (status === "pending" && selectedRecall.includes(id))
                        }
                        checkId={`${id}-${idx}`}
                        key={idx}
                        item={chi}
                        one={product?.name}
                        two={trimLongString(description, "25")}
                        three={portfolio?.name || <></>}
                        four={unit || 0}
                        five={formatNumber(String(unit_price || 0))}
                        six={formatNumber(String(total_revenue_project || 0))}
                        seven={formatNumber(String(total_gross_profit || 0))}
                        eight={formatNumber(String(gpp))}
                        progressType={status}
                        action
                        recallText={"recall"}
                        onRecall={() => {
                          setIndexNo(false);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, recall: true };
                          });
                          actionCtx?.setIsModalOut(true);
                        }}
                        loading={loadingPage}
                        rowNo={idx}
                        indexNo={indexNo}
                        setIndexNo={() => setIndexNo(idx)}
                        // updateText={`Update`}
                        deleteText={
                          user?.permissions?.includes(
                            "approve/reject revenue projection"
                          )
                            ? "reject"
                            : null
                        }
                        // rejectedText={
                        //   user?.permissions?.includes(
                        //     "approve/reject revenue projection"
                        //   )
                        //     ? "Reject"
                        //     : null
                        // }
                        approveText={
                          status === "pending" &&
                          current_approver &&
                          user?.permissions?.includes(
                            "approve/reject revenue projection"
                          )
                            ? "Approve"
                            : null
                        }
                        updateText={
                          status === "pending" &&
                          user?.permissions?.includes(
                            "edit revenue projection"
                          ) &&
                          "Update"
                        }
                        onUpdate={() => {
                          handleRevnueProjectionUpdate(chi);
                        }}
                        viewText={"View Comments"}
                        exportText={`Export as PDF`}
                        // onUpdate={() => handleRevenueProjectionUpdate(chi)}
                        onOverview={() => {
                          // eslint-disable-next-line
                          {
                            actionCtx?.setIsModalOut(true);
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, pendingOverview: true };
                            });
                            setIndexNo(false);
                          }
                        }}
                        onRejected={() => {
                          // eslint-disable-next-line
                          {
                            actionCtx?.setIsModalOut(true);
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, reject: true };
                            });
                            setIndexNo(false);
                          }
                        }}
                        onAcceptStatus={() => {
                          // eslint-disable-next-line
                          {
                            actionCtx?.setIsModalOut(true);
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, approveOverview: true };
                            });
                            setIndexNo(false);
                          }
                        }}
                        onRejectStatus={() => {
                          // eslint-disable-next-line
                          {
                            actionCtx?.setIsModalOut(true);
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, rejectOverview: true };
                            });
                            setIndexNo(false);
                          }
                        }}
                        onExport={() => handleExport(chi)}
                        exportLoader={exportLoader}
                        onApprove={() => {
                          // eslint-disable-next-line
                          {
                            actionCtx?.setIsModalOut(true);
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, approve: true };
                            });
                            setIndexNo(false);
                          }
                        }}
                        onView={() => {
                          // eslint-disable-next-line
                          {
                            actionCtx?.setIsModalOut(true);
                            handleSingleCommentData(chi);
                            setShowModal((prev) => {
                              return { ...prev, comment: true };
                            });
                            setIndexNo(false);

                            // setTimeout(() => {
                            // }, 1000);
                          }
                        }}
                        onDelete={() => {
                          // eslint-disable-next-line
                          {
                            actionCtx?.setIsModalOut(true);
                            setSingleChild(chi);
                            setShowModal((prev) => {
                              return { ...prev, reject: true };
                            });
                            setIndexNo(false);
                          }
                        }}
                      />
                    );
                  }
                )}
              </>
            ) : (
              <EmployeeDraftProjection projectionText={`revenue`}>
                <ButtonBlue
                  onClick={() =>
                    history.push("/employee-dashboard-projection-revenue-new")
                  }
                  text={`Create Revenue`}
                >
                  <FaPlus style={{ marginRight: "15px" }} />
                </ButtonBlue>
              </EmployeeDraftProjection>
            )}
          </ActualReuseableTable>
        </div>
      </EmployeeCommonRevenueLayout>
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.pendingOverview}>
        <EmployeeRevenuePendingOverviewModal
          details={singleChild}
          setShowModal={setShowModal}
          updateText={
            user?.permissions?.includes("edit revenue projection") && "update"
          }
          onUpdate={() => {
            handleRevnueProjectionUpdate(singleChild);
          }}
          onRecall={() => {
            setModalView(true);
            setIndexNo(false);
            actionCtx?.setIsModalOut(true);
            setSingleChild(singleChild);
            setShowModal((prev) => {
              return { ...prev, recall: true, pendingOverview: false };
            });
          }}
          rejectText={
            user?.permissions?.includes("approve/reject revenue projection")
              ? true
              : null
          }
          onReject={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, reject: true, pendingOverview: false };
            });
          }}
          approveBtn={
            user?.permissions?.includes("approve/reject revenue projection")
              ? true
              : null
          }
          onComment={() => {
            // console.log(singleChild?.id);
            // actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, pendingOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          onApprove={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, approve: true, pendingOverview: false };
            });
          }}
          // approveBtn={false}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, pendingOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeRevApproveOverview
          details={singleChild}
          setShowModal={setShowModal}
          onComment={() => {
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            });
            setModalView(true);
            handleGetCommentData(singleChild?.id);
          }}
          // onApprove={() =>
          //   setShowModal((prev) => {
          //     return { ...prev, approve: true, approveOverview: false };
          //   })
          // }
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
            setModalView(false);
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.rejectOverview}>
        <EmployeeRevenueOverviewRejectModal
          details={singleChild}
          setShowModal={setShowModal}
          onComment={() => {
            // actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          // approveBtn={user?.permissions?.includes(
          //   "approve/reject revenue projection"
          // )}
          onApprove={() => {
            // actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, approve: true, rejectOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmpExpRevRejectModal
          details={singleChild}
          // data={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                reject: false,
                pendingOverview: modalView && true,
              };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
          }}
          // onRefreshCard={handleGetRevenueData}
          // onDispatch={() => {
          //   const obj = {
          //     org_id: user?.organizations[0]?.id,
          //     dept: dept?.id,
          //     status: selectStat?.value,
          //   };
          //   dispatch(getRevenueProjectionById(obj));
          // }}
          onDispatch={() => {
            (activeStat?.one && handleGetRevenueData("")) ||
              (activeStat?.two && handleGetRevenueData("approved")) ||
              (activeStat?.three && handleGetRevenueData("pending")) ||
              (activeStat?.four && handleGetRevenueData("rejected"));
          }}
        />
      </Modal>
      {/* modal end */}
      <Modal visible={showModal.approve}>
        <EmployeeProjectionApprovalModal
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                approve: false,
                pendingOverview: modalView && true,
              };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
            // handleGetRevenueData();
          }}
          // onRefreshCard={handleGetRevenueData}
          // onDispatch={() => {
          //   const obj = {
          //     org_id: user?.organizations[0]?.id,
          //     dept: dept?.id,
          //     status: selectStat?.value,
          //   };
          //   dispatch(getRevenueProjectionById(obj));
          // }}
          onDispatch={() => {
            (activeStat?.one && handleGetRevenueData("")) ||
              (activeStat?.two && handleGetRevenueData("approved")) ||
              (activeStat?.three && handleGetRevenueData("pending")) ||
              (activeStat?.four && handleGetRevenueData("rejected"));
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal end */}
      <Modal visible={showModal.comment}>
        <EmployeeProjectionComentModal
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                comment: false,
                pendingOverview:
                  singleChild?.status === "pending" && modalView && true,
                approveOverview:
                  singleChild?.status === "approved" && modalView && true,
                rejectOverview:
                  singleChild?.status === "rejected" && modalView && true,
              };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInRight" visible={showModal.approve_all}>
        <FinalApprovalModal
          allApprovalStatus={allApprovalStatus}
          // detail={expenditure_projection_single?.expenditure?.data[0]}
          allId={allApproveId}
          onFinish={() => {
            actionCtx?.setIsModalOut(false);
            handleGetRevenueData();
            setShowModal({
              pendingOverview: false,
              approveOverview: false,
              rejectOverview: false,
              approve: false,
              reject: false,
              comment: false,
              approve_all: false,
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve_all: false };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* modal start */}
      <Modal effect="fadeInRight" visible={showModal.recall}>
        <RevenueRecallModal
          details={singleChild}
          loading={recallLoader}
          onClose={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                recall: false,
                pendingOverview: modalView && true,
              };
            });
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
          }}
          onSubmit={() => {
            handleRecallProjection("", singleChild?.id);
          }}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeRevenueSubmitProjection;
