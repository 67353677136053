import React, { useState, useEffect } from "react";
// import ZojatechContext from "../store/ZojatechContext"
import { Route, Redirect, useLocation } from "react-router-dom";
import DashboardLayout from "../component/layout/dashboard/DashboardLayout";
// import DashboardLayout from "../component/dashboard/layout";
// import DashboardLayout from "../components/DashboardLayout";
// import {  useSelector } from "react-redux";

const PrivateRoute = ({
  component: Component,
  pageTitle,
  pageTitleSpan,
  goBack,
  ...rest
}) => {
  const location = useLocation();
  // const zojatechCtx = useContext(ZojatechContext);
  // const history = useHistory()
  const [pagetitle, setPageTitle] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [psmallTitle, setSmallTitle] = useState("");

  const login = JSON.parse(localStorage.getItem("login")) || false;
  const token = localStorage.getItem("token");

  useEffect(() => {
    setPageTitle(pageTitle);
    setSmallTitle(pageTitleSpan);
    // if(l)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, pagetitle, pageTitleSpan]);

  const urlSave = location.pathname.split("/")[1];

  return (
    <Route
      {...rest}
      render={(props) =>
        login && token ? (
          <DashboardLayout
            goBack={goBack}
            pageTitleSpan={pageTitleSpan}
            pageTitle={pageTitle}
          >
            <Component
              {...props}
              pageTitleSpan={pageTitleSpan}
              pageTitle={pagetitle}
            />
          </DashboardLayout>
        ) : location.pathname.includes("/dashboard-single-approve") ? (
          <Redirect to={`/login?${urlSave}`} />
        ) : (
          <Redirect to={`/login`} />
        )
      }
    />
  );
};

export default PrivateRoute;
