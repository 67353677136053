import React, { useState, useEffect, useContext } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import { useHistory } from "react-router-dom";
// import ReUseableTable from "../../../../tables/ReUseableTable";
import Modal from "react-awesome-modal";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import DeleteSharedModal from "./DeleteSharedModal";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import {
  getSharedServiceByFilter,
  getSharedServicePagination,
  getSharedServiceWithPagination,
} from "../../../../../redux/shared-service/shareService";
import useDebounce from "../../../../../helper/UseDebounce";

const SharedServiceMain = () => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const { user } = useSelector((state) => state.user);
  const [refreshTable, setRefreshTable] = useState(false);
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "Name", value: "name" },
    { label: "Subsidiary", value: "subsidiary" },
    { label: "Branch", value: "branch" },
    { label: "Status", value: "status" },
    { label: "Hod", value: "hod" },
    // { label: "Parent Company", value: "Parent Company" },
  ];
  const { loadingPage, sharedServices, meta, links } = useSelector(
    (state) => state.shared_service
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const tableHeadList = ["title", "Shared Apportion%"];
  const tableBodyList = [
    {
      title: "Special Subsidiary",
      shared_apportion: "50% / 50%",
    },
    {
      title: "Special Subsidiary",
      shared_apportion: "50% / 50%",
    },
    {
      title: "Special Subsidiary",
      shared_apportion: "50% / 50%",
    },
  ];

  useEffect(() => {
    if (!actionCtx?.activeOrg?.shared_service_activated) {
      history.push("/dashboard-administration-admin-settings");
    }
      // eslint-disable-next-line
  }, [actionCtx.setActiveOrgManual]);

  useEffect(() => {
   if(actionCtx?.activeOrg?.type === "single"){
    history.push("/dashboard-administration-admin-settings");
   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      // dispatch(getBranches(actionCtx?.activeOrg?.id));
      dispatch(getSharedServiceWithPagination(obj));
    }
      // eslint-disable-next-line
  }, [refreshTable, actionCtx?.activeOrg]);

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getSharedServicePagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    // console.log(obj);
    user?.organizations?.length > 0 &&
      dispatch(getSharedServiceWithPagination(obj));
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: "",
        org_id: actionCtx?.activeOrg?.id,
      };
      // console.log(obj);
      dispatch(getSharedServiceByFilter(obj));
    }

    return () => {
      isMount = false;
    };

      // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 2 of 6`}
        pageTitle={`Admin Settings`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            //   onClick={() =>
            //     history.push(
            //       "/dashboard-administration-admin-settings-subsidaries"
            //     )
            //   }
            className="active text"
          >
            Shared Service
          </p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>

        {/* table box start */}
        <ActualReuseableTable
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          perPageValue={actionCtx?.perPage?.value}
          setPerPageValue={(e) => {
            // setPerpageVal(e);
            handleSortPerPage(e.value);
          }}
          nextPage={links?.next}
          prevPage={links?.prev}
          onNextPage={() => handlePagination(links?.next)}
          onPrevPage={() => handlePagination(links?.prev)}
          fromPage={meta?.from || 1}
          toPage={meta?.to || sharedServices?.length}
          totalPage={meta?.total || sharedServices?.length}
          tableTitle={`Shared Service`}
          tableTitleSpan={`(${tableBodyList.length})`}
          addBtntext={`New Shared Service`}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          action
          onCreateNew={() =>
            history.push(
              "/dashboard-administration-admin-settings-add-subsidary-shared-service-add"
            )
          }
        >
          {sharedServices.map((chi, idx) => {
            const { title, apportions, id } = chi;
            const test = apportions?.map((chi) => chi?.budget_percentage);
            var strArr = test?.map(function (e) {
              return `${e}%`;
            });
            let text = strArr.toString();
            const val = text.split(",").map((_, i) => {
              if (i === 0) {
                return ` ${_} `;
              } else {
                return `/ ${_} `;
              }
            });

            return (
              <TableBodyRow
                loading={loadingPage}
                key={idx}
                one={title}
                two={val || "kj"}
                action
                rowNo={idx}
                indexNo={indexNo}
                setIndexNo={() => setIndexNo(idx)}
                viewText={`view`}
                onView={() => {
                  localStorage.setItem("shared-service-id", id);
                  history.push(
                    "/dashboard-administration-admin-settings-add-subsidary-shared-service-view"
                  );
                }}
                updateText={`update`}
                onUpdate={() => {
                  localStorage.setItem("shared-service-id", id);
                  history.push(
                    "/dashboard-administration-admin-settings-add-subsidary-shared-service-update"
                  );
                }}
                deleteText={`Delete`}
                onDelete={() => {
                  setSingleChild(chi);
                  setShowModal(true);
                }}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
      </div>
      {/* </DashboardLayout> */}

      {/* modal start  */}
      <Modal visible={showModal} effect="fadeInUp">
        <DeleteSharedModal
          detail={singleChild}
          onClose={() => {
            setShowModal(false);
            setSingleChild({});
          }}
          onFinish={() => {
            setSingleChild({});
            setRefreshTable(!refreshTable);
            setShowModal(false);
          }}
        ></DeleteSharedModal>
      </Modal>
      {/* modal end */}
    </>
  );
};

export default SharedServiceMain;
