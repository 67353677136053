import React, { useEffect, useState } from "react";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import "../../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
// import imgOne from "../../../../../../../assets/hod-two-img.svg";
// import cardviewImg from "../../../../../../../../assets/view-card-img.png";
import { FaEllipsisH, FaTimes } from "react-icons/fa";
// import ApprovalContent from "../../../actual/modals/contentHead";
// // import userImg from "../../../../../../../assets/hod-one-img.svg";
// import EmployeeOverviewTableBodyRow from "../../../home/modals/Reimbursement/overviewTableRow";
// import { formatNumber } from "../../../../../../../helper/Helpers";
// import { useSelector } from "react-redux";
// import userImg from "../../../../../../../assets/Unknown_person.jpeg";
// import { PendingContent } from "./pendingContent";
import { useRef } from "react";
import { getExpenditureProJectionActivityLog } from "../../../../../../../redux/projections/ExpenditureProjection";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import EmployeeOverviewTableBodyRow from "../../../home/modals/Reimbursement/overviewTableRow";
import { formatNumber } from "../../../../../../../helper/Helpers";
import userImg from "../../../../../../../assets/Unknown_person.jpeg";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import ApprovalContent from "../../../actual/modals/contentHead";
import {
  approvedIcon,
  rejectIcon,
} from "../../../../../../../component/pages/dashboard/projection/expense/activityLogIcons";
import { Dna } from "react-loader-spinner";
import {
  getAllExpenditureProjectionComment,
  getExportToPdf,
} from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { useContext } from "react";
import ActionContext from "../../../../../../../context/ActionContext";
import moment from "moment";

const EmpExpProjectionOverview = ({
  onCancel,
  onApprove,
  onReject,
  onComment,
  rejectText,
  onUpdate,
  update,
  details,
  approveBtn,
  onRecall,
  updateText,
  setShowModal,
}) => {
  const [showActions, setShowActions] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });
  const contentHeader = ["Months", "Units", "Total Value"];
  const contentHeaderTwo = ["Level", "Name", "Status"];
  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  const screenShotRef = useRef();
  const actionCtx = useContext(ActionContext);

  const [detailToShow, setDetailToShow] = useState("overview");
  const dispatch = useDispatch();
  const [logList, setLogList] = useState([]);
  const [loadingLog, setLoadingLog] = useState(false);
  const [comments, setComments] = useState([]);
  const handleGetAllCommentData = async (id) => {
    const data = await dispatch(getAllExpenditureProjectionComment(id));
    if (data?.payload?.success) {
      const commentVal =
        data?.payload?.data?.expenditure_projection_comments?.map(
          (chi) => chi?.activities[0]
        );
      setComments(commentVal);
    }
  };

  useEffect(() => {
    if (details?.id && comments) {
      handleGetActivityLogDetails();
    }
  }, [comments, details?.id]);

  const [activityLogData, setActivityLogData] = useState([]);
  const handleGetActivityLogDetails = () => {
    const data = [...comments, ...details?.activities];
    setActivityLogData(data);
  };
  const handleGetActivityLog = async (id) => {
    setLoadingLog(true);
    const obj = {
      id: id,
    };
    const data = await dispatch(getExpenditureProJectionActivityLog(obj));
    if (data?.payload?.success) {
      setLoadingLog(false);
      setLogList(data?.payload?.data);
    }
  };

  const [exportLoader, setExportLoader] = useState(false);
  const handleExport = async () => {
    const obj = {
      id: details?.id,
      type: "expenditure_projection",
      currency_id: actionCtx?.currencyValue?.id,
    };
    // console.log(obj?.id, "id");
    setExportLoader(true);
    const data = await dispatch(getExportToPdf(obj));
    if (data?.payload?.url) {
      setExportLoader(false);
      // actionCtx?.setIsModalOut(false);
      window.open(data?.payload?.url, "_blank").focus();
      // setShowActions(false);
      // setShowModal((prev) => {
      //   return {
      //     ...prev,
      //     pendingOverview: false,
      //   };
      // });
    } else {
      setExportLoader(false);
    }
  };

  return (
    <div className="overview-card-modal-wrap">
      <div className="top-box">
        <div className="title-box">
          <p
            onClick={() => {
              setDetailToShow("overview");
            }}
            className={`title ${detailToShow === "overview" && "title-active"}`}
          >
            Overview
          </p>
          <p
            onClick={() => {
              setDetailToShow("activity");
              handleGetAllCommentData(details?.id);
              handleGetActivityLog(details?.id);
            }}
            className={`title ${detailToShow === "activity" && "title-active"}`}
          >
            Activity Log
          </p>
        </div>
        {approveBtn && details?.current_approver && (
          <ButtonBlue
            onClick={() => {
              onApprove(setShowActions(false));
            }}
            text={`Approve`}
          />
        )}
        <div className="ellipse-box">
          <FaEllipsisH
            className="icon"
            onClick={() => setShowActions(!showActions)}
          />
          {/* action box start */}
          <div
            className={`action-box ${showActions && "action-box-active"}`}
            style={{
              gap: "2rem",
              height: "20rem",
              paddingTop: "1rem",
              zIndex: "1000",
            }}
          >
            {update && (
              <p onClick={onUpdate}>
                <span style={{ color: "#5B6871" }}>Update</span>
              </p>
            )}
            {rejectText && (
              <p onClick={onReject} style={{ color: "#CC0905" }}>
                <span>Reject</span>
              </p>
            )}
            <p onClick={onComment}>
              <span style={{ color: "#5B6871" }}>Comments</span>
            </p>
            <div>
              <p onClick={onRecall}>
                <span style={{ color: "#5B6871" }}>Recall</span>
              </p>
            </div>
            {updateText && (
              <div style={{ marginTop: ".6rem" }}>
                <p onClick={onUpdate}>
                  <span style={{ color: "#5B6871" }}>Update</span>
                </p>
              </div>
            )}
            {exportLoader ? (
              <div style={{ alignSelf: "center", display: "flex" }}>
                <Dna
                  visible={true}
                  height="25"
                  width="30"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <p style={{ marginTop: "0.5rem" }} onClick={handleExport}>
                <span>Export as PDF</span>
              </p>
            )}
          </div>
          {/* action box end */}
        </div>
        <div
          onClick={() => {
            onCancel(setShowActions(false));
            setDetailToShow("overview");
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      <div ref={screenShotRef} className="content-box">
        {/* overview start --------------- */}
        {detailToShow === "overview" && (
          <>
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("one")}
                className="title-drop-control-box"
              >
                <p className="title">Budget Owner Details</p>
                <div className="icon-box">
                  {!showBox.one && <FaAngleDown className="icon" />}
                  {showBox.one && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.one && (
                <div className="content-wrap">
                  <div className="three-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Chart of Account</p>
                      <p className="value">{`${details?.chart_of_account?.name}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Class</p>
                      <p className="value">{`${details?.chart_of_account?.class}`}</p>
                    </div>
                    {/* box start */}
                    <div className="box">
                      <p className="text">Units</p>
                      <p className="value">{details?.units || 0}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      {/* <p className="text"></p> */}
                      {/* <p className="value">Healthcare</p> */}
                    </div>
                    {/* box end */}
                    {/* box end */}
                  </div>
                  <div className="three-box">
                    <div className="box">
                      <p className="text">Unit Price</p>
                      <p className="value">{`${formatNumber(
                        String(details?.unit_price)
                      )}`}</p>
                    </div>
                    {/* box start */}
                    <div className="box">
                      <p className="text">Total Value</p>
                      <p className="value">{`${formatNumber(
                        String(details?.total_value)
                      )}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Department</p>
                      <p
                        className="value"
                        style={{ textTransform: "capitalize" }}
                      >{`${details?.department?.name}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    {/* <div className="box">
                      <p className="text">Total Income Projected</p>
                      <p className="value">200</p>
                    </div>
                    box end */}
                    {/* box start */}
                    {/* <div className="box">
                      <p className="text">Total Value</p>
                      <p className="value">20,000.00</p>
                    </div> */}
                    {/* box end */}
                  </div>

                  <div className="one-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Description</p>
                      <p className="value">{`${details?.description}`}</p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="two-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Date Created</p>
                      <p className="value">{`${details?.created_at}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Last Updated</p>
                      <p className="value">{`${details?.updated_at}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}

                    {/* box end */}
                  </div>

                  {/* created by wrap start */}
                  <div className="one-box">
                    <div className="box">
                      <p className="text">Created by</p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: ".3rem",
                        }}
                        className="img-text-box"
                      >
                        <figure
                          style={{ marginRight: "1rem" }}
                          className="img-box"
                        >
                          <img
                            style={{
                              borderRadius: "20%",
                              width: "4rem",
                              height: "4rem",
                            }}
                            src={user?.profile_picture || userImg}
                            alt=""
                            className="img"
                          />
                        </figure>
                        <div className="text-box">
                          <p className="value">
                            {" "}
                            {`${details?.createdBy?.full_name}`}
                          </p>
                          <p style={{ marginBottom: "unset" }} className="text">
                            {`${details?.createdBy?.email}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* created by wrap end */}
                </div>
              )}
            </>
            {/* title drop control box end */}

            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("two")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem" }}
              >
                <p className="title">Monthly Breakdown</p>
                <div className="icon-box">
                  {!showBox.two && <FaAngleDown className="icon" />}
                  {showBox.two && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.two && (
                <div>
                  <ApprovalContent
                    headerOne={contentHeader[0]}
                    headerTwo={contentHeader[1]}
                    headerThree={contentHeader[2]}
                  />
                  {details?.monthly_breakdown?.map((chi, idx) => {
                    const { month_name, value: total_value } = chi;
                    return (
                      <>
                        <EmployeeOverviewTableBodyRow
                          key={idx}
                          one={month_name}
                          two={
                            details?.entry_type === "Quantity"
                              ? formatNumber(String(total_value))
                              : formatNumber(String(details?.units / 12)) ||
                                details?.entry_type === "Amount"
                              ? formatNumber(
                                  String(total_value / details?.unit_price)
                                )
                              : formatNumber(String(details?.units / 12))
                          }
                          three={
                            details?.entry_type === "Quantity"
                              ? formatNumber(
                                  String(total_value * details?.unit_price)
                                )
                              : formatNumber(String(total_value))
                          }
                        />
                      </>
                    );
                  })}
                </div>
              )}
              {/* {showBox.two && <div className="content-wrap"></div>} */}
            </>
            {/* title drop control box end */}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("three")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem" }}
              >
                <p className="title">Approval Flow</p>
                <div className="icon-box">
                  {!showBox.three && <FaAngleDown className="icon" />}
                  {showBox.three && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.three && (
                <div className="content-wrap">
                  <ApprovalContent
                    headerOne={contentHeaderTwo[0]}
                    headerTwo={contentHeaderTwo[1]}
                    headerThree={contentHeaderTwo[2]}
                  />
                  {details?.approval_flow?.map((chi, idx) => {
                    const { step, user, status, approval_flow_id } = chi;
                    return (
                      <>
                        {approval_flow_id !== null ? (
                          <EmployeeOverviewTableBodyRow
                            key={idx}
                            one={step.toString().padStart(2, "0")}
                            userOne
                            userName={user?.first_name + " " + user?.last_name}
                            userEmail={user?.email}
                            userImg={user?.profile_picture || userImg}
                            status={
                              status === "approved"
                                ? "Approved"
                                : status === "pending"
                                ? "Pending"
                                : status === "rejected"
                                ? "Rejected"
                                : "pending"
                            }
                          />
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "3rem",
                              fontWeight: "600",
                              fontSize: "2rem",
                            }}
                          >
                            <p>NO APPROVAL FLOW FOUND</p>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
            </>
            {/* title drop control box end */}
          </>
        )}
        {/* overview end --------------- */}
        {/* activity log start -------- */}
        {detailToShow === "activity" && (
          <>
            <div
              onClick={() => {
                // console.log(logList);
              }}
              className="activity-log-wrap-box"
            >
              {/* box start */}
              {activityLogData?.map((chi, idx) => {
                const { causer, title, type, created_at, icon } = chi;
                if (logList?.length < 1) {
                  return (
                    <div key={idx} className="log-box">
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                      <div className="img-wrap">
                        <>
                          <Skeleton width={35} height={35} circle />
                        </>
                      </div>
                      <div className="dash"></div>
                      {/* detail wrap start */}
                      <div className="log-detail-wrap">
                        <>
                          <Skeleton width={100} height={15} />
                        </>
                        <>
                          <Skeleton width={170} height={12} />
                        </>
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      </div>
                      {/* detail wrap end */}
                    </div>
                  );
                }
                return (
                  <div key={idx} className="log-box">
                    {loadingLog ? (
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                    ) : (
                      <div className="num">
                        <span>
                          {/* {logList.length - idx < 10
                            ? `0${logList.length - idx}`
                            : logList.length - idx} */}
                          {`${idx + 1 < 10 ? "0" : ""}${idx + 1}`}
                        </span>
                      </div>
                    )}
                    <div className="img-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={35} height={35} circle />
                        </>
                      ) : (
                        <figure className="img-box">
                          <img src={icon} alt="" className="img" />
                        </figure>
                      )}
                    </div>
                    <div className="dash"></div>
                    {/* detail wrap start */}
                    <div className="log-detail-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={15} />
                        </>
                      ) : (
                        <p className="title">{title || "---"}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={170} height={12} />
                        </>
                      ) : (
                        <p className="initiator">{`${type || ""} by: ${
                          causer?.full_name
                        }`}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="date">
                          {moment(created_at).format("MM-DD-YYYY HH:mm:ss A")}
                        </p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="time">{created_at?.split(" ")[1]}</p>
                      )}
                    </div>
                    {/* detail wrap end */}
                  </div>
                );
              })}
              {/* box end */}
            </div>
          </>
        )}
        {/* activity log end ----------- */}
      </div>
      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default EmpExpProjectionOverview;
