import React, { useEffect, useState } from "react";
import { FaAngleRight } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import { useHistory } from "react-router-dom";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import ButtonBlue from "../../../../buttons/ButtonBlue";
import noteIcon from "../../../../../assets/note-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  // formatDate,
  formatNumberNaira,
  // formatNumberWithoutDecimal,
} from "../../../../../helper/Helpers";
import moment from "moment";
import ActionContext from "../../../../../context/ActionContext";
import { useContext } from "react";
import { getUserActivePlan } from "../../../../../redux/users/users";
import {
  autoRenewSubscriptionSubscription,
  downloadInvoiceSubscription,
} from "../../../../../redux/subscription/subscription";
import { Dna } from "react-loader-spinner";

const BillingMain = () => {
  const history = useHistory();
  const { plan_details } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.user);
  const { loadingDownload, loading, loadingAuto } = useSelector(
    (state) => state.subscription
  );
  const tableHeadList = ["Date", "Subscription Details", "Amount", "Status"];
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const [perPageval] = useState({
    label: "5",
    value: "5",
  });

  useEffect(() => {
    if (user?.role !== "creator administrator") {
      history.goBack();
    }
    // console.log(plan_details);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan_details]);

  const getDaysLeft = (param) => {
    const currentDate = moment(); // Current date and time
    const targetDate = moment(param); // Replace with your target date

    const daysLeft = targetDate.diff(currentDate, "days");
    return daysLeft;
  };

  const handlePagination = (url) => {
    const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    getActivePlanDetails(obj);
  };

  const handlePaginationNum = (num) => {
    // const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    getActivePlanDetails(obj);
  };
  const [autoRenew, setAutoRenew] = useState("0");
  const getActivePlanDetails = async (obj) => {
    let param;
    const localOrg = localStorage?.getItem("user");
    param =
      Object?.keys(obj)?.length > 0
        ? obj
        : {
            org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
            num: 1,
            cur_id: actionCtx?.currencyValue?.id || "",
            per_page: actionCtx?.perPage?.value,
          };
    dispatch(getUserActivePlan(param));
  };

  useEffect(() => {
    if (plan_details?.active_plan_details?.payment_info) {
      setAutoRenew(
        String(
          plan_details?.active_plan_details?.payment_info?.auto_renewal
        ) === "0"
          ? "0"
          : "1"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan_details]);

  const autoRenewalSub = async (param) => {
    const obj = {
      auto_renewal: param === "1" ? true : false,
      id: plan_details?.active_plan_details?.payment_info?.id,
    };
    dispatch(autoRenewSubscriptionSubscription(obj));
  };

  const [savedId, setSavedId] = useState("");
  const downloadInvoiceFunc = async (param, val) => {
    const obj = {
      id: param,
    };
    setSavedId(val);
    //  console.log(obj);
    //  return
    const data = await dispatch(downloadInvoiceSubscription(obj));
    if (data?.payload?.data?.success) {
      const url = data?.payload?.data?.data.url;
      downloadCsv(url);
    }
    //  console.log(data);
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 2 of 6`}
        pageTitle={`Admin Settings`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Billing & Subscription</p>
          {/* <FaAngleRight className="icon" />
            <p className="active text">Add Subsidiaries</p> */}
        </div>

        {/* cards box start */}
        <div className="card-box-billing-wrap">
          <div className="left-box">
            <div className="plan-upgrade-box">
              <div className="text-btn-box">
                <p className="text">Plan Details</p>
                <ButtonBlue
                  onClick={() => {
                    history.push("/dashboard-subscription-upgrade-plan");
                  }}
                  text={`Upgrade Plan`}
                />
              </div>
              <p className="plan">{`${
                plan_details?.active_plan_details?.subscription_plan_details
                  ?.title || ""
              } Plan`}</p>
              <p className="amount">
                {formatNumberNaira(
                  plan_details?.active_plan_details?.subscription_plan_details
                    ?.amount
                )}
              </p>
              <p className="time">{`${getDaysLeft(
                plan_details?.active_plan_details?.subscription_plan_details
                  ?.end_date
              )} days left in your free trial`}</p>
            </div>
          </div>
          {/* end of left box */}
          <div className="right-box">
            <div className="payment-info-wrap">
              <div className="top-box">
                <p className="text">Payment Info</p>
                <p className="auto">Auto renewal</p>
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
                <input
                  type="checkbox"
                  name=""
                  id="input-switch"
                  className="input-switch"
                  // value={autoRenew}
                  checked={autoRenew === "0" ? false : true}
                  onChange={(e) => {
                    setAutoRenew(autoRenew === "0" ? "1" : "0");
                    autoRenewalSub(autoRenew === "0" ? "1" : "0");
                  }}
                />
                {loadingAuto ? (
                  <>
                    {" "}
                    <div
                      style={{
                        alignSelf: "center",
                        marginTop: "0rem",
                        marginLeft: "auto",
                      }}
                    >
                      <Dna
                        visible={true}
                        height="50"
                        width="50"
                        ariaLabel="dna-loading"
                        // wrapperStyle={{color: "red", backgroundColor : "red"}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  </>
                ) : (
                  <label htmlFor="input-switch" className="label-switch">
                    <span></span>
                    <span></span>
                  </label>
                )}
              </div>
              <p className="name">
                {plan_details?.active_plan_details?.payment_info
                  ?.account_name || "---"}
              </p>
              <p style={{ textTransform: "capitalize" }} className="address">
                {`${plan_details?.active_plan_details?.payment_info?.card_type} ${plan_details?.active_plan_details?.payment_info?.channel}`}
              </p>
              <p className="num">
                {plan_details?.active_plan_details?.payment_info?.bank}
              </p>
              <p className="pan">{`${
                plan_details?.active_plan_details?.payment_info?.bin || ""
              } ***** ${
                plan_details?.active_plan_details?.payment_info?.last4 || ""
              }`}</p>
            </div>
          </div>
          {/* end of right box */}
        </div>
        {/* cards box end */}

        {/* table box start */}
        <ActualReuseableTable
          useNumPagination
          currentPage={plan_details?.plans?.meta?.current_page}
          totalTableLength={plan_details?.plans?.meta?.total}
          onNumPage={(e) => {
            handlePaginationNum(e);
          }}
          totalPage={plan_details?.plans?.meta?.total}
          fromPage={plan_details?.plans?.meta?.from}
          toPage={plan_details?.plans?.meta?.to}
          nextPage={plan_details?.plans?.meta?.next_page_url}
          onPrevPage={() =>
            handlePagination(plan_details?.plans?.meta?.prev_page_url)
          }
          onNextPage={() =>
            handlePagination(plan_details?.plans?.meta?.next_page_url)
          }
          prevPage={plan_details?.plans?.meta?.prev_page_url}
          tableTitle={`Billing`}
          perPageValue={perPageval}
          setPerPageValue={(e) => {
            // setPerpageVal(e);
            // handleSortPerPage(e.value);
          }}
          dontShowSearch
          dontShowBtn
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          five={tableHeadList[3]}
          six
          four="Payment channel"
        >
          {plan_details?.plans?.data?.map((chi, idx) => {
            const { plan_details } = chi;
            return (
              <TableBodyRow
                // setIndexNo={idx + 1}
                loading={loading}
                rowNo={idx}
                donstShowStatusCircle
                key={idx}
                one={moment(plan_details?.created_at)?.format("YYYY-MM-DD")}
                two={`${
                  plan_details?.subscription_plan_details?.title || "---"
                } / ${plan_details?.subscription_plan_details?.type || ""}`}
                three={formatNumberNaira(plan_details?.payment_details?.amount)}
                status={
                  plan_details?.status === "active" ? "active" : "inactive"
                }
                invoice
                four={plan_details?.payment_details?.payment_method || " "}
                loadingDownload={
                  loadingDownload &&
                  plan_details?.payment_details?.id === savedId
                }
                onDownLoadInvoice={() => {
                  // console.log(plan_details);
                  downloadInvoiceFunc(
                    plan_details?.id,
                    plan_details?.payment_details?.id
                  );
                }}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table box end */}
      </div>
      {/* </DashboardLayout> */}

      {/* modal start  */}
      {/* <Modal  visible={showModal.update} effect="fadeInUp">
        <UpdateCurrencyModal onCancel={() => setShowModal((prev) => {
            return{...prev, update: false}
        })}  />
      </Modal> */}
      {/* modal end */}
      {/* modal start  */}
      {/* <Modal  visible={showModal.add} effect="fadeInUp">
        <AddCurrencyModal onCancel={() => setShowModal((prev) => {
            return{...prev, add: false}
        })}  />
      </Modal> */}
      {/* modal end */}
    </>
  );
};

export default BillingMain;
