import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "...axios";
import axios from "../../../utils/axios";
import { toast } from "react-toastify";

export const getProcessInvite = createAsyncThunk(
  "employee/get-employee",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/email/process-invite/${formData}`
        // formData
      );
      // console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
       
        await thunkAPI.dispatch(setUserData(data?.data[0]));
        // toast.success(data.message, {
        //   theme: "colored",
        // });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);



export const activateAccount = createAsyncThunk(
  "admin/activate-employee",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/employee/email/process_invites",
        formData
      );
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);



export const emailInvites = createAsyncThunk(
  "admin/email-invites",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        "/admin/user/email_invites",
        formData
      );
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const employee_accept_invite = createSlice({
  name: "employee-accept-invite",
  initialState: {
    isAuth: false,
    loading: false,
    employee: []
    // loadingPage: false,
  },
  reducers: {
    setUserData: (state,action) => {
      state.isAuth = true;
      state.employee = action.payload;
    },
  },
  extraReducers: {
    [activateAccount.pending]: (state) => {
      state.loading = true;
    },
    [activateAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [activateAccount.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [emailInvites.pending]: (state) => {
      state.loading = true;
    },
    [emailInvites.fulfilled]: (state) => {
      state.loading = false;
    },
    [emailInvites.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

export const{setUserData} = employee_accept_invite.actions;

export default employee_accept_invite.reducer;
