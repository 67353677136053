import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import { encrypt, decrypt } from 'utils/encrypt';
import setAuthToken from "../../utils/auth";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getUsers = createAsyncThunk(
  "admin/get-users",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(`admin/users/organization/${formData}`);
      // console.log("country", data.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(addLabelValueFunc(data.data.users));
        await thunkAPI.dispatch(setUsersDrop(data.data.users));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getUsersWithPagination = createAsyncThunk(
  "admin/get-users",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `admin/users/organization/${formData?.org_id}?paginated&per_page=${
          formData?.per_page || "5"
        }`
      );
      // console.log("country", data.data.users);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(addLabelValueFunc(data.data.users));
        await thunkAPI.dispatch(setAllUsers(data.data.users));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);


export const getUserActivePlan = createAsyncThunk(
  "admin/get-user-active-plan",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `get-current-plan-by-user?organization_id=${formData?.org_id}`
      );
      // console.log("country----------------", data.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("country----------------", data.data);
        // console.log(addLabelValueFunc(data.data.users));
        await thunkAPI.dispatch(setPlanDetail(data.data));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getUsersPagination = createAsyncThunk(
  "admin/get-users",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(`${formData}`);
      // console.log("country", data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(addLabelValueFunc(data.data.users));
        await thunkAPI.dispatch(setAllUsers(data.data.users));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getDashboardDetails = createAsyncThunk(
  "admin/get-dasboard-details",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `admin/dashboard/home-menu?organization_id=${formData?.org_id}`
      );
      // const { data } = await axios.get(
      //   `admin/dashboard/home-menu?organization_id=${formData?.org_id || ""}&department=${formData?.dept_id || ""}&subsidiary_id=${formData?.sub_id || ""}&fiscal_year_id=${formData?.fis_id || ""}&branch_id=${formData?.bra_id || ""}&period=${formData?.per_id || ""}`
      // );
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        // console.log(addLabelValueFunc(data.data.users));
        await thunkAPI.dispatch(setDashboardDetail(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getDashboardDetailsBySort = createAsyncThunk(
  "admin/get-dasboard-details",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      // const { data } = await axios.get(
      //   `admin/dashboard/home-menu?organization_id=${formData?.org_id}` cur_id
      // );
      const { data } = await axios.get(
        `admin/dashboard/home-menu?department_id=${
          formData?.dept_id || ""
        }&organization_id=${formData?.org_id || ""}&subsidiary_id=${
          formData?.sub_id || ""
        }&fiscal_year_id=${formData?.fis_id || ""}&branch_id=${
          formData?.bra_id || ""
        }&period=${formData?.date || ""}&currency_conversion_id=${
          formData?.cur_id
        }`
      );
      // console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        // console.log(addLabelValueFunc(data.data.users));
        await thunkAPI.dispatch(setDashboardDetail(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getUser = createAsyncThunk(
  "admin/get-user",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(`admin/users/${formData}`);
      // console.log("country", data.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        // console.log(addLabelValueFunc(data.data.users));
        // await thunkAPI.dispatch(setUser(data.data.user));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const markAsRead = createAsyncThunk(
  "admin/mark-as-read",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(`/notification/read_at/${formData}`);
      // console.log("country", data.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        // console.log(addLabelValueFunc(data.data.users));
        // await thunkAPI.dispatch(setUser(data.data.user));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createUser = createAsyncThunk(
  "admin/get-users",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(`admin/user/create_employee`, formData);
      // console.log("country", data.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(addLabelValueFunc(data.data.users));
        // await thunkAPI.dispatch(setUsersDrop(data.data.users));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        console.log(err?.response?.data?.errors[0][0], "response");
        // toast.error(err?.response?.data?.errors.name[0], {
        toast.error(err?.response?.data?.errors[0][0], {
          theme: "colored",
        });
      }
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.email[0], {
          theme: "colored",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const TransferProfile = createAsyncThunk(
  "admin/transfer-profile",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(`transferProfile`, formData);
      console.log("country", data.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(addLabelValueFunc(data.data.users));
        // await thunkAPI.dispatch(setUsersDrop(data.data.users));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        console.log(err?.response?.data?.errors[0][0], "response");
        // toast.error(err?.response?.data?.errors.name[0], {
        toast.error(err?.response?.data?.errors[0][0], {
          theme: "colored",
        });
      }
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.email[0], {
          theme: "colored",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        console.log(err);
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getUsersByFilter = createAsyncThunk(
  "admin/get-users-by-filter",
  async (formData, thunkAPI) => {
    // const actionCtx = useContext(ActionContext);
    // console.log("yes");
    try {
      const { data } = await axios.get(
        `admin/users/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      //   console.log("currency", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setUsers(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const usersInfo = data.data.users;
        await thunkAPI.dispatch(setUsersSort(usersInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const geUsersPagination = createAsyncThunk(
  "admin/get-subsidiaries-pagination",
  async (formData, thunkAPI) => {
    // const actionCtx = useContext(ActionContext);
    // console.log("yes");
    try {
      const { data } = await axios.get(`${formData}`);
      //   console.log("currency", data);
      if (!data?.success) {
        // await thunkAPI.dispatch(setSubsidiaries(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const usersInfo = data.data.users;
        await thunkAPI.dispatch(setUsers(usersInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  "admin/delete-user",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/users/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateUser = createAsyncThunk(
  "admin/update-user",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/users/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

const addLabelValueFunc = (list) => {
  // console.log(list);
  const newList = list.map((chi) => {
    return { ...chi, label: chi?.full_name, value: chi?.id };
  });
  // console.log(newList);
  return newList;
};

export const users = createSlice({
  name: "users",
  initialState: {
    dashboard_details: {},
    plan_details: {},
    allUssers: [],
    usersDrop: [],
    users: [],
    singleUser: {},
    isAuth: false,
    loading: false,
    links: {},
    meta: {},
    // token: JSON.parse(localStorage.getItem('token')) ,
  },
  reducers: {
    setUsersDrop: (state, action) => {
      state.isAuth = true;
      state.usersDrop = addLabelValueFunc(action.payload);
    },
    setUsers: (state, action) => {
      state.isAuth = true;
      state.users = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setAllUsers: (state, action) => {
      state.isAuth = true;
      state.allUssers = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setUsersSort: (state, action) => {
      state.isAuth = true;
      state.users = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setUser: (state, action) => {
      state.isAuth = true;
      state.singleUser = action.payload;
    },
    setDashboardDetail: (state, action) => {
      state.isAuth = true;
      state.dashboard_details = action.payload;
    },
    setPlanDetail: (state, action) => {
      state.isAuth = true;
      state.plan_details = action.payload;
    },
  },
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state) => {
      state.loading = false;
    },
    [getUsers.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [createUser.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getUsersByFilter.pending]: (state) => {
      state.loading = true;
    },
    [getUsersByFilter.fulfilled]: (state) => {
      state.loading = false;
    },
    [getUsersByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getUsersWithPagination.pending]: (state) => {
      state.loading = true;
    },
    [getUsersWithPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getUsersWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getUsersPagination.pending]: (state) => {
      state.loading = true;
    },
    [getUsersPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [getUsersPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [TransferProfile.pending]: (state) => {
      state.loading = true;
    },
    [TransferProfile.fulfilled]: (state) => {
      state.loading = false;
    },
    [TransferProfile.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [geUsersPagination.pending]: (state) => {
      state.loading = true;
    },
    [geUsersPagination.fulfilled]: (state) => {
      state.loading = false;
    },
    [geUsersPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateUser.pending]: (state) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateUser.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteUser.pending]: (state) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteUser.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getDashboardDetails.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardDetails.fulfilled]: (state) => {
      state.loading = false;
    },
    [getDashboardDetails.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getDashboardDetailsBySort.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardDetailsBySort.fulfilled]: (state) => {
      state.loading = false;
    },
    [getDashboardDetailsBySort.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  login,
  setUsersDrop,
  setUser,
  setUsers,
  setUsersSort,
  setDashboardDetail,
  setAllUsers,
  setPlanDetail,
} = users.actions;

export default users.reducer;
