import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "../../../utils/axios";
import axios from "../../utils/axios"
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getSubscriptions = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get("/subscription-plan", formData);
      if (!data?.success) {
        toast.error(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });
        const subInfo = data?.data?.data;
        await thunkAPI.dispatch(setSubscriptions(subInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getSubscription = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(`/subscription-plan/${formData}`);
      if (!data?.success) {
        toast.error(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const downloadInvoiceSubscription = createAsyncThunk(
  "download-invoice-subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.get(`admin/download-subscribed-plan/${formData?.id}`);
      // console.log(data);
      if (!data?.success) {
        toast.error(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.success === false) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const upgradeSubscription = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(`/subscription/payment`, formData);
      if (!data?.status) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      console.log(data, "data-upgrade");
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const autoRenewSubscriptionSubscription = createAsyncThunk(
  "auto-subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.patch(`/admin/auto-renewal/${formData?.id}`, formData);
      if (data?.data?.success === false) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data, "data-upgrade");
      if (data?.data?.success === true) {
        toast.success(data.data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCountries = createAsyncThunk(
  "admin/get-countries",
  async (formData, thunkAPI) => {
    try {
      // const token = localStorage.getItem("token");
      // await setAuthToken(token);
      const { data } = await axios.get("/dropdowns/countries");
      // console.log("-------country------", data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data.success) {
        // // console.log(data);
        await thunkAPI.dispatch(setCountries(data.data));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const bookADemoWithUs = createAsyncThunk(
  "subscription-plan",
  async (formData, thunkAPI) => {
    try {
      const data = await axios.post(`/booking`, formData);
      if (!data?.status) {
        toast.error(data?.payload?.response?.data?.message, {
          theme: "colored",
        });
        // console.log(data?.payload?.response?.data?.message, "message");
        return thunkAPI.rejectWithValue(data);
      }
      // console.log(data, "data-upgrade");
      if (data?.status === 200) {
        toast.success(data?.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      // // console.log(err);
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getCurrenciesTwo = createAsyncThunk(
  "admin/get-currencies",
  async (formData, thunkAPI) => {
    try {
      // const token = localStorage.getItem("token");
      // await setAuthToken(token);
      const { data } = await axios.get("/convertCurrency");
      // console.log("-------country------", data);
      if (!data.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data.success) {
        // // console.log(data);
        await thunkAPI.dispatch(setCurrencies(data.data));
      }
    } catch (err) {
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

const addLabelValueFunc = (list) => {
  // // console.log(list);
  const newList = list.map((chi) => {
    return {
      ...chi,
      label:
        chi?.name === "NGN"
          ? "Naira"
          : chi?.name === "EUR"
          ? "Euro"
          : chi?.name === "USD"
          ? "Dollar"
          : chi?.name,
      value: chi?.id || chi?.name,
    };
  });
  // // console.log(newList);
  return newList;
};

export const subscription = createSlice({
  name: "subscription",
  initialState: {
    subscriptions: [],
    isAuth: false,
    loading: false,
    loadingDownload: false,
    loadingAuto: false,
  },
  reducers: {
    setSubscriptions: (state, action) => {
      //   state.isAuth = true;
      state.subscriptions = action.payload;
    },

    setCountries: (state, action) => {
      state.countries = addLabelValueFunc(action.payload);
    },
    setCurrencies: (state, action) => {
      state.currencies = addLabelValueFunc(action.payload);
    },
  },
  extraReducers: {
    [getSubscriptions.pending]: (state) => {
      state.loading = true;
    },
    [getSubscriptions.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscriptions.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [autoRenewSubscriptionSubscription.pending]: (state) => {
      state.loadingAuto = true;
    },
    [autoRenewSubscriptionSubscription.fulfilled]: (state) => {
      state.loadingAuto = false;
    },
    [autoRenewSubscriptionSubscription.rejected]: (state) => {
      state.loadingAuto = false;
      state = null;
    },
    [downloadInvoiceSubscription.pending]: (state) => {
      state.loadingDownload = true;
    },
    [downloadInvoiceSubscription.fulfilled]: (state) => {
      state.loadingDownload = false;
    },
    [downloadInvoiceSubscription.rejected]: (state) => {
      state.loadingDownload = false;
      state = null;
    },
    [getSubscription.pending]: (state) => {
      state.loading = true;
    },
    [getSubscription.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubscription.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [upgradeSubscription.pending]: (state) => {
      state.loading = true;
    },
    [upgradeSubscription.fulfilled]: (state) => {
      state.loading = false;
    },
    [upgradeSubscription.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
    [bookADemoWithUs.pending]: (state) => {
      state.loading = true;
    },
    [bookADemoWithUs.fulfilled]: (state) => {
      state.loading = false;
    },
    [bookADemoWithUs.rejected]: (state) => {
      state.loading = false;
      state = null;
    },
  },
});

export const { setSubscriptions, setCountries, setCurrencies } = subscription.actions;

export default subscription.reducer;
