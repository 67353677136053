import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getApprovalClass = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`admin/approvalclass`);
      //   console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const branchInfo = data.data.approval_class;
        await thunkAPI.dispatch(setApprovalClass(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovalClassById = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`/admin/approvals/${formData?.id}`);
        // console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        // const branchInfo = data.data.approval_class;
        // await thunkAPI.dispatch(setApprovalClass(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovalType = createAsyncThunk(
  "admin/get-approval-type",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`admin/approvalType`);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovalType(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);


export const getApprovalByClass = createAsyncThunk(
  "admin/get-approval-by-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`admin/approvals/organization/${formData?.org_id}/${formData?.class_id}`);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.approvals);
        // const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovals(data?.data?.approvals));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesWithPagination = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/branches/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesByFilter = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/branches/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setApprovals(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesPagination = createAsyncThunk(
  "admin/get-branches-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranch = createAsyncThunk(
  "admin/get-branch",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/branches/${formData}`);
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesBySubsidiary = createAsyncThunk(
  "admin/get-branch",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(
        `/admin/branches/subsidiary/${formData}`
      );
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // const branchInfo = data.data.branches;
        // console.log(branchInfo);
        // await thunkAPI.dispatch(setBranchSubDrop(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const setUpApprovalFlow = createAsyncThunk(
  "admin/create-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { org_id } = formData;
      const data = await axios.post(
        `/admin/organizations/${org_id}/approval-setup`,
        formData
      );
      // console.log('set-up-org', data);
      if (data?.data?.success === false) {
        toast.error(data.data.message, {
          theme: "colored",
        });
        // console.log(data);
        // return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.success === true) {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // console.log(data);
        return data;
      }
    } catch (err) {
      // console.log(err);
      if(err.response?.status === 422){
         toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);


export const updateUpApprovalFlow = createAsyncThunk(
  "admin/update-approval-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      // const { org_id } = formData;
      const data = await axios.post(
        `/admin/update-approval/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (data?.data?.success === false) {
        toast.error(data.data.message, {
          theme: "colored",
        });
        // console.log(data);
        // return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.success === true) {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // console.log(data);
        return data;
      }
    } catch (err) {
      // console.log(err);
      if(err.response?.status === 422){
         toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        
        if(err?.response?.status === 422){
          toast.error(err?.response?.data?.errors.name[0], {
            theme: "colored",
          });
        }
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "admin/delete-delete",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/branches/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateBranch = createAsyncThunk(
  "admin/update-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/branches/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const approvals = createSlice({
  name: "approvals",
  initialState: {
    approvals: [],
    approvalClass: [],
    approvalType: [],
    branches: [],
    links: {},
    meta: {},
    branch: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setBranches: (state, action) => {
      state.isAuth = true;
      state.branches = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setApprovals: (state, action) => {
      state.isAuth = true;
      state.approvals = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setApprovalClass: (state, action) => {
      state.isAuth = true;
      state.approvalClass = action.payload;
    },
    setApprovalType: (state, action) => {
      state.isAuth = true;
      state.approvalType = action.payload;
    },
    setBranch: (state, action) => {
      state.isAuth = true;
      state.branch = action.payload;
    },
  },
  extraReducers: {
    [setUpApprovalFlow.pending]: (state) => {
      state.loading = true;
    },
    [setUpApprovalFlow.fulfilled]: (state) => {
      state.loading = false;
    },
    [setUpApprovalFlow.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateBranch.pending]: (state) => {
      state.loading = true;
    },
    [updateBranch.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateBranch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovalClass.pending]: (state) => {
      state.loading = true;
    },
    [getApprovalClass.fulfilled]: (state) => {
      state.loading = false;
    },
    [getApprovalClass.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [setUpApprovalFlow.pending]: (state) => {
      state.loading = true;
    },
    [setUpApprovalFlow.fulfilled]: (state) => {
      state.loading = false;
    },
    [setUpApprovalFlow.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateUpApprovalFlow.pending]: (state) => {
      state.loading = true;
    },
    [updateUpApprovalFlow.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateUpApprovalFlow.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesBySubsidiary.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesBySubsidiary.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesBySubsidiary.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovalByClass.pending]: (state) => {
      state.loading = true;
    },
    [getApprovalByClass.fulfilled]: (state) => {
      state.loading = false;
    },
    [getApprovalByClass.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBranch,
  setBranches,
  setApprovalClass,
  setApprovals,
  setApprovalType,
} = approvals.actions;

export default approvals.reducer;
