import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
// import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { addLabelValueFunc, formatDate } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getAdminExpenseProjectionReport = createAsyncThunk(
    "admin/get-expense-projection-report",
    async (formData, thunkAPI) => {
        try {
            const { data } = await axios.get(
                `/admin/report/details?report_type=${formData?.report_type || ""
                }&organization_id=${formData?.org_id || ""}&currency_conversion_id=${formData?.cur_id || ""}`
            );
            if (!data?.success) {
                return thunkAPI.rejectWithValue(data);
            }
            if (data?.success) {
                // console.log("data-check", data?.data?.consolidated_budget);
                await thunkAPI.dispatch(
                    setAdminExpenseProjectionReports(data?.data?.expense_projection)
                );
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                theme: "colored",
            });
            if (err) {
                return thunkAPI.rejectWithValue(err);
            }
        }
    }
);

export const getAdminExpenseProjectionReportPagination = createAsyncThunk(
    "admin/get-expense-projection-report-pagination",
    async (formData, thunkAPI) => {
        try {
            const { data } = await axios.get(
                `/admin/report/details?report_type=${formData?.report_type || ""
                }&organization_id=${formData?.org_id || ""}&page=${formData?.page}&currency_conversion_id=${formData?.cur_id || ""}&status=${formData?.status || ""}`
            );
            if (!data?.success) {
                return thunkAPI.rejectWithValue(data);
            }
            // /api/employee/reports/consolidated-report?page=2
            if (data?.success) {
                // console.log("data-check", data?.data?.consolidated_budget);
                await thunkAPI.dispatch(
                    setAdminExpenseProjectionReports(data?.data?.expense_projection)
                );
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            if (err.response.status === 500) {
                toast.error("Bad Network, check your network settings", {
                    theme: "colored",
                });
            }
            if (err) {
                return thunkAPI.rejectWithValue(err);
            }
        }
    }
);

export const admin_expense_projection_report = createSlice({
    name: "admin_expense_projection_report",
    initialState: {
        adminExpenseProjectionReports: [],
        links: {},
        meta: {},
        isAuth: false,
        loading: false,
        loadingTwo: false,
        deleteLoading: false,
        loadingPage: false,
    },

    reducers: {
        setAdminExpenseProjectionReports: (state, action) => {
            state.isAuth = true;
            state.adminExpenseProjectionReports = action.payload;
            state.links = action.payload.links;
            state.meta = action.payload.meta;
        },
    },
    extraReducers: {
        [getAdminExpenseProjectionReport.pending]: (state) => {
            state.loadingPage = true;
        },
        [getAdminExpenseProjectionReport.fulfilled]: (state) => {
            state.loadingPage = false;
        },
        [getAdminExpenseProjectionReport.rejected]: (state) => {
            // localStorage.removeItem('token');
            state.loadingPage = false;
            state.isAuth = false;
            state = null;
        },
        [getAdminExpenseProjectionReportPagination.pending]: (state) => {
            state.loadingPage = true;
        },
        [getAdminExpenseProjectionReportPagination.fulfilled]: (state) => {
            state.loadingPage = false;
        },
        [getAdminExpenseProjectionReportPagination.rejected]: (state) => {
            // localStorage.removeItem('token');
            state.loadingPage = false;
            state.isAuth = false;
            state = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setAdminExpenseProjectionReports } = admin_expense_projection_report.actions;

export default admin_expense_projection_report.reducer;
