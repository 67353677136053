import React, { useEffect } from "react";
import { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import Select from "react-select";
import "../../../../../../../employee-folder/styles/dashboard/reports/consolidatedBudget/index.scss";
// import { FiArrowUp } from "react-icons/fi";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
// import EmpTableBodyRow from "../../../../../layout/dashboard/EmpTableBodyRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeProjectionDownload,
  getUserDepartment,
} from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import {
  getRevenueProjectionsReport,
  getRevenueProjectionsReportPagination,
} from "../../../../../../../redux/employee/report/revenue-projection-report";
import {
  addAllToList,
  checkCurrencyFormat,
  formatNumber,
  perPageOptions,
  reactSelectStyle,
  reactSelectStyleTable,
} from "../../../../../../../helper/Helpers";
// import { getRevenueProjectionReport } from "../../../../../../../redux/employee/report/general-report";
// import Flatpickr from "react-flatpickr";
// import moment from "moment";
// import { getSubsidiaries } from "../../../../../../../redux/subsidiary/subsidiary";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import TableBodyRow from "../../../../../../../component/tables/TableBodyRow";
// import { CSVLink } from "react-csv";
import { Dna } from "react-loader-spinner";
// import { useReactToPrint } from "react-to-print";
import { useContext } from "react";
import ActionContext from "../../../../../../../context/ActionContext";
import { getDepartments } from "../../../../../../../redux/department/deparment";

const EmployeeRevenueProjectionReportIndex = () => {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  // const dispatch = useDispatch()
  // const { loading } = useSelector((state) => state.general_report);
  const [details, setDetails] = useState({
    subsidiary_id: "",
    department_id: "",
    status: "",
    // subsidiary_id: "",
    date: "",
    user_id: "",
  });
  const actionCtx = useContext(ActionContext);

  // const { user } = useSelector((state) => state.user);
  const { userDepartmentDrop, loading } = useSelector(
    (state) => state.expenditure_projection
  );
  const { revenueProjectionReports, loadingPage } = useSelector(
    (state) => state.revenue_projection_report
  );
  const { departmentDrop } = useSelector((state) => state.department);

  // const [startDate, setStartDate] = useState("");
  // const { subsidiariesDrop } = useSelector((state) => state.subsidiary);

  const dispatch = useDispatch();
  // const [singleDept, setSingleDept] = useState({});

  // console.log(userDepartmentDrop, "userDEp")
  // console.log(userDepartmentDrop, "user");

  const [userDrop, setUserDrop] = useState({});
  // const [singleUserDrop, setSingleUserDrop] = useState({});
  const [loader, setLoader] = useState({
    one: false,
    two: false,
    three: false,
  });

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    dispatch(getDepartments(user?.organizations[0]?.id));
    // handleGetRevenueReport();
    //eslint-disable-next-line
  }, [
    //eslint-disable-next-line
    user?.organizations[0]?.id,
  ]);

  useEffect(() => {
    if (
      user?.organizations[0]?.id ||
      actionCtx?.perPage ||
      actionCtx?.currencyValue
    ) {
      handleGetRevenueReport();
      handleGetUser();
    }
  }, [
    user?.organizations[0]?.id,
    actionCtx?.perPage,
    actionCtx?.currencyValue,
    details,
  ]);

  const handleGetRevenueReport = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      user_id: details?.user_id?.value,
      status: details?.status?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    // setLoader((prev) => {
    //   return { ...prev, three: param === "" && true };
    // });
    const data = await dispatch(getRevenueProjectionsReport(obj));
    if (data?.payload?.success) {
      // setDetails((prev) => {
      //   return { ...prev, department_id: "" };
      // });
      setLoader((prev) => {
        return { ...prev, three: false };
      });
    } else
      setLoader((prev) => {
        return { ...prev, three: false };
      });
  };

  // console.log(userDepartmentDrop, "user department drop");

  const tableHeadList = [
    "Product",
    "Vertical",
    `Unit`,
    `GP Value (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Total GP (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    "Status",
  ];

  const statusDrop = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  // pagination
  const handlePagination = async (param) => {
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      page: linkTwo,
      user_id: details?.user_id?.value,
      status: details?.status?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getRevenueProjectionsReportPagination(obj));
  };

  const handlePaginationNum = async (num) => {
    // const splitLink = param.split("?page=");
    // const linkTwo = splitLink[1];
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      page: num,
      user_id: details?.user_id?.value,
      status: details?.status?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getRevenueProjectionsReportPagination(obj));
  };

  const handleGetUser = () => {
    const userDropVal = details?.department_id?.users?.map((chi) => {
      return {
        label: chi?.full_name,
        value: chi?.id,
      };
    });
    setUserDrop(userDropVal);
  };

  const handleFileDownload = async (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      type: "revenue_projection",
      format: param,
      user_id: user?.id,
      currency: actionCtx?.currencyValue?.id || "",
      status: details?.status?.value,
    };
    setLoader((prev) => {
      return {
        ...prev,
        one: param === "excel" && true,
        two: param === "pdf" && true,
      };
    });
    const data = await dispatch(getEmployeeProjectionDownload(obj));
    if (data?.payload?.status === 200) {
      window.open(data?.payload?.data?.url, "_blank").focus();
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    } else {
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    }
  };

  const handleRevenueProjectionReport = (id) => {
    // const id = details?.department_id?.value;
    localStorage.setItem("revenue-projection-report", id);
  };

  return (
    <>
      {/* <EmployeeDashboardLayout
        goBack
        pageTitle={`View Revenue Projection Report`}
        organization
      > */}
      <div className="admin-org-settings-wrap">
        {/* left box start */}
        <div
          className="title-btn-bo"
          style={{ borderBottom: "1px solid var(--border-color)" }}
        >
          <div className="emp-consolidate-wra">
            {/* <div className="consolidate-left-box"> */}
            <div className="subtitle-box ">
              <p className="text">Report</p>
              <FaAngleRight className="icon" />
              <p
                className="text"
                onClick={() =>
                  history.push("/employee-dashboard-general-report")
                }
              >
                General Reports
              </p>
              <FaAngleRight className="icon" />
              <p className="active text">Revenue Projection</p>
            </div>
            {/* </div> */}
            {/* left box end */}

            {/* select wrap start */}

            <div
              className="consolidate-right-box"
              style={{ marginTop: "-5rem" }}
            >
              <div className="filter-label">
                <label className="label">Filter By</label>
              </div>
              <div style={{ zIndex: "1000" }}>
                <Select
                  // isSearchable={true}
                  // isMulti
                  styles={reactSelectStyleTable}
                  isLoading={loading}
                  placeholder="Status"
                  className={`select-wrap ${
                    details?.status && "select-wrap-active"
                  }`}
                  value={details?.status}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, status: e };
                    });
                  }}
                  options={statusDrop}
                />
              </div>
              {/* <div className="form-group">
                <Flatpickr
                  id="date"
                  name="date"
                  // multiple
                  options={range}
                  className={`input ${startDate && "input-active"}`}
                  autoComplete="off"
                  value={startDate}
                  onClose={(date) => {
                    setStartDate(
                      moment(date[0]).format("YYYY-MM-DD") +
                        " to " +
                        moment(date[1]).format("YYYY-MM-DD")
                    );
                    // console.log(, "date-date");
                  }}
                  // onClose={handleDateChange}
                  placeholder="YYYY/MM/DD"
                />
              </div> */}
              <div style={{ zIndex: "1000" }}>
                <Select
                  // isSearchable={true}
                  // isMulti

                  placeholder="Department"
                  className={`select-wrap ${
                    details?.department_id && "select-wrap-active"
                  }`}
                  value={details?.department_id}
                  styles={reactSelectStyle}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, department_id: e };
                    });
                    // setSingleDept((prev) => {
                    //   return { ...prev, department: e };
                    // });
                  }}
                  options={addAllToList(
                    user?.user_type === "ADMIN"
                      ? departmentDrop
                      : userDepartmentDrop,
                    "Departments"
                  )}
                />
              </div>
              <div style={{ zIndex: "1000" }}>
                <Select
                  // isSearchable={true}
                  // isMulti
                  isLoading={loading}
                  styles={reactSelectStyle}
                  placeholder="User"
                  className={`select-wrap ${
                    details?.user_id && "select-wrap-active"
                  }`}
                  value={details?.user_id}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, user_id: e };
                    });
                    // console.log(e, "e");
                  }}
                  options={userDrop}
                />
              </div>
            </div>
          </div>
        </div>
        {/* select wrap end */}
        {/* sub-title start */}
        <div className="title-btn-bo">
          {/* text title box start */}
          <div className=" sub-right-box">
            <div className="emp-sub-report">
              <p className="title">
                {`${details?.department_id?.label || "All"} Revenue Projection`}
              </p>
              <Select
                className="per-page-select"
                styles={reactSelectStyleTable}
                value={actionCtx?.perPage}
                onChange={(e) => {
                  actionCtx?.setPerpage(e);
                }}
                options={perPageOptions}
              />
            </div>
            <div className="emp-btn-wrap">
              {/* <button className="emp-export-btn">
                <FiArrowUp className="export-icon" />
                Export
              </button> */}
              {loader?.one ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleFileDownload("excel");
                  }}
                  text={`Export`}
                />
              )}
              {loader?.two ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleFileDownload("pdf");
                  }}
                  text={`Print`}
                />
              )}

              {/* {loader?.three ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleGetRevenueReport("");
                  }}
                  text={`All Department`}
                />
              )} */}
            </div>
            {/* sub-title end */}
          </div>
        </div>
        {/* table start */}
        {/* <ComponentToPrint ref={componentRef} /> */}
        <ActualReuseableTable
          useNumPagination
          currentPage={revenueProjectionReports?.current_page}
          totalTableLength={revenueProjectionReports?.total}
          onNumPage={(e) => {
            handlePaginationNum(e);
          }}
          dontShowPagination={revenueProjectionReports?.data?.length < 1}
          fromPage={revenueProjectionReports?.from}
          toPage={revenueProjectionReports?.to}
          totalPage={revenueProjectionReports?.total}
          nextPage={revenueProjectionReports?.next_page_url}
          prevPage={revenueProjectionReports?.prev_page_url}
          onNextPage={() =>
            handlePagination(revenueProjectionReports?.next_page_url)
          }
          onPrevPage={() =>
            handlePagination(revenueProjectionReports?.prev_page_url)
          }
          dontShowBnSearch={true}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          six={tableHeadList[5]}
        >
          {revenueProjectionReports?.data?.map((chi, idx) => {
            const { product, vertical, unit, gp_value, total_gp, status, id } =
              chi;
            return (
              <TableBodyRow
                key={idx}
                one={product}
                two={vertical}
                three={unit || 0}
                four={formatNumber(String(gp_value || 0))}
                five={formatNumber(String(total_gp || 0))}
                loading={loadingPage}
                onNextPage={() => {
                  handleRevenueProjectionReport(details?.department_id?.id);
                  history.push(
                    "/employee-dashboard-projection-revenue-submitted"
                  );
                }}
                rowNo={idx}
                progressType={status}
              />
            );
          })}
        </ActualReuseableTable>
      </div>
      {/* table end */}
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default EmployeeRevenueProjectionReportIndex;
