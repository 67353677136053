import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SetupLayout from "../../layout/dashboard/SetupLayout";
import fileIcon from "../../../assets/file-icon.svg";
import noteIcon from "../../../assets/note-icon.svg";
import moment from "moment";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/airbnb.css";
import Modal from "react-awesome-modal";
import AccountSetUpSuccessModal from "./modal/AccountSetupSuccessModal";
import ButtonBlue from "../../buttons/ButtonBlue";
import Select from "react-select";
import { ColorPicker, useColor } from "react-color-palette";
import { NumericFormat, PatternFormat } from "react-number-format";
import "react-color-palette/lib/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountries,
  getUserDetails,
  setUpOrganization,
} from "../../../redux/user";
import { Dna } from "react-loader-spinner";
import { getCurriencies } from "../../../redux/currency/currency";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../helper/Helpers";

const SetupOrganizationSetting = () => {
  const [color, setColor] = useColor("hex", "#004BFF");
  const dispatch = useDispatch();
  const history = useHistory();
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { loading, countries } = useSelector((state) => state.user);
  const { currencyDrop } = useSelector((state) => state.currency);
  const [details, setDetails] = useState({
    name: "",
    country: "",
    address: "",
    contact_line: "",
    logo: "",
    brand_color: "#004BFF",
    fiscal_year_base_currency: "",
    currency_rate: "",
    other_currency_id: "",
    fiscal_year_title: "",
    type: localStorage.getItem("organization_type_id") || "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append(
      "fiscal_year_base_currency",
      details?.fiscal_year_base_currency.value
    );
    formData.append(
      "base_currency_id",
      details?.fiscal_year_base_currency.value
    );
    formData.append("country", details.country.label);
    formData.append("other_currency_id", details.other_currency_id.value);
    formData.append("fiscal_year_end_date", endDate);
    formData.append("fiscal_year_start_date", startDate);
    formData.append("name", details.name);
    formData.append("address", details.address);
    formData.append(
      "contact_line",
      formatNumberWithoutComma(details.contact_line)
    );
    formData.append("logo", details.logo);
    formData.append("brand_color", details.brand_color);
    formData.append("type", details.type);
    formData.append("fiscal_year_title", details.fiscal_year_title);
    formData.append(
      "currency_rate",
      formatNumberWithoutComma(details.currency_rate)
    );
    // let dataView;
    // for (const [key, value] of formData) {
    //    console.log(`${key}: ${value}\n`)
    //    dataView = value
    //    console.log(dataView);
    // }
    // return;
    // const fileSize = details.logo[0].size / 1024 / 1024; // in MiB
    // if (fileSize > 2) {
    //   alert('File size exceeds 2 MiB');
    //   // $(file).val(''); //for clearing with Jquery
    // } else {
    //   // Proceed further
    //   alert('yes');
    // }
    // return;
    const data = await dispatch(setUpOrganization(formData));
    console.log(data);
    if (data?.payload?.data?.success === true) {
      // console.log(data.payload);
      setDetails({
        name: "",
        country: "",
        address: "",
        contact_line: "",
        logo: "",
        brand_color: "#004BFF",
        fiscal_year_base_currency: "",
        currency_rate: "",
        other_currency_id: "",
        fiscal_year_title: "",
        type: localStorage.getItem("organization_type_id") || "",
      });
      setstartDate("");
      setendDate("");
      dispatch(getUserDetails());
      setShowModal(true);
      // dispatch(getOrganizations())
    }
  };

  // const selectListOne = [
  //   { label: "Naira", value: "naira" },
  //   { label: "USD", value: "usd" },
  //   { label: "Euro", value: "euro" },
  // ];

  const selectListCountry = [
    { label: "Nigeria", value: "nigeria" },
    { label: "Egypt", value: "egypt" },
    { label: "Kenya", value: "kenya" },
  ];

  useEffect(() => {
    const orgId = localStorage.getItem("organization_type_id") || "";
    if (!orgId) {
      history.push("/setup-org");
    }

    dispatch(getCountries());
    dispatch(getCurriencies());
    // dispatch(getCurrencies())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SetupLayout>
        <div>
          {/* wrap start */}
          <div className="wrapper">
            <p className="box-title">
              {" "}
              1. {details?.type !== "single" && "Parent"} Company Information
            </p>
            <div className="form-wrap">
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Company" className="label">
                  Company <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Company"
                  className={`input ${details.name && "input-active"}`}
                  id="Company"
                  name="name"
                  onChange={handleChange}
                  value={details.name}
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Country" className="label">
                  Country <span>*</span>
                </label>
                <Select
                  styles={reactSelectStyle}
                  className="select-wrap"
                  placeholder="Country"
                  value={details.country}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, country: e };
                    });
                  }}
                  options={countries || selectListCountry}
                />
                {/* <select
                  name="Country"
                  onChange={handleChange}
                  value={details.country}
                  id=""
                  className={`input ${details.country && "input-active"}`}
                >
                  <option value="Naira">Nigeria</option>
                </select> */}
                {/* <input
                  name="comapny"
                  onChange={handleChange}
                  value={details.country}
                  type="text"
                  className="input"
                  id="Company"
                /> */}
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Company address" className="label">
                  Company Address <span>*</span>
                </label>
                <input
                  name="address"
                  onChange={handleChange}
                  value={details.address}
                  type="text"
                  placeholder="Address"
                  className={`input ${details.address && "input-active"}`}
                  id="Company address"
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="contact line" className="label">
                  Contact line <span>*</span>
                </label>
                <PatternFormat
                  type="text"
                  className={`input ${details.contact_line && "input-active"}`}
                  id="contact line"
                  onChange={handleChange}
                  value={details.contact_line}
                  name="contact_line"
                  placeholder="Contact line"
                  format="### ### ### ###"
                />
              </div>
              {/* group box end */}
            </div>
            {/* note box start */}
            <div className="note-box">
              <p>
                <span>note:</span>For organizations with shared budgets, you
                would need to have created at least two subsidiaries to set up a
                shared services
              </p>
            </div>
            {/* note box end */}
          </div>
          {/* wrap end */}
          {/* wrap start */}
          <div className="wrapper">
            <p className="box-title">2. Brand</p>
            <p className="small-text">
              Customize your organization to meet your brand identity.
            </p>
            {/* logo group start */}
            <div className="logo-box">
              <p className="logo-title">Logo</p>
              <div className="form-group">
                <div className="box">
                  <label className="label" htmlFor="imageUpload">
                    <img src={fileIcon} className="img" alt="" />
                    <span
                      style={{
                        color: details.logo ? "var(--blue-color)" : "#5B6871",
                      }}
                    >
                      {details.logo ? "Change attachment" : "Select Logo"}
                    </span>
                  </label>
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    placeholder={
                      details.logo ? details?.logo?.name : "Change Attachment"
                    }
                    onChange={(e) =>
                      setDetails((prev) => {
                        return {
                          ...prev,
                          logo: e.target.files[0],
                        };
                      })
                    }
                  />
                </div>
                {/* end of box  */}
                <p className="logo-display">{details?.logo?.name}</p>
              </div>
              <p className="logo-text">
                Only standard format are allowed Jpg, png. File must not be more
                than 500kb
              </p>
            </div>
            {/* logo group end */}
            {/* color group start */}
            <div className="color-group-box">
              <p className="color-title">Color</p>
              <div className="wrap">
                <div className="text-box">
                  <p className="color-text">Main color</p>
                  <p className="color-text">
                    This color would be use for action text and button
                  </p>
                </div>
                <div className="color-box">
                  <div className="form-group">
                    <input
                      onChange={(e) => {
                        handleChange(e);

                        document.documentElement.style.setProperty(
                          "--blue-color",
                          e.target.value
                        );
                      }}
                      type="text"
                      value={details.brand_color}
                      className="input"
                      name="brand_color"
                      placeholder="input color"
                    />
                    <div
                      style={{ backgroundColor: details?.brand_color }}
                      className="display-color"
                    ></div>
                  </div>
                  <ColorPicker
                    width={300}
                    height={200}
                    color={color}
                    onChange={(e) => {
                      setColor(e);
                      document.documentElement.style.setProperty(
                        "--blue-color",
                        e.hex
                      );
                      setDetails((prev) => {
                        return { ...prev, brand_color: e?.hex };
                      });
                    }}
                    hideHEX
                    hideHSV
                    hideRGB
                    // dark
                  />
                </div>
              </div>
            </div>
            {/* color group end */}
          </div>
          {/* wrap end */}
          {/* wrap start */}
          <div className="wrapper">
            <p className="box-title">3. Financial Information</p>
            <p className="small-title financial-title">Currency</p>
            <div className="form-wrap form-wrap-financial ">
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Company" className="label">
                  Base Currency <span>*</span>
                </label>
                <Select
                  styles={reactSelectStyle}
                  className="select-wrap"
                  placeholder="Select Base Currency"
                  value={details.fiscal_year_base_currency}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, fiscal_year_base_currency: e };
                    });
                  }}
                  options={currencyDrop}
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Company" className="label">
                  Other Currency <span>*</span>
                </label>
                <Select
                  styles={reactSelectStyle}
                  className="select-wrap"
                  placeholder="Select Currency"
                  value={details.other_currency_id}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, other_currency_id: e };
                    });
                  }}
                  options={currencyDrop}
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="set_currency" className="label">
                  Set Currency Rate <span>*</span>
                </label>
                {/* <input
                  name="currency_rate"
                  onChange={(e) =>
                    !e.target.value.match(/[A-Z]+/g) &&
                    !e.target.value.match(/[!@#$.%^&*_=+-]/g) &&
                    !e.target.value.match(/[a-z]+/g) &&
                    handleChange(e)
                  }
                  value={details.currency_rate}
                  type="text"
                  className="input"
                  id="set_currency"
                  placeholder="rate"
                /> */}
                <NumericFormat
                  className="input"
                  decimalScale={3}
                  decimalSeparator="."
                  type="text"
                  name="currency_rate"
                  thousandsGroupStyle={"lakh"}
                  allowNegative
                  onChange={handleChange}
                  thousandSeparator={","}
                  value={details.currency_rate}
                  id="set_currency"
                  placeholder="rate"
                />
              </div>
              {/* group box end */}
            </div>
            {/* note box start */}
            <div className="note-box" style={{ marginBottom: "3rem" }}>
              <div>
                <figure className="img-box">
                  <img src={noteIcon} alt="" className="img" />
                </figure>
                <p>
                  {" "}
                  Once your base currency is selected, You will not be able to
                  change the base currency as you would have transactions
                  recorded in your account.
                </p>
              </div>
            </div>
            {/* note box end */}
            <p className="small-title financial-title">Fiscal Cycle</p>
            <div className="form-wrap form-wrap-financial ">
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="Company" className="label">
                  Title <span>*</span>
                </label>
                <input
                  name="fiscal_year_title"
                  onChange={handleChange}
                  value={details.fiscal_year_title}
                  type="text"
                  className="input"
                  id="set_currency"
                  placeholder="title"
                />
                {/* <select
                  name="fiscal_year_base_currency"
                  onChange={handleChange}
                  value={details.fiscal_year_title}
                  id=""
                  className={`input ${
                    details.fiscal_year_title && "input-active"
                  }`}
                >
                  <option value="Naira">2022</option>
                </select> */}
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="start_date" className="label">
                  Start Date <span>*</span>
                </label>
                <Flatpickr
                  id="start_date"
                  name="start_date"
                  className="form-input-calendar"
                  autoComplete="off"
                  value={startDate}
                  onChange={(date) =>
                    setstartDate(moment(date[0]).format("YYYY/MM/DD"))
                  }
                  placeholder="DD/MM/YYYY"
                />
              </div>
              {/* group box end */}
              {/* group box start */}
              <div className="form-group">
                <label htmlFor="set_currency" className="label">
                  End Date <span>*</span>
                </label>
                <Flatpickr
                  id="end_date"
                  name="end_date"
                  className="form-input-calendar"
                  autoComplete="off"
                  value={endDate}
                  onChange={(date) => {
                    setendDate(moment(date[0]).format("YYYY/MM/DD"));
                    // setDetails((prev) => {
                    //   return {...prev, start_date: moment(date[0]).format("YYYY/MM/DD")}
                    // })
                  }}
                  placeholder="DD/MM/YYYY"
                />
              </div>
              {/* group box end */}
            </div>

            {/* button start */}
            {!loading ? (
              <button
                className={`btn-set-up ${
                  details.fiscal_year_base_currency &&
                  details.name &&
                  details.address &&
                  details.currency_rate &&
                  details.contact_line &&
                  details.country &&
                  details.fiscal_year_title &&
                  details.other_currency_id &&
                  startDate &&
                  endDate &&
                  "btn-set-up-active"
                }`}
                onClick={(e) => {
                  details.fiscal_year_base_currency &&
                    details.name &&
                    details.address &&
                    details.currency_rate &&
                    details.contact_line &&
                    details.country &&
                    details.fiscal_year_title &&
                    details.other_currency_id &&
                    startDate &&
                    endDate &&
                    handleSubmit(e);
                }}
              >
                Complete Setup
              </button>
            ) : (
              <div style={{ alignSelf: "center", marginTop: "3rem" }}>
                <Dna
                  visible={true}
                  height="80"
                  width="100"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* button end */}
          </div>
          {/* wrap end */}
        </div>
      </SetupLayout>

      {/* modal start */}
      <Modal visible={showModal} effect={`fadeInUp`}>
        <AccountSetUpSuccessModal onCancel={() => setShowModal(false)}>
          <ButtonBlue
            onClick={() => {
              localStorage.setItem("login", true);
              history.push("/dashboard-home");
            }}
            text={`Continue`}
          />
        </AccountSetUpSuccessModal>
      </Modal>
      {/* modal end */}
    </>
  );
};

export default SetupOrganizationSetting;
