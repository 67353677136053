import React, { useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH, FaTimes } from "react-icons/fa";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import cancel_btn from "../../../../../../assets/CancelBtn.svg";
// import ApprovalContent from "./contentHead";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
// import ContentBody from "./contentBody";
import { formatNumber } from "../../../../../../helper/Helpers";
import { useSelector } from "react-redux";
// import EmployeeOverviewTableBodyRow from "../../home/modals/Reimbursement/overviewTableRow";
import Modal from "react-awesome-modal";
import moment from "moment";
import userImg from "../../../../../../assets/Unknown_person.jpeg";
import { Dna } from "react-loader-spinner";

const EmployeeActualExpenseOverviewModal = ({
  onCancel,
  onUpdate,
  handleSubmit,
  onDelete,
  details,
  onShowImg,
  setShowLink,
}) => {
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });
  const [showActions, setShowActions] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { loadingTwo } = useSelector((state) => state.actual_expense_employee);

  // const contentHeader = ["Level", "Name", "Status"];

  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <div className="overview-card-modal-wrap">
      <div className="top-box">
        <div className="title-box">
          <p
            // onClick={() => {
            //   setDetailToShow("overview");
            // }}
            className="title"
          >
            Overview
          </p>
          {/* <p
            onClick={() => {
              setDetailToShow("activity");
            }}
            className="title"
          >
            Activity Log
          </p> */}
        </div>
        {!loadingTwo ? (
          <ButtonBlue
            onClick={() => {
              handleSubmit(setShowActions(false));
            }}
            text={`Submit`}
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
        <div
          onClick={() => setShowActions(!showActions)}
          className="ellipse-box"
        >
          <FaEllipsisH className="icon" />
          <div
            style={{
              //   height: "15rem",
              //   paddingTop: "1rem",
              //   gap: "1.5rem",
              marginBottom: "unset",
              paddingBottom: "unset",
            }}
            className={`action-box ${showActions && "action-box-active"}`}
          >
            <p style={{ display: "none" }}></p>
            <p onClick={onUpdate}>
              <span style={{ marginBottom: "0px" }}>Update</span>
            </p>
            <p onClick={onDelete}>
              <span style={{ color: "#CC0905" }}>Delete</span>
            </p>
            {/* <p onClick={onComment}>
              <span>Comments</span>
            </p> */}
            {/* <p>
              <span>Export as PDF</span>
            </p> */}
          </div>
          {/* action box end */}
        </div>
        <div
          onClick={() => {
            onCancel(setShowActions(false));
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      <div className="content-box">
        {/* title drop control box start */}
        <>
          <div
            onClick={() => handleShowBox("one")}
            className="title-drop-control-box"
          >
            <p className="title">Budget Owner Details</p>
            <div className="icon-box">
              {!showBox.one && <FaAngleDown className="icon" />}
              {showBox.one && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.one && (
            <div className="content-wrap">
              <div className="two-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Chart of Account</p>
                  <p className="value">{`${details?.chart_of_account?.name}`}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Class</p>
                  <p className="value">{`${details?.chart_of_account?.class}`}</p>
                </div>
                {/* box end */}
                {/* box start */}
                {/* <div className="box">
                  <p className="text">Units</p>
                  <p className="value">{`${details?.units}`}</p>
                </div> */}
                {/* box end */}
              </div>
              <div className="three-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Department</p>
                  <p
                    className="value"
                    style={{ width: "80%" }}
                  >{`${details?.department?.name}`}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Units</p>
                  <p className="value">{details?.units}</p>
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Unit_price</p>
                  <p className="value">
                    {" "}
                    {`${formatNumber(String(details?.unit_price))}`}
                  </p>
                </div>
              </div>
              {/* box end */}
              {/* <div className="three-box"> */}
              {/* box start */}
              {/* <div className="box">
                  <p className="text">Subsidiary</p>
                  <p className="value">Zojatech</p>
                </div> */}
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                  <p className="text">Company Name</p>
                  <p className="value">Unknown</p>
                </div> */}
              {/* box end */}
              {/* box start */}
              {/* <div className="box">
                  <p className="text">Revenue</p>
                  <p className="value">200,000.00</p>
                </div> */}
              {/* box end */}
              {/* </div> */}
              <div className="one-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Description</p>
                  <p
                    className="value"
                    style={{ width: "80%" }}
                  >{`${details?.description}`}</p>
                </div>
                {/* box end */}
              </div>
              <div className="two-box">
                {/* box start */}
                <div className="box">
                  <p className="text">Expense Date</p>
                  <p className="value">{`${moment(details?.expense_date).format(
                    "YYYY-MM-DD HH:mm a"
                  )}`}</p>
                  {/* {moment(date).format("YYYY-MM-DD")} */}
                </div>
                {/* box end */}
                {/* box start */}
                <div className="box">
                  <p className="text">Last Updated</p>
                  <p className="value">{`${details?.updated_at}`}</p>
                </div>
                {/* box end */}
              </div>
              {/* created by wrap start */}
              <div className="one-box">
                <div className="box">
                  <p className="text">Created by</p>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="img-text-box"
                  >
                    <figure style={{ marginRight: "1rem" }} className="img-box">
                      <img
                        style={{
                          borderRadius: "20%",
                          width: "4rem",
                          height: "4rem",
                        }}
                        src={user?.profile_picture || userImg}
                        alt=""
                        className="img"
                      />
                    </figure>
                    <div className="text-box">
                      <p className="value">
                        {`${details?.createdBy?.full_name}`}
                      </p>
                      <p style={{ marginBottom: "unset" }} className="text">
                        {`${details?.createdBy?.email}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="three-box">
                {details?.receipts !== "" ? (
                  details?.receipts?.map((chi, idx) => {
                    const { original_url, mime_type, file_name } = chi;
                    // console.log(mime_type, "picture");
                    return (
                      <div>
                        {!mime_type.includes("image/") ? (
                          <div>
                            <p
                              style={{
                                width: "10rem",
                                height: "10rem",
                                backgroundColor: "#ffff",
                                padding: "0rem 1rem",
                                fontWeight: "800",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                !mime_type.includes("image/") &&
                                  downloadCsv(original_url);
                              }}
                            >
                              File Name: {file_name}
                            </p>
                          </div>
                        ) : (
                          <figure
                            onClick={() => {
                              onShowImg();
                              setShowLink(original_url);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              style={{
                                width: "10rem",
                                height: "10rem",
                              }}
                              src={original_url}
                              alt=""
                            />
                          </figure>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      // width: "10rem",
                      // height: "10rem",
                      // backgroundColor: "#ffff",
                      // boxShadow: "0.1rem 0.1rem 8rem rgba(0, 0, 0, .2)",
                      // cursor: "pointer",
                      padding: "0rem 1rem",
                      fontWeight: "800",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p>No receipt found!</p>
                  </div>
                )}
              </div>

              {/* created by wrap end */}
            </div>
          )}
        </>
        {/* title drop control box end */}
        {/* title drop control box start */}
        {/* <>
          <div
            onClick={() => handleShowBox("two")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Monthly Breakdown</p>
            <div className="icon-box">
              {!showBox.two && <FaAngleDown className="icon" />}
              {showBox.two && <FaAngleUp className="icon" />}
            </div>
          </div>
          {showBox.two && <div className="content-wrap"></div>}
        </> */}
        {/* title drop control box end */}
        {/* title drop control box start */}
        {/* <>
          <div
            onClick={() => handleShowBox("three")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Approval Flow</p>
            <div className="icon-box">
              {!showBox.three && <FaAngleDown className="icon" />}
              {showBox.three && <FaAngleUp className="icon" />}
            </div>
          </div>
         
          {showBox.three && (
            <div className="content-wra">
              <ApprovalContent
                headerOne={contentHeaderTwo[0]}
                headerTwo={contentHeaderTwo[1]}
                headerThree={contentHeaderTwo[2]}
              />
              {details?.approval_flow?.map((chi, idx) => {
                const { step, user, status } = chi;
                return (
                  <>
                    <EmployeeOverviewTableBodyRow
                      key={idx}
                      one={step.toString().padStart(2, "0")}
                      userOne
                      userName={user?.first_name + " " + user?.last_name}
                      userEmail={user?.email}
                      userImg={user[0]?.profile_picture || userImg}
                      status={
                        status === "approved"
                          ? "Approved"
                          : status === "pending"
                          ? "Pending"
                          : status === "rejected"
                          ? "Rejected"
                          : "pending"
                      }
                    />
                  </>
                );
              })}
            </div>
          )}
        </> */}
        
        {/* title drop control box end */}
      </div>
      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default EmployeeActualExpenseOverviewModal;
