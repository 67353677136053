import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { addLabelValueFunc } from "../../../helper/Helpers";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getBudgetAssumptions = createAsyncThunk(
  "admin/get-budget-ass",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/budget-assumptions/organization/${formData}`
      );
      // console.log("branch", data?.data?.budget_assumptions);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBudgetAssumptions(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // const departmentsInfo = data.data.classes;
        await thunkAPI.dispatch(
          setBudgetAssumptionDrop(data.data.budget_assumptions)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBudgetAssumptionWithPagination = createAsyncThunk(
  "admin/get-budg-ass-with-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/budget-assumptions/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}`
      );
      // console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBudgetAssumptions(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(
          setBudgetAssumptions(data.data.budget_assumptions)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBudgetAssumptionsByFilter = createAsyncThunk(
  "admin/get-budg-ass-filter",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/admin/budget-assumptions/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBudgetAssumptions(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const classesInfo = data.data.budget_assumptions;
        await thunkAPI.dispatch(setBudgetAssumptionFilter(classesInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBudgetAssumptionPagination = createAsyncThunk(
  "admin/get-classes-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        // await thunkAPI.dispatch(setBudgetAssumptions(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const classesInfo = data.data.budget_assumptions;
        await thunkAPI.dispatch(setBudgetAssumptions(classesInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBudgetAssumption = createAsyncThunk(
  "admin/get-budget-assumption",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/budget-assumptions/${formData}`);
      console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createBudgetAssumption = createAsyncThunk(
  "admin/create-budget-assumptions",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/budget-assumptions", formData);
      //   console.log('class', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      // if(err?.response?.status === 422){
      //   toast.error(err?.response?.data?.errors.attachment[0], {
      //     theme: "colored",
      //   });
      // }
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.attachment[0], {
          theme: "colored",
        });
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
   
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteBudgetAssumption = createAsyncThunk(
  "admin/delete-budget-assumption",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `/admin/budget-assumptions/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateBudgetAssumption = createAsyncThunk(
  "admin/update-budget-assumption",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        `/admin/budget-assumptions/${formData?.id}`,
        formData?.formData,
        config
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.attachment[0], {
          theme: "colored",
        });
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const budgetAssumptions = createSlice({
  name: "class",
  initialState: {
    budgetAssumptionDrop: [],
    budgetAssumptions: [],
    links: {},
    meta: {},
    budgetAssumption: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setBudgetAssumptions: (state, action) => {
      state.isAuth = true;
      state.budgetAssumptions = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setBudgetAssumptionFilter: (state, action) => {
      state.isAuth = true;
      state.budgetAssumptions = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setBudgetAssumptionDrop: (state, action) => {
      state.isAuth = true;
      state.budgetAssumptionDrop = action.payload;
    },
    setBudgetAssumption: (state, action) => {
      state.isAuth = true;
      state.budgetAssumption = action.payload;
    },
  },
  extraReducers: {
    [createBudgetAssumption.pending]: (state) => {
      state.loading = true;
    },
    [createBudgetAssumption.fulfilled]: (state) => {
      state.loading = false;
    },
    [createBudgetAssumption.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateBudgetAssumption.pending]: (state) => {
      state.loading = true;
    },
    [updateBudgetAssumption.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateBudgetAssumption.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getBudgetAssumptionPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBudgetAssumptionPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBudgetAssumptionPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBudgetAssumptionsByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBudgetAssumptionsByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBudgetAssumptionsByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBudgetAssumptionWithPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBudgetAssumptionWithPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBudgetAssumptionWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteBudgetAssumption.pending]: (state) => {
      state.loading = true;
    },
    [deleteBudgetAssumption.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteBudgetAssumption.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBudgetAssumption,
  setBudgetAssumptions,
  setBudgetAssumptionDrop,
  setBudgetAssumptionFilter,
} = budgetAssumptions.actions;

export default budgetAssumptions.reducer;
