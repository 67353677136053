import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { getChartStructures } from "../../helper/Helpers";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getExpenditureProjectionCharts = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/expenditure?organization_id=${formData?.org_id}&currency_conversion_id=${formData?.cur_id}&perPage=${formData?.per_page || 20}`
      );
      // console.log("approval-class", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        await thunkAPI.dispatch(setExpenditureProjection(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjectionChartsBySort = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/expenditure?organization_id=${formData?.org_id}&subsidiary_id=${formData?.sub_id || ""}&branch_id=${formData?.bra_id || ""}&department_id=${formData?.dept_id || ""}&currency_conversion_id=${formData?.cur_id || ""}&month=${formData?.date || ""}&perPage=${formData?.per_page || 20}`
      );
      // console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        await thunkAPI.dispatch(setExpenditureProjection(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjectionChartsPagination = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/expenditure?organization_id=${formData?.org_id}&page=${formData?.num}&currency_conversion_id=${formData?.cur_id}&perPage=${formData?.per_page || 20}`
      );
      // console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        await thunkAPI.dispatch(setExpenditureProjection(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjectionChartByDepartment = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/department-expenditure?organization_id=${formData?.org_id
        }&filter[department_id]=${formData?.dept_id === "all" ? "" : formData?.dept_id || ""}&filter[status]=${formData?.status || ""
        }&page=${formData?.page || "1"}&currency_conversion_id=${formData?.cur_id}&perPage=${formData?.per_page || 20}`
      );
      // console.log("approval-class", data.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        //   console.log(data.data);
        await thunkAPI.dispatch(setExpenditureProjectionSingle(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjectionChartByDepartmentPendingApproval = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `get-my-pending-projections-yet-to-be-approved?organization_id=${formData?.org_id
        }&filter[department_id]=${formData?.dept_id}&projection_type=expense_projection&page=${formData?.page || "1"}&currency_conversion_id=${formData?.cur_id}&perPage=${formData?.per_page || 20}&fiscal_year_id=${formData?.fis_id || ""}`
      );
      // console.log("approval-class", data.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
          // console.log(data.data);
        await thunkAPI.dispatch(setAwaitingListApprove(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);


export const getSingleViewProjectionType = createAsyncThunk(
  "admin/view-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/single-view-projection?id=${formData?.id}&projection_type=${formData?.type}`
      );
      console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        //   console.log(data.data);
        // await thunkAPI.dispatch(setExpenditureProjectionSingle(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjectionChartByDepartmentByStatus =
  createAsyncThunk("admin/get-approval-class", async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/projections/department-expenditure?organization_id=${formData?.org_id}&department_id=${formData?.dept_id}&filter[status]=${formData?.status}&currency_conversion_id=${formData?.cur_id}&perPage=${formData?.per_page || 20}`
      );
      // console.log("approval-class", data.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        //   console.log(data.data);
        await thunkAPI.dispatch(setExpenditureProjectionSingle(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  });

export const getExpenditureProjectionChartByProjection = createAsyncThunk(
  "admin/get-approval-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `employee/expenditure_projection/show-expenditure/${formData?.id}&currency_conversion_id=${formData?.cur_id}&perPage=${formData?.per_page || 20}`
      );
      // console.log("approval-class", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        //   console.log(data.data);
        //   await thunkAPI.dispatch(setExpenditureProjectionSingle(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const approveExpenditureProjection = createAsyncThunk(
  "admin/approve-exp-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `/approval/projections?organization_id=${formData?.organization_id || ""
        }&department_id=${formData?.department_id || ""}&projection_type=${formData?.projection_type || ""
        }&comment=${formData?.comment || ""}&status=${formData?.status || ""
        }&projection_id=${formData?.projection_id || ""}`
      );
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data);
        toast.success(data.message, {
          theme: "colored",
        });
        const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovalType(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const approveAllProjection = createAsyncThunk(
  "admin/approve-exp-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `approval/all-projections?organization_id=${formData?.org_id}`,
        formData
      );
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        // const branchInfo = data.data.approval_type;
        // await thunkAPI.dispatch(setApprovalType(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const addProjectionCommentExp = createAsyncThunk(
  "admin/approve-exp-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `employee/comments/expenditure/add-comment?expenditure_projection_id=${formData?.id}`,
        formData
      );
      console.log(data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovalType(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);


export const notifyNextApprover = createAsyncThunk(
  "admin/notify-next-approver",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.post(
        `/notify-next-approver`,
        formData
      );
      // console.log(data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovalType(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovalByClass = createAsyncThunk(
  "admin/get-approval-by-class",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/approvals/organization/${formData?.org_id}/${formData?.class_id}`
      );
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log(data.data.approvals);
        // const branchInfo = data.data.approval_type;
        await thunkAPI.dispatch(setApprovals(data?.data?.approvals));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProJectionActivityLog = createAsyncThunk(
  "admin/get-exp-pro-activity-log",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/expenditure/${formData?.id}`
      );
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        // const branchInfo = data.data.approval_type;
        // await thunkAPI.dispatch(setApprovals(data?.data?.approvals));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesWithPagination = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/branches/organization/${formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getExpenditureProjectionComments = createAsyncThunk(
  "admin/get-branches",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/comments/expenditure/${formData}`
      );
      console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        //   toast.success(data.message, {
        //   theme: "colored",
        // });
        const branchInfo = data.data.expenditure_projection_comments;
        await thunkAPI.dispatch(setComments(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesPagination = createAsyncThunk(
  "admin/get-branches-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        await thunkAPI.dispatch(setBranches(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const branchInfo = data.data.branches;
        await thunkAPI.dispatch(setBranches(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranch = createAsyncThunk(
  "admin/get-branch",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/branches/${formData}`);
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBranchesBySubsidiary = createAsyncThunk(
  "admin/get-branch",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(
        `/admin/branches/subsidiary/${formData}`
      );
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // const branchInfo = data.data.branches;
        // await thunkAPI.dispatch(setComments(branchInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const setUpApprovalFlow = createAsyncThunk(
  "admin/create-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { org_id } = formData;
      const data = await axios.post(
        `/admin/organizations/${org_id}/approval-setup`,
        formData
      );
      // console.log('set-up-org', data);
      if (data?.data?.success === false) {
        toast.error(data.data.message, {
          theme: "colored",
        });
        // console.log(data);
        // return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.success === true) {
        toast.success(data.data.message, {
          theme: "colored",
        });
        // console.log(data);
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response?.status === 422) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteCommentExpenditureProjection = createAsyncThunk(
  "admin/delete-delete",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `employee/comments/expenditure/${formData?.exp_id}/${formData?.comment_id}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateBranch = createAsyncThunk(
  "admin/update-branch",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/branches/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const expenditure_projection_chart = createSlice({
  name: "expenditure_projection_chart",
  initialState: {
    chartInfo: {},
    approvals: [],
    awaitingListApprove: [],
    approvalClass: [],
    approvalType: [],
    comments: [],
    expenditure_projection: [],
    expenditure_projection_single: [],
    links: {},
    meta: {},
    branch: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
    loadingNotify: false,
  },
  reducers: {
    setExpenditureProjection: (state, action) => {
      state.isAuth = true;
      state.expenditure_projection = action.payload;
      state.links = action.payload.links;
    }, 
     setAwaitingListApprove: (state, action) => {
      state.isAuth = true;
      state.awaitingListApprove = action.payload;
      state.links = action.payload.links;
    },
    setExpenditureProjectionSingle: (state, action) => {
      state.isAuth = true;
      state.expenditure_projection_single = action.payload;
      state.links = action.payload.links;
    },
    setComments: (state, action) => {
      state.isAuth = true;
      state.comments = action.payload;
      state.links = action.payload.links;
    },
    setChartInfo: (state, action) => {
      state.isAuth = true;
      state.chartInfo = getChartStructures(action.payload);
    },
    setApprovals: (state, action) => {
      state.isAuth = true;
      state.approvals = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setApprovalClass: (state, action) => {
      state.isAuth = true;
      state.approvalClass = action.payload;
    },
    setApprovalType: (state, action) => {
      state.isAuth = true;
      state.approvalType = action.payload;
    },
    setBranch: (state, action) => {
      state.isAuth = true;
      state.branch = action.payload;
    },
  },
  extraReducers: {
    [addProjectionCommentExp.pending]: (state) => {
      state.loading = true;
    },
    [addProjectionCommentExp.fulfilled]: (state) => {
      state.loading = false;
    },
    [addProjectionCommentExp.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionChartByDepartment.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjectionChartByDepartment.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjectionChartByDepartment.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionChartByDepartmentPendingApproval.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjectionChartByDepartmentPendingApproval.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjectionChartByDepartmentPendingApproval.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionChartByDepartmentByStatus.pending]: (state) => {
      state.loading = true;
    },
    [getExpenditureProjectionChartByDepartmentByStatus.fulfilled]: (state) => {
      state.loading = false;
    },
    [getExpenditureProjectionChartByDepartmentByStatus.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionCharts.pending]: (state) => {
      state.loading = true;
    },
    [getExpenditureProjectionCharts.fulfilled]: (state) => {
      state.loading = false;
    },
    [getExpenditureProjectionCharts.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSingleViewProjectionType.pending]: (state) => {
      state.loading = true;
    },
    [getSingleViewProjectionType.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSingleViewProjectionType.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [deleteCommentExpenditureProjection.pending]: (state) => {
      state.loading = true;
      state.loadingPage = true;
    },
    [deleteCommentExpenditureProjection.fulfilled]: (state) => {
      state.loading = false;
      state.loadingPage = false;
    },
    [deleteCommentExpenditureProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionComments.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjectionComments.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjectionComments.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBranchesBySubsidiary.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBranchesBySubsidiary.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBranchesBySubsidiary.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionChartsPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getExpenditureProjectionChartsPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getExpenditureProjectionChartsPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [approveExpenditureProjection.pending]: (state) => {
      state.loading = true;
    },
    [approveExpenditureProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [approveExpenditureProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [approveAllProjection.pending]: (state) => {
      state.loading = true;
    },
    [approveAllProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [approveAllProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getExpenditureProjectionChartsBySort.pending]: (state) => {
      state.loading = true;
    },
    [getExpenditureProjectionChartsBySort.fulfilled]: (state) => {
      state.loading = false;
    },
    [getExpenditureProjectionChartsBySort.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [notifyNextApprover.pending]: (state) => {
      state.loadingNotify = true;
    },
    [notifyNextApprover.fulfilled]: (state) => {
      state.loadingNotify = false;
    },
    [notifyNextApprover.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingNotify = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBranch,
  setBranches,
  setApprovalClass,
  setApprovals,
  setApprovalType,
  setChartInfo,
  setExpenditureProjection,
  setExpenditureProjectionSingle,
  setComments,
  setAwaitingListApprove,
} = expenditure_projection_chart.actions;

export default expenditure_projection_chart.reducer;
