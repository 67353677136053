import React from "react";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeGeneralReportsPage from "./generalReports";

const EmployeeGeneralReportIndex = () => {
  const { plan_details } = useSelector((state) => state.users);
  const list = [
    {
      title: "General Reports",
      content: "These reports are automaically generated by the system.",
      link: "/employee-dashboard-general-report",
    },
    {
      title: "Custom Reports",
      content: "Customize your preferred reports based on your interest.",
      link: "/employee-dashboard-general-report-custom",
    },
  ];

  const location = useLocation();

  const history = useHistory();

  // const links = ["/employee-dashboard-general-report"];
  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Reports`}> */}
        {/* title wrap start */}
        <div
          className="admin-approval-settings-wrap"
          style={{ marginTop: "-5rem" }}
        >
          <div className="title-btn-box">
            <div className="title-text-box">
              <p className="title">Reports</p>
            </div>
          </div>
          {/* title wrap end */}

          {/* list box start */}
          <div className="list-wrap">
            {list.map((chi, idx) => {
              const { title, content, link } = chi;
              if (!plan_details?.privilages?.custom_report && title === "Custom Reports") {
                return (
                  <React.Fragment key={idx}></React.Fragment>
                )
              }
              return (
                <div
                  key={idx}
                  className={`item ${
                    location.pathname === link && "item-active"
                  }`}
                  onClick={() => history.push(link)}
                >
                  <p className="title">{title}</p>
                  <p className="text">{content}</p>
                  {/* check box start */}
                  <div className="check-box">
                    <FaCheck
                      className={`icon ${
                        location.pathname === link && "icon-active"
                      }`}
                    />
                  </div>
                  {/* check box end */}
                </div>
              );
            })}
          </div>
          {/* list box end */}
          {/* wrapper start */}
          <div className="title-btn-box" />
          <EmployeeGeneralReportsPage />

          {/* wrapper end */}
        </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default EmployeeGeneralReportIndex;
