import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
import ActionContext from "../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import {
  formatNumber,
  FormatNumWithCurrency,
  getChartStructureGross,
  getChartStructures,
} from "../../../../../helper/Helpers";
import {
  getRevenueProjectionCharts,
  getRevenueProjectionChartsBySort,
} from "../../../../../redux/projections/RevenueProjection";

const CommonRevenueLayout = ({
  children,
  chartBox,
  showChart,
  setShowChart,
  setDataChartValue,
  setDataChartValueGross,
  setDataChartValueGrossVer,
}) => {
  const actionCtx = useContext(ActionContext);
  const { revenue_projections } = useSelector(
    (state) => state.revenue_projection_chart
  );

  const dispatch = useDispatch();
  const [sortVal, setSortval] = useState({
    bra: "",
    sub: "",
    dept: "",
    fis: "",
    date: "",
  });

  // useEffect(() => {
  //   const localOrg = localStorage?.getItem("user");
  //   const obj = {
  //     org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
  //     sub_id: sortVal?.sub?.value,
  //     dept_id: sortVal?.dept?.value,
  //     bra_id: sortVal?.bra?.value,
  //     date: sortVal?.date,
  //     fis_id: sortVal?.fis?.value,
  //   };
  //   if (sortVal?.bra || sortVal?.dept || sortVal?.sub) {
  //     console.log(obj);
  //     dispatch(getRevenueProjectionChartsBySort(obj));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sortVal]);

  useEffect(() => {
    if (sortVal?.bra || sortVal?.dept || sortVal?.sub || sortVal?.date) {
      sortValFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortVal]);

  const sortValFunc = async () => {
    const localOrg = localStorage?.getItem("user");
    const obj = {
      // org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      sub_id: sortVal?.sub?.value,
      dept_id: sortVal?.dept?.value,
      bra_id: sortVal?.bra?.value,
      date: sortVal?.date.value,
      fis_id: sortVal?.fis?.value,
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      cur_id: actionCtx?.currencyValue?.id  || "",
    };
    // console.log(obj);
    const data = await dispatch(getRevenueProjectionChartsBySort(obj));
    if (data?.payload?.success) {
      const list = getChartStructures(data?.payload?.data?.graph_data);
      const listGross = getChartStructureGross(
        data?.payload?.data?.gross_per_product
      );
      const listGrossVer = getChartStructureGross(
        data?.payload?.data?.gross_per_vertical
      );
      setDataChartValue(list);
      setDataChartValueGross(listGross);
      setDataChartValueGrossVer(listGrossVer);
    }
  };

  const getChartValueHere = async () => {
    // console.log("yes");
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      // org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      cur_id: actionCtx?.currencyValue?.id  || "",
    };
    const data = await dispatch(getRevenueProjectionCharts(obj));
    // console.log(data);
    if (data?.payload?.success) {
      const list = getChartStructures(data?.payload?.data?.graph_data);
      const listGross = getChartStructureGross(
        data?.payload?.data?.gross_per_product
      );
      const listGrossVer = getChartStructureGross(
        data?.payload?.data?.gross_per_vertical
      );
      setDataChartValue(list);
      setDataChartValueGross(listGross);
      setDataChartValueGrossVer(listGrossVer);
    }
  };

  return (
    <>
      {/* <DashboardLayout goBack pageTitle={`Revenue Projection`}> */}
      <div className="expense-projection-inde-wrap">
        <CommonSortBox
          subVal={sortVal?.sub}
          deptVal={sortVal?.dept}
          braVal={sortVal?.bra}
          fisVal={sortVal?.fis}
          dateVal={sortVal?.date}
          onReset={() => {
            setSortval({
              bra: "",
              sub: "",
              dept: "",
              fis: "",
              date: "",
            });
            getChartValueHere();
          }}
          onDept={(e) => {
            setSortval((prev) => {
              return { ...prev, dept: e };
            });
          }}
          onBra={(e) => {
            setSortval((prev) => {
              return { ...prev, bra: e };
            });
          }}
          onSub={(e) => {
            setSortval((prev) => {
              return { ...prev, sub: e };
            });
          }}
          onFis={(e) => {
            setSortval((prev) => {
              return { ...prev, fis: e };
            });
          }}
          onDate={(e) => {
            setSortval((prev) => {
              return { ...prev, date: e };
            });
          }}
        />

        {/* title hide box start */}
        <div className="title-hide-box">
          <p className="title">
            Total Revenue Projection{" "}
            <span>
              {formatNumber(
                String(revenue_projections?.total_revenue_projection || "0"
                )
              )}
            </span>
          </p>
          {/* hide box start */}
          <div className="hide-box">
            <input
              checked={actionCtx.isShowChart?.expense_projection}
              className="input"
              onChange={(e) => actionCtx.showChartFunc("expense-projection")}
              type="checkbox"
              name=""
              id="chart-check"
            />
            <p className="label-text">
              {actionCtx.isShowChart?.expense_projection
                ? "Hide Chart"
                : "Show Chart"}
            </p>
            <label htmlFor="chart-check" className="label">
              <span></span>
              <span></span>
            </label>
          </div>
          {/* hide box end */}
        </div>
        {/* title hide box end */}
        {/* common links start */}
        <>{/* <CommonNavLink /> */}</>
        {/* common links end */}
        {/* chart and table box start */}
        <>{chartBox}</>
        {/* chart  box end */}
        {/* children box start */}
        <>{children}</>
        {/* children box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default CommonRevenueLayout;
