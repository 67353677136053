import React, { useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const AllACtionWrapper = ({ children }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  return (
    <div className="all-action-drop-down-wrapper">
      {/* open action box start */}
      <div
        onClick={() => {
          setShowDropDown(!showDropDown);
        }}
        className="open-drop-down"
      >
        <p className="text">Action Buttons</p>
      {!showDropDown ?  <FaAngleDown className="icon" /> : <FaAngleUp className="icon" />}
      </div>
      {/* open action box end */}
      {/* drop down box start */}
      {showDropDown && <div className="drop-down-box">{children}</div>}
      {/* drop down box end */}
    </div>
  );
};

export default AllACtionWrapper;
