import React, { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import "../../../../../styles/dashboard/actuals/expense/actualExpense.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
// import EmployeeAttachMoreFiles from "./addMoreFiles";
import moment from "moment";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/themes/airbnb.css";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import { Dna } from "react-loader-spinner";
import {
  ActualExpenseDownload,
  createEmployeeActualExpense,
} from "../../../../../../redux/employee/actual-expense";
// import { getUserDetails } from "../../../../../../redux/user";
// >>>>>>> ecb72c0a072084fc7ba31a8c191900c979ba6733
import { getUserDepartment } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import EmpActualExpenseBulkModal from "../modals/actual-expense/upload";
import Modal from "react-awesome-modal";
import { useContext } from "react";
import ActionContext from "../../../../../../context/ActionContext";
import { useThemeDetector } from "../../../../../../utils/getThemeColor";
import { NumericFormat } from "react-number-format";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import EmpSharedServiceModal from "../modals/sharedServiceModal";
import { getDepartments } from "../../../../../../redux/department/deparment";
import { toast } from "react-toastify";
import { MdCancel } from "react-icons/md";

const EmployeeActualNewExpense = ({ text, name, button }) => {
  const [startDate, setstartDate] = useState("");
  const dispatch = useDispatch();
  const { loadingTwo } = useSelector((state) => state.actual_expense_employee);
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);
  const { departmentDrop, loading } = useSelector((state) => state.department);
  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [details, setDetails] = useState({
    expense_date: "",
    chart_of_account_id: "",
    unit_price: "",
    units: "",
    description: "",
    total_expense: "",
    is_submitted: "",
    receipt: [],
    departments_aportions: [
      { department_id: "", subsidiary_id: "", apportions: "" },
    ],
  });

  // const status = localStorage.getItem("employee-actual-expense-status");

  const [imageToUpload, setImageToUpload] = useState([]);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [draftLoader, setDraftLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);

  const [showSharedModal, setShowSharedModal] = useState(false);
  const [isApportion, setIsApportion] = useState([]);

  const editApportionToSubmit = (list, dept) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          subsidiary_id: chi?.subsidiary?.id,
          department_id: dept,
          apportion: chi?.budget_percentage,
        };
      });
      // console.log(newList, "newList");
      setIsApportion(newList);
    }
  };

  const formatApportionToSubmit = (obj, e) => {
    const newList = isApportion?.map((chi) =>
      obj?.id === chi?.id ? { ...chi, apportion: e } : chi
    );
    setIsApportion(newList);
  };

  useEffect(() => {
    handleApportionSum();
  }, [isApportion]);

  const [apportionSum, setApportionSum] = useState("");
  const handleApportionSum = () => {
    const totalApportion = isApportion
      ?.map((chi) => chi.apportion)
      .reduce((a, b) => Number(a) + Number(b), 0);
    setApportionSum(totalApportion);
  };

  const handleSubmit = async (e, param) => {
    e.preventDefault();
    const newApportionList = isApportion?.map((chi) => {
      return {
        subsidiary_id: chi?.subsidiary_id,
        department_id: chi?.department_id,
        apportions: chi?.apportion,
      };
    });
    // console.log(newApportionList);

    const newApportionListData = JSON.stringify(newApportionList);

    const formData = new FormData();

    formData.append("expense_date", startDate);
    formData.append("chart_of_account_id", details?.chart_of_account_id?.value);
    formData.append(
      "unit_price",
      formatNumberWithoutComma(String(details?.unit_price))
    );
    formData.append("is_shared_service", isApportion?.length > 0 ? 1 : 0);
    formData.append("departments_aportions", newApportionListData);
    formData.append("units", formatNumberWithoutComma(String(details?.units)));
    formData.append("description", details?.description);
    formData.append("total_expense", details?.total_expense);
    Array.from(imageToUpload).forEach((image) => {
      formData.append("receipt[]", image);
    });
    formData.append("status", param);
    formData.append("is_submitted", param);
    formData.append("organization_id", user?.organizations[0]?.id);
    formData.append("department_id", details?.department_id?.value);

    if (param === 0) {
      setDraftLoader(true);
    }
    if (param === 1) {
      setApproveLoader(true);
    }
    // console.log(formData, "form-data");

    const data = await dispatch(createEmployeeActualExpense(formData));

    if (
      data?.payload?.success &&
      data?.payload?.data?.expense?.is_submitted === "0"
    ) {
      // setDetails({
      //   expense_date: "",
      //   chart_of_account_id: "",
      //   unit_price: "",
      //   units: "",
      //   description: "",
      //   total_expense: "",
      //   department_id: "",
      //   receipt: [],
      // });
      // setstartDate("");
      // setImageToUpload([]);
      setDraftLoader(false);
      history.push("/employee-dashboard-actual-expense");
    } else if (
      data?.payload?.success &&
      data?.payload?.data?.expense?.is_submitted === "1"
    ) {
      setApproveLoader(false);
      history.push("/employee-dashboard-actual-expense-submitted");
    } else {
      setDraftLoader(false);
      setApproveLoader(false);
    }
  };

  // const [displayNumber, setDisplayVal] = useState("");

  useEffect(() => {
    // console.log("ertyuiuyt");
    const empObj = {
      reason: true,
      id: user?.organizations[0]?.id,
    };
    dispatch(getChatAccounts(empObj));
    dispatch(getDepartments(user?.organizations[0]?.id));
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calcVal =
      formatNumberWithoutComma(String(details?.unit_price || 0)) *
      formatNumberWithoutComma(String(details?.units || 0));
    setDetails((prev) => {
      return { ...prev, total_expense: calcVal };
    });
  }, [details?.unit_price, details?.units]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const handleUploadLogoToList = (param) => {
    const list = [...imageToUpload, param];
    setImageToUpload(list);
  };

  const apportionData =
    details?.department_id?.branch?.subsidiary?.shared_service;

  const handleApportionSubmit = () => {
    const alertText = "The sharing percentage is above 100%";
    if (isApportion && apportionSum > 100) {
      toast.error(alertText, {
        theme: "colored",
      });
    } else {
      setShowSharedModal(false);
      actionCtx?.setIsModalOut(false);
    }
  };

  const enableSubmit = () => {
    let allowSubmit = false;

    allowSubmit =
      details?.chart_of_account_id &&
      details?.description &&
      startDate &&
      details?.receipt &&
      details?.unit_price &&
      details?.units &&
      imageToUpload.length > 0;

    return allowSubmit;
  };

  const handleReceiptDelete = (item) => {
    const newItem = imageToUpload.filter((chi) => chi !== item);
    setImageToUpload(newItem);
  };
  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Actual Expense`}> */}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container start */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">New Expense</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          <div
            className="btn-download-box"
            //   onClick={() => setShowModal(true)}
          >
            <button
              className={`btn-bulk`}
              onClick={() => {
                actionCtx?.setIsModalOut(true);
                setShowModal(true);
              }}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            {loadingTwo ? (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <p
                className="text"
                onClick={() => dispatch(ActualExpenseDownload())}
              >
                See and download template
              </p>
            )}
          </div>
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container end */}
        <div
          className="form-box"
          style={{
            width: "100%",
          }}
        >
          <form action="" className="form" onSubmit={handleSubmit}>
            {/* <div className=""> */}
            <div
              className="wrapper"
              style={{
                borderBottom: "unset",
                // paddingBottom: "-40rem",
                height: "unset",
                minHeight: "unset",
                // background: "green"
              }}
            >
              <p className="box-title">1.Expense Information</p>
              <div
                className="form-wrap"
                style={{
                  gridTemplateRows: "1fr",
                  gridTemplateColumns: "1fr 1fr",
                }}
              >
                <div className="employee-actual-expense-left-upload-wrap">
                  {/* select content wrap start */}
                  <div className="select-content">
                    {imageToUpload?.length < 1 && (
                      <>
                        <p className="title">Upload receipt </p>
                        <input
                          type="file"
                          name="upload-recipt-employee"
                          id="upload-recipt-employee"
                          className="upload-receipt"
                          onChange={(e) =>
                            handleUploadLogoToList(e.target.files[0])
                          }
                        />
                        <label
                          htmlFor="upload-recipt-employee"
                          className="label-upload"
                        >
                          <div className="wrap">
                            <FaPlus className="icon" />
                            <p className="text">Attach file</p>
                          </div>
                        </label>
                        <p className="info">
                          Supported format is jpg, xls, pdf. maximum of 5 files
                          and must not be less than 450kb
                        </p>
                      </>
                    )}
                    {/* less than end */}
                    {imageToUpload?.length > 0 && (
                      <>
                        <input
                          type="file"
                          name="upload-recipt-employee"
                          id="upload-recipt-employee"
                          className="upload-receipt"
                          onChange={(e) =>
                            handleUploadLogoToList(e.target.files[0])
                          }
                        />
                        <label
                          htmlFor="upload-recipt-employee"
                          className="label-upload-two"
                        >
                          {" "}
                          <div className="plus-box">
                            <FaPlus className="icon" />
                          </div>
                          <p className="text">Attach more files</p>
                        </label>
                      </>
                    )}
                  </div>
                  {/* select content wrap end */}
                  {/* slected content box start */}
                  <div className="selected-content-box">
                    <div
                      style={{
                        gridTemplateColumns: `repeat(${imageToUpload?.length}, max-content)`,
                      }}
                      className="wrap"
                    >
                      {imageToUpload?.map((chi, idx) => {
                        if (!chi?.type.includes("image/")) {
                          return (
                            <div className="box box-file" key={idx}>
                              {/* <p className="type">File type: <span>{formatApplicationType(chi?.type)}</span></p> */}
                              <p className="name">
                                <figure
                                  onClick={() => handleReceiptDelete(chi)}
                                  className="box-delete-file"
                                >
                                  <MdCancel />
                                </figure>
                                File name: <span>{chi?.name}</span>
                              </p>
                            </div>
                          );
                        }
                        return (
                          <>
                            <div
                              style={{
                                backgroundImage: `url(${URL.createObjectURL(
                                  chi
                                )})`,
                                width: "25rem",
                              }}
                              key={idx}
                              className="box"
                            >
                              <figure
                                onClick={() => handleReceiptDelete(chi)}
                                className="box-delete"
                              >
                                <MdCancel />
                              </figure>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  {/* slected content box error */}
                </div>
                {/* form group-container start */}
                <div
                  // style={{ background: "green" }}
                  className="form-group form-group right-box-expense-actual-box-wrap"
                >
                  {/* form-group start */}
                  <div className="form-group">
                    <label htmlFor="expense_date" className="label">
                      Expense Date <span>*</span>
                    </label>
                    <Flatpickr
                      id="start_date"
                      name="start_date"
                      className={`form-input-calender input ${
                        startDate && "input-active"
                      }`}
                      autoComplete="off"
                      value={startDate}
                      onChange={(date) =>
                        setstartDate(moment(date[0]).format("YYYY-MM-DD"))
                      }
                      placeholder="DD/MM/YYYY"
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="chart_of_account" className="label">
                      Chart of Account <span>*</span>
                    </label>
                    <Select
                      styles={reactSelectStyle}
                      className={`select-wrap ${
                        details.chart_of_account_id && "select-wrap-active"
                      }`}
                      placeholder="Select chart of account"
                      value={details.chart_of_account_id}
                      isLoading={loading}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, chart_of_account_id: e };
                        });
                      }}
                      options={chatAccountDrop}
                    />
                  </div>
                  <div>
                    <label htmlFor="department" className="label">
                      Department <span>*</span>
                    </label>
                    <Select
                      styles={reactSelectStyle}
                      className={`select-wrap ${
                        details.department_id && "select-wrap-active"
                      }`}
                      placeholder="Select Department"
                      value={details.department_id}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return { ...prev, department_id: e };
                        });
                        editApportionToSubmit(
                          e?.branch?.subsidiary?.shared_service[0]?.apportions,
                          e?.id
                        );
                      }}
                      isLoading={loading}
                      options={
                        user?.user_type === "ADMIN"
                          ? departmentDrop
                          : userDepartmentDrop
                      }
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="unit_price" className="label">
                      Unit Price <span>*</span>
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      id="unit_price"
                      name="unit_price"
                      placeholder="specify unit price"
                      value={details.unit_price}
                      onChange={handleChange}
                      className={`input ${
                        details.unit_price && "input-active"
                      }`}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="units" className="label">
                      Units <span>*</span>
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      type="text"
                      id="units"
                      name="units"
                      placeholder="specify unit"
                      value={details?.units}
                      // onChange={(e) =>
                      //   setDetails((prev) => {
                      //     return {
                      //       ...prev,
                      //       units: handleNumberFormatInput(e),
                      //     };
                      //   })
                      // }
                      onChange={handleChange}
                      className={`input ${details.units && "input-active"}`}
                    />
                  </div>
                  {/* form-group end */}
                  {/* form-group start */}
                  <div>
                    <label htmlFor="description" className="label">
                      Description <span>*</span>
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      onChange={handleChange}
                      style={{ resize: "none" }}
                      cols="30"
                      rows="7"
                      placeholder="Enter your description here..."
                      className={`input ${
                        details.description && "input-active"
                      }`}
                      value={details.description}
                    />
                  </div>
                  {/* form-group end */}
                  {/* sharing percent start */}
                  {apportionData?.length > 0 && (
                    <div>
                      <label className="label">
                        Set Sharing Percentage <span>*</span>
                      </label>
                      <ButtonBlue
                        onClick={() => {
                          actionCtx?.setIsModalOut(true);
                          setShowSharedModal(true);
                        }}
                        text={"Set Below"}
                      />
                    </div>
                  )}
                  {/* sharing percent end */}

                  {/* form-group start */}
                  <div>
                    <label htmlFor="total_expense" className="label">
                      Total Expense
                    </label>
                    <NumericFormat
                      decimalScale={3}
                      decimalSeparator="."
                      allowNegative
                      thousandSeparator={","}
                      disabled
                      style={{
                        backgroundColor: "#F1F2F3",
                        marginBottom: "-10rem",
                      }}
                      type="text"
                      onChange={handleChange}
                      id="total_expense"
                      name="total_expense"
                      placeholder="shows total value"
                      value={details?.total_expense || ""}
                      // onChange={handleChange}
                      className={`input`}
                    />
                  </div>
                  {/* form-group end */}
                </div>

                <div className="btn-box-wrap">
                  {/* btn-box-start */}
                  {/* <button
                    className="btn-reuse btn-two"
                    style={{
                      backgroundColor: "#ffff",
                      marginRight: "3rem",
                    }}
                    onClick={() =>
                      history.push(
                        "/employee-dashboard-actual-expense-submitted"
                      )
                    }
                  >
                    Submit for approval
                  </button> */}
                  {!approveLoader ? (
                    <input
                      onClick={(e) => {
                        handleSubmit(e, 1);
                      }}
                      style={{ marginRight: "3rem" }}
                      disabled={
                        !details?.chart_of_account_id ||
                        !details?.description ||
                        !startDate ||
                        !details?.receipt ||
                        !details?.unit_price ||
                        !details?.units ||
                        imageToUpload.length < 1
                      }
                      type="submit"
                      value={"Submit for approval"}
                      className={`form-submit ${
                        details?.chart_of_account_id &&
                        details?.description &&
                        startDate &&
                        details?.receipt &&
                        details?.unit_price &&
                        details?.units &&
                        imageToUpload.length > 0 &&
                        "form-submit-active"
                      }`}
                    />
                  ) : (
                    <div style={{ alignSelf: "center" }}>
                      <Dna
                        visible={true}
                        height="50"
                        width="200"
                        ariaLabel="dna-loading"
                        // wrapperStyle={{color: "red", backgroundColor : "red"}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  )}
                  {!draftLoader ? (
                    <input
                      onClick={(e) => {
                        handleSubmit(e, 0);
                      }}
                      disabled={!enableSubmit()}
                      type="submit"
                      value={"Save To Draft"}
                      className={`form-submit ${
                        enableSubmit() && "form-submit-active"
                      }`}
                      // style={{ height: "5rem" }}
                      style={{
                        background: "#ffff",
                        color: !enableSubmit() ? "#cccc" : "var(--blue-color)",
                        border: !enableSubmit()
                          ? ".1rem solid #cccc"
                          : ".1rem solid var(--blue-color)",
                      }}
                    />
                  ) : (
                    <div style={{ alignSelf: "center", marginLeft: "3rem" }}>
                      <Dna
                        visible={true}
                        height="50"
                        width="80"
                        ariaLabel="dna-loading"
                        // wrapperStyle={{color: "red", backgroundColor : "red"}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  )}
                  {/* btn-box-end */}
                </div>
                {/* form group-container end */}
              </div>
            </div>
            {/* </div> */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
      {/* modal start */}
      <Modal effect="fadeInUp" visible={showModal}>
        <EmpActualExpenseBulkModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal(false);
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInLeft" visible={showSharedModal}>
        <EmpSharedServiceModal
          onCancel={() => {
            setShowSharedModal(false);
            actionCtx?.setIsModalOut(false);
          }}
          list={isApportion}
          listData={apportionData}
          deptVal={details?.department_id?.label}
          onSubmit={handleApportionSubmit}
          formatApportionToSubmit={formatApportionToSubmit}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};
export default EmployeeActualNewExpense;
