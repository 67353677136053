import React from "react";
import "../../styles/dashboard/SubscriptionEndModal.css";
import imgLoad from "../../assets/https___lottiefiles.com_animations_error-5Dbr8RmAan.gif";
import ButtonBlue from "../buttons/ButtonBlue";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SubscriptionEndModal = () => {
  const history = useHistory();
  return (
    <div className="subscription-end-general-modal">
      <div className="wrap">
        <div className="img-wrap">
          <figure className="img-box">
            <img src={imgLoad} alt="" className="img" />
          </figure>
        </div>
        <p className="title">Subscription expired</p>
        <p className="text">Your subscription has expired</p>
        <ButtonBlue
          onClick={() => {
            history.push("/dashboard-administration-admin-settings-billing");
          }}
          btnClassName={`btn`}
          text={`Go back to billing`}
        />
      </div>
    </div>
  );
};

export default SubscriptionEndModal;
