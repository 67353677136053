import React, { useContext, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import ActionContext from "../../../context/ActionContext";
import "../../../styles/dashboard/DashboardLayout.css";
import HeaderNav from "./HeaderNav";
import Modal from "react-awesome-modal";
import SidebarMenu from "./SidebarMenu";
import NotificationModal from "./NotificationModal";
import { useSelector } from "react-redux";
import {
  // useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import SubscriptionEndModal from "../../fragments/SubscriptionEndModal";
// import { toast } from "react-toastify";
// import { useThemeDetector } from "../../../utils/getThemeColor";

const DashboardLayout = ({ children, goBack, pageTitle, pageTitleSpan }) => {
  const actionCtx = useContext(ActionContext);
  const location = useLocation();
  const [showExpire, setShowExpire] = useState(false)
  // const { user } = useSelector((state) => state.user);

  const { plan_details } = useSelector((state) => state.users);
  useEffect(() => {
    if (Object.keys(plan_details)?.length > 0) {
      if (
        plan_details?.active_plan_details?.status !== "active" &&  plan_details?.active_plan_details?.status !== "trail" &&
        location?.pathname !==
          "/dashboard-administration-admin-settings-billing"
      ) {
        setShowExpire(true)
        // history.push("/dashboard-home");
        // toast.info("Please Click on subscribe now to subscribe ", { theme: "colored",})
      }
    }

    // console.log(plan_details?.privilages);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan_details]);

  return (
    <>
      <div className="dashboard-layout-wrap">
        <div
          className={`sidebar ${actionCtx.isSidebarReduce && "sidebar-reduce"}`}
          style={{
            backgroundColor: "var(--blue-color)",
          }}
          // style={{
          //   backgroundColor:
          //     preference?.display === "Light"
          //       ? "var(--blue-color)"
          //       : preference?.display === "Dark"
          //       ? "var(--bg-white-color)"
          //       : isDarkTheme
          //       ? "var(--bg-white-color)"
          //       : "var(--blue-color)",
          // }}
        >
          <SidebarMenu setShowExpire={setShowExpire} />
        </div>
        <div
          className={`header ${actionCtx.isSidebarReduce && "header-reduce"}`}
        >
          <HeaderNav
            goBack={goBack}
            pageTitle={pageTitle}
            pageTitleSpan={pageTitleSpan}
          />
        </div>
        <div
          onClick={() => {
            actionCtx.profileBoxOpenFunc("close");
            actionCtx.setShowSupportDropDown("close");
          }}
          className={`main ${actionCtx.isSidebarReduce && "main-reduce"}`}
          style={{ zIndex: actionCtx?.isModalOut ? "4" : "1" }}
        >
          {children}
        </div>

        {/* notification start */}
        {actionCtx?.showNotification && <NotificationModal />}
        {/* notification end */}
      </div>
      <Modal visible={plan_details?.active_plan_details?.status !== "active" &&  plan_details?.active_plan_details?.status !== "trail" &&  location?.pathname !==
          "/dashboard-administration-admin-settings-billing"}>
        <SubscriptionEndModal />
      </Modal>
    </>
  );
};

export default DashboardLayout;
