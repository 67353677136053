import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
// import CommonExpenseLayout from "./CommonLayout";
// import EmployeeCommonExpenseLayout from "../expense/CommonLayout";

// import Select from "react-select";
import ActionContext from "../../../../../../context/ActionContext";
import { useHistory } from "react-router-dom";
// import ExpenseChart from "../../../../../../component/pages/dashboard/actuals/expense/ExpenseChart";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
// import TableBodyRow from "../../../../../../component/tables/TableBodyRow";
// import EmployeeDraftProjection from "./EmpDraftProjection";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
// import illustrationIcon from "../../../../assets/Illustration-icon.svg";
// import illustrationIcon from "../../../../../../assets/Illustration-icon.svg";
// import EmployeeCommonExpenseLayout from "../../projection/expense/CommonLayout";
// import EmployeeDraftProjection from "../../projection/expense/EmpDraftProjection";
import EmployeeCommonExpenseLayout from "./CommonLayout";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import Modal from "react-awesome-modal";
// import EmployeeActualNewExpense from "./form";
import EmployeeActualDraftExpense from "../actualDraftExpense";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeeActualExpensesDraft,
  getEmployeeActualExpensesDraftPagination,
  submitDraftEmployeeActualExpense,
} from "../../../../../../redux/employee/actual-expense";
import {
  formatNumber,
  perPageOptions,
  trimLongString,
  // FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import EmployeeActualExpenseDeleteModal from "../modals/deleteModal";
import { Dna } from "react-loader-spinner";
// import { ToastContainer } from "react-toastify";
import useDebounce from "../../../../../../helper/UseDebounce";
import moment from "moment";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
// import EmployeeActualOverviewModal from "../modals/overviewModal";
import EmployeeActualExpenseOverviewModal from "../modals/actualexpenseOverview";
import EmpActualSubmitForApproval from "../modals/submitModal";
import { deleteExpenditueProjection } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import BigShowModalComponent from "../../../../../../component/pages/dashboard/modal/BigShowModalComponent";
// import Modal from "react-awesome-modal"

const EmployeeActualExpenseIndex = ({ children, chartBox }) => {
  const [showModal, setShowModal] = useState({
    edit: false,
    delete: false,
    overview: false,
    submit: false,
    img: false,
    pdf: false,
  });
  const [showLink, setShowLink] = useState("");
  const [singleChild, setSingleChild] = useState({});
  const history = useHistory();
  const {
    loading,
    actualExpenseDraft,
    meta,
    links,
    loadingTwo,
    // actualBookBal,
    // actualAvailableBal,
  } = useSelector((state) => state.actual_expense_employee);
  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const [indexNo, setIndexNo] = useState("");
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const [refreshTable, setRefreshTable] = useState(false);
  // const [perPageVal, setPerPageVal] = useState({ label: "Per Page" });

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Unit",
    "Unit Price",
    "Date",
    "Action",
  ];

  const cardHeader = [
    "Projected Expense",
    "Actual Expense",
    "Book Balance",
    "Available Balance",
  ];
  // const cardValue = ["00.00", "00.00", "00.00", "00.00"];

  const [activeStat, setActiveStat] = useState({
    one: false, //change back to true
    two: false,
    three: false,
    four: false,
  });

  useEffect(() => {
    // getDraftActualExpense();
    handleGetActualExpenseData();
    // eslint-disable-next-line
  }, [refreshTable, user?.organizations[0]?.id, actionCtx?.perPage?.value]);
  // const getDraftActualExpense = () => {
  //   const id = user?.organizations[0]?.id;
  //   const obj = {
  //     id: id,
  //     search: "",
  //   };
  //   dispatch(getEmployeeActualExpensesDraft(obj));
  // };

  // const handlePagination = async (param) => {
  //   const splitLink = param.split("/api");
  //   const linkTwo = splitLink[1];
  //   setIndexNo(false);
  //   dispatch(getEmployeeActualExpensesDraftPagination(linkTwo));
  // };

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: false,
      filter: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: linkTwo,
    };
    setIndexNo(false);
    dispatch(getEmployeeActualExpensesDraftPagination(obj));
  };
  const handlePaginationNum = async (num) => {
    // const splitLink = param.split("/api");
    // const splitLink = param.split("?page=");
    // const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: false,
      filter: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: num,
    };
    setIndexNo(false);
    dispatch(getEmployeeActualExpensesDraftPagination(obj));
  };

  const handleFinish = () => {
    setIndexNo(false);
    setShowModal({
      edit: false,
      delete: false,
    });
    setRefreshTable(!refreshTable);
    actionCtx?.setIsModalOut(false);
  };

  const handleUpdate = (param) => {
    actionCtx?.setIsModalOut(false);
    const id = param?.id;
    localStorage.setItem("employee-actual-expense-id", id);
    // return;
    history.push("/employee-dashboard-actual-expense-update");
  };

  // const handleSubmitDrafts = async () => {
  //   const val = actualExpenseDraft.map((chi) => String(chi?.id));
  //   const obj = {
  //     actual_expense_ids: val,
  //   };
  //   const data = await dispatch(submitDraftEmployeeActualExpense(obj));
  //   if (data?.payload?.success) {
  //     handleFinish();
  //   }
  // };

  const handleSubmitDrafts = async () => {
    // const val = expenditureProjctions?.data?.map((chi) => String(chi?.id));
    const obj = {
      actual_expense_ids: selectedSubmit,
    };
    setSubmitLoading(true);
    const data = await dispatch(submitDraftEmployeeActualExpense(obj));
    if (data?.payload?.success) {
      setSubmitLoading(false);
      handleFinish();
      history.push("/employee-dashboard-actual-expense-submitted");
    } else {
      setSubmitLoading(false);
    }
  };

  // const tableSelectOption = [
  //   { label: "Chart of Account", value: "chart_of_account_id" },
  //   { label: "Class", value: "class" },
  //   { label: "Description", value: "description" },
  //   { label: "Units", value: "units" },
  //   { label: "Unit Price", value: "unit_price" },
  //   // { label: "Status", value: "status" },
  // ];

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const id = user?.organizations[0]?.id;
      const obj = {
        id: id,
        search: search,
        filter: filterByVal?.value,
        per_page: actionCtx?.perPage?.value,
      };
      dispatch(getEmployeeActualExpensesDraft(obj));
      setIndexNo(false);
    } else {
      // getDraftActualExpense();
      handleGetActualExpenseData();
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const [singleItem, setSingleItem] = useState({});

  const handleGetActualExpenseData = async () => {
    const id = user?.organizations[0]?.id;
    const obj = {
      id: id,
      search: "",
      per_page: actionCtx?.perPage?.value,
    };
    const data = await dispatch(getEmployeeActualExpensesDraft(obj));
    // console.log(data, "single");
    // setIndexNo(false);
    setSingleItem(data);
    // console.log(actualExpenseDraft, "actualdraft")
    // if (data?.success) {
    //   // console.log(data?.payload?.data);
    // }
  };

  const expenseData = actualExpenseDraft?.data?.actual_draft?.data;
  const singleExpenseData = actualExpenseDraft?.data;

  // const SortByOptions = [
  //   { label: "Ascending", value: "ASC" },
  //   { label: "Descending", value: "DESC" },
  // ];
  // const [sortByVal, setSortByVal] = useState({ label: "Sort by" });

  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart of Account", value: "chart_of_account_id" },
    { label: "Class", value: "class" },
    { label: "Description", value: "description" },
    { label: "Units", value: "units" },
    { label: "Unit Price", value: "unit_price" },
    // { label: "Date Created", value: "created_at" },
  ];

  // const handleSortChange = (param) => {
  //   const obj = {
  //     // q: search,
  //     sort_by: param?.value,
  //     per_page: actionCtx?.perPage?.value,
  //     // sort_by: sortByVal?.value,
  //     id: user?.organizations[0]?.id,
  //   };
  //   // console.log("obj", obj)
  //   setIndexNo(false);
  //   dispatch(getEmployeeActualExpensesDraft(obj));
  // };

  // console.log(expenseData, "expenseData")

  const cardValue = [
    singleExpenseData?.actual_expense || 0,
    singleExpenseData?.expenditure_projection || 0,
    singleExpenseData?.book_balance || 0,
    singleExpenseData?.available_balance || 0,
  ];

  // handleDeleteAll
  const [selectedSubmit, setSelectedSubmit] = useState([]);
  const [checkHeader, setCheckHeader] = useState(!true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async () => {
    // const id = selectedSubmit;
    const obj = {
      multi: true,
      ids: selectedSubmit,
      projection_type: "actual_expense",
    };
    // console.log(obj, "obj");
    setDeleteLoading(true);
    const data = await dispatch(deleteExpenditueProjection(obj));
    if (data?.payload?.success) {
      setDeleteLoading(false);
      handleFinish();
    } else {
      setDeleteLoading(false);
    }
  };

  const handleCheckSubmit = (id) => {
    const checkedList = selectedSubmit.indexOf(id);
    if (checkedList > -1) {
      setSelectedSubmit(selectedSubmit.filter((chi) => chi !== id));
    } else {
      setSelectedSubmit([...selectedSubmit, id]);
    }
  };

  const handleAllSubmitCheck = (e) => {
    if (e.target.checked) {
      setSelectedSubmit(expenseData?.map((chi) => chi.id));
    } else {
      setSelectedSubmit([]);
    }
    setCheckHeader(e.target.checked);
  };

  // get all tables
  // const [availBal, setAvailBal] = useState({});
  // const handleGetAvailableBalTable = async () => {
  //   const id = user?.organizations[0]?.id;
  //   const obj = {
  //     id: id,
  //     search: "",
  //     per_page: actionCtx?.perPage?.value,
  //   };
  //   const data = await dispatch(getEmployeeActualExpensesDraft(obj));
  //   console.log(data, "data")
  //   // if(data?.payload?.success){
  //   // }
  // };

  return (
    <>
      <EmployeeCommonExpenseLayout commonLink={true}>
        <EmployeeReusableTable
          submitAll
          onSubmitAll={() => {
            selectedSubmit?.length > 0 && handleSubmitDrafts();
          }}
          submitBgColor={selectedSubmit?.length < 1 && "#cccccc"}
          submitLoader={submitLoading}
          allActionBtn={expenseData?.length > 0}
          perPage={expenseData?.length > 0}
          optionPerPage={perPageOptions}
          setPerPage={(e) => {
            actionCtx?.setPerpage(e);
          }}
          perPageVal={actionCtx?.perPage}
          // sort
          filter={expenseData?.length > 0}
          // sort={true}
          // filter={true}
          // optionSort={SortByOptions}
          // optionSortVal={sortByVal}
          // setOptionSortVal={(e) => {
          //   setIndexNo(false);
          //   setSortByVal(e);
          //   handleSortChange(e);
          // }}
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => {
            setIndexNo(false);
            setFilterByVal(e);
          }}
          onSearchTable={(e) => {
            setIndexNo(false);
            setSearch(e);
          }}
          delBtn
          onDel={() => {
            selectedSubmit?.length > 0 && handleDelete();
          }}
          delLoader={deleteLoading}
          delBtnTitle={"Delete Expense"}
          delBgColor={selectedSubmit?.length > 0}
          addButton={expenseData?.length > 0}
          BtnTitle={`New Expense`}
          onPage={() => {
            history.push("/employee-dashboard-actual-expense-new");
          }}
          firstCard
          secondCard
          loading={loading}
          thirdCard
          fourthCard
          showSearch={expenseData?.length > 0}
          cardTitleOne={cardHeader[1]}
          titleCardTwo={cardHeader[0]}
          titleCardThree={cardHeader[2]}
          titleCardFour={cardHeader[3]}
          cardBodyOne={formatNumber(String(cardValue[1]) || 0)}
          cardBodyTwo={formatNumber(String(cardValue[0] || 0))}
          cardBodyThree={formatNumber(String(cardValue[2] || 0))}
          cardBodyFour={formatNumber(String(cardValue[3] || 0))}
          // onFirstCard={() => {
          //   // handleGetRevenueData("");
          //   setIndexNo(false);
          //   setActiveStat((prev) => {
          //     return {
          //       ...prev,
          //       one: true,
          //       two: false,
          //       three: false,
          //       four: false,
          //     };
          //   });
          // }}
          // onSecondCard={() => {
          //   // handleGetRevenueData("approved");
          //   setIndexNo(false);
          //   setActiveStat((prev) => {
          //     return {
          //       ...prev,
          //       one: false,
          //       two: true,
          //       three: false,
          //       four: false,
          //     };
          //   });
          // }}
          // onThirdCard={() => {
          //   // handleGetRevenueData("pending");
          //   setIndexNo(false);
          //   setActiveStat((prev) => {
          //     return {
          //       ...prev,
          //       one: false,
          //       two: false,
          //       three: true,
          //       four: false,
          //     };
          //   });
          // }}
          // onFourthCard={() => {
          //   // handleGetRevenueData("rejected");
          //   // handleGetAvailableBalTable();
          //   setIndexNo(false);
          //   setActiveStat((prev) => {
          //     return {
          //       ...prev,
          //       one: false,
          //       two: false,
          //       three: false,
          //       four: true,
          //     };
          //   });
          // }}
          linkCardOne={activeStat?.one}
          linkCardTwo={activeStat?.two}
          linkCardThree={activeStat?.three}
          linkCardFour={activeStat?.four}
          // onSecondCard={()=>{}}

          // showSearch={true}
          // Title={"new expense"}
        />

        {/* table start  */}
        {children}
        <div className="table-bo">
          {/* table box start */}
          <ActualReuseableTable
            key={`dffgjsh`}
            massSelectable={expenseData?.length > 0}
            checkValue={checkHeader}
            onMassCheck={(e) => {
              handleAllSubmitCheck(e);
            }}
            useNumPagination
            currentPage={meta?.current_page}
            totalTableLength={meta?.total}
            onNumPage={(e) => {
              handlePaginationNum(e);
            }}
            // addBtntext={`Create Expense`}
            // onCreateNew={() => {
            //   history.push("/employee-dashboard-actual-expense-new");
            // }}
            optionFilterVal={filterByVal}
            dontShowBnSearch
            setOptionFilterVal={(e) => setFilterByVal(e)}
            dontShowPagination={expenseData?.length < 1}
            // optionFilter={tableSelectOption}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            fromPage={meta?.from}
            toPage={meta?.to}
            totalPage={meta?.total}
            nextPage={links?.next_page_url}
            prevPage={links?.prev_page_url}
            onNextPage={() => handlePagination(links?.next_page_url)}
            onPrevPage={() => handlePagination(links?.prev_page_url)}
            onSearchTable={(e) => setSearch(e)}
          >
            {expenseData?.length > 0 ? (
              <>
                {expenseData?.map((chi, idx) => {
                  const {
                    // id,
                    chart_of_account,
                    description,
                    units,
                    unit_price,
                    expense_date,
                    id,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      checkBoxType
                      onCheck={(e) => {
                        handleCheckSubmit(id);
                      }}
                      checkValue={selectedSubmit.includes(id)}
                      checkId={`${id}-${idx}`}
                      item={chi}
                      rowNo={idx}
                      indexNo={indexNo}
                      setIndexNo={() => setIndexNo(idx)}
                      action
                      loading={loading}
                      key={idx}
                      one={chart_of_account?.name}
                      two={chart_of_account?.class}
                      three={trimLongString(description, "25")}
                      four={formatNumber(String(units) || 0)}
                      five={formatNumber(String(unit_price || 0))}
                      six={moment(expense_date).format("YYYY-MM-DD")}
                      deleteText="delete"
                      updateTextTwo={"Submit for Approval"}
                      onDelete={() => {
                        // console.log(chi);
                        actionCtx?.setIsModalOut(true);
                        setIndexNo(false);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, delete: true };
                        });
                      }}
                      updateText="update"
                      onUpdateTwo={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, submit: true };
                        });
                      }}
                      onUpdate={() => handleUpdate(chi)}
                      onOverview={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, overview: true };
                        });
                      }}
                    />
                  );
                })}
              </>
            ) : (
              <EmployeeActualDraftExpense>
                <ButtonBlue
                  onClick={() => {
                    history.push("/employee-dashboard-actual-expense-new");
                  }}
                  text={`Create Expense`}
                >
                  <FaPlus style={{ marginRight: "15px" }} />
                </ButtonBlue>
              </EmployeeActualDraftExpense>
            )}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
      </EmployeeCommonExpenseLayout>

      {/* sub mit draft actuals start */}
      {/* {expenseData?.length > 0 ? (
        <div className="submit-all-draft-actual-box">
          {loadingTwo ? (
            <div style={{ alignSelf: "center", marginLeft: "3rem" }}>
              <Dna
                visible={true}
                height="80"
                width="100"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <ButtonBlue
              onClick={handleSubmitDrafts}
              text="Submit for approval"
            />
          )}
        </div>
      ) : (
        ""
      )} */}
      {/* sub mit draft actuals end */}

      {/* delete modal start */}
      <Modal visible={showModal?.delete}>
        <EmployeeActualExpenseDeleteModal
          detail={singleChild}
          onFinish={handleFinish}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setSingleChild({});
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
          }}
        />
      </Modal>
      {/* delete modal  end */}
      {/* overview modal start */}
      <Modal visible={showModal?.overview} effect="fadeInLeft">
        <EmployeeActualExpenseOverviewModal
          setShowLink={setShowLink}
          onShowImg={() => {
            setShowModal((prev) => {
              return { ...prev, img: true, overview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);

            setShowModal((prev) => {
              return { ...prev, overview: false };
            });
          }}
          details={singleChild}
          handleSubmit={async () => {
            // e.preventDefault();
            // dispatch submit projection action
            // then redirect to the submission page

            const dat = { actual_expense_ids: [singleChild?.id] };

            const data = await dispatch(submitDraftEmployeeActualExpense(dat));
            if (data?.payload?.success) {
              handleFinish();
              history.push("/employee-dashboard-actual-expense-submitted");
            }
          }}
          onDelete={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, delete: true, overview: false };
            });
          }}
          onUpdate={() => handleUpdate(singleChild)}
        />
      </Modal>
      {/* overview modal end */}
      {/* modal start */}
      <Modal visible={showModal.submit}>
        <EmpActualSubmitForApproval
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, submit: false };
            });
          }}
          details={singleChild}
          onFinish={handleFinish}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.img} effect="fadeInLeft">
        <BigShowModalComponent
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, img: false, overview: true };
            });
          }}
          detail={showLink}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeActualExpenseIndex;
