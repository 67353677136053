import React from "react";
// import CommonSortBox from "../../../../../../component/fragments/CommonSortBox";
// import CommonNavLink from "../../../../../../component/pages/dashboard/actuals/expense/CommonNavLink";
// import ActionContext from "../../../../../../context/ActionContext";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
// import ActionContext from "../../../../../context/ActionContext";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeCommonNavLink from "./CommonNavLink";
import Select from "react-select";
import { reactSelectStyle } from "../../../../../../helper/Helpers";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { Dna } from "react-loader-spinner";

const EmployeeCommonRevenueLayout = ({
  children,
  chartBox,
  showChart,
  setShowChart,
  options,
  exportVal,
  onExport,
  exportDrop,
  onGetDept,
  loading,
  onFileDownload,
  exportSelect,
}) => {
  // const actionCtx = useContext(ActionContext);

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Revenue Projection`}> */}
      <div className="expense-projection-inde-wrap">
        {exportDrop && (
          <div className="header-submit-filter">
            <label>Export</label>
            <Select
              styles={reactSelectStyle}
              className="select-filter-by"
              options={options}
              placeholder="File Type"
              value={exportVal}
              onChange={(e) => onExport(e)}
            />

            {/* <ButtonBlue
              onClick={onGetDept}
              text="All Department"
              //
            /> */}
          </div>
        )}
        <>
          <EmployeeCommonNavLink link={"/dashboard-home"} />
        </>
        {/* common links end */}
        {/* chart and table box start */}
        <>{chartBox}</>
        {/* chart  box end */}
        {/* children box start */}
        <>{children}</>
        {/* children box end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};

export default EmployeeCommonRevenueLayout;
