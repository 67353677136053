import React, { useState } from "react";
import { FaAngleRight, FaCheck, FaEllipsisV, FaPlus } from "react-icons/fa";
// import { Button, Dropdown } from "react-bootstrap";
import "../../styles/dashboard/tables/ActualReuseableTable.css";
import ProgressBar from "../fragments/ProgressBar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Dna } from "react-loader-spinner";

const TableBodyRow = ({
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  twelve,
  thirtheeen,
  action,
  status,
  imageData,
  Freenum,
  indexNo,
  rowNo,
  setIndexNo,
  updateText,
  onUpdate,
  deleteText,
  bigActionPop,
  onDelete,
  viewText,
  onView,
  donstShowStatusCircle,
  invoice,
  onDownLoadInvoice,
  progressType,
  user,
  onNextPage,
  userOne,
  loading,
  imgDataName,
  textOneText,
  textTwoText,
  ontextOneText,
  onTextTwoText,
  removeCapitalize,
  checkBoxType,
  onCheck,
  checkValue,
  checkId,
  loadingDownload,
}) => {
  const [showDropAction, setShowDropAction] = useState(false);
  return (
    <>
      <tr
        style={{
          zIndex: showDropAction && rowNo === indexNo ? "3" : "1",
          cursor: onNextPage ? "pointer" : "",
        }}
      >
        <td
          onClick={() => {
            //  onNextPage && onNextPage();
            setShowDropAction(false);
          }}
        >
          {checkBoxType ? (
            <div className="table-check-wrap-box">
              <input
                type="checkbox"
                name={`table-check-${checkId}`}
                id={`table-check-${checkId}`}
                className="table-check-input-row"
                value={checkValue}
                onChange={(e) => {
                  onCheck(e);
                  // console.log(checkId);
                }}
                checked={checkValue}
              />
              {loading ? (
                <Skeleton width={17} height={17} />
              ) : (
                <label
                  className="table-check-label-input-row"
                  htmlFor={`table-check-${checkId}`}
                >
                  <FaCheck className="icon" />
                </label>
              )}
            </div>
          ) : (
            <span></span>
          )}
        </td>
        <td
          onClick={() => {
            //  onNextPage && onNextPage();
            setShowDropAction(false);
          }}
        >
          {loading ? (
            <Skeleton width={17} height={17} />
          ) : (
            <span>{rowNo + 1 < 10 ? `0${rowNo + 1}` : rowNo + 1}</span>
          )}
        </td>
        {one && (
          <td
            style={{ color: Freenum ? "var(--blue-color)" : "" }}
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : one}
          </td>
        )}
        {userOne && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? (
              <Skeleton width={"70%"} height={17} />
            ) : (
              <div className="user-box">
                <figure className="img-box">
                  <img src={userOne?.img} alt="" className="img" />
                </figure>
                <p className="text">{userOne?.name}</p>
              </div>
            )}
          </td>
        )}
        {two && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
            style={{ textTransform: removeCapitalize && "lowercase" }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : two}
          </td>
        )}
        {three && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : three}
          </td>
        )}
        {four && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : four}
          </td>
        )}
        {user && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? (
              <Skeleton width={"70%"} height={17} />
            ) : (
              <div className="user-box">
                {user?.img && (
                  <figure className="img-box">
                    <img src={user?.img} alt="" className="img" />
                  </figure>
                )}
                <p className="text">{user.name}</p>
              </div>
            )}
          </td>
        )}
        {five && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : five}
          </td>
        )}
        {status && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? (
              <Skeleton width={"70%"} height={17} />
            ) : (
              <p
                style={{
                  color:
                    status.toLowerCase() === "active"
                      ? "#07A287"
                      : status.toLowerCase() === "successful"
                      ? "#07A287"
                      : "#FCA549",
                }}
                className={`body-text `}
              >
                {!donstShowStatusCircle && <span></span>}
                {status}
              </p>
            )}
          </td>
        )}
        {six && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : six}
          </td>
        )}
        {seven && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : seven}
          </td>
        )}
        {invoice && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? (
              <Skeleton width={"70%"} height={17} />
            ) : (
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className=""
              >
                {" "}
                {loadingDownload ? (
                  <>
                    {" "}
                    <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                      <Dna
                        visible={true}
                        height="35"
                        width="100"
                        ariaLabel="dna-loading"
                        // wrapperStyle={{color: "red", backgroundColor : "red"}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  </>
                ) : (
                  <span
                    onClick={() => {
                      onDownLoadInvoice && onDownLoadInvoice();
                    }}
                    style={{
                      color: "var(--blue-color)",
                      // backgroundColor: "red",
                      cursor: "pointer",
                    }}
                  >
                    Download Invoice
                  </span>
                )}
              </div>
            )}
          </td>
        )}
        {eight && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : eight}
          </td>
        )}
        {progressType && (
          <td>
            {loading ? (
              <Skeleton width={"70%"} height={17} />
            ) : (
              <div className="progress-text-wrap">
                <div className="progress-wrap">
                  {/* <ProgressBar
                    bgcolor={
                      progressType === "reject"
                        ? "#CC0905"
                        : progressType === "accept"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  />
                  {/* <ProgressBar
                    bgcolor={
                      progressType === "reject"
                        ? "#CC0905"
                        : progressType === "accept"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  /> */}
                  {/* <ProgressBar
                    bgcolor={
                      progressType === "reject"
                        ? "#CC0905"
                        : progressType === "accept"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  /> */}
                  {/* <ProgressBar
                    bgcolor={
                      progressType === "reject"
                        ? "#CC0905"
                        : progressType === "accept"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  /> */}
                  <ProgressBar
                    bgcolor={
                      progressType === "reject"
                        ? "#CC0905"
                        : progressType === "accept"
                        ? "#07A287"
                        : "#FCA549"
                    }
                    completed={100}
                  />
                </div>
                <p className="text">
                  {progressType === "reject"
                    ? "Rejected"
                    : progressType === "accept"
                    ? "Approved"
                    : "Pending"}
                </p>
              </div>
            )}
          </td>
        )}
        {nine && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : nine}
          </td>
        )}
        {imageData && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
            className="img-wrap "
          >
            {loading ? (
              <Skeleton width={"70%"} height={17} />
            ) : (
              <>
                {imageData?.map((chi, idx) => {
                  const { img, first_name, last_name } = chi;
                  if (!img && idx === 0) {
                    return (
                      <>
                        {first_name && last_name ? (
                          <div
                            key={idx}
                            className="img-data-name"
                            style={{ color: "#ffffff" }}
                          >
                            <>
                              <span style={{ color: "#ffffff" }}>
                                {first_name?.charAt(0)}
                              </span>
                              <span style={{ color: "#ffffff" }}>
                                {last_name?.charAt(0)}
                              </span>
                            </>
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </>
                    );
                  }
                  if (!img && idx === 1) {
                    return (
                      <>
                        {first_name && last_name ? (
                          <div
                            key={idx}
                            className="img-data-name img-data-name-two"
                            style={{ color: "#ffffff" }}
                          >
                            <>
                              <span style={{ color: "#ffffff" }}>
                                {first_name?.charAt(0)}
                              </span>
                              <span style={{ color: "#ffffff" }}>
                                {last_name?.charAt(0)}
                              </span>
                            </>
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </>
                    );
                  }
                  if (img && idx === 0) {
                    return (
                      <figure key={idx} className="img-box">
                        <img src={img} alt="" className="img" />
                      </figure>
                    );
                  }
                  if (img && idx === 1) {
                    return (
                      <figure key={idx} className="img-box">
                        <img src={img} alt="" className="img" />
                      </figure>
                    );
                  }
                  return <React.Fragment key={idx}></React.Fragment>;
                })}
                {imageData.length > 2 && (
                  <div className="other">
                    <FaPlus className="icon" /> {imageData.length - 2}
                  </div>
                )}
              </>
            )}
          </td>
        )}
        {imgDataName && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
            className="img-wrap img-wrap-two"
          >
            {loading ? (
              <Skeleton width={"70%"} height={17} />
            ) : (
              <>
                {imgDataName.map((chi, idx) => {
                  const { first_name, last_name } = chi;
                  if (idx === 0 || idx === 1) {
                    return (
                      <div key={idx} className="img-data-name">
                        <>
                          <span>{first_name.charAt(0)}</span>
                          <span>{last_name.charAt(0)}</span>
                        </>
                      </div>
                    );
                  }
                  return (
                    <div className="other">
                      <FaPlus className="icon" /> {imgDataName.length - 2}
                    </div>
                  );
                })}
              </>
            )}
          </td>
        )}
        {ten && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : ten}
          </td>
        )}
        {twelve && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : twelve}
          </td>
        )}
        {thirtheeen && (
          <td
            onClick={() => {
              onNextPage && onNextPage();
              setShowDropAction(false);
            }}
          >
            {loading ? <Skeleton width={"70%"} height={17} /> : thirtheeen}
          </td>
        )}
        {action && (
          <td
            onClick={() => {
              onNextPage && onNextPage && onNextPage();
            }}
          >
            {/* wrpa start */}
            <div className="drop-wrap">
              {onNextPage ? (
                <FaAngleRight className="drop-icon" />
              ) : (
                <FaEllipsisV
                  className="drop-icon"
                  onClick={() => {
                    setIndexNo();
                    indexNo === rowNo
                      ? setShowDropAction(!showDropAction)
                      : setShowDropAction(true);
                  }}
                />
              )}
              <div
                className={`drop-box ${
                  showDropAction && indexNo === rowNo && "drop-box-show"
                }`}
                style={{ width: bigActionPop && "22rem" }}
                onClick={() => setShowDropAction(!showDropAction)}
              >
                {updateText && (
                  <p onClick={onUpdate} className="text">
                    {updateText}
                  </p>
                )}
                {viewText && (
                  <p onClick={onView} className="text">
                    {viewText}
                  </p>
                )}
                {textOneText && (
                  <p onClick={ontextOneText} className="text">
                    {textOneText}
                  </p>
                )}
                {textTwoText && (
                  <p onClick={onTextTwoText} className="text">
                    {textTwoText}
                  </p>
                )}
                {deleteText && (
                  <p
                    style={{ color: "#CC0905" }}
                    onClick={onDelete}
                    className="text"
                  >
                    {deleteText}
                  </p>
                )}
              </div>
            </div>
            {/* wrpa end */}
          </td>
        )}
      </tr>
    </>
  );
};

export default TableBodyRow;
