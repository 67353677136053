import React from "react";

const ApprovalContent = ({ headerOne, headerTwo, headerThree, children }) => {
  return (
    <div className="content-wrap">
      <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr  1fr" }}>
        {headerOne && <p>{headerOne}</p>}
        {headerTwo && <p>{headerTwo}</p>}
        {headerThree && <p>{headerThree}</p>}
      </div>

      {children}
    </div>
  );
};

export default ApprovalContent;
