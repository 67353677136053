import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc } from "../../../helper/Helpers";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getPortfolios = createAsyncThunk(
  "admin/get-portfolios",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/dropdowns/portfolio/${formData?.org_id}`
      );
      // console.log("branch", data.data[0]);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setPortfolios(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // const departmentsInfo = data.data.classes;
        await thunkAPI.dispatch(setPortfolioDrop(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getPortfoliosWithPagination = createAsyncThunk(
  "admin/get-portfolio-with-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/portfolios/all-portfolio/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}`
      );
      // console.log("branch", data.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setPortfolios(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("branch", data.data);
        await thunkAPI.dispatch(setPortfolios(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getVerticalByFilter = createAsyncThunk(
  "admin/get-vertical-filter",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/admin/verticals/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setPortfolios(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const verticalInfo = data.data.verticals;
        await thunkAPI.dispatch(setPortfoliosFilter(verticalInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getPortfolioPagination = createAsyncThunk(
  "admin/get-portfolio-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        // await thunkAPI.dispatch(setPortfolios(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        // console.log("branch", data.data);
        await thunkAPI.dispatch(setPortfolios(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getPortfolio = createAsyncThunk(
  "admin/get-portfolio",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(
        `/admin/portfolios/get-portfolio/${formData}`
      );
      console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createPortfolio = createAsyncThunk(
  "admin/create-portfolio",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/admin/portfolios/portfolio",
        formData
      );
      //   console.log('class', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deletePortfolio = createAsyncThunk(
  "admin/delete-portfolio",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `/admin/portfolios/delete-portfolio/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updatePortfolio = createAsyncThunk(
  "admin/update-vertical",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `/admin/portfolios/update-portfolio/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err?.response?.status === 422) {
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const portfolio = createSlice({
  name: "portfolio",
  initialState: {
    portfolioDrop: [],
    portfolios: [],
    links: {},
    meta: {},
    portfolio: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setPortfolios: (state, action) => {
      state.isAuth = true;
      state.portfolios = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setPortfoliosFilter: (state, action) => {
      state.isAuth = true;
      state.portfolios = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setPortfolioDrop: (state, action) => {
      state.isAuth = true;
      state.portfolioDrop = addLabelValueFunc(action.payload);
    },
    setPortfolio: (state, action) => {
      state.isAuth = true;
      state.portfolio = action.payload;
    },
  },
  extraReducers: {
    [createPortfolio.pending]: (state) => {
      state.loading = true;
    },
    [createPortfolio.fulfilled]: (state) => {
      state.loading = false;
    },
    [createPortfolio.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updatePortfolio.pending]: (state) => {
      state.loading = true;
    },
    [updatePortfolio.fulfilled]: (state) => {
      state.loading = false;
    },
    [updatePortfolio.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getPortfolios.pending]: (state) => {
      state.isLoading = true;
    },
    [getPortfolios.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getPortfolios.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.isLoading = false;
      state.isAuth = false;
      state = null;
    },
    [getPortfolioPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getPortfolioPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getPortfolioPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getVerticalByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getVerticalByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getVerticalByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getPortfoliosWithPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getPortfoliosWithPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getPortfoliosWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deletePortfolio.pending]: (state) => {
      state.loading = true;
    },
    [deletePortfolio.fulfilled]: (state) => {
      state.loading = false;
    },
    [deletePortfolio.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPortfolio,
  setPortfolios,
  setPortfolioDrop,
  setPortfoliosFilter,
} = portfolio.actions;

export default portfolio.reducer;
