import React, { useContext, useEffect, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
import CommonExpenseLayout from "./CommonLayout";
import Select from "react-select";
import ExpenseChart from "./ExpenseChart";
import ActualReuseableTable from "../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../tables/TableBodyRow";
import ActionContext from "../../../../../context/ActionContext";
import { useHistory } from "react-router-dom";
import {
  checkCurrencyFormat,
  formatNumberWithComma,
  // FormatNumWithCurrency,
  getChartStructures,
  perPageOptions,
  // reactSelectStyle,
  reactSelectStyleTable,
  // reactSelectStyleCommonSort,
} from "../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  getExpenditureProjectionCharts,
  getExpenditureProjectionChartsPagination,
} from "../../../../../redux/projections/ExpenditureProjection";

const ExpenseProjectionIndex = ({ children, chartBox }) => {
  const [indexNo, setIndexNo] = useState("");
  // const [chartFilterVal, setChartFilterVal] = useState("");
  const history = useHistory();
  const { loading, expenditure_projection, loadingPage } = useSelector(
    (state) => state.expenditure_projection_chart
  );
  const [dataChartValue, setDataChartValue] = useState({
    labels: "",
    datasets: [
      {
        label: "Approved",
        data: [],
        backgroundColor: "#07A287",
      },
      {
        label: "Pending",
        data: [],
        backgroundColor: "#FCA549",
      },
      {
        label: "Rejected",
        data: [],
        backgroundColor: "#CC0905",
      },
    ],
  });

  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();

  useEffect(() => {
    getChartValueHere();
    // eslint-disable-next-line
  }, [actionCtx?.activeOrg, actionCtx?.currencyValue, actionCtx?.perPage]);

  const getChartValueHere = async () => {
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    // console.log(obj);
    const data = await dispatch(getExpenditureProjectionCharts(obj));
    // console.log(data);
    if (data?.payload?.success) {
      const list = getChartStructures(data?.payload?.data?.graph_data);
      setDataChartValue(list);
    }
  };

  const handlePagination = (url ) => {
    const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getExpenditureProjectionChartsPagination(obj));
  };

  const handlePaginationNum = (num) => {
    // const num = url.split("?page=")[1];
    const localOrg = localStorage?.getItem("user");
    const obj = {
      org_id: actionCtx?.activeOrg?.id || localOrg?.organizations[0]?.id,
      num: num,
      cur_id: actionCtx?.currencyValue?.id || "",
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getExpenditureProjectionChartsPagination(obj));
  };



  // const categoryList = [
  //   { label: "Zojapay", value: 1 },
  //   { label: "ITH", value: 2 },
  //   { label: "Zojatech", value: 3 },
  // ];

  const tableHeadList = [
    "Subsidiary",
    "Branch",
    "Departments",
    `Amount Projected ( ${checkCurrencyFormat(actionCtx?.currencyValue?.label?.split(" - ")[0])} )`,
    `Amount Rejected ( ${checkCurrencyFormat(actionCtx?.currencyValue?.label?.split(" - ")[0])} )`,
    "Status",
    "Action",
  ];

  return (
    <>
      <CommonExpenseLayout setDataChartValue={setDataChartValue}>
        {/* graph wrap start */}

        {actionCtx.isShowChart?.expense_projection_box && (
          <div
            className={`chart-wrap ${
              actionCtx.isShowChart?.expense_projection && "chat-wrap-show"
            }`}
          >
            {/* title select box start */}
            <div className="title-select-box">
              <p className="title">Expense Projection Graph</p>
              {/* <Select
                // isSearchable={true}
                // isMulti
                styles={reactSelectStyleCommonSort}
                placeholder="Sort By"
                className="select"
                value={chartFilterVal.value}
                onChange={(e) => setChartFilterVal(e.value)}
                options={categoryList}
              /> */}
            </div>
            {/* title select box end */}
            {/* chart start */}

            <div className="chart-box">
              <ExpenseChart loading={loading} dataToShow={dataChartValue} />
            </div>
            {/* chart end */}
          </div>
        )}

        {/* graph wrap end */}
        {/* table start  */}
        <div className="table-box">
          {/* table box start */}
          {/* perpage box start */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              position: "relative",
              zIndex: "20",
              marginBottom: "-1rem",
            }}
            className="perpage-wrap"
          >
            <span style={{ fontWeight: "600" }}> All Expense Projection</span>{" "}
            <Select
              styles={reactSelectStyleTable}
              onChange={(e) => {
                actionCtx?.setPerpage(e);
              }}
              value={actionCtx?.perPage}
              options={perPageOptions}
            />
          </div>
          {/* perpage box end */}
          <ActualReuseableTable
          
          loading={loading && expenditure_projection?.length < 1}
          loadingNum={7}
            useNumPagination
            currentPage={
              expenditure_projection?.departments_projections?.current_page
            }
            totalTableLength={
              expenditure_projection?.departments_projections?.total
            }
            onNumPage={(e) => {
              handlePaginationNum(e);
            }}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six
            toPage={expenditure_projection?.departments_projections?.to}
            fromPage={expenditure_projection?.departments_projections?.from}
            totalPage={expenditure_projection?.departments_projections?.total}
            onPrevPage={() =>
              handlePagination(
                expenditure_projection?.departments_projections?.prev_page_url
              )
            }
            onNextPage={() =>
              handlePagination(
                expenditure_projection?.departments_projections?.next_page_url
              )
            }
            prevPage={
              expenditure_projection?.departments_projections?.prev_page_url
            }
            nextPage={
              expenditure_projection?.departments_projections?.next_page_url ||
              ""
            }
          >
            {expenditure_projection?.departments_projections?.data?.map(
              (chi, idx) => {
                const {
                  subsidiary,
                  branch,
                  department,
                  amount_projected,
                  amount_rejected,
                } = chi || "";
                return (
                  <TableBodyRow
                    key={idx}
                    one={subsidiary?.name || ""}
                    two={branch?.name || ""}
                    three={department?.name || ""}
                    four={
                      amount_projected === 0
                        ? "0"
                        : formatNumberWithComma(String(amount_projected))
                    }
                    five={formatNumberWithComma(String(amount_rejected || 0))}
                    loading={loading || loadingPage}
                    action
                    rowNo={idx}
                    indexNo={indexNo}
                    setIndexNo={() => setIndexNo(idx)}
                    updateText={` Update Branch`}
                    onNextPage={() => {
                      localStorage.setItem(
                        "expense-pro-chart-id",
                        department?.id
                      );
                      history.push("/dashboard-projection-expense-main");
                    }}
                    //   onUpdate={() =>
                    //     history.push(
                    //       "/dashboard-administration-admin-settings-branch-update"
                    //     )
                    //   }
                  />
                );
              }
            )}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
        {/* table end */}
      </CommonExpenseLayout>
    </>
  );
};

export default ExpenseProjectionIndex;


{/* <svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_13500_14654)">
<g clip-path="url(#clip1_13500_14654)">
<path d="M18.0039 9.99512C22.0538 9.99512 25.3606 13.1899 25.4997 17.2442L25.5039 17.4951V21.5921L26.8839 24.7481C26.953 24.906 26.9886 25.0765 26.9886 25.2489C26.9886 25.9392 26.429 26.4989 25.7386 26.4989L21.0039 26.5004C21.0039 28.1572 19.6608 29.5004 18.0039 29.5004C16.4062 29.5004 15.1002 28.2515 15.009 26.6767L15.0035 26.4981L10.2788 26.4989C10.1074 26.4989 9.93792 26.4637 9.78077 26.3954C9.14757 26.1204 8.85722 25.3841 9.13226 24.7509L10.5039 21.593V17.495C10.5045 13.3402 13.856 9.99512 18.0039 9.99512ZM19.5035 26.4981L16.5039 26.5004C16.5039 27.3288 17.1755 28.0004 18.0039 28.0004C18.7836 28.0004 19.4244 27.4055 19.497 26.6448L19.5035 26.4981ZM18.0039 11.4951C14.6837 11.4951 12.0044 14.1694 12.0039 17.4951V21.9047L10.6599 24.9989H25.3564L24.0039 21.9057L24.004 17.508L24.0003 17.2828C23.8892 14.0493 21.2455 11.4951 18.0039 11.4951ZM27.0039 16.2489H29.0039C29.4181 16.2489 29.7539 16.5847 29.7539 16.9989C29.7539 17.3786 29.4718 17.6924 29.1057 17.742L29.0039 17.7489H27.0039C26.5897 17.7489 26.2539 17.4131 26.2539 16.9989C26.2539 16.6192 26.5361 16.3054 26.9021 16.2557L27.0039 16.2489ZM7.00391 16.2489H9.00391C9.41812 16.2489 9.75391 16.5847 9.75391 16.9989C9.75391 17.3786 9.47175 17.6924 9.10568 17.742L9.00391 17.7489H7.00391C6.58969 17.7489 6.25391 17.4131 6.25391 16.9989C6.25391 16.6192 6.53606 16.3054 6.90214 16.2557L7.00391 16.2489ZM28.6039 10.5489C28.8298 10.8501 28.7949 11.266 28.5377 11.5259L28.4539 11.5989L26.4539 13.0989C26.1225 13.3474 25.6524 13.2803 25.4039 12.9489C25.178 12.6476 25.2129 12.2317 25.4701 11.9719L25.5539 11.8989L27.5539 10.3989C27.8853 10.1504 28.3554 10.2175 28.6039 10.5489ZM8.45391 10.3989L10.4539 11.8989C10.7853 12.1474 10.8524 12.6175 10.6039 12.9489C10.3554 13.2803 9.88528 13.3474 9.55391 13.0989L7.55391 11.5989C7.22254 11.3504 7.15538 10.8803 7.40391 10.5489C7.65243 10.2175 8.12254 10.1504 8.45391 10.3989Z" fill="#004BFF"/>
</g>
</g>
<rect x="0.503906" y="0.499023" width="35" height="39" rx="5.5" stroke="#004BFF"/>
<defs>
<clipPath id="clip0_13500_14654">
<rect width="24" height="24" fill="white" transform="translate(6.00391 7.99902)"/>
</clipPath>
<clipPath id="clip1_13500_14654">
<rect width="24" height="24" fill="white" transform="translate(6.00391 7.99902)"/>
</clipPath>
</defs>
</svg> */}
