import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../../utils/axios";
import setAuthToken from "../../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getConsolidatedReport = createAsyncThunk(
  "admin/get-actual-employee-expense-draft",
  async (formData, thunkAPI) => {
    try {
      const { org_id, dept_id } = formData;
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      console.log(org_id);
      const { data } = await axios.get(
        `/employee/reports/consolidated-report?organization_id=${org_id}&department_id=${dept_id}`
      );
      console.log(data);
      if (!data?.success) {
        //   toast.error(data.message, {
        //     theme: "colored",
        //   });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        //   await thunkAPI.dispatch(setConsolidateReport(data.data.expense));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjectionReport = createAsyncThunk(
  "admin/get-revenue-projection-report",
  async (formData, thunkAPI) => {
    try {
      const { org_id, dept_id } = formData;
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      // console.log(org_id);
      const { data } = await axios.get(
        `/employee/reports/revenue-projection-report?organization_id=${
          org_id || ""
        }&department_id=${dept_id || ""}`
      );
      // console.log(data);
      // const { data } = await axios.get(
      //   `/employee/reports/revenue-projection-report?organization_id=${
      //     formData?.org_id || ""
      //   }&date=${formData?.date}&department_id=${
      //     formData?.dept_id || ""
      //   }&user_id=${formData?.user_id || ""}&subsidiary_id=${
      //     formData?.sub_id || ""
      //   }`
      // );
      if (!data?.success) {
        //   toast.error(data.message, {
        //     theme: "colored",
        //   });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data);
        //   await thunkAPI.dispatch(setConsolidateReport(data.data.expense));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.message === "Network Error") {
        toast.error(err.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const general_report = createSlice({
  name: "general_report",
  initialState: {
    consolidate_report: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setConsolidateReport: (state, action) => {
      state.isAuth = true;
      state.consolidate_report = action.payload.data;
    },
  },
  extraReducers: {
    [getConsolidatedReport.pending]: (state) => {
      state.loading = true;
    },
    [getConsolidatedReport.fulfilled]: (state) => {
      state.loading = false;
    },
    [getConsolidatedReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConsolidateReport } = general_report.actions;

export default general_report.reducer;
