import React, { useContext } from "react";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
// import EmployeeDraftProjection from "../../projection/expense/EmpDraftProjection";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import EmployeeCommonRevenueLayout from "./layout/CommonLayout";
import EmployeeActualRevenueDraftExpense from "./revenueDraftIndex";
import { useDispatch, useSelector } from "react-redux";
import {
  getActualSubmittedRevenueBySearch,
  getAllRevenueActualComment,
  getSubmittedActualRevenue,
  getSubmittedActualRevenuePagination,
} from "../../../../../../redux/employee/actual-revenue/actual-revenue";
import { useState } from "react";
import { useEffect } from "react";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import EmployeeActualRevenuePendingOverviewModal from "./layout/modals/pendingOverview";
import EmployeeActualRevenueApproveOverviewModal from "./layout/modals/approveOverviewModal";
import EmployeeActualRevenueRejectOverviewModal from "./layout/modals/rejectOverview";
import Modal from "react-awesome-modal";
import EmployeeRevenueApprovalModal from "../modals/approve";
import EmployeeRevenueComentModal from "../modals/comment";
import EmployeeRejectModal from "../modals/reject";
import {
  checkCurrencyFormat,
  formatNumber,
  perPageOptions,
} from "../../../../../../helper/Helpers";
import useDebounce from "../../../../../../helper/UseDebounce";
import ActionContext from "../../../../../../context/ActionContext";
import moment from "moment";
// import { useRef } from "react";
// import { useReactToPrint } from "react-to-print";
// import Papa from "papaparse";
import {
  getEmployeeProjectionDownload,
  getExportToPdf,
  getUserDepartment,
  recallProjections,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { notifyNextApprover } from "../../../../../../redux/projections/ExpenditureProjection";
import { getDepartments } from "../../../../../../redux/department/deparment";
import { getVerticals } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import { getProducts } from "../../../../../../redux/budget-setting/revenue-projection/product";
import FinalApprovalModal from "./modal/FinalApprovalModal";
import ActualRevenueRecallModal from "./modal/recallModal";

const EmployeeActualRevenueSubmit = ({ children }) => {
  const { user } = useSelector((state) => state.user);
  const { loadingPage, submittedActualRevenues } = useSelector(
    (state) => state.actual_revenue
  );
  const [selectStat, setSelectStat] = useState("");
  const [dept, setDept] = useState("");
  const { userDepartmentDrop, loading, recallLoading } = useSelector(
    (state) => state.expenditure_projection
  );
  const { productDrop } = useSelector((state) => state.product);
  const { verticalDrop } = useSelector((state) => state.vertical);
  const { departmentDrop } = useSelector((state) => state.department);
  const actionCtx = useContext(ActionContext);

  const [refreshTable] = useState(false);
  const [search, setSearch] = useState("");
  const [modalView, setModalView] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [showModal, setShowModal] = useState({
    approve: false,
    comment: false,
    reject: false,
    overview: false,
    approveOverview: false,
    rejectOverview: false,
    recall: false,
  });
  const [activeStat, setActiveStat] = useState({
    one: true,
    two: false,
    three: false,
    four: false,
  });
  const [singleChild, setSingleChild] = useState({});
  const [indexNo, setIndexNo] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const [productVal, setProductVal] = useState("");
  const [verticalVal, setVerticalVal] = useState("");

  const [filterByVal, setFilterByVal] = useState("");
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Product", value: "product_id" },
    { label: "Vertical", value: "vertical_id" },
  ];

  const statusDropdown = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const statusDropDownTwo = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  // const dispatch = useDispatch();
  const debouncedSearchTerm = useDebounce(search, 1000);
  const handleSearch = (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      search: search,
      dept_id: dept?.id,
      filter_by: filterByVal?.value,
      status: selectStat?.value || param,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      // sort_by: sortByVal?.value,
    };
    setIndexNo(false);
    dispatch(getActualSubmittedRevenueBySearch(obj));
  };

  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      (activeStat?.one && handleSearch("")) ||
        (activeStat?.two && handleSearch("approved")) ||
        (activeStat?.three && handleSearch("pending")) ||
        (activeStat?.four && handleSearch("rejected"));
      setIndexNo(false);
    } else {
      // handleGetActualRevenueData();
      (activeStat?.one && handleGetActualRevenueData("")) ||
        (activeStat?.two && handleGetActualRevenueData("approved")) ||
        (activeStat?.three && handleGetActualRevenueData("pending")) ||
        (activeStat?.four && handleGetActualRevenueData("rejected"));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const obj = {
      reason: true,
      id: user?.organizations[0]?.id,
    };
    if (user?.organizations[0]?.id) {
      dispatch(getUserDepartment(user?.organizations[0]?.id));
      dispatch(getDepartments(user?.organizations[0]?.id));
      dispatch(getVerticals(obj));
      dispatch(getProducts(obj));
    }
  }, [user?.organizations[0]?.id]);

  useEffect(() => {
    if (actionCtx?.perPage || actionCtx?.currencyValue) {
      handleGetActualRevenueData();
    }
  }, [actionCtx?.perPage, actionCtx?.currencyValue]);

  const handlePagination = async (param, statusParam) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      dept_id: dept?.id,
      status: statusParam || selectStat?.value,
      filter: filterByVal?.value,
      search: search,
      // sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      product_id: productVal?.value,
      vertical_id: verticalVal?.value,
    };
    setIndexNo(false);
    dispatch(getSubmittedActualRevenuePagination(obj));
  };
  const handlePaginationNum = async (num, statusParam) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: num,
      dept_id: dept?.id,
      status: statusParam || selectStat?.value,
      filter: filterByVal?.value,
      search: search,
      // sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      product_id: productVal?.value,
      vertical_id: verticalVal?.value,
    };
    setIndexNo(false);
    dispatch(getSubmittedActualRevenuePagination(obj));
  };

  const tableHeadList = [
    "Product",
    "Portfolio",
    "Units",
    `Unit Price (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Revenue (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `GP (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    "GP %",
    "Date",
    "Status",
  ];

  const cardHeader = [
    "Total Revenue",
    "Approved",
    "Pending",
    "Rejected",
    "Pending My Approval",
  ];
  const [singleItem, setSingleItem] = useState({});

  const handleGetActualRevenueData = async (param, deptParam) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id:
        deptParam ||
        dept?.id ||
        localStorage.getItem("actual-revenue-report") ||
        "",
      status: param || selectStat?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      product_id: productVal?.value,
      vertical_id: verticalVal?.value,
    };
    const data = await dispatch(getSubmittedActualRevenue(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
    }
  };

  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterLoad, setFilterLoad] = useState(false);
  const [activeBell, setActiveBell] = useState(true);
  const handleFilterSubmit = async (param, deptParam) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: deptParam || dept?.id,
      status: param || selectStat?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      product_id: productVal?.value,
      vertical_id: verticalVal?.value,
    };
    setFilterLoad(true);
    const data = await dispatch(getSubmittedActualRevenue(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      setFilterLoad(false);
      setShowFilterModal(false);
      actionCtx?.setIsModalOut(false);
      // setSelectStat("");
      localStorage.setItem("actual-revenue-notify-dept", dept?.id);
      // setVerticalVal("");
      // setProductVal("");
      // setPdfExport("");
      // setFilterByVal("");
      dept?.id && setActiveBell(false);
      // setDept("");
      setActiveStat((prev) => {
        return {
          ...prev,
          one: obj?.status === "",
          two: obj?.status === "approved" && true,
          three: obj?.status === "pending" && true,
          five: obj?.status === "pending_my_current_approver" && true,
          four: obj?.status === "rejected" && true,
        };
      });
    } else {
      setFilterLoad(false);
    }
  };

  const handleActualRevenueUpdate = (param) => {
    actionCtx?.setIsModalOut(false);
    localStorage.setItem("single-actual-revenue-id", param.id);
    history.push("/employee-dashboard-actual-revenue-update");
  };

  const cardValue = [
    singleItem?.summary?.total_projection || 0,
    singleItem?.summary?.approved || 0,
    singleItem?.summary?.awaiting_approval || 0,
    singleItem?.summary?.rejected || 0,
    singleItem?.summary?.total_gp || 0,
    singleItem?.summary?.pending_my_approver || 0,
  ];

  const handleGetAllComment = (param) => {
    dispatch(getAllRevenueActualComment(param));
  };

  const link = [
    "/employee-dashboard-actual-revenue-form",
    "/employee-dashboard-actual-revenue-submitted",
    "/employee-dashboard-actual-revenue-total-revenue-submitted-approved",
    "/employee-dashboard-actual-revenue-total-revenue-submitted-pending",
    "/employee-dashboard-actual-revenue-total-revenue-submitted-rejected",
  ];

  // export to pdf

  // export dropdown
  const [pdfExport, setPdfExport] = useState("");
  const exportOption = [
    { label: "CSV", value: "excel" },
    { label: "PDF", value: "pdf" },
  ];

  const [exportLoader, setExportLoader] = useState(false);
  const handleExport = async (param) => {
    const obj = {
      id: param?.id,
      type: "actual_revenue",
      currency_id: actionCtx?.currencyValue?.id,
    };
    setExportLoader(true);
    const data = await dispatch(getExportToPdf(obj));
    if (data?.payload?.url) {
      setIndexNo(false);
      window.open(data?.payload?.url, "_blank").focus();
      setShowModal((prev) => {
        return {
          ...prev,
          approveOverview: false,
          overview: false,
          rejectOverview: false,
        };
      });
      setExportLoader(false);
    } else {
      setExportLoader(false);
    }
  };

  const handleFileDownload = async (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: dept?.id,
      type: "actual_revenue",
      format: pdfExport?.value,
      user_id: user?.id,
      currency: actionCtx?.currencyValue?.id || "",
      status: param || selectStat?.value,
      product_id: productVal?.value,
      vertical_id: verticalVal?.value,
    };
    setDownloading(true);
    const data = await dispatch(getEmployeeProjectionDownload(obj));
    if (data?.payload?.status === 200) {
      setDownloading(false);
      setIndexNo(false);
      window.open(data?.payload?.data?.url, "_blank").focus();
      setShowFilterModal(false);
      setSelectStat("");
      setDept("");
      setVerticalVal("");
      setProductVal("");
      setPdfExport("");
      setFilterByVal("");
      actionCtx?.setIsModalOut(false);
    } else {
      setDownloading(false);
    }
  };

  const handleEditListCheck = (id) => {
    const newList = singleItem?.actualrevenue?.data?.map((chi) =>
      chi?.id === id
        ? { ...chi, current_approver: !chi?.current_approver }
        : chi
    );
    const newObj = {
      ...singleItem,
      actualrevenue: {
        ...singleItem?.actualrevenue,
        data: newList,
      },
    };
    setSingleItem(newObj);
  };

  // multi select checkbox (recall)
  const [selectedRecall, setSelectedRecall] = useState([]);
  const [checkHeader, setCheckHeader] = useState(!true);
  const [recallLoader, setRecallLoader] = useState(false);

  const handleRecallProjection = async (param, singleParam) => {
    const obj = {
      id: param || [singleParam],
      type: "revenue_actual",
    };
    setRecallLoader(true);
    const data = await dispatch(recallProjections(obj));
    // console.log(obj, "obj-check");
    if (data?.payload?.success) {
      setRecallLoader(false);
      actionCtx?.setIsModalOut(false);
      setIndexNo(false);
      history.push("/employee-dashboard-actual-revenue");
    } else {
      setRecallLoader(false);
    }
  };

  const handleCheckRecall = (id) => {
    const checkedList = selectedRecall.indexOf(id);
    if (checkedList > -1) {
      setSelectedRecall(selectedRecall.filter((chi) => chi !== id));
    } else {
      setSelectedRecall([...selectedRecall, id]);
    }
  };
  const [allApproveId, setAllAppproveId] = useState([]);

  const [allApprovalStatus, setAllApprovalStatus] = useState("");
  const [headerCheck, setHeaderCheck] = useState(true);

  const handleApproveAll = () => {
    const list = singleItem?.actualrevenue?.data?.filter(
      (chi) => chi?.status === "pending" && chi?.current_approver
    );
    const newList = list.map((chi) => chi?.id);
    setAllAppproveId(newList);
  };

  const handleAllRecallCheck = (e) => {
    if (e.target.checked) {
      setSelectedRecall(singleItem?.actualrevenue?.data?.map((chi) => chi.id));
    } else {
      setSelectedRecall([]);
    }
    setCheckHeader(e.target.checked);
  };
  const deptId = localStorage.getItem("actual-revenue-notify-dept");
  const [notifyLoader, setNotifyLoader] = useState(false);
  const handleNotifyApprover = async () => {
    const obj = {
      department_id: deptId,
      projection_type: "actual_revenue",
    };
    setNotifyLoader(true);
    const data = await dispatch(notifyNextApprover(obj));
    if (data.payload?.success) {
      setNotifyLoader(false);
      setDept("");
      setActiveBell(true);
    } else {
      setNotifyLoader(false);
    }
  };

  return (
    <>
      <EmployeeCommonRevenueLayout
        commonNavLink={true}
        exportDrop
        exportVal={pdfExport}
        options={exportOption}
        onExport={(e) => {
          setIndexNo(false);
          setPdfExport(e);
        }}
      >
        <EmployeeReusableTable
          downloading={downloading}
          onDownload={() => {
            handleFileDownload(
              (activeStat?.one && "") ||
                (activeStat?.two && "approved") ||
                (activeStat?.three && "pending") ||
                (activeStat?.four && "rejected")
            );
          }}
          exportDrop
          exportVal={pdfExport}
          options={exportOption}
          onExport={(e) => {
            setPdfExport(e);
            setIndexNo(false);
          }}
          onOpenFilter={() => {
            setIndexNo(false);
            actionCtx?.setIsModalOut(true);
            setShowFilterModal(true);
          }}
          showFilter={showFilterModal}
          onCloseFilter={() => {
            actionCtx?.setIsModalOut(false);
            setShowFilterModal(false);
            setSelectStat("");
            setVerticalVal("");
            setProductVal("");
            setPdfExport("");
            setFilterByVal("");
            setDept("");
          }}
          filterLoading={filterLoad}
          onFilterSubmit={() => {
            handleFilterSubmit();
          }}
          filterIcon
          vertical
          verticalVal={verticalVal}
          onVerticalChange={(e) => {
            setVerticalVal(e);
          }}
          verticalDrop={verticalDrop}
          product
          productVal={productVal}
          onProductChange={(e) => {
            setProductVal(e);
          }}
          productDrop={productDrop}
          onRejectAll={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, approve_all: true };
            });
            setAllApprovalStatus("rejected");
            handleApproveAll();
          }}
          onApproveAll={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, approve_all: true };
            });
            setAllApprovalStatus("approved");
            handleApproveAll();
          }}
          rejectAll={activeStat?.five}
          approveAll={activeStat?.five}
          totalTitle={"Total Revenue"}
          totalValue={formatNumber(String(cardValue[0] || 0))}
          totalTitleTwo={"Total GP"}
          totalValueTwo={formatNumber(String(cardValue[4] || 0))}
          // sortText
          perPage
          optionPerPage={perPageOptions}
          setPerPage={(e) => {
            actionCtx?.setPerpage(e);
          }}
          recallAllBtn={
            activeStat?.three || activeStat?.one || activeStat?.five
          }
          loading={loadingPage}
          recallLoader={recallLoading || loadingPage}
          onRecallAll={() => {
            selectedRecall?.length > 0 &&
              handleRecallProjection(selectedRecall, "");
          }}
          recallBgColor={selectedRecall?.length < 1 && "#cccccc"}
          perPageVal={actionCtx?.perPage}
          statusDrop
          statusDropdown={
            user?.role !== "budget rep" ? statusDropdown : statusDropDownTwo
          }
          setStatusVal={(e) => {
            setSelectStat(e);
          }}
          fileDownload
          onFileDownload={() => {
            pdfExport?.value &&
              handleFileDownload(
                (activeStat?.one && "") ||
                  (activeStat?.two && "approved") ||
                  (activeStat?.three && "pending") ||
                  (activeStat?.four && "rejected")
              );
            setIndexNo(false);
          }}
          onNotify={handleNotifyApprover}
          notifyLoader={notifyLoader}
          exportSelect={pdfExport?.label || "File"}
          ApprovalBell={!activeStat?.four}
          disableBell={activeBell}
          statusVal={selectStat}
          dept
          departmentDrop={
            user?.user_type === "ADMIN" ? departmentDrop : userDepartmentDrop
          }
          deptVal={dept}
          setDepartmentVal={(e) => {
            setIndexNo(false);
            setDept(e);
          }}
          filter
          allActionBtn
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => {
            setIndexNo(false);
            setFilterByVal(e);
          }}
          onSearchTable={(e) => {
            setIndexNo(false);
            setSearch(e);
          }}
          cardTitleTwo={cardHeader[1]}
          cardTitleThree={cardHeader[2]}
          cardTitleFour={cardHeader[3]}
          cardTitleFive={cardHeader[4]}
          cardBodyTwo={formatNumber(String(cardValue[1] || 0))}
          cardBodyThree={formatNumber(String(cardValue[2] || 0))}
          cardBodyFour={formatNumber(String(cardValue[3] || 0))}
          cardBodyFive={formatNumber(String(cardValue[5] || 0))}
          secondCard={true}
          thirdCard={true}
          fourthCard={true}
          fifthCard={user?.role !== "budget rep"}
          showSearch={true}
          addButton
          BtnTitle={`New Revenue`}
          onPage={() => history.push(link[0])}
          onFirstCard={() => {
            setIndexNo(false);
            handleGetActualRevenueData("");
            setSelectStat({
              label: "All Projection",
              value: "",
            });
            setActiveStat((prev) => {
              return {
                ...prev,
                one: true,
                two: false,
                three: false,
                four: false,
                five: false,
              };
            });
          }}
          onSecondCard={() => {
            setIndexNo(false);
            handleGetActualRevenueData("approved");
            setSelectStat({
              label: "Approved",
              value: "approved",
            });
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: true,
                three: false,
                four: false,
                five: false,
              };
            });
          }}
          onThirdCard={() => {
            setIndexNo(false);
            handleGetActualRevenueData("pending");
            setSelectStat({
              label: "Pending",
              value: "Pending",
            });
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: false,
                three: true,
                four: false,
                five: false,
              };
            });
          }}
          onFourthCard={() => {
            setIndexNo(false);
            handleGetActualRevenueData("rejected");
            setSelectStat({
              label: "Rejected",
              value: "rejected",
            });
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: false,
                three: false,
                four: true,
                five: false,
              };
            });
          }}
          onFifthCard={() => {
            setIndexNo(false);
            setSelectStat({
              label: "Pending My Approval",
              value: "pending_my_current_approver",
            });
            handleGetActualRevenueData("pending_my_current_approver");
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: false,
                three: false,
                four: false,
                five: true,
              };
            });
          }}
          linkCardOne={activeStat?.one}
          linkCardTwo={activeStat?.two}
          linkCardThree={activeStat?.three}
          linkCardFour={activeStat?.four}
          linkCardFive={activeStat?.five}
        />

        {/* table start  */}
        {children}
        <div className="table-bo">
          {/* table box start */}
          <ActualReuseableTable
            key={`dffgjsh`}
            massSelectable={
              (activeStat?.five &&
                singleItem?.actualrevenue?.data?.length > 0) ||
              (activeStat?.three && singleItem?.actualrevenue?.data?.length > 0)
            }
            checkValue={
              (activeStat?.five && headerCheck) ||
              (activeStat?.three && checkHeader)
            }
            onMassCheck={(e) => {
              setHeaderCheck(!headerCheck);
              const newList = singleItem?.actualrevenue?.data?.map((chi) => {
                return { ...chi, current_approver: e.target.checked };
              });
              const newObj = {
                ...singleItem,
                actualrevenue: {
                  ...singleItem?.actualrevenue,
                  data: newList,
                },
              };
              setSingleItem(newObj);
              // pending
              activeStat?.three && handleAllRecallCheck(e);
            }}
            useNumPagination
            currentPage={singleItem?.actualrevenue?.current_page}
            totalTableLength={singleItem?.actualrevenue?.total}
            onNumPage={(e) => {
              handlePaginationNum(
                e,
                (activeStat?.one && "") ||
                  (activeStat?.two && "approved") ||
                  (activeStat?.three && "pending") ||
                  (activeStat?.four && "rejected")
              );
            }}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            eight={tableHeadList[7]}
            action
            dontShowPagination={singleItem?.actualrevenue?.data?.length < 1}
            fromPage={singleItem?.actualrevenue?.from}
            toPage={singleItem?.actualrevenue?.to}
            nextPage={singleItem?.actualrevenue?.next_page_url}
            prevPage={singleItem?.actualrevenue?.prev_page_url}
            totalPage={singleItem?.actualrevenue?.total}
            // onNextPage={() =>
            //   handlePagination(singleItem?.actualrevenue?.next_page_url)
            // }
            // onPrevPage={() =>
            //   handlePagination(singleItem?.actualrevenue?.prev_page_url)
            // }
            onNextPage={() => {
              (activeStat?.one &&
                handlePagination(
                  singleItem?.actualrevenue?.next_page_url,
                  ""
                )) ||
                (activeStat?.two &&
                  handlePagination(
                    singleItem?.actualrevenue?.next_page_url,
                    "approved"
                  )) ||
                (activeStat?.three &&
                  handlePagination(
                    singleItem?.actualrevenue?.next_page_url,
                    "pending"
                  )) ||
                (activeStat?.one &&
                  handlePagination(
                    singleItem?.actualrevenue?.next_page_url,
                    "rejected"
                  ));
            }}
            onPrevPage={() => {
              (activeStat?.one &&
                handlePagination(
                  singleItem?.actualrevenue?.prev_page_url,
                  ""
                )) ||
                (activeStat?.two &&
                  handlePagination(
                    singleItem?.actualrevenue?.prev_page_url,
                    "approved"
                  )) ||
                (activeStat?.three &&
                  handlePagination(
                    singleItem?.actualrevenue?.prev_page_url,
                    "pending"
                  )) ||
                (activeStat?.one &&
                  handlePagination(
                    singleItem?.actualrevenue?.prev_page_url,
                    "rejected"
                  ));
            }}
          >
            {singleItem?.actualrevenue?.data?.length > 0 ? (
              <>
                {singleItem?.actualrevenue?.data?.map((chi, idx) => {
                  const {
                    product,
                    unit,
                    unit_price,
                    gross_revenue,
                    gp,
                    gross_profit_percent,
                    status,
                    date,
                    current_approver,
                    portfolio,
                    id,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      checkBoxType={
                        activeStat?.five || activeStat?.three || activeStat?.one
                      }
                      onCheck={(e) => {
                        handleEditListCheck(id);
                        handleCheckRecall(id);
                      }}
                      checkValue={
                        // ((activeStat?.three || activeStat?.one) &&
                        (activeStat?.five && current_approver) ||
                        (status === "pending" && selectedRecall.includes(id))
                      }
                      // (activeStat?.one && selectedRecall.includes(id))
                      checkId={`${id}-${idx}`}
                      item={chi}
                      rowNo={idx}
                      key={idx}
                      one={product?.name}
                      three={formatNumber(String(unit))}
                      two={portfolio?.name || <></>}
                      four={formatNumber(String(unit_price || 0))}
                      five={formatNumber(String(gross_revenue || 0))}
                      six={formatNumber(String(gp || 0))}
                      seven={formatNumber(String(gross_profit_percent)) || 0}
                      eight={moment(date).format("YYYY-MM-DD")}
                      progressType={status}
                      loading={loadingPage}
                      indexNo={indexNo}
                      setIndexNo={() => setIndexNo(idx)}
                      // updateText={`Update`}
                      // onDelete={() => {
                      //   {
                      //     setSingleChild(chi);
                      //     setShowModal((prev) => {
                      //       return { ...prev, delete: true };
                      //     });
                      //   }
                      // }}
                      // onUpdate={() => handleActualRevenueUpdate(chi)}
                      // setIndexNo={() => setIndexNo(idx)}
                      approveText={
                        status === "pending" &&
                        current_approver &&
                        user?.permissions?.includes(
                          "approve/reject actual revenue"
                        )
                          ? "Approve"
                          : null
                      }
                      recallText={"recall"}
                      onRecall={() => {
                        setIndexNo(false);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, recall: true };
                        });
                        actionCtx?.setIsModalOut(true);
                      }}
                      onUpdate={() => handleActualRevenueUpdate(chi)}
                      updateText={
                        status === "pending" &&
                        user?.permissions?.includes("edit actual revenue") &&
                        "update"
                      }
                      viewText={"View Comments"}
                      // updateTextThree={'comments'}
                      deleteText={`Reject`}
                      exportText={`Export as PDF`}
                      onExport={() => handleExport(chi)}
                      exportLoader={exportLoader}
                      onApprove={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approve: true };
                        });
                      }}
                      onView={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, comment: true };
                        });
                      }}
                      onDelete={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, reject: true };
                        });
                      }}
                      onOverview={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, overview: true };
                        });
                      }}
                      onAcceptStatus={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approveOverview: true };
                        });
                      }}
                      onRejectStatus={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, rejectOverview: true };
                        });
                      }}
                      action
                    />
                  );
                })}
              </>
            ) : (
              <EmployeeActualRevenueDraftExpense>
                <ButtonBlue
                  onClick={() =>
                    history.push("/employee-dashboard-actual-revenue-form")
                  }
                  text={`Create Revenue`}
                >
                  <FaPlus style={{ marginRight: "15px" }} />
                </ButtonBlue>
              </EmployeeActualRevenueDraftExpense>
            )}
          </ActualReuseableTable>
        </div>

        {/* table box end */}
      </EmployeeCommonRevenueLayout>

      {/* modal start */}
      <Modal visible={showModal.approve}>
        <EmployeeRevenueApprovalModal
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false, overview: modalView && true };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
          }}
          // onRefreshCard={handleGetActualRevenueData}
          // onDispatch={handleGetSubmittedRevenue}
          onDispatch={() => {
            (activeStat?.one && handleGetActualRevenueData("")) ||
              (activeStat?.two && handleGetActualRevenueData("approved")) ||
              (activeStat?.three && handleGetActualRevenueData("pending")) ||
              (activeStat?.four && handleGetActualRevenueData("rejected"));
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.comment}>
        <EmployeeRevenueComentModal
          details={singleChild}
          data={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                comment: false,
                overview:
                  singleChild?.status === "pending" && modalView && true,
                approveOverview:
                  singleChild?.status === "approved" && modalView && true,
                rejectOverview:
                  singleChild?.status === "rejected" && modalView && true,
              };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmployeeRejectModal
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false, overview: modalView && true };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
          }}
          // onRefreshCard={handleGetActualRevenueData}
          // onDispatch={handleGetSubmittedRevenue}
          onDispatch={() => {
            (activeStat?.one && handleGetActualRevenueData("")) ||
              (activeStat?.two && handleGetActualRevenueData("approved")) ||
              (activeStat?.three && handleGetActualRevenueData("pending")) ||
              (activeStat?.four && handleGetActualRevenueData("rejected"));
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.overview}>
        <EmployeeActualRevenuePendingOverviewModal
          // update
          details={singleChild}
          handleShowModal={setShowModal}
          onExport={() => {
            handleExport(singleChild);
          }}
          approveBtn={
            user?.permissions?.includes("approve/reject actual revenue")
              ? true
              : null
          }
          updateText={
            user?.permissions?.includes("edit actual revenue") && "update"
          }
          onUpdate={() => {
            handleActualRevenueUpdate(singleChild);
          }}
          onRecall={() => {
            setIndexNo(false);
            setModalView(true);
            actionCtx?.setIsModalOut(true);
            setSingleChild(singleChild);
            setShowModal((prev) => {
              return { ...prev, recall: true, overview: false };
            });
          }}
          // data={singleChild}
          rejectText={
            user?.permissions?.includes("approve/reject actual revenue")
              ? true
              : false
          }
          // onNextPage={() => history.push(links[0])}
          onReject={() => {
            setModalView(true);
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            });
          }}
          // onUpdate={() => {
          //   localStorage.setItem("single-actual-revenue-id", singleChild?.id);
          //   history.push("/employee-dashboard-actual-revenue-update");
          // }}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            });
            handleGetAllComment(singleChild?.id);
          }}
          onApprove={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, approve: true, overview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, overview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeActualRevenueApproveOverviewModal
          details={singleChild}
          handleShowModal={setShowModal}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
          onComment={() => {
            setModalView(true);
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            });
            handleGetAllComment(singleChild?.id);
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.rejectOverview}>
        <EmployeeActualRevenueRejectOverviewModal
          details={singleChild}
          handleShowModal={setShowModal}
          onExport={() => {
            handleExport(singleChild);
          }}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            });
            handleGetAllComment(singleChild?.id);
          }}
          // onApprove={() => {
          //   actionCtx?.setIsModalOut(true);
          //   setShowModal((prev) => {
          //     return { ...prev, approve: true, rejectOverview: false };
          //   });
          // }}
          onCancel={() => {
            setModalView(false);
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInRight" visible={showModal.approve_all}>
        <FinalApprovalModal
          allApprovalStatus={allApprovalStatus}
          // detail={expenditure_projection_single?.expenditure?.data[0]}
          allId={allApproveId}
          onFinish={() => {
            actionCtx?.setIsModalOut(false);
            handleGetActualRevenueData();
            setShowModal({
              overview: false,
              approveOverview: false,
              rejectOverview: false,
              approve: false,
              reject: false,
              comment: false,
              approve_all: false,
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve_all: false };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* modal start */}
      <Modal effect="fadeInRight" visible={showModal.recall}>
        <ActualRevenueRecallModal
          details={singleChild}
          loading={recallLoader}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, recall: false, overview: modalView && true };
            });
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
          }}
          onSubmit={() => {
            handleRecallProjection("", singleChild?.id);
          }}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeActualRevenueSubmit;
