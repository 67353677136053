import React, { useEffect, useState } from "react";
import WelcomeBox from "../../../fragments/WelcomeBox";
// import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
import "../../../../styles/dashboard/home/DashboardHome.css";
import { FaAngleRight, FaTimes } from "react-icons/fa";
import CommonSortBox from "../../../fragments/CommonSortBox";
import ButtonBlue from "../../../buttons/ButtonBlue";
// import EmployeeProgressBar from "../../../../employee-folder/component/pages/dashboard/home/progressBar/progressBar";
// import Select from "react-select";
import NetProfitChart from "./chart/chartOne";
import MultiProgress from "react-multi-progress";
import AvailableBalanceChart from "./chart/charttwoContent";
import { useHistory } from "react-router-dom";
import BudgetAssmptionModal from "../modal/budgetAssumption";
import Modal from "react-awesome-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  // getDashboardDetails,
  getDashboardDetailsBySort,
} from "../../../../redux/users/users";
import { useContext } from "react";
import ActionContext from "../../../../context/ActionContext";
import {
  defaultHomeGraphVal,
  formatDataForDashboardGraph,
  formatNumber,
  formatNumberWithComma,
  // FormatNumWithCurrency,
} from "../../../../helper/Helpers";
import { getBudgetAssumptions } from "../../../../redux/budget-setting/budget-assumption/budgetAssumptions";
import BigShowModalComponent from "../modal/BigShowModalComponent";
import { useThemeDetector } from "../../../../utils/getThemeColor";
// import EmployeeNetProfitChart from "../../../../employee-folder/component/pages/dashboard/home/chart/netProfitChart";

// import { getOrganizations } from "../../../../redux/user";
// import { useDispatch } from "react-redux";
// import { useContext } from "react";
// import ActionContext from "../../../../context/ActionContext";

const DashboardHome = () => {
  // const [showWelcomeModal, setSHowWelcomeModal] = useState(true);
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  const isDarkTheme = useThemeDetector();
  const { preference } = useSelector((state) => state.preference);
  const [showBigModal, setShowBigModal] = useState({
    url: "",
    show: false,
  });
  const [showCurrentWrap, setShowCurrentWrap] = useState(true);
  const { dashboard_details, loading } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.user);
  const [netProfitGraphVal, setNetProfitGraphVal] =
    useState(defaultHomeGraphVal);
  const [grossProfitGraphVal, setGrossProfitGraphVal] =
    useState(defaultHomeGraphVal);
  const history = useHistory();
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);

  const onCloseModal = () => {
    // setSHowWelcomeModal(false);
    actionCtx?.setShowCheckList(false);
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCtx?.activeOrg, actionCtx?.currencyValue]);

  const getDashboardData = async () => {
    // console.log(user);
    const obj = {
      org_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
      sub_id: sortVal?.sub?.value,
      dept_id: sortVal?.dept?.value,
      bra_id: sortVal?.bra?.value,
      date: sortVal?.date,
      fis_id: sortVal?.fis?.value,
      cur_id: actionCtx?.currencyValue?.id || "",
    };
    // console.log(obj);
    // return
    dispatch(getBudgetAssumptions(obj?.org_id));
    const data = await dispatch(getDashboardDetailsBySort(obj));
    // console.log(data.payload);
    if (data?.payload?.success) {
      const netProfitList =
        data?.payload?.data?.net_profit?.projected_profit_data;
      setNetProfitGraphVal(formatDataForDashboardGraph(netProfitList));
      const grossProfitList =
        data?.payload?.data?.gross_profit?.gross_profit_data;
      setGrossProfitGraphVal(formatDataForDashboardGraph(grossProfitList));
      // console.log(formatDataForDashboardGraph(grossProfitList));
    }
  };

  const [sortVal, setSortval] = useState({
    bra: "",
    sub: "",
    dept: "",
    fis: "",
    date: "",
  });

  useEffect(() => {
    getSortByValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortVal]);

  const getSortByValues = async () => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
      sub_id: sortVal?.sub?.value,
      dept_id: sortVal?.dept?.value,
      bra_id: sortVal?.bra?.value,
      date: sortVal?.date.value,
      fis_id: sortVal?.fis?.value,
      cur_id: actionCtx?.currencyValue?.id || "",
    };

    if (
      sortVal?.sub?.value ||
      sortVal?.bra?.value ||
      sortVal?.dept?.value ||
      sortVal?.date.value ||
      sortVal?.fis?.value
    ) {
      // console.log(obj);
      const data = await dispatch(getDashboardDetailsBySort(obj));
      if (data?.payload?.success) {
        const netProfitList =
          data?.payload?.data?.net_profit?.projected_profit_data;
        setNetProfitGraphVal(formatDataForDashboardGraph(netProfitList));
        const grossProfitList =
          data?.payload?.data?.gross_profit?.gross_profit_data;
        setGrossProfitGraphVal(formatDataForDashboardGraph(grossProfitList));
      }
    }
  };

  const getPercentage = (valOne, valTwo) => {
    const percentage = (valOne / (valOne + valTwo)) * 100;
    // console.log(Math.round(percentage));
    return Math.round(percentage);
  };

  return (
    <>
      {/* <DashboardLayout
        // goBack
        pageTitle={`Dashboard`}
        // pageTitleSpan="Step 1 of 5"
      > */}
      {/* </DashboardLayout> */}
      {/* welcome text start */}
      {actionCtx?.activeOrg?.check_list?.percentage !== 100 &&
        actionCtx?.showCheckList && (
          <div className="blur-bg-wrap">
            <WelcomeBox onClose={onCloseModal} />
          </div>
        )}
      {/* <div className="blur-bg-wrap">
        <WelcomeBox onClose={onCloseModal} />
      </div> */}
      {/* welcome text end */}
      <div className="expense-projection-inde-wrap">
        {actionCtx?.activeOrg?.check_list?.percentage !== 100 && (
          <div
            className="dashboard-home-wrap"
            style={{ paddingBottom: "unset" }}
          >
            {/* complete check list start */}
            <div
              className="complete-checklist-box"
              style={{ marginBottom: "1rem" }}
            >
              {actionCtx?.activeOrg?.check_list?.percentage !== 100 && (
                <div
                  className="wrap"
                  onClick={() => actionCtx?.setShowCheckList(true)}
                  style={{ marginTop: "-4rem", marginRight: "1rem" }}
                >
                  <p className="text">Complete your checklist</p>
                  <FaAngleRight className="icon" />
                </div>
              )}
            </div>
            {/* complete check list end */}
          </div>
        )}
        <CommonSortBox
          subVal={sortVal?.sub}
          deptVal={sortVal?.dept}
          braVal={sortVal?.bra}
          fisVal={sortVal?.fis}
          dateVal={sortVal?.date}
          onReset={() => {
            setSortval({
              bra: "",
              sub: "",
              dept: "",
              fis: "",
              date: "",
            });
            getDashboardData();
          }}
          onDept={(e) => {
            setSortval((prev) => {
              return { ...prev, dept: e };
            });
          }}
          onBra={(e) => {
            setSortval((prev) => {
              return { ...prev, bra: e };
            });
          }}
          onSub={(e) => {
            setSortval((prev) => {
              return { ...prev, sub: e };
            });
          }}
          onFis={(e) => {
            setSortval((prev) => {
              return { ...prev, fis: e };
            });
          }}
          onDate={(e) => {
            setSortval((prev) => {
              return { ...prev, date: e };
            });
          }}
        />
        {showCurrentWrap &&
          actionCtx?.activeOrg?.active_fiscal_year?.budget_assumption && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "12fr 1fr",
                marginTop: "3rem",
                padding: "3rem",
                paddingBottom: "unset",
                backgroundColor: "var(--blue-fade-color)",
              }}
            >
              <div>
                <p>
                  Current fiscal year is{" "}
                  <span>{actionCtx?.activeOrg?.active_fiscal_year?.title}</span>
                </p>
                <ButtonBlue
                  style={{ margin: "2rem 0 2rem 0" }}
                  text={`View Budget Assumption`}
                  onClick={() => setShowBudgetModal(true)}
                  // onClick={()=>history.push('/dashboard')}
                />
              </div>
              <div
                style={{
                  backgroundColor: " var(--back-bg-color)",
                  placeItems: "center",
                  display: "grid",
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={() => setShowCurrentWrap(false)}
              >
                <FaTimes />
              </div>
            </div>
          )}
        {/* employee top container start */}
        <div className="emp-top-wrap">
          {/* first card start */}
          <div
            onClick={() => {
              console.log(dashboard_details);
            }}
          >
            <p>Total Projection</p>
            {/* card group start */}
            <div
              style={{
                border:
                  preference?.display === "Light"
                    ? "1px solid #dde2e4"
                    : preference?.display === "Dark"
                    ? "unset"
                    : isDarkTheme
                    ? "unset"
                    : "1px solid #dde2e4",
              }}
              className="card-wrap"
            >
              {/* card-content start */}
              <div className="emp-home-card">
                {/* <div> */}
                {/* progress bar start */}
                <div>
                  {/* percent box start */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    className=""
                  >
                    <p style={{ color: "#07A287", fontWeight: "600" }}>
                      {getPercentage(
                        dashboard_details?.total_projection?.revenue,
                        dashboard_details?.total_projection?.expenditure
                      ) || "0"}
                      %
                    </p>
                    <p style={{ color: "#FCA549", fontWeight: "600" }}>
                      {getPercentage(
                        dashboard_details?.total_projection?.expenditure,
                        dashboard_details?.total_projection?.revenue
                      ) || "0"}
                      %
                    </p>
                  </div>
                  {/* percent box end */}
                  <MultiProgress
                    transitionTime={1.2}
                    elements={[
                      {
                        value: getPercentage(
                          dashboard_details?.total_projection?.revenue,
                          dashboard_details?.total_projection?.expenditure
                        ),
                        color: "#07A287",
                      },
                      {
                        value:
                          100 -
                          getPercentage(
                            dashboard_details?.total_projection?.revenue,
                            dashboard_details?.total_projection?.expenditure
                          ),
                        color: "#FCA549",
                      },
                    ]}
                    roundLastElement={false}
                    backgroundColor="#ECEDED"
                    round={false}
                    height={13}
                  />
                </div>
                {/* progress bar end */}
                {/* top text wrap start */}
                <div className="top-text-wrap">
                  {/* revenue wrap start */}
                  <div
                    onClick={() => {
                      history.push("/dashboard-projection-revenue");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>Revenue </p>
                    <p className="approve-digit">
                      {dashboard_details?.total_projection?.revenue < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboard_details?.total_projection?.revenue || "0"
                        )
                      )}
                    </p>
                  </div>
                  {/* revenue wrap end */}
                  {/* expenditure wrap start */}
                  <div
                    onClick={() => {
                      history.push("/dashboard-projection-expense");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>Expenditure </p>
                    <p className="pending-digit">
                      {dashboard_details?.total_projection?.expenditure < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboard_details?.total_projection?.expenditure ||
                            "0"
                        )
                      )}
                    </p>
                  </div>
                  {/* expenditure wrap end */}
                </div>
                {/* top text wrap end */}
              </div>
              {/* card-content end */}
            </div>
            {/* card group end */}
          </div>
          {/* first card end */}

          {/* second card start */}
          <div>
            <p>Total Actual</p>
            {/* card group start */}
            <div
              style={{
                border:
                  preference?.display === "Light"
                    ? "1px solid #dde2e4"
                    : preference?.display === "Dark"
                    ? "unset"
                    : isDarkTheme
                    ? "unset"
                    : "1px solid #dde2e4",
              }}
              className="card-wrap"
            >
              {/* card-content start */}
              <div className="emp-home-card">
                {/* <div> */}
                {/* progress bar start */}
                <div>
                  {/* percent box start */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    className=""
                  >
                    <p style={{ color: "#07A287", fontWeight: "600" }}>
                      {getPercentage(
                        dashboard_details?.total_actual?.revenue,
                        dashboard_details?.total_actual?.expense
                      ) || "0"}
                      %
                    </p>
                    <p style={{ color: "#FCA549", fontWeight: "600" }}>
                      {getPercentage(
                        dashboard_details?.total_actual?.expense,
                        dashboard_details?.total_actual?.revenue
                      ) || "0"}
                      %
                    </p>
                  </div>
                  {/* percent box end */}
                  <MultiProgress
                    transitionTime={1.2}
                    elements={[
                      {
                        value: getPercentage(
                          dashboard_details?.total_actual?.revenue || 0,
                          dashboard_details?.total_actual?.expense || 0
                        ),
                        color: "#07A287",
                      },
                      {
                        value:
                          100 -
                          getPercentage(
                            dashboard_details?.total_actual?.revenue || 0,
                            dashboard_details?.total_actual?.expense || 0
                          ),
                        color: "#FCA549",
                      },
                    ]}
                    roundLastElement={false}
                    backgroundColor="#ECEDED"
                    round={false}
                    height={13}
                  />
                </div>
                {/* progress bar end */}
                {/* top text wrap start */}
                <div className="top-text-wrap">
                  {/* revenue wrap start */}
                  <div
                    onClick={() => {
                      history.push("/dashboard-actual-revenue");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>Revenue </p>
                    <p className="approve-digit">
                      {dashboard_details?.total_actual?.revenue < 0 ? "- " : ""}
                      {formatNumber(
                        String(dashboard_details?.total_actual?.revenue || "0")
                      )}
                    </p>
                  </div>
                  {/* revenue wrap end */}
                  {/* expenditure wrap start */}
                  <div
                    onClick={() => {
                      history.push("/dashboard-actual-expense");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <p>Expense</p>
                    <p className="pending-digit">
                      {dashboard_details?.total_actual?.expense < 0 ? "- " : ""}
                      {formatNumber(
                        String(dashboard_details?.total_actual?.expense || "0")
                      )}
                    </p>
                  </div>
                  {/* expenditure wrap end */}
                </div>
                {/* top text wrap end */}
              </div>
              {/* card-content end */}
            </div>
            {/* card group end */}
          </div>

          {/* second card end */}

          {/* employee net profit chart start */}
          {/* emp-chart-container start */}
          <div className="emp-linechart-container">
            <div
              className="emp-line-chart"
              style={{
                border:
                  preference?.display === "Light"
                    ? "1px solid #dde2e4"
                    : preference?.display === "Dark"
                    ? "unset"
                    : isDarkTheme
                    ? "unset"
                    : "1px solid #dde2e4",
              }}
            >
              {/* chart top wrap start */}
              <div className="chart-top-wrap">
                <div>
                  <p>Net Profit</p>
                  <p className="emp-chart-head">Projected Vs Actual</p>
                </div>
                {/* select start */}
                <div>
                  {/* <Select
                    options={optionList}
                    placeholder="Current Fiscal Year"
                  /> */}
                </div>
                {/* select end */}
                {/* chart top wrap end */}
              </div>
              <NetProfitChart loading={loading} dataValue={netProfitGraphVal}>
                <hr />
                <div className="chart-bottom-wrap">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push("/dashboard-home-net-profit-projection")
                    }
                  >
                    <p className="chart-pending-text">Projected Net Profit</p>
                    <p
                      style={{ letterSpacing: "-.05rem" }}
                      className="chart-bottom-digit"
                    >
                      {dashboard_details?.net_profit?.projected_net_profit < 0
                        ? "- "
                        : ""}
                      {formatNumberWithComma(
                        String(
                          dashboard_details?.net_profit?.projected_net_profit ||
                            "0"
                        )
                      )}
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      history.push("/dashboard-home-actual-net-profit")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <p className="chart-approve-text">Actual Net Profit</p>
                    <p
                      style={{ letterSpacing: "-.05rem" }}
                      className="chart-bottom-digit"
                    >
                      {dashboard_details?.net_profit?.actual_net_profit < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboard_details?.net_profit?.actual_net_profit ||
                            "0"
                        )
                      )}
                    </p>
                  </div>
                </div>
              </NetProfitChart>
            </div>
          </div>
          {/* emp-chart-container end */}
          {/* second chart-card start */}
          <div className="emp-linechart-container">
            <div
              style={{
                border:
                  preference?.display === "Light"
                    ? "1px solid #dde2e4"
                    : preference?.display === "Dark"
                    ? "unset"
                    : isDarkTheme
                    ? "unset"
                    : "1px solid #dde2e4",
              }}
              className="emp-line-chart"
            >
              {/* chart top wrap start */}
              <div className="chart-top-wrap">
                <div>
                  <p>Gross Profit</p>
                  <p className="emp-chart-head">Projected Vs Actual</p>
                </div>
                {/* select start */}
                <div>
                  {/* <Select
                    options={optionList}
                    placeholder="Current Fiscal Year"
                  /> */}
                </div>
                {/* select end */}
                {/* chart top wrap end */}
              </div>
              <NetProfitChart loading={loading} dataValue={grossProfitGraphVal}>
                <hr />
                <div className="chart-bottom-wrap">
                  <div
                    onClick={() =>
                      history.push("/dashboard-home-projected-gross-profit")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <p className="chart-pending-text">Projected Gross Profit</p>
                    <p
                      style={{ letterSpacing: "-.05rem" }}
                      className="chart-bottom-digit"
                    >
                      {dashboard_details?.gross_profit?.projected_gross_profit <
                      0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboard_details?.gross_profit
                            ?.projected_gross_profit || "0"
                        )
                      )}
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      history.push("/dashboard-home-actual-gross-profit")
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <p className="chart-approve-text">Actual Gross Profit</p>
                    <p
                      style={{ letterSpacing: "-.05rem" }}
                      className="chart-bottom-digit"
                    >
                      {dashboard_details?.gross_profit?.actual_gross_profit < 0
                        ? "- "
                        : ""}
                      {formatNumber(
                        String(
                          dashboard_details?.gross_profit
                            ?.actual_gross_profit || "0"
                        )
                      )}
                    </p>
                  </div>
                </div>
              </NetProfitChart>
            </div>
          </div>
          {/* emp-chart-container end */}
          {/* second chart-card end */}
          {/* employee net profit chart end */}
        </div>

        {/* second chart start */}
        <div className="emp-reinburstment-container">
          <AvailableBalanceChart
            loading={loading}
            avail_bal={`${
              dashboard_details?.book_vs_available_balance
                ?.total_available_balance < 0
                ? "- "
                : ""
            } ${formatNumber(
              String(
                dashboard_details?.book_vs_available_balance
                  ?.total_available_balance || "0.00"
              )
            )}`}
            book_bal={`${
              dashboard_details?.book_vs_available_balance?.total_book_balance <
              0
                ? "- "
                : ""
            } ${formatNumber(
              String(
                dashboard_details?.book_vs_available_balance
                  ?.total_book_balance || "0.00"
              )
            )}`}
          />
          {/* employee reinbursement chart end */}
          {/* monthly expense chart start */}

          <div
            style={{
              marginTop: "3rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          ></div>
        </div>
        {/* second chart end */}
      </div>
      {/* modal start */}
      <Modal effect="fadeInLeft" visible={showBudgetModal}>
        <BudgetAssmptionModal
          onBigShow={(param) => {
            setShowBudgetModal(false);
            actionCtx?.setIsModalOut(true);
            setShowBigModal((prev) => {
              return { ...prev, show: true, url: param };
            });
          }}
          onClose={() => setShowBudgetModal(false)}
        />
      </Modal>

      {/* modal end */}
      <Modal visible={showBigModal?.show} effect="fadeInLeft">
        <BigShowModalComponent
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowBudgetModal(true);
            setShowBigModal((prev) => {
              return { ...prev, url: "", show: false };
            });
          }}
          detail={showBigModal?.url}
        />
      </Modal>
    </>
  );
};

export default DashboardHome;
