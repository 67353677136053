import React, { useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
import imgOne from "../../../../../../assets/hod-two-img.svg";
import { FaTimes, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  addActualCommentExp,
  deleteCommentExpActual,
  getExpActualComments,
} from "../../../../../../redux/actuals/expense";
import { Dna } from "react-loader-spinner";
import moment from "moment";

const ComentModal = ({ onCancel, detail }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { comments, loading, loadingTwo } = useSelector(
    (state) => state.actual_expense_chart
  );
  const [comment, setComments] = useState("");

  const handleDelete = async (id) => {
    const obj = {
      exp_id: detail?.id,
      comment_id: id,
    };
    // console.log(obj);
    const data = await dispatch(deleteCommentExpActual(obj));
    console.log(data);
    if (data?.payload?.status) {
      dispatch(getExpActualComments(detail?.id));
    }
  };

  const handleApprove = async () => {
    const obj = {
      comment: comment,
      id: detail.id,
      user_id: user?.id,
    };
    console.log(obj);
    // return;
    const data = await dispatch(addActualCommentExp(obj));
    if (data?.payload?.success) {
      setComments("");
      dispatch(getExpActualComments(detail?.id));
    }

    // console.log(detail, "details");
  };
  return (
    <div style={{ width: "55rem" }} className="overview-card-modal-wrap">
      <div className="top-box">
        <p className="title">Comments</p>
        <div onClick={onCancel} className="cancel-box">
          <FaTimes className="icon" />
        </div>
      </div>
      <div className="content-box">
        <div className="img-input-wrap">
          <figure className="img-box">
            <img src={imgOne} alt="" className="img" />
          </figure>
          <textarea
            placeholder="type here your comment"
            style={{ resize: "unset" }}
            name=""
            id=""
            cols="30"
            rows="3"
            className="input-text"
            value={comment}
            onChange={(e) => setComments(e.target.value)}
          ></textarea>
        </div>
        {/* btn box start */}
        <div className="btn-box">
          {loading ? (
            <div style={{ alignSelf: "center", marginTop: "0rem" }}>
              <Dna
                visible={true}
                height="80"
                width="150"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <ButtonBlue
              bgColor={!comment && "#cccccc"}
              style={{ margin: "2rem 0rem" }}
              onClick={() => {
                comment && handleApprove();
              }}
              text={`Add comment`}
            />
          )}
        </div>
        {/* btn box end */}
      </div>
      <div className="bottom-box-two">
        {/* <ButtonBlue onClick={onCancel} text={`Cancel`} /> */}
        {/* comment wrap start */}
        {comments?.map((chi, idx) => {
          const { user, comment, status } = chi || "";
          return (
            <div key={idx} className="comment-wrap">
              <figure
                style={{ width: "3rem", height: "3rem" }}
                className="img-box"
              >
                <img
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                  src={user?.profile_picture || imgOne}
                  alt=""
                  className="img"
                />
              </figure>
              <div className="box-wrap">
                <div className="top-box-wrap">
                  <div className="box">
                    <p className="value">{user?.name}</p>
                    <p style={{ marginBottom: "unset" }} className="text">
                      {user?.email}
                    </p>

                    <p className="text">
                      {" "}
                      {moment(chi?.created_at).format("YYYY-MM-DD HH:mm a")}
                    </p>
                  </div>
                  <div className={`reject-box ${status}`}>
                    <p>{status}</p>
                  </div>
                  {loadingTwo ? (
                    <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                      <Dna
                        visible={true}
                        height="35"
                        width="50"
                        ariaLabel="dna-loading"
                        // wrapperStyle={{color: "red", backgroundColor : "red"}}
                        wrapperClass="dna-wrapper"
                      />
                    </div>
                  ) : (
                    <FaTrash
                      onClick={() => handleDelete(chi?.id)}
                      className="delete-icon"
                    />
                  )}
                </div>
                {/* end of top box */}
                <p className="comment">{comment}</p>
              </div>
              {/* end of box wrap */}
            </div>
          );
        })}
        {/* comment wrap end */}
      </div>
    </div>
  );
};

export default ComentModal;
