/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
/* eslint-disable import/first */
// const nairaSymbol = "\u{020A6}";
// import { useContext } from "react";
// import ActionContext from "../context/ActionContext";

export function validateEmail(email) {
  var filter =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  if (!filter.test(email)) {
    return false;
  } else {
    return true;
  }
}

export const perPageOptions = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "200", value: 200 },
  { label: "500", value: 500 },
];



export const reactSelectStyle = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused
      ? "0.1rem solid var(--blue-color)"
      : "0.1rem solid #DDE2E4",
    // backgroundColor: state.isSelected ? "#DDE2E4" : "white",
    boxShadow: state.isFocused ? "0.1rem solid var(--blue-color)" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "var(--blue-color)",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "var(--blue-color)" : "white",
  }),
};

export const reactSelectStyleCommonSort = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "0.1rem solid #b0babf" : "0.1rem solid #b0babf",
    // backgroundColor: state.isSelected ? "#DDE2E4" : "white",
    boxShadow: state.isFocused ? "0.1rem solid #b0babf" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "var(--blue-color)" : "white",
  }),
};

export const reactSelectStyleTable = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "0.1rem solid #DDE2E4" : "0.1rem solid #DDE2E4",
    // backgroundColor: state.isSelected ? "#DDE2E4" : "white",
    boxShadow: state.isFocused
      ? "0.1rem solid #DDE2E4"
      : "0.1rem solid #DDE2E4",
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "var(--blue-color)" : "white",
  }),
};

export const reactSelectStyleHeaderNav = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused
      ? "0.15rem solid var(--blue-color)"
      : "0.15rem solid var(--blue-color)",
    // backgroundColor: state.isSelected ? "var(--blue-color)" : "white",
    boxShadow: state.isFocused ? "0.15rem solid var(--blue-color)" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "var(--blue-color)" : "white",
  }),
};

export const addLabelValueFunc = (list) => {
  // console.log(list);
  const newList = list.map((chi) => {
    return { ...chi, label: chi?.name || chi?.title, value: chi?.id };
  });
  // console.log(newList);
  return newList;
};

export const formatDate = (param) => {
  const valSplit = param.split("/");
  const val = `${valSplit[2]} - ${valSplit[1]} - ${valSplit[0]}`;
  return val;
};

export function formatNumber(numb) {
  var regex = /[,\sNG]/g;
  var result = String(numb).replace(regex, "");
  var num = Math.abs(result);
  num = num.toFixed(2);
  const numSplit = num.split(".");
  var int = numSplit[0];
  const dec = numSplit[1];
  if (int.length > 3) {
    int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return int + "." + dec;
}

export function formatNumberNaira(numb) {
  var regex = /[,\sNG]/g;
  var result = String(numb || "0").replace(regex, "");
  var num = Math.abs(result);
  num = num.toFixed(2);
  const numSplit = num.split(".");
  var int = numSplit[0];
  const dec = numSplit[1];
  if (int.length > 3) {
    int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return "NGN " + int + "." + dec;
}

export function formatNumberWithComma(numb) {
  var regex = /[,\sNG]/g;
  var result = String(numb).replace(regex, "");
  var num = Math.abs(result);
  num = num.toFixed(2);
  const numSplit = num.split(".");
  var int = numSplit[0];
  // const dec = numSplit[1];
  if (int.length > 3) {
    int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return int;
}
export const handleNumberFormatInput = (e) => {
  const value = e.target.value;
  const clean = value.replace(/,/g, "");
  const regex = /^[0-9]*\.?[0-9]*$/;

  if (value && clean.match(regex)) {
    if (!value.includes(".")) {
      const formatted = new Intl.NumberFormat().format(parseFloat(clean));
      return formatted;
    } else {
      return value;
    }
  } else {
    return;
  }
};

export function formatNumberWithoutDecimal(numb) {
  var regex = /[,\sNG]/g;
  var result = String(numb || "0").replace(regex, "");
  var num = Math.abs(result);
  num = num.toFixed(2);
  const numSplit = num.split(".");
  var int = numSplit[0];
  // const dec = numSplit[1];
  if (int.length > 3) {
    int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return int;
}

export function formatNumberWithoutComma(numb) {
  var regex = /[,\sNG]/g;
  var result = numb.replace(regex, "");
  return result;
}

export const getChartStructures = (data) => {
  // console.log(data);
  let val;
  if (data) {
    const getNameFromDat = () => {
      const list = data?.map((chi) => chi?.attributes?.name);
      return list;
    };

    const getApprovedFromDat = () => {
      const list = data?.map((chi) => chi?.attributes?.approved);
      return list;
    };

    const getRejectedFromDat = () => {
      const list = data?.map((chi) => chi?.attributes?.rejected);
      return list;
    };

    const getPendingFromDat = () => {
      const list = data?.map((chi) => chi?.attributes?.awaiting_approval);
      return list;
    };

    const nameList = getNameFromDat();
    const rejectList = getRejectedFromDat();
    const approveList = getApprovedFromDat();
    const pendingList = getPendingFromDat();

    const dataChart = {
      labels: nameList,
      datasets: [
        {
          label: "Approved",
          data: approveList,
          backgroundColor: "#07A287",
        },
        {
          label: "Pending",
          data: pendingList,
          backgroundColor: "#FCA549",
        },
        {
          label: "Rejected",
          data: rejectList,
          backgroundColor: "#CC0905",
        },
      ],
    };
    val = dataChart;
  }
  //  console.log(val);
  return val;
};

export const getChartStructureGross = (data) => {
  // console.log(data);
  const brandcolor = localStorage.getItem("brand_color");
  let val;
  if (data) {
    const getNameFromDat = () => {
      const list = data?.map((chi) => chi?.attributes?.name);
      return list;
    };

    const getApprovedFromGross = () => {
      const list = data?.map((chi) => chi?.attributes?.gross);
      return list;
    };

    const nameList = getNameFromDat();
    const grossList = getApprovedFromGross();

    const dataChart = {
      labels: nameList,
      datasets: [
        {
          label: "Gross Profit",
          data: grossList,
          backgroundColor: brandcolor,
        },
      ],
    };
    val = dataChart;
  }
  //  console.log(val);
  return val;
};

export const getChartStructureHomeNetProfit = (data) => {
  // console.log(data);
  // const brandcolor = localStorage.getItem("brand_color");
  let val;
  if (data) {
    const getNameFromDat = () => {
      const list = data?.map((chi) => chi?.department?.name);
      return list;
    };

    const getApprovedFromGross = () => {
      const list = data?.map((chi) => chi?.projected_net_profit);
      return list;
    };

    const nameList = getNameFromDat();
    const grossList = getApprovedFromGross();

    const dataChart = {
      labels: nameList,
      datasets: [
        {
          label: "Net Profit Projection",
          data: grossList,
          backgroundColor: "#FCA549",
        },
      ],
    };
    val = dataChart;
  }
  //  console.log(val);
  return val;
};

export const getChartStructureHomeNetActualProfit = (data) => {
  // console.log(data);
  // const brandcolor = localStorage.getItem("brand_color");
  let val;
  if (data) {
    const getNameFromDat = () => {
      const list = data?.map((chi) => chi?.department?.name);
      return list;
    };

    const getApprovedFromGross = () => {
      const list = data?.map((chi) => chi?.actual_net_profit);
      return list;
    };

    const nameList = getNameFromDat();
    const grossList = getApprovedFromGross();

    const dataChart = {
      labels: nameList,
      datasets: [
        {
          label: "Net Actual Profit",
          data: grossList,
          backgroundColor: "#07A287",
        },
      ],
    };
    val = dataChart;
  }
  //  console.log(val);
  return val;
};

export const getChartStructureHomeGrossProfit = (data) => {
  // console.log(data);
  // const brandcolor = localStorage.getItem("brand_color");
  let val;
  if (data) {
    const getNameFromDat = () => {
      const list = data?.map((chi) => chi?.department?.name);
      return list;
    };

    const getApprovedFromGross = () => {
      const list = data?.map((chi) => chi?.gross_net_profit);
      return list;
    };

    const nameList = getNameFromDat();
    const grossList = getApprovedFromGross();

    const dataChart = {
      labels: nameList,
      datasets: [
        {
          label: "Project Gross Profit",
          data: grossList,
          backgroundColor: "#FCA549",
        },
      ],
    };
    val = dataChart;
  }
  //  console.log(val);
  return val;
};

export const getChartStructureHomeActualGrossProfit = (data) => {
  let val;
  if (data) {
    const getNameFromDat = () => {
      const list = data?.map((chi) => chi?.department?.name);
      return list;
    };

    const getApprovedFromGross = () => {
      const list = data?.map((chi) => chi?.gross_net_profit);
      return list;
    };

    const nameList = getNameFromDat();
    const grossList = getApprovedFromGross();

    const dataChart = {
      labels: nameList,
      datasets: [
        {
          label: "Actua Gross Profit",
          data: grossList,
          backgroundColor: "#07A287",
        },
      ],
    };
    val = dataChart;
  }
  return val;
};

export const getChartStructureHomeBookBalance = (data) => {
  // console.log(data);
  // const brandcolor = localStorage.getItem("brand_color");
  let val;
  if (data) {
    const getNameFromDat = () => {
      const list = data?.map((chi) => chi?.department?.name);
      return list;
    };

    const getApprovedFromGross = () => {
      const list = data?.map((chi) => chi?.book_balance);
      return list;
    };

    const nameList = getNameFromDat();
    const grossList = getApprovedFromGross();

    const dataChart = {
      labels: nameList,
      datasets: [
        {
          label: "Book Balance",
          data: grossList,
          backgroundColor: "#FCA549",
        },
      ],
    };
    val = dataChart;
  }
  //  console.log(val);
  return val;
};

export const getChartStructureHomeAvailableBalance = (data) => {
  // console.log(data);
  // const brandcolor = localStorage.getItem("brand_color");
  let val;
  if (data) {
    const getNameFromDat = () => {
      const list = data?.map((chi) => chi?.department?.name);
      return list;
    };

    const getApprovedFromGross = () => {
      const list = data?.map((chi) => chi?.available_balance);
      return list;
    };

    const nameList = getNameFromDat();
    const grossList = getApprovedFromGross();

    const dataChart = {
      labels: nameList,
      datasets: [
        {
          label: "Available Balance",
          data: grossList,
          backgroundColor: "#07A287",
        },
      ],
    };
    val = dataChart;
  }
  //  console.log(val);
  return val;
};

// const formatNumToMont = (val) => {
//   let month;
//   if (val === "01") {
//     const mon = "Jan";
//     month = mon;
//   }
//   if (val === "02") {
//     const mon = "Feb";
//     month = mon;
//   }
//   if (val === "03") {
//     const mon = "Mar";
//     month = mon;
//   }
//   if (val === "04") {
//     const mon = "Apr";
//     month = mon;
//   }
//   if (val === "05") {
//     const mon = "May";
//     month = mon;
//   }
//   if (val === "06") {
//     const mon = "Jun";
//     month = mon;
//   }
//   if (val === "07") {
//     const mon = "Jul";
//     month = mon;
//   }
//   if (val === "08") {
//     const mon = "Aug";
//     month = mon;
//   }
//   if (val === "09") {
//     const mon = "Sep";
//     month = mon;
//   }
//   if (val === "10") {
//     const mon = "Oct";
//     month = mon;
//   }
//   if (val === "11") {
//     const mon = "Nov";
//     month = mon;
//   }
//   if (val === "12") {
//     const mon = "Dec";
//     month = mon;
//   }
//   return month;
// };

export const formatDataForDashboardGraph = (data) => {
  const projectedList = data?.map((chi) => {
    const { value } = chi;
    return value?.projected;
  });

  const actualList = data?.map((chi) => {
    const { value } = chi;
    return value?.actual;
  });

  // const labelList = data?.map((chi) => {
  //   const { month } = chi;
  //   return formatNumToMont(month);
  // });

  const dataVal = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Projected",
        data: projectedList,
        borderColor: "#FCA549",
        tension: 0.2,
        backgroundColor: "#FCA549",
      },
      {
        label: "Actual",
        tension: 0.2,
        data: actualList,
        borderColor: "#07A287",
        backgroundColor: "#07A287",
      },
    ],
  };

  //  console.log(dataVal);
  return dataVal;
};

export const defaultHomeGraphVal = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Projected",
      data: [""],
      borderColor: "#07A287",
      backgroundColor: "#07A287",
    },
    {
      label: "Actual",
      data: [""],
      borderColor: "#FCA549",
      backgroundColor: "#FCA549",
    },
  ],
};

export const formatDataForDashboardGraphBookVsAvailable = (data) => {
  // console.log(data);
  const availableList = data?.available_balance_data?.map((chi) => {
    const { available_balance } = chi;
    return available_balance;
  });
  //  console.log(availableList);
  const bookList = data?.book_balance_data?.map((chi) => {
    const { book_balance } = chi;
    return book_balance;
  });

  // const labelList = data?.map((chi) => {
  //   const { month } = chi;
  //   return formatNumToMont(month);
  // });

  const dataVal = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Available",
        data: availableList,
        borderColor: "#07A287",
        backgroundColor: "#07A287",
        tension: 0.2,
      },
      {
        label: "Book",
        tension: 0.2,
        data: bookList,
        borderColor: "#FCA549",
        backgroundColor: "#FCA549",
      },
    ],
  };

  //  console.log(dataVal);
  return dataVal;
};

export const FormatNumWithCurrency = (numOne, base, rate) => {
  let numb;
  if (base === rate?.label) {
    // console.log("yes");
    const val = numOne * 1;
    numb = val;
  } else {
    // console.log("no");
    const val = Number(numOne) / Number(rate?.rate);
    numb = val;
  }
  return numb;
};

export function numberFormatChart(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" },
    { value: 1e18, symbol: "QT" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export const trimLongString = (str, num) => {
  if (str && num) {
    const val =
      String(str).length > Number(num)
        ? `${String(str).slice(0, Number(num))}...`
        : str;
    return val;
  }
};

export const addAllToList = (list, str) => {
  if (list?.length > 0) {
    const val = [{ label: `All ${str || ""}`, value: "all" }, ...list];
    return val;
  }
};

export const checkCurrentApproval = (list) => {
  let val;
  if (list?.length > 0) {
    const value = list?.some((chi) => !chi?.current_approver);

    val = value ? false : true;
  }

  return val;
};

export const formatNumWithCommaNairaSymbol = (numb, cur) => {
  const nairaSymbol = "\u{020A6}";

  var regex = /[,\sNG]/g;
  var result = String(numb).replace(regex, "");
  var num = Math.abs(result);
  num = num.toFixed(2);
  const numSplit = num.split(".");
  var int = numSplit[0];
  const dec = numSplit[1];
  if (int.length > 3) {
    int = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (numb) {
    return cur ? checkCurrencyFormat(cur) + "" + int + "." + dec :  nairaSymbol + "" + int + "." + dec;
  }

  return cur ? checkCurrencyFormat(cur) + "" + int + "." + dec : nairaSymbol + "" + "0" + "." + "00";
};

export const checkCurrencyFormat = (cur) => {
  const val =
    cur === "Naira"
      ? "₦"
      : cur === "USD"
      ? "$" : cur === "Dollar"
      ? "$"
      : cur === "EUR"
      ? "€"  : cur === "Euro"
      ? "€"
      : cur === "GBP"
      ? "£"
      : "₦";
  return val;
};
