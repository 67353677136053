import React, { useContext } from "react";
import { useEffect } from "react";
// import { Children } from "react";
import { useSelector } from "react-redux";
// import HeaderNav from "../../../../component/layout/dashboard/HeaderNav";
import NotificationModal from "../../../../component/layout/dashboard/NotificationModal";
import ActionContext from "../../../../context/ActionContext";
import EmployeeHeaderNav from "./EmpHeaderNav";
import EmployeeSidebarMenu from "./EmpSidebarMenu";

const EmployeeDashboardLayout = ({
  goBack,
  pageTitle,
  pageTitleSpan,
  children,
  organization,
}) => {
  const actionCtx = useContext(ActionContext);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (user?.organizations?.length > 0) {
      const brandcolor = localStorage.getItem("brand_color");
      document.documentElement.style.setProperty("--blue-color", brandcolor);
    }

    // console.log("yes");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashboard-layout-wrap">
      <div
        onClick={() => actionCtx.profileBoxOpenFunc("close")}
        className={`sidebar ${actionCtx.isSidebarReduce && "sidebar-reduce"}`}
      >
        <EmployeeSidebarMenu />
      </div>
      <div
        // onClick={() => actionCtx.setShowSupportDropDown("close")}
        className={`header ${actionCtx.isSidebarReduce && "header-reduce"}`}
      >
        <EmployeeHeaderNav
          goBack={goBack}
          pageTitle={pageTitle}
          pageTitleSpan={pageTitleSpan}
          organization={organization}
        />
      </div>
      <div
        onClick={() => {
          actionCtx.profileBoxOpenFunc("close");
          actionCtx.setShowSupportDropDown("close");
        }}
        className={`main ${actionCtx.isSidebarReduce && "main-reduce"}`}
        style={{ zIndex: actionCtx?.isModalOut ? "4" : "1" }}
      >
        {children}
      </div>

      {/* notification start */}
      {actionCtx?.showNotification && <NotificationModal />}
      {/* notification end */}
    </div>
  );
};
export default EmployeeDashboardLayout;
