import React, { useEffect, useContext } from "react";
import "../../../styles/auth/AuthLayout.css";
import { FaCheck } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";
import getHelpIcon from "../../../assets/get-help-icon.svg";
import ActionContext from "../../../context/ActionContext";
import revvexLogo from "../../../assets/revvex-logo.svg";

const AuthLayout = ({
  children,
  title,
  small_text,
  small_text_two,
  getHelp,
}) => {
  const actionCtx = useContext(ActionContext);
  useEffect(() => {
    Aos.init({
      duration: 1200,
      offset: 0,
    });
  }, []);
  const list = [
    "Track real-time overview of company’s financial performance.",
    "Track created projects budget against actual revenue and expenses.",
    "Highlighted reports on budget deficit and surplus, accounting dimensions, balance sheets and real-time sales margin estimation.",
  ];
  return (
    <div className="auth-layout-wrap">
      <div className="left-box">
        <div className="content-box">
          {/* logo box start */}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://revvex.io/home"
            className="logo-box"
          >
            <figure className="img-box">
              <img src={revvexLogo} alt="" className="img" />
            </figure>
          </a>
          {/* logo box end */}
          {/* list start */}
          <div
            className={`list-box ${
              actionCtx.authSlideShow && "list-box-anime"
            }`}
          >
            {list.map((chi, idx) => {
              return (
                <div key={idx} className="item">
                  <span>
                    <FaCheck className="icon" />
                  </span>
                  <p className="text">{chi}</p>
                </div>
              );
            })}
          </div>
          {/* list end */}

          {/* copy write text start */}
          <p className="copy-text">© 2022 Revvex. All rights reserved</p>
          {/* copy write text end */}
        </div>
      </div>
      {/* end of left box */}
      {/* right-box start */}
      <div className="right-box">
        <div className="wrap">
          <div className="content-wrap">
            <div className="content-box" data-aos="fade-up">
              {title && (
                <p className="title">{title || "Register your account"}</p>
              )}
              {small_text && <p className="small-text">{small_text}</p>}
              {small_text_two && <p className="small-text">{small_text_two}</p>}
              {children}
            </div>
          </div>
          {/* get help box start */}
          {getHelp && (
            <div className="get-help-box">
              <p className="text">Get Help</p>
              <figure className="img-box">
                <img src={getHelpIcon} alt="" className="img" />
              </figure>
            </div>
          )}
          {/* get help box end */}
        </div>
        {/* end of wrap */}
      </div>
      {/* right-box end */}
    </div>
  );
};

export default AuthLayout;
