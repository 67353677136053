import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
// import TableBodyRow from "../../../../../../component/tables/TableBodyRow";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import "../../../../../styles/dashboard/projection/expense/SubmitProjection.scss";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
// import EmployeeDraftProjection from "../../projection/expense/EmpDraftProjection";
import EmployeeCommonExpenseLayout from "./CommonLayout";
import EmployeeActualDraftExpense from "../actualDraftExpense";
import { useDispatch, useSelector } from "react-redux";
import {
  getActualExpenseSubmittedBySearch,
  getEmployeeActualExpensesPending,
  getEmployeeActualExpensesPendingPagination,
  getAllActualExpenseComment,
  getEmpActualExpenseReimburse,
} from "../../../../../../redux/employee/actual-expense";
import {
  checkCurrencyFormat,
  formatNumber,
  perPageOptions,
  trimLongString,
} from "../../../../../../helper/Helpers";
import EmployeeActualExpenseDeleteModal from "../modals/deleteModal";
import Modal from "react-awesome-modal";
import useDebounce from "../../../../../../helper/UseDebounce";
import EmployeePendingOverviewModal from "../modals/submitted revenue/pending-overview";
// import EmployeeRevenueApprovalModal from "../modals/approve";
import EmpActualExpenseApproveOverview from "../modals/actual-expense/approveModal";
import EmpActualExpenseRejectModal from "../modals/actual-expense/rejectedModal";
// import EmpExpProjectionComentModal from "../../projection/expense/modals/comments";
import EmpActualExpenseComentModal from "../modals/actual-expense/commentsModal";
// import { fa } from "faker/lib/locales";
import EmployeeApprovedActualExpenseOverviewModal from "../modals/submitted revenue/approveOverview";
import EmployeeActualExepenseRejectAllModal from "../modals/rejectAllModal";
import EmployeeActualExpenseReimburseModal from "../modals/reimburseModal";
import EmpRejectActualExpOverviewModal from "../modals/submitted revenue/reject-overview";
import EmployeeActualExpenseFinalReimburseModal from "../modals/finalReimburseModal";
import ActionContext from "../../../../../../context/ActionContext";
import {
  getEmployeeProjectionDownload,
  getExportToPdf,
  getUserDepartment,
  recallProjections,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import moment from "moment";
import PartialReimbursement from "../modals/partial";
import { notifyNextApprover } from "../../../../../../redux/projections/ExpenditureProjection";
import FinalApprovalModalExpense from "../modals/finalApprovalModal";
import { getDepartments } from "../../../../../../redux/department/deparment";
import { getVerticals } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import { getProducts } from "../../../../../../redux/budget-setting/revenue-projection/product";
import ActualExpenseRecallModal from "../modals/recallModal";
import { fa } from "faker/lib/locales";
import BigShowModalComponent from "../../../../../../component/pages/dashboard/modal/BigShowModalComponent";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
// import Papa from "papaparse";
// import { useReactToPrint } from "react-to-print";

const EmployeeActualSubmit = () => {
  const history = useHistory();
  const [filterByVal, setFilterByVal] = useState("");
  const { loading, actualExpensePending, meta, links, loadingPage } =
    useSelector((state) => state.actual_expense_employee);
  const { recallLoading } = useSelector(
    (state) => state.expenditure_projection
  );
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);

  const [modalView, setModalView] = useState(false);

  const [refreshTable, setRefreshTable] = useState(false);
  const [showModal, setShowModal] = useState({
    pendingOverview: false,
    approveOverview: false,
    rejectOverview: false,
    edit: false,
    delete: false,
    reject: false,
    comment: false,
    reimburse: false,
    rejectAll: false,
    partialReimburse: false,
    approve_all: false,
    recall: false,
    img: false,
    pdf: false,
  });

  const [showLink, setShowLink] = useState("");

  const [activeStat, setActiveStat] = useState({
    one: true,
    two: false,
    three: false,
    four: false,
  });
  const [singleChild, setSingleChild] = useState({});
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [indexNo, setIndexNo] = useState("");
  const [selectStat, setSelectStat] = useState("");
  const [dept, setDept] = useState("");
  const { userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const { productDrop } = useSelector((state) => state.product);
  const { verticalDrop } = useSelector((state) => state.vertical);
  const { departmentDrop } = useSelector((state) => state.department);
  const [reimburseCheck, setReimburseCheck] = useState("");
  const [chartOfAcctVal, setChartOfAcct] = useState("");
  // const [perPageVal, setPerPageVal] = useState({ label: "Per Page" });
  const actionCtx = useContext(ActionContext);
  const statusDropdown = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    { label: "Pending My Approval", value: "pending_my_current_approver" },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const statusDropDownTwo = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const cardHeader = [
    "Total Expenses",
    "Approved",
    "Pending",
    "Rejected",
    "Pending My Approval",
  ];

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Units",
    `Unit Price (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Total Reimbursed (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Total Expense (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    "Date",
    "Status",
  ];

  useEffect(() => {
    const obj = {
      reason: true,
      id: user?.organizations[0]?.id,
    };
    if (user?.organizations[0]?.id) {
      dispatch(getUserDepartment(user?.organizations[0]?.id));
      dispatch(getDepartments(user?.organizations[0]?.id));
      dispatch(getChatAccounts(obj));
      dispatch(getVerticals(obj));
      dispatch(getProducts(obj));
    }
  }, [user?.organizations[0]?.id]);

  useEffect(() => {
    if (actionCtx?.perPage || actionCtx?.currencyValue) {
      handleGetActualExpenseData();
    }
  }, [actionCtx?.perPage, actionCtx?.currencyValue]);

  const handlePagination = async (param, statusParam) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      dept_id: dept?.id,
      status: statusParam || selectStat?.value,
      filter: filterByVal?.value,
      search: search,
      sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
    };
    setIndexNo(false);
    dispatch(getEmployeeActualExpensesPendingPagination(obj));
  };
  const handlePaginationNum = async (num, statusParam) => {
    const obj = {
      id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: num,
      dept_id: dept?.id,
      status: statusParam || selectStat?.value,
      filter: filterByVal?.value,
      search: search,
      sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
    };
    setIndexNo(false);
    const data = await dispatch(
      getEmployeeActualExpensesPendingPagination(obj)
    );
    if (data?.payload?.success) {
      // setIndexNo(false);
      setSingleItem(data?.payload?.data);
    }
  };

  const handleFinish = () => {
    setIndexNo(false);
    setShowModal({
      edit: false,
      delete: false,
    });
    setRefreshTable(!refreshTable);
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");

  const [sortByVal, setSortByVal] = useState("");
  const [selectedRecall, setSelectedRecall] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterLoad, setFilterLoad] = useState(false);
  const [productVal, setProductVal] = useState("");
  const [verticalVal, setVerticalVal] = useState("");

  const SortByOptions = [
    { label: "Ascending", value: "ASC" },
    { label: "Descending", value: "DESC" },
  ];
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart Of Account", value: "chart_of_account_id" },
    { label: "class", value: "class" },
    { label: "description", value: "description" },
    { label: "units", value: "units" },
    { label: "unit_price", value: "unit_price" },

    // { label: "Date", value: "created_at" },
  ];

  const handleGetAllComment = (param) => {
    dispatch(getAllActualExpenseComment(param));
  };

  const handleSearch = (param) => {
    const obj = {
      id: user?.organizations[0]?.id,
      search: search,
      dept_id: dept?.id,
      filter: filterByVal?.value,
      status: selectStat?.value || param,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      // sort_by: sortByVal?.value,
    };
    setIndexNo(false);
    dispatch(getActualExpenseSubmittedBySearch(obj));
  };

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      (activeStat?.one && handleSearch("")) ||
        (activeStat?.two && handleSearch("approved")) ||
        (activeStat?.three && handleSearch("pending")) ||
        (activeStat?.four && handleSearch("rejected"));
      // const obj = {
      //   id: user?.organizations[0]?.id,
      //   search: search,
      //   is_submitted: 1,
      //   dept_id: dept?.id,
      //   status: selectStat?.value,
      //   filter_by: filterByVal?.value,
      //   // sort_by: sortByVal?.value,
      // };
      // dispatch(getActualExpenseSubmittedBySearch(obj));
    } else {
      // handleGetActualExpenseData();
      (activeStat?.one && handleGetActualExpenseData("")) ||
        (activeStat?.two && handleGetActualExpenseData("approved")) ||
        (activeStat?.three && handleGetActualExpenseData("pending")) ||
        (activeStat?.four && handleGetActualExpenseData("rejected"));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const link = [
    "/employee-dashboard-actual-expense-new",
    "/employee-dashboard-actual-expense-submitted",
    "/employee-dashboard-actual-expense-submitted-approved",
    "/employee-dashboard-actual-expense-submitted-pending",
    "/employee-dashboard-actual-expense-submitted-rejected",
  ];

  const handleSortChange = (param, statParam) => {
    const obj = {
      sort: param?.value,
      is_submitted: 1,
      dept_id: dept?.id,
      status: statParam || selectStat?.value,
      id: user?.organizations[0]?.id,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };

    setIndexNo(false);
    dispatch(getActualExpenseSubmittedBySearch(obj));
  };

  const [singleItem, setSingleItem] = useState({});

  const handleGetActualExpenseData = async (param, deptParam) => {
    const obj = {
      dept_id: deptParam || dept?.id,
      id: user?.organizations[0]?.id,
      status: param || selectStat?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
    };
    const data = await dispatch(getEmployeeActualExpensesPending(obj));
    if (data?.payload?.success) {
      // setIndexNo(false);
      setSingleItem(data?.payload?.data);
      // console.log(data?.payload?.data);
    }
  };
  const [activeBell, setActiveBell] = useState(true);
  const handleFilterSubmit = async (param, deptParam) => {
    const obj = {
      dept_id: deptParam || dept?.id,
      id: user?.organizations[0]?.id,
      status: param || selectStat?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
    };
    // console.log(obj, "obj");
    setFilterLoad(true);
    const data = await dispatch(getEmployeeActualExpensesPending(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      setFilterLoad(false);
      setShowFilterModal(false);
      actionCtx?.setIsModalOut(false);
      localStorage.setItem("expense-notify-dept", dept?.id);
      dept?.id && setActiveBell(false);
      // setVerticalVal({ label: "Select Vertical" });
      // setProductVal({ label: "Select Product" });
      // setPdfExport({ label: "File" });
      // setChartOfAcct({ label: "Select Chart of Acct..." });
      // setFilterByVal({ label: "Filter by" });
      // setSelectStat({ label: "Select Status" });
      // setDept({ label: "Select Department" });
      setActiveStat((prev) => {
        return {
          ...prev,
          one: obj?.status === "",
          two: obj?.status === "approved" && true,
          three: obj?.status === "pending" && true,
          five: obj?.status === "pending_my_current_approver" && true,
          four: obj?.status === "rejected" && true,
        };
      });
    } else {
      setFilterLoad(false);
    }
  };

  const handleUpdate = (param) => {
    actionCtx?.setIsModalOut(false);
    const id = param?.id;
    localStorage.setItem("employee-actual-expense-id", id);
    // return;
    history.push("/employee-dashboard-actual-expense-update");
  };

  const cardValue = [
    singleItem?.total_actual_expense || 0,
    singleItem?.Approved || 0,
    singleItem?.awaiting_approval || 0,
    singleItem?.rejected || 0,
    singleItem?.pending_my_approver || 0,
    singleItem?.total_gp || 0,
  ];

  // export dropdown
  const [pdfExport, setPdfExport] = useState("");
  const exportOption = [
    { label: "CSV", value: "excel" },
    { label: "PDF", value: "pdf" },
  ];

  const [downloading, setDownloading] = useState(false);
  const [allApprovalStatus, setAllApprovalStatus] = useState("");
  const [headerCheck, setHeaderCheck] = useState(true);

  const handleFileDownload = async (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: dept?.id,
      type: "actual_expense",
      format: pdfExport?.value,
      user_id: user?.id,
      currency: actionCtx?.currencyValue?.id || "",
      status: param || selectStat?.value,
      chart_of_account_id: chartOfAcctVal?.value,
    };
    console.log(obj, "obj");
    setDownloading(true);
    const data = await dispatch(getEmployeeProjectionDownload(obj));
    if (data?.payload?.status === 200) {
      setDownloading(false);
      window.open(data?.payload?.data?.url, "_blank").focus();
      setIndexNo(false);
      setShowFilterModal(false);
      actionCtx.isModalOut(false);
      // setSelectStat("");
      // setDept("");
      // setVerticalVal("");
      // setProductVal("");
      // setPdfExport("");
      // setFilterByVal("");
    } else {
      setDownloading(false);
    }
  };

  const [allApproveId, setAllAppproveId] = useState([]);
  const handleApproveAll = () => {
    const list = actualExpensePending?.filter(
      (chi) => chi?.status === "pending" && chi?.current_approver
    );
    const newList = list.map((chi) => chi?.id);
    setAllAppproveId(newList);
  };

  const [exportLoader, setExportLoader] = useState(false);
  const handleExport = async (param) => {
    const obj = {
      id: param?.id,
      type: "actual_expense",
      currency_id: actionCtx?.currencyValue?.id,
    };
    setExportLoader(true);
    const data = await dispatch(getExportToPdf(obj));
    if (data?.payload?.url) {
      setIndexNo(false);
      window.open(data?.payload?.url, "_blank").focus();
      setShowModal((prev) => {
        return {
          ...prev,
          approveOverview: false,
          pendingOverview: false,
          rejectOverview: false,
        };
      });
      setExportLoader(false);
    } else {
      setExportLoader(false);
    }
  };

  const [checkHeader, setCheckHeader] = useState(!true);
  const [recallLoader, setRecallLoader] = useState(false);
  const handleRecallProjection = async (param, singleParam) => {
    const obj = {
      id: param || [singleParam],
      type: "actual_expense",
    };
    setRecallLoader(true);
    const data = await dispatch(recallProjections(obj));
    if (data?.payload?.success) {
      actionCtx?.setIsModalOut(false);
      setRecallLoader(false);
      setIndexNo(false);
      history.push("/employee-dashboard-actual-expense");
    } else {
      setRecallLoader(false);
    }
  };

  const handleCheckRecall = (id) => {
    const checkedList = selectedRecall.indexOf(id);
    if (checkedList > -1) {
      setSelectedRecall(selectedRecall.filter((chi) => chi !== id));
    } else {
      setSelectedRecall([...selectedRecall, id]);
    }
  };

  const handleAllRecallCheck = (e) => {
    if (e.target.checked) {
      setSelectedRecall(actualExpensePending?.map((chi) => chi.id));
    } else {
      setSelectedRecall([]);
    }
    setCheckHeader(e.target.checked);
  };

  const handleEditListCheck = (id) => {
    const newList = actualExpensePending?.data?.map((chi) =>
      chi?.id === id
        ? { ...chi, current_approver: !chi?.current_approver }
        : chi
    );
    const newObj = {
      ...singleItem,
      actualExpensePending: { ...actualExpensePending, data: newList },
    };
    setSingleItem(newObj);
  };

  const [notifyLoader, setNotifyLoader] = useState(false);
  const deptId = localStorage.getItem("expense-notify-dept");

  const handleNotifyApprover = async () => {
    const obj = {
      department_id: deptId,
      projection_type: "actual_expense",
    };
    setNotifyLoader(true);
    const data = await dispatch(notifyNextApprover(obj));
    if (data.payload?.success) {
      setNotifyLoader(false);
      setDept("");
      setActiveBell(true);
    } else {
      setNotifyLoader(false);
    }
  };

  return (
    <>
      <div>
        <EmployeeCommonExpenseLayout
          commonLink={true}
          exportDrop
          exportVal={pdfExport}
          options={exportOption}
          onExport={(e) => {
            setPdfExport(e);
            setIndexNo(false);

            // console.log(e?.value);
          }}
        >
          <EmployeeReusableTable
            downloading={downloading}
            onDownload={() => {
              handleFileDownload(
                (activeStat?.one && "") ||
                  (activeStat?.two && "approved") ||
                  (activeStat?.three && "pending") ||
                  (activeStat?.four && "rejected")
              );
            }}
            exportDrop
            exportVal={pdfExport}
            options={exportOption}
            onExport={(e) => {
              setPdfExport(e);
              setIndexNo(false);
            }}
            onOpenFilter={() => {
              setIndexNo(false);
              actionCtx?.setIsModalOut(true);
              setShowFilterModal(true);
            }}
            showFilter={showFilterModal}
            onCloseFilter={() => {
              actionCtx?.setIsModalOut(false);
              setShowFilterModal(false);
              setSelectStat("");
              setVerticalVal("");
              setProductVal("");
              setPdfExport("");
              setFilterByVal("");
              setDept("");
            }}
            filterLoading={filterLoad}
            onFilterSubmit={() => {
              handleFilterSubmit();
            }}
            filterIcon
            // vertical
            verticalVal={verticalVal}
            onVerticalChange={(e) => {
              setVerticalVal(e);
            }}
            verticalDrop={verticalDrop}
            // product
            chartAcct
            chartAcctVal={chartOfAcctVal}
            chartAcctDrop={chatAccountDrop}
            onChartAcctChange={(e) => {
              setChartOfAcct(e);
            }}
            productVal={productVal}
            onProductChange={(e) => {
              setProductVal(e);
            }}
            productDrop={productDrop}
            fileDownload
            onRejectAll={() => {
              setShowModal((prev) => {
                return { ...prev, approve_all: true };
              });
              actionCtx?.setIsModalOut(true);
              setAllApprovalStatus("rejected");
              handleApproveAll();
            }}
            onApproveAll={() => {
              setShowModal((prev) => {
                return { ...prev, approve_all: true };
              });
              actionCtx?.setIsModalOut(true);
              setAllApprovalStatus("approved");
              handleApproveAll();
            }}
            rejectAll={activeStat?.five}
            approveAll={activeStat?.five}
            recallAllBtn={
              activeStat?.three || activeStat?.one || activeStat?.five
            }
            loading={loading}
            notifyLoader={notifyLoader}
            recallLoader={recallLoading}
            onRecallAll={() => {
              handleRecallProjection(selectedRecall, "");
            }}
            recallBgColor={selectedRecall?.length < 1 && "#cccccc"}
            allActionBtn
            downloadLoader={downloading}
            onFileDownload={() => {
              pdfExport?.value &&
                handleFileDownload(
                  (activeStat?.one && "") ||
                    (activeStat?.two && "approved") ||
                    (activeStat?.three && "pending") ||
                    (activeStat?.four && "rejected")
                );
              setIndexNo(false);
            }}
            exportSelect={pdfExport?.label || "File"}
            ApprovalBell={!activeStat?.four}
            disableBell={activeBell}
            onNotify={handleNotifyApprover}
            totalTitle={"Expense Projected"}
            totalValue={formatNumber(String(cardValue[0] || 0))}
            totalTitleTwo={"Total GP"}
            totalValueTwo={formatNumber(String(cardValue[5] || 0))}
            perPageVal={actionCtx?.perPage}
            perPage
            optionPerPage={perPageOptions}
            setPerPage={(e) => {
              actionCtx?.setPerpage(e);
            }}
            statusDrop
            statusDropdown={
              user?.role !== "budget rep" ? statusDropdown : statusDropDownTwo
            }
            setStatusVal={(e) => {
              setIndexNo(false);
              setSelectStat(e);
            }}
            // notifyLoader={loading || notifyLoader}
            statusVal={selectStat}
            dept
            departmentDrop={
              user?.user_type === "ADMIN" ? departmentDrop : userDepartmentDrop
            }
            deptVal={dept}
            setDepartmentVal={(e) => {
              setIndexNo(false);
              setDept(e);
            }}
            // sort={true}
            filter
            optionSort={SortByOptions}
            optionSortVal={sortByVal}
            setOptionSortVal={(e) => {
              setIndexNo(false);
              setSortByVal(e);
              (activeStat?.one && handleSortChange(e, "")) ||
                (activeStat?.two && handleSortChange(e, "approved")) ||
                (activeStat?.three && handleSortChange(e, "pending")) ||
                (activeStat?.four && handleSortChange(e, "rejected"));
            }}
            optionFilter={filterByOptions}
            optionFilterVal={filterByVal}
            setOptionFilterVal={(e) => setFilterByVal(e)}
            onSearchTable={(e) => {
              setIndexNo(false);
              setSearch(e);
            }}
            addButton
            BtnTitle={`New Expense`}
            onPage={() => history.push(link[0])}
            // firstCard
            secondCard
            // loading={loading}
            thirdCard
            fourthCard
            fifthCard={user?.role !== "budget rep"}
            showSearch
            cardTitleTwo={cardHeader[1]}
            cardTitleThree={cardHeader[2]}
            cardTitleFour={cardHeader[3]}
            cardTitleFive={cardHeader[4]}
            cardBodyTwo={formatNumber(String(cardValue[1] || 0))}
            cardBodyThree={formatNumber(String(cardValue[2] || 0))}
            cardBodyFour={formatNumber(String(cardValue[3] || 0))}
            cardBodyFive={formatNumber(String(cardValue[4] || 0))}
            onFirstCard={() => {
              setIndexNo(false);
              handleGetActualExpenseData("");
              setActiveStat((prev) => {
                return {
                  ...prev,
                  one: true,
                  two: false,
                  three: false,
                  four: false,
                  five: false,
                };
              });
            }}
            onSecondCard={() => {
              setIndexNo(false);
              handleGetActualExpenseData("approved");
              setSelectStat({ label: "Approved", value: "approved" });
              setActiveStat((prev) => {
                return {
                  ...prev,
                  one: false,
                  two: true,
                  three: false,
                  four: false,
                  five: false,
                };
              });
            }}
            onThirdCard={() => {
              setIndexNo(false);
              handleGetActualExpenseData("pending");
              setSelectStat({ label: "Pending", value: "pending" });
              setActiveStat((prev) => {
                return {
                  ...prev,
                  one: false,
                  two: false,
                  three: true,
                  four: false,
                  five: false,
                };
              });
            }}
            onFourthCard={() => {
              setIndexNo(false);
              handleGetActualExpenseData("rejected");
              setSelectStat({ label: "Rejected", value: "rejected" });
              setActiveStat((prev) => {
                return {
                  ...prev,
                  one: false,
                  two: false,
                  three: false,
                  four: true,
                  five: false,
                };
              });
            }}
            onFifthCard={() => {
              setIndexNo(false);
              setSelectStat({
                label: "Pending My Approval",
                value: "pending_my_current_approver",
              });
              handleGetActualExpenseData("pending_my_current_approver");
              setActiveStat((prev) => {
                return {
                  ...prev,
                  one: false,
                  two: false,
                  three: false,
                  four: false,
                  five: true,
                };
              });
            }}
            linkCardOne={activeStat?.one}
            linkCardTwo={activeStat?.two}
            linkCardThree={activeStat?.three}
            linkCardFour={activeStat?.four}
            linkCardFive={activeStat?.five}
          />
          <ActualReuseableTable
            key={`dffgjsh`}
            massSelectable={
              (activeStat?.five && actualExpensePending?.length > 0) ||
              (activeStat?.three && actualExpensePending?.length > 0)
            }
            checkValue={
              (activeStat?.five && headerCheck) ||
              (activeStat?.three && checkHeader)
            }
            onMassCheck={(e) => {
              setHeaderCheck(!headerCheck);
              const newList = actualExpensePending?.map((chi) => {
                return { ...chi, current_approver: e.target.checked };
              });
              const newObj = {
                ...singleItem,
                actualExpensePending: {
                  ...actualExpensePending,
                  data: newList,
                },
              };
              setSingleItem(newObj);
              // pending
              activeStat?.three && handleAllRecallCheck(e);
            }}
            useNumPagination
            currentPage={actualExpensePending?.current_page}
            totalTableLength={actualExpensePending?.total}
            onNumPage={(e) => {
              handlePaginationNum(
                e,
                (activeStat?.one && "") ||
                  (activeStat?.two && "approved") ||
                  (activeStat?.three && "pending") ||
                  (activeStat?.four && "rejected")
              );
            }}
            dontShowBnSearch
            dontShowPagination={actualExpensePending?.length < 1}
            addBtntext={`Create Expense`}
            onCreateNew={() => {
              history.push("/employee-dashboard-actual-expense-new");
            }}
            // optionFilterVal={filterByVal}
            // setOptionFilterVal={(e) => setFilterByVal(e)}
            // optionFilter={tableSelectOption}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={(activeStat?.one || activeStat?.two) && tableHeadList[5]}
            seven={(activeStat?.one || activeStat?.two) && tableHeadList[6]}
            eight={tableHeadList[7]}
            nine={tableHeadList[8]}
            action
            fromPage={meta?.from || 1}
            toPage={meta?.to || actualExpensePending?.length}
            totalPage={meta?.total || actualExpensePending?.expense?.total}
            nextPage={links?.next_page_url}
            prevPage={links?.prev_page_url}
            // onSearchTable={(e) => setSearch(e)}
            onNextPage={() => {
              (activeStat?.one && handlePagination(links?.next_page_url, "")) ||
                (activeStat?.two &&
                  handlePagination(links?.next_page_url, "approved")) ||
                (activeStat?.three &&
                  handlePagination(links?.next_page_url, "pending")) ||
                (activeStat?.one &&
                  handlePagination(links?.next_page_url, "rejected"));
            }}
            onPrevPage={() => {
              (activeStat?.one && handlePagination(links?.prev_page_url, "")) ||
                (activeStat?.two &&
                  handlePagination(links?.prev_page_url, "approved")) ||
                (activeStat?.three &&
                  handlePagination(links?.prev_page_url, "pending")) ||
                (activeStat?.one &&
                  handlePagination(links?.prev_page_url, "rejected"));
            }}
          >
            {actualExpensePending?.length > 0 ? (
              <>
                {actualExpensePending?.map((chi, idx) => {
                  const {
                    chart_of_account,
                    description,
                    units,
                    unit_price,
                    status,
                    date,
                    current_approver,
                    re_imburse,
                    total_reimbursed,
                    total_expense,
                    // reimbursement,
                    id,
                  } = chi;

                  return (
                    <EmpTableBodyRow
                      checkBoxType={
                        activeStat?.five || activeStat?.three || activeStat?.one
                      }
                      onCheck={(e) => {
                        handleEditListCheck(id);
                        handleCheckRecall(id);
                      }}
                      checkValue={
                        // ((activeStat?.three || activeStat?.one) &&
                        (activeStat?.five && current_approver) ||
                        (status === "pending" && selectedRecall.includes(id))
                      }
                      // (activeStat?.one && selectedRecall.includes(id))
                      checkId={`${id}-${idx}`}
                      rowNo={idx}
                      indexNo={indexNo}
                      setIndexNo={() => setIndexNo(idx)}
                      action
                      loading={loading}
                      key={idx}
                      one={chart_of_account?.name}
                      two={chart_of_account?.class}
                      three={trimLongString(description, "25")}
                      four={units}
                      five={formatNumber(String(unit_price || 0))}
                      six={
                        activeStat?.one || activeStat?.two
                          ? formatNumber(String(total_reimbursed))
                          : null
                      }
                      seven={
                        activeStat?.one || activeStat?.two
                          ? formatNumber(String(total_expense))
                          : null
                      }
                      eight={moment(date).format("YYYY-MM-DD")}
                      progressType={re_imburse || status}
                      deleteText={
                        user?.permissions?.includes(
                          "approve/reject actual expense"
                        )
                          ? "Reject"
                          : null
                      }
                      exportText={`Export as PDF`}
                      recallText={"recall"}
                      onRecall={() => {
                        setIndexNo(false);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, recall: true };
                        });
                        actionCtx?.setIsModalOut(true);
                      }}
                      reimburseText={
                        re_imburse !== "full_payment" && `re-imburse`
                      }
                      onReimburse={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return {
                            ...prev,
                            // reimburse: true,
                            approveOverview: false,
                            partialReimburse: true,
                          };
                        });
                      }}
                      // updateText={`Recall`}
                      onApprove={() => {
                        // eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setIndexNo(false);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, approve: true };
                          });
                        }
                      }}
                      // recallText={"recall"}
                      // onRecall={() => {
                      //   handleRecallProjection(chi);
                      // }}
                      onView={() => {
                        // eslint-disable-next-line
                        {
                          setIndexNo(false);
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, comment: true };
                          });
                          handleGetAllComment(chi?.id);
                        }
                      }}
                      onAcceptStatus={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approveOverview: true };
                        });
                        setReimburseCheck(re_imburse);
                      }}
                      onDelete={() => {
                        // eslint-disable-next-line
                        {
                          setIndexNo(false);
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, reject: true };
                          });
                        }
                      }}
                      onExport={() => handleExport(chi)}
                      exportLoader={exportLoader}
                      onUpdate={() => handleUpdate(chi)}
                      updateText={
                        status === "pending" &&
                        user?.permissions?.includes(
                          "edit expense projection"
                        ) &&
                        "update"
                      }
                      // deleteText={`Reject`}
                      rejectedText={
                        user?.permissions?.includes(
                          "approve/reject actual expense"
                        )
                          ? "Reject all Approvals"
                          : null
                      }
                      onRejected={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approve_all: true };
                        });
                      }}
                      approveText={
                        status === "pending" &&
                        current_approver &&
                        user?.permissions?.includes(
                          "approve/reject actual expense"
                        )
                          ? "Approve"
                          : null
                      }
                      viewText={"View Comments"}
                      onOverview={() => {
                        // eslint-disable-next-line
                        {
                          setIndexNo(false);
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, pendingOverview: true };
                          });
                        }
                      }}
                      onRejectStatus={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, rejectOverview: true };
                        });
                      }}
                      // onDelete={() => {
                      //   // console.log(chi);
                      //   setSingleChild(chi);
                      //   setShowModal((prev) => {
                      //     return { ...prev, delete: true };
                      //   });
                      // }}
                      // updateText="update"
                      // onUpdate={() => handleUpdate(chi)}
                    />
                  );
                })}
              </>
            ) : (
              <EmployeeActualDraftExpense>
                <ButtonBlue
                  onClick={() =>
                    history.push("/employee-dashboard-actual-expense-new")
                  }
                  text={`Create Expense`}
                >
                  <FaPlus style={{ marginRight: "15px" }} />
                </ButtonBlue>
              </EmployeeActualDraftExpense>
            )}
          </ActualReuseableTable>
        </EmployeeCommonExpenseLayout>
      </div>

      {/* delete modal start */}
      <Modal visible={showModal?.delete}>
        <EmployeeActualExpenseDeleteModal
          detail={singleChild}
          onFinish={handleFinish}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setSingleChild({});
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
          }}
        />
      </Modal>
      {/* delete modal  end */}
      {/* modal end */}
      <Modal visible={showModal.approve}>
        <EmpActualExpenseApproveOverview
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                approve: false,
                pendingOverview: modalView && true,
              };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
          }}
          // onRefreshCard={handleGetActualExpenseData}
          // onDispatch={() =>
          //   dispatch(getExpenditureProjectionById(user?.organizations[0]?.id))
          // }
          onDispatch={() => {
            (activeStat?.one && handleGetActualExpenseData("")) ||
              (activeStat?.two && handleGetActualExpenseData("approved")) ||
              (activeStat?.three && handleGetActualExpenseData("pending")) ||
              (activeStat?.four && handleGetActualExpenseData("rejected"));
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal end */}
      <Modal visible={showModal.reject}>
        <EmpActualExpenseRejectModal
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                reject: false,
                pendingOverview: modalView && true,
              };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
          }}
          onDispatch={() => {
            (activeStat?.one && handleGetActualExpenseData("")) ||
              (activeStat?.two && handleGetActualExpenseData("approved")) ||
              (activeStat?.three && handleGetActualExpenseData("pending")) ||
              (activeStat?.four && handleGetActualExpenseData("rejected"));
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal end */}
      <Modal visible={showModal.comment}>
        <EmpActualExpenseComentModal
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                comment: false,
                pendingOverview:
                  singleChild?.status === "pending" && modalView && true,
                approveOverview:
                  singleChild?.status === "approved" && modalView && true,
                rejectOverview:
                  singleChild?.status === "rejected" && modalView && true,
              };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInLeft" visible={showModal.pendingOverview}>
        <EmployeePendingOverviewModal
          setShowLink={setShowLink}
          onShowImg={() => {
            setShowModal((prev) => {
              return { ...prev, img: true, pendingOverview: false };
            });
          }}
          details={singleChild}
          handleShowModal={setShowModal}
          approveBtn={
            user?.permissions?.includes("approve/reject actual expense")
              ? true
              : false
          }
          updateText={
            user?.permissions?.includes("edit actual expense") && "update"
          }
          onUpdate={() => {
            handleUpdate(singleChild);
          }}
          onRecall={() => {
            setIndexNo(false);
            setModalView(true);
            actionCtx?.setIsModalOut(true);
            setSingleChild(singleChild);
            setShowModal((prev) => {
              return { ...prev, recall: true, pendingOverview: false };
            });
          }}
          rejectText={
            user?.permissions?.includes("approve/reject actual expense")
              ? true
              : false
          }
          onReject={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, reject: true, pendingOverview: false };
            });
          }}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, pendingOverview: false };
            });
            handleGetAllComment(singleChild?.id);
          }}
          onApprove={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, approve: true, pendingOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, pendingOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal?.approveOverview}>
        <EmployeeApprovedActualExpenseOverviewModal
          details={singleChild}
          setShowLink={setShowLink}
          onShowImg={() => {
            setShowModal((prev) => {
              return { ...prev, img: true, approveOverview: false };
            });
          }}
          handleShowModal={setShowModal}
          onReimburse={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return {
                ...prev,
                partialReimburse: true,
                approveOverview: false,
              };
            });
            handleGetActualExpenseData();
          }}
          reimburseText={reimburseCheck !== "full_payment" && `re-imburse`}
          onReject={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, rejectAll: true, approveOverview: false };
            });
          }}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            });
            handleGetAllComment(singleChild?.id);
          }}
          onApprove={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, reimburse: true, approveOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.rejectOverview}>
        <EmpRejectActualExpOverviewModal
          details={singleChild}
          setShowLink={setShowLink}
          onShowImg={() => {
            setShowModal((prev) => {
              return { ...prev, img: true, rejectOverview: false };
            });
          }}
          handleShowModal={setShowModal}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            });
            handleGetAllComment(singleChild?.id);
          }}
          onApprove={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, approve: true, rejectOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* reimburse modal start */}
      {/* <Modal effect="fadeInDown" visible={showModal.rejectAll}>
        <EmployeeActualExepenseRejectAllModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, rejectAll: false };
            });
          }}
          details={singleChild}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, rejectAll: false };
            });
          }}
          // onRefreshCard={handleGetActualExpenseData}
          // onDispatch={handleGetActualExpenseData}
          onDispatch={() => {
            (activeStat?.one && handleGetActualExpenseData("")) ||
              (activeStat?.two && handleGetActualExpenseData("approved")) ||
              (activeStat?.three && handleGetActualExpenseData("pending")) ||
              (activeStat?.four && handleGetActualExpenseData("rejected"));
          }}
        />
      </Modal> */}
      {/* reimburse modal end */}
      {/* modal end */}

      {/* final reimburese modal start */}
      <Modal effect="fadeInDown" visible={showModal.finalReimburse}>
        <EmployeeActualExpenseFinalReimburseModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                finalReimburse: false,
                approveOverview: modalView && true,
              };
            });
          }}
        />
      </Modal>
      {/* final reimburese modal end */}
      {/* partial reimbursement start */}
      <Modal effect="fadeInLeft" visible={showModal?.partialReimburse}>
        <PartialReimbursement
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                partialReimburse: false,
                approveOverview: modalView && true,
              };
            });
          }}
          handleFinalModal={() => {
            actionCtx?.setIsModalOut(true);
            activeStat?.two && handleGetActualExpenseData("approved");
            activeStat?.one && handleGetActualExpenseData("");
            setShowModal((prev) => {
              return { ...prev, partialReimburse: false, finalReimburse: true };
            });
          }}
          expense={singleChild}
        />
      </Modal>
      {/* partial reimbursement end */}
      {/* approve modal start */}
      <Modal effect="fadeInRight" visible={showModal.approve_all}>
        <FinalApprovalModalExpense
          allApprovalStatus={allApprovalStatus}
          allId={allApproveId}
          onFinish={() => {
            handleGetActualExpenseData();
            actionCtx?.setIsModalOut(false);
            setShowModal({
              pendingOverview: false,
              approveOverview: false,
              rejectOverview: false,
              approve: false,
              reject: false,
              comment: false,
              approve_all: false,
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve_all: false };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* modal start */}
      <Modal effect="fadeInRight" visible={showModal.recall}>
        <ActualExpenseRecallModal
          details={singleChild}
          loading={recallLoader}
          onClose={() => {
            setShowModal((prev) => {
              return {
                ...prev,
                recall: false,
                pendingOverview: modalView && true,
              };
            });
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
          }}
          onSubmit={() => {
            handleRecallProjection("", singleChild?.id);
          }}
        />
      </Modal>
      {/* modal end */}
      <Modal visible={showModal.img} effect="fadeInLeft">
        <BigShowModalComponent
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, img: false, pendingOverview: true };
            });
          }}
          detail={showLink}
        />
      </Modal>
    </>
  );
};

export default EmployeeActualSubmit;
