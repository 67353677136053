import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
// import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { addLabelValueFunc } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getProjectionActualRevenueGpReport = createAsyncThunk(
  "employee/get-projection-actual-revenue-gp-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/revenue-projected-vs-actual-report?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&date=${
          formData?.date || ""
        }&user_id=${formData?.user_id || ""}&search=${
          formData?.search || ""
        }&status=${formData?.status || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", data?.data?.revenue_projections_vs_actuals);
        await thunkAPI.dispatch(
          setProjectionActualRevenueGpReports(
            data?.data?.revenue_projections_vs_actuals
          )
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error("Bad Network, check your network settings", {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const geProjectionActualRevenueGpPagination = createAsyncThunk(
  "employee/get-projection-actual-revenue-gp-report-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/revenue-projected-vs-actual-report?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&user_id=${
          formData?.user_id || ""
        }&search=${formData?.search || ""}&status=${
          formData?.status || ""
        }&page=${formData?.page}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", )
        await thunkAPI.dispatch(
          setProjectionActualRevenueGpReports(
            data?.data?.revenue_projections_vs_actuals
          )
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Bad Network, check your network settings", {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const projection_actual_revenue_gp_report = createSlice({
  name: "projection_actual_revenue_gp_report",
  initialState: {
    projectionActualRevenueGpReports: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
  },

  reducers: {
    setProjectionActualRevenueGpReports: (state, action) => {
      state.isAuth = true;
      state.projectionActualRevenueGpReports = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getProjectionActualRevenueGpReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getProjectionActualRevenueGpReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getProjectionActualRevenueGpReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [geProjectionActualRevenueGpPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [geProjectionActualRevenueGpPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [geProjectionActualRevenueGpPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProjectionActualRevenueGpReports } =
  projection_actual_revenue_gp_report.actions;

export default projection_actual_revenue_gp_report.reducer;
