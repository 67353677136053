import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc } from "../../../helper/Helpers";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getChatAccounts = createAsyncThunk(
  "admin/get-chat-accounts",
  async (formData, thunkAPI) => {
    try {
      let url = "";
      if (formData?.reason) {
        // console.log("uytr");
        url = `/employee/chart-of-accounts/organization/${formData?.id}`;
      } else {
        url = `/admin/chart-of-accounts/organization/${formData}`;
      }
      const { data } = await axios.get(url);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setChatAccounts(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("branchrrtyuijhgfdfghjhgfdftyuygfdfghjhgfdfghjhgfv", data.data.chart_of_accounts);
        await thunkAPI.dispatch(
          setChatAccountDrop(data.data.chart_of_accounts)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getChatOfAccountWithPagination = createAsyncThunk(
  "admin/get-chat-account-with-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/chart-of-accounts/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || 5}`
      );
      //   console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setChatAccounts(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setChatAccounts(data.data.chart_of_accounts));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getChatAccountByFilter = createAsyncThunk(
  "admin/get-chat-account-filter",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/admin/chart-of-accounts/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      // console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setChatAccounts(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const chartInfo = data.data.chart_of_accounts;
        await thunkAPI.dispatch(setChatAccountFilter(chartInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getChatAccountPagination = createAsyncThunk(
  "admin/get-chat-account-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        // await thunkAPI.dispatch(setChatAccounts(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const chartInfo = data.data.chart_of_accounts;
        await thunkAPI.dispatch(setChatAccounts(chartInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getChatAccount = createAsyncThunk(
  "admin/get-chat-account",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/classes/${formData}`);
      console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createChatOfAccount = createAsyncThunk(
  "admin/create-chat-account",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/chart-of-accounts", formData);
      //   console.log('class', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteChatOfaccount = createAsyncThunk(
  "admin/delete-chat-account",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `/admin/chart-of-accounts/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateChatOfAccount = createAsyncThunk(
  "admin/update-chat-account",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/chart-of-accounts/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const chatOfAccount = createSlice({
  name: "chat-of-account",
  initialState: {
    chatAccountDrop: [],
    chatAccounts: [],
    links: {},
    meta: {},
    chatOfAccount: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setChatAccounts: (state, action) => {
      state.isAuth = true;
      state.chatAccounts = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setChatAccountFilter: (state, action) => {
      state.isAuth = true;
      state.chatAccounts = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setChatAccountDrop: (state, action) => {  
      state.isAuth = true;
      state.chatAccountDrop = addLabelValueFunc(action.payload);
    },
    setChatAccount: (state, action) => {
      state.isAuth = true;
      state.chatOfAccount = action.payload;
    },
  },
  extraReducers: {
    [createChatOfAccount.pending]: (state) => {
      state.loading = true;
    },
    [createChatOfAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [createChatOfAccount.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateChatOfAccount.pending]: (state) => {
      state.loading = true;
    },
    [updateChatOfAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateChatOfAccount.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getChatAccountPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getChatAccountPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getChatAccountPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getChatAccountByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getChatAccountByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getChatAccountByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getChatOfAccountWithPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getChatOfAccountWithPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getChatOfAccountWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteChatOfaccount.pending]: (state) => {
      state.loading = true;
    },
    [deleteChatOfaccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteChatOfaccount.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setChatAccount,
  setChatAccounts,
  setChatAccountDrop,
  setChatAccountFilter,
} = chatOfAccount.actions;

export default chatOfAccount.reducer;
