import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
toast.configure();



export const getNotifications = createAsyncThunk(
    "get notifications",
    async (formData, thunkAPI) => {
        try {
            const token = localStorage.getItem("token");
            await setAuthToken(token);
            const { data } = await axios.get('notification/user?type=unread');
            // console.log("country", data);   
            if (!data?.success) {
                toast.error(data.message, {
                    theme: "colored",
                });
                // console.log(data);
                return thunkAPI.rejectWithValue(data);
            }
            if (data?.success) {
                // console.log(addLabelValueFunc(data.data.users));
                await thunkAPI.dispatch(setNotifications(data.data.attributes));
            }
        } catch (err) {
            if (err.message === "Network Error") {
                toast.error(err.message, {
                    theme: "colored",
                });
            }
            if (err) {
                return thunkAPI.rejectWithValue(err);
            }
        }
    }
);


export const markAsRead = createAsyncThunk(
    "admin/mark-as-read",
    async (formData, thunkAPI) => {
        try {
            const token = localStorage.getItem("token");
            await setAuthToken(token);
            const { data } = await axios.get(`/notification/read_at/${formData}`);
            // console.log("country", data.data);
            if (!data?.success) {
                toast.error(data.message, {
                    theme: "colored",
                });
                // console.log(data);
                return thunkAPI.rejectWithValue(data);
            }
            if (data?.success) {
                // console.log(data);
                // console.log(addLabelValueFunc(data.data.users));
                // await thunkAPI.dispatch(setUser(data.data.user));
                // await thunkAPI.dispatch(setNotifications());
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            if (err.message === "Network Error") {
                toast.error(err.message, {
                    theme: "colored",
                });
            }
            if (err) {
                return thunkAPI.rejectWithValue(err);
            }
        }
    }
);


export const notifications = createSlice({
    name: "notifications",
    initialState: {
        notifications: []
        // token: JSON.parse(localStorage.getItem('token')) ,
    },
    reducers: {
        setNotifications: (state, action) => {
            state.isAuth = true;
            state.notifications = action.payload;
        },
    },
});


// Action creators are generated for each case reducer function
export const {
    setNotifications,
} = notifications.actions;

export default notifications.reducer;
