import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH, FaTimes } from "react-icons/fa";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import "../../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
// import ButtonBlue from "../../../../../buttons/ButtonBlue";
// import imgOne from "../../../../../../../assets/hod-two-img.svg";
// import cardviewImg from "../../../../../../../assets/view-card-img.png";
import ApprovalContent from "../../../actual/modals/contentHead";
// import ContentBody from "../../../actual/modals/contentBody";
// import userImg from "../../../../../../../assets/hod-one-img.svg";
import EmployeeOverviewTableBodyRow from "../../../home/modals/Reimbursement/overviewTableRow";
import { useDispatch, useSelector } from "react-redux";
import { getExpenditureProjection } from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { Dna } from "react-loader-spinner";
import { formatNumber } from "../../../../../../../helper/Helpers";
import userImg from "../../../../../../../assets/Unknown_person.jpeg";
// import moment from "moment";

const EmpExpenditureOverview = ({
  onCancel,
  handleSubmit,
  onDelete,
  onUpdate,
  details,
  // details,
}) => {
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });

  const { loadingTwo } = useSelector((state) => state.expenditure_projection);

  // console.log(details, "---<"); //   console.log(details);

  const dispatch = useDispatch();
  useEffect(() => {
    //fetch single expenditure
    if (details?.id) {
      dispatch(getExpenditureProjection(details?.id));
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.id]);

  const [showActions, setShowActions] = useState(false);

  // console.log(details);

  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  const { user } = useSelector((state) => state.user);

  // console.log("details", details);

  const screenShotRef = useRef();
  const [detailToShow, setDetailToShow] = useState("overview");
  const contentHeader = ["Months", "Units", "Value"];
  // const contentHeaderTwo = ["Level", "Name", "Status"];

  // console.log(details, "details");

  return (
    <div className="overview-card-modal-wrap">
      <div className="top-box">
        <div className="title-box">
          <p
            onClick={() => {
              setDetailToShow("overview");
            }}
            className="title"
          >
            Overview
          </p>
          {/* <p
            onClick={() => {
              setDetailToShow("activity");
            }}
            className="title"
          >
            Activity Log
          </p> */}
        </div>

        {!loadingTwo ? (
          <ButtonBlue
            onClick={() => {
              handleSubmit(setShowActions(false));
            }}
            text={`Submit`}
          />
        ) : (
          <div className="dna-wrapper-box" style={{ alignSelf: "center" }}>
            <Dna
              visible={true}
              height="80"
              width="80"
              ariaLabel="dna-loading"
              // wrapperStyle={{color: "red", backgroundColor : "red"}}
              wrapperClass="dna-wrapper"
            />
          </div>
        )}
        <div
          onClick={() => setShowActions(!showActions)}
          className="ellipse-box"
        >
          <FaEllipsisH className="icon" />
          {/* action box start */}
          <div
            className={`action-box ${showActions && "action-box-active"}`}
            style={{ paddingBottom: "unset" }}
          >
            <p onClick={onUpdate}>
              <span style={{ color: "#5B6871" }}>Update</span>
            </p>

            <p onClick={onDelete}>
              <span style={{ color: "#CC0905" }}>Delete</span>
            </p>
          </div>
          {/* action box end */}
        </div>
        <div
          onClick={() => {
            onCancel(setShowActions(false));
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      <div ref={screenShotRef} className="content-box">
        {/* overview start --------------- */}
        {detailToShow === "overview" && (
          <>
            <>
              {/* title drop control box start */}
              <div
                onClick={() => handleShowBox("one")}
                className="title-drop-control-box"
              >
                <p className="title">Budget Owner Details</p>
                <div className="icon-box">
                  {!showBox.one && <FaAngleDown className="icon" />}
                  {showBox.one && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.one && (
                <div className="content-wrap">
                  <div className="three-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Chart of Account</p>
                      <p
                        className="value"
                        style={{ textTransform: "capitalize" }}
                      >{`${details?.chart_of_account?.name}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Class</p>
                      <p
                        className="value"
                        style={{ textTransform: "capitalize" }}
                      >{`${details?.chart_of_account?.class}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Department</p>
                      <p
                        className="value"
                        style={{ textTransform: "capitalize" }}
                      >{`${details?.department?.name}`}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}

                    {/* box end */}
                  </div>
                  <div className="three-box">
                    <div className="box">
                      <p className="text">Units</p>
                      <p className="value">{details?.units || 0}</p>
                    </div>

                    {/* box start */}

                    <div className="box">
                      <p className="text">Unit Price</p>
                      <p className="value">{`${formatNumber(
                        String(details?.unit_price)
                      )}`}</p>
                    </div>

                    <div className="box">
                      <p className="text">Total Value</p>
                      <p className="value">{`${formatNumber(
                        String(details?.total_value)
                      )}`}</p>
                    </div>

                    {/* box end */}
                  </div>
                  <div className="one-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Description</p>
                      <p
                        className="value"
                        style={{ textTransform: "capitalize" }}
                      >{`${details?.description}`}</p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="two-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Date Created</p>
                      <p className="value">{`${details?.created_at}
                  `}</p>
                    </div>
                    {/* box start */}
                    <div className="box">
                      <p className="text">Last Updated</p>
                      {/* <p className="value">{`${moment(details?.updated_at).format(
                    "YYYY-MM-DD h:s a"
                  )}`}</p> */}
                      <p className="value">{`${details?.updated_at}`}</p>
                    </div>
                    {/* box end */}
                  </div>
                  {details?.shared_service_details?.length > 1 && (
                    <div className="">
                      {/* box start */}
                      <div className="box">
                        <p className="text">Shared service</p>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gap: "3rem",
                            fontWeight: "500",
                            marginBottom: "1rem",
                          }}
                        >
                          <p>Department</p>
                          <p>Subsidiary</p>
                          <p>Apportions</p>
                        </div>
                        {details?.shared_service_details?.map((chi, idx) => {
                          const { department, subsidiary, apportions } = chi;
                          return (
                            <div
                              key={idx}
                              style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gap: "3rem",
                                marginBottom: "2rem",
                                borderBottom: ".1rem solid var(--border-color)",
                                //  fontWeight: "400",
                              }}
                            >
                              <p>{department?.name}</p>
                              <p>{subsidiary?.name}</p>
                              <p>{apportions + "%"}</p>
                            </div>
                          );
                        })}
                      </div>

                      {/* box end */}
                    </div>
                  )}

                  {/* created by wrap start */}
                  <div className="one-box">
                    <div className="box">
                      <p className="text">Created by</p>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="img-text-box"
                      >
                        <figure
                          style={{ marginRight: "1rem" }}
                          className="img-box"
                        >
                          <img
                            style={{
                              borderRadius: "20%",
                              width: "4rem",
                              height: "4rem",
                            }}
                            src={user?.profile_picture || userImg}
                            alt=""
                            className="img"
                          />
                        </figure>
                        <div className="text-box">
                          <p className="value">
                            {details?.createdBy?.full_name}
                          </p>
                          <p style={{ marginBottom: "unset" }} className="text">
                            {details?.createdBy?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* created by wrap end */}
                </div>
              )}
            </>

            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("two")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem" }}
              >
                <p className="title">Monthly Breakdown</p>
                <div className="icon-box">
                  {!showBox.two && <FaAngleDown className="icon" />}
                  {showBox.two && <FaAngleUp className="icon" />}
                </div>
              </div>

              {/* {showBox.two && <div className="content-wrap"></div>} */}
            </>
            {/* title drop control box end */}
            <>
              {showBox.two && (
                <div>
                  <ApprovalContent
                    headerOne={contentHeader[0]}
                    headerTwo={contentHeader[1]}
                    headerThree={contentHeader[2]}
                  />
                  {details?.monthly_breakdown?.map((breakdown, idx) => {
                    // console.log("breakdown", details?.monthly_breakdown);
                    const {
                      month_name,
                      value: total_value,
                      // value: units,
                    } = breakdown;
                    return (
                      <>
                        <EmployeeOverviewTableBodyRow
                          key={idx}
                          one={month_name}
                          two={
                            details?.entry_type === "Quantity"
                              ? formatNumber(String(total_value))
                              : formatNumber(String(details?.units / 12)) ||
                                details?.entry_type === "Amount"
                              ? formatNumber(
                                  String(total_value / details?.unit_price)
                                )
                              : formatNumber(String(details?.units / 12))
                          }
                          three={
                            details?.entry_type === "Quantity"
                              ? formatNumber(
                                  String(total_value * details?.unit_price)
                                )
                              : formatNumber(String(total_value))
                          }
                        />
                      </>
                    );
                  })}
                </div>
              )}
              {/* {showBox.two && <div className="content-wrap"></div>} */}
            </>
            {/* title drop control box end */}
            {/* title drop control box start */}

            <>
              {/* <div
            onClick={() => handleShowBox("three")}
            className="title-drop-control-box"
            style={{ marginTop: "2rem" }}
          >
            <p className="title">Approval Flow</p>
            <div className="icon-box">
              {!showBox.three && <FaAngleDown className="icon" />}
              {showBox.three && <FaAngleUp className="icon" />}
            </div>
          </div> */}
              {/* {showBox.three && (
            <div className="content-wra">
              <ApprovalContent
                headerOne={contentHeaderTwo[0]}
                headerTwo={contentHeaderTwo[1]}
                headerThree={contentHeaderTwo[2]}
              />
              {details?.approval_flow?.map((chi, idx) => {
                
                const { step, user, status } = chi;
                
                return (
                  <>
                    <EmployeeOverviewTableBodyRow
                      key={idx}
                      one={step.toString().padStart(2, "0")}
                      userOne
                     userName={user?.first_name + " " + user?.last_name}
                      userEmail={user?.email}
                      userImg={user?.profile_picture || userImg}
                      status={
                        status === "approved"
                          ? "Approved"
                          : status === "pending"
                          ? "Pending"
                          : status === "rejected"
                          ? "Rejected"
                          : "pending"
                      }
                    />
                  </>
                );
              })}
            </div>
          )} */}
            </>
            {/* title drop control box end */}
          </>
        )}
        {/* overview end ------------------ */}
        {/* activity log start -------- */}
        {/* {detailToShow === "activity" && <></>} */}
        {/* activity log end ----------- */}
      </div>

      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default EmpExpenditureOverview;
