import React, { useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
// import { useLocation } from "react-router-dom";
// import ButtonBlue from "../../../component/buttons/ButtonBlue";
import "../../styles/dashboard/table/EmployeeReusableTable.scss";
import "../../styles/dashboard/table/EmpReusableCard.scss";
import Select from "react-select";
import {
  addAllToList,
  reactSelectStyle,
  reactSelectStyleTable,
} from "../../../helper/Helpers";
import Skeleton from "react-loading-skeleton";
import ButtonBlue from "../../../component/buttons/ButtonBlue";
import { Dna } from "react-loader-spinner";
import ActiveApprovalBell from "../../../assets/approval-bell.png";
import DisableApprovalBell from "../../../assets/disable-approval-bell.png";
import AllACtionWrapper from "../../../component/fragments/AllACtionWrapper";
import Modal from "react-awesome-modal";
import { CiFilter } from "react-icons/ci";
import { useContext } from "react";
import ActionContext from "../../../context/ActionContext";
import FilterModal from "../pages/dashboard/projection/modals/filterModal";

const EmployeeReusableTable = ({
  children,
  cardBodyOne,
  cardBodyTwo,
  cardBodyThree,
  cardTitleFive,
  cardBodyFour,
  cardTitleOne,
  cardTitleTwo,
  cardTitleThree,
  cardBodyFive,
  cardTitleFour,
  addButton,
  BtnTitle,
  filter,
  sort,
  onPage,
  firstCard,
  secondCard,
  thirdCard,
  fourthCard,
  fifthCard,
  onFirstCard,
  onSecondCard,
  onThirdCard,
  onFourthCard,
  showSearch,
  linkCardOne,
  linkCardTwo,
  linkCardThree,
  linkCardFour,
  optionSort,
  optionSortVal,
  setOptionSortVal,
  optionFilter,
  optionFilterVal,
  setOptionFilterVal,
  onSearchTable,
  loading,
  titleCardTwo,
  titleCardThree,
  titleCardFour,
  showCardThree,
  showCardFour,
  showCardFive,
  linkCardFive,
  dept,
  departmentDrop,
  deptVal,
  setDepartmentVal,
  statusDrop,
  statusVal,
  statusDropdown,
  setStatusVal,
  perPage,
  perPageVal,
  optionPerPage,
  setPerPage,
  sortText,
  onFifthCard,
  sixthCard,
  linkCardSix,
  showCardSix,
  titleCardSix,
  cardTitleSix,
  cardBodySix,
  onSixthCard,
  recallAllBtn,
  recallBgColor,
  onRecallAll,
  recallLoader,
  delBtn,
  onDel,
  delBtnTitle,
  delBgColor,
  totalTitle,
  totalValue,
  totalTitleTwo,
  totalValueTwo,
  ApprovalBell,
  disableBell,
  onNotify,
  notifyLoader,
  onFileDownload,
  exportSelect,
  downloadLoader,
  allActionBtn,
  onRejectAll,
  onApproveAll,
  rejectAll,
  approveAll,
  fileDownload,
  submitLoader,
  onSubmitAll,
  submitAll,
  submitBgColor,
  delLoader,
  vertical,
  verticalVal,
  verticalDrop,
  product,
  productVal,
  productDrop,
  filterIcon,
  onVerticalChange,
  onProductChange,
  onFilterSubmit,
  filterLoading,
  onCloseFilter,
  showFilter,
  onOpenFilter,
  onDownload,
  exportDrop,
  exportVal,
  options,
  onExport,
  downloading,
  chartAcct, 
  chartAcctDrop,
  chartAcctVal,
  onChartAcctChange
}) => {
  const bellIconTwo = (
    <svg
      className="img"
      width="37"
      height="48"
      viewBox="0 0 37 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13465_384352)">
        <g clip-path="url(#clip1_13465_384352)">
          <path
            d="M18.0039 13.9951C22.0538 13.9951 25.3606 17.1899 25.4997 21.2442L25.5039 21.4951V25.5921L26.8839 28.7481C26.953 28.906 26.9886 29.0765 26.9886 29.2489C26.9886 29.9392 26.429 30.4989 25.7386 30.4989L21.0039 30.5004C21.0039 32.1572 19.6608 33.5004 18.0039 33.5004C16.4062 33.5004 15.1002 32.2515 15.009 30.6767L15.0035 30.4981L10.2788 30.4989C10.1074 30.4989 9.93792 30.4637 9.78077 30.3954C9.14757 30.1204 8.85722 29.3841 9.13226 28.7509L10.5039 25.593V21.495C10.5045 17.3402 13.856 13.9951 18.0039 13.9951ZM19.5035 30.4981L16.5039 30.5004C16.5039 31.3288 17.1755 32.0004 18.0039 32.0004C18.7836 32.0004 19.4244 31.4055 19.497 30.6448L19.5035 30.4981ZM18.0039 15.4951C14.6837 15.4951 12.0044 18.1694 12.0039 21.4951V25.9047L10.6599 28.9989H25.3564L24.0039 25.9057L24.004 21.508L24.0003 21.2828C23.8892 18.0493 21.2455 15.4951 18.0039 15.4951ZM27.0039 20.2489H29.0039C29.4181 20.2489 29.7539 20.5847 29.7539 20.9989C29.7539 21.3786 29.4718 21.6924 29.1057 21.742L29.0039 21.7489H27.0039C26.5897 21.7489 26.2539 21.4131 26.2539 20.9989C26.2539 20.6192 26.5361 20.3054 26.9021 20.2557L27.0039 20.2489ZM7.00391 20.2489H9.00391C9.41812 20.2489 9.75391 20.5847 9.75391 20.9989C9.75391 21.3786 9.47175 21.6924 9.10568 21.742L9.00391 21.7489H7.00391C6.58969 21.7489 6.25391 21.4131 6.25391 20.9989C6.25391 20.6192 6.53606 20.3054 6.90214 20.2557L7.00391 20.2489ZM28.6039 14.5489C28.8298 14.8501 28.7949 15.266 28.5377 15.5259L28.4539 15.5989L26.4539 17.0989C26.1225 17.3474 25.6524 17.2803 25.4039 16.9489C25.178 16.6476 25.2129 16.2317 25.4701 15.9719L25.5539 15.8989L27.5539 14.3989C27.8853 14.1504 28.3554 14.2175 28.6039 14.5489ZM8.45391 14.3989L10.4539 15.8989C10.7853 16.1474 10.8524 16.6175 10.6039 16.9489C10.3554 17.2803 9.88528 17.3474 9.55391 17.0989L7.55391 15.5989C7.22254 15.3504 7.15538 14.8803 7.40391 14.5489C7.65243 14.2175 8.12254 14.1504 8.45391 14.3989Z"
            fill={disableBell ? "#cccc" : "var(--blue-color)"}
          />
        </g>
      </g>
      <rect
        x="0.503906"
        y="4.49902"
        width="35"
        height="39"
        rx="5.5"
        stroke={disableBell ? "#cccc" : "var(--blue-color)"}
      />
      <defs>
        <clipPath id="clip0_13465_384352">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6.00391 11.999)"
          />
        </clipPath>
        <clipPath id="clip1_13465_384352">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(6.00391 11.999)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      {/* new card for employee */}
      {/* card wrap start */}
      <>
        {/* total info detail box start */}
        {totalValue && (
          <div className="emp-total-info-detail-box">
            {totalValue && (
              <div className="detail-box">
                <p className="title">{totalTitle}</p>
                {loading ? (
                  <Skeleton width={`50%`} height={17} />
                ) : (
                  <p className="value">{totalValue}</p>
                )}
              </div>
            )}
            {totalValueTwo && (
              <div className="detail-box">
                <p className="title">{totalTitleTwo}</p>
                {loading ? (
                  <Skeleton width={`50%`} height={17} />
                ) : (
                  <p className="value">{totalValueTwo}</p>
                )}
              </div>
            )}
          </div>
        )}
        <div className="emp-card-wrapper">
          {firstCard && (
            <div
              className={`emp-card  ${linkCardOne && "emp-card-active"}`}
              style={{ cursor: onFirstCard && "pointer" }}
              onClick={onFirstCard}
            >
              <p style={{ color: "#0000FF" }} className="card-name">
                {cardTitleOne}
              </p>
              {loading ? (
                <Skeleton width={"70%"} height={17} />
              ) : (
                <p className="card-value">{cardBodyOne}</p>
              )}
            </div>
          )}

          {secondCard && (
            <div
              className={`emp-card  ${linkCardTwo && "emp-card-active"}`}
              style={{ cursor: onSecondCard && "pointer" }}
              onClick={onSecondCard}
            >
              <p
                style={{ color: titleCardTwo ? "#0000FF" : "#07A287" }}
                className="card-name"
              >
                {cardTitleTwo || titleCardTwo}
              </p>
              {loading ? (
                <Skeleton width={"70%"} height={17} />
              ) : (
                <p className="card-value">{cardBodyTwo}</p>
              )}
            </div>
          )}
          {thirdCard && (
            <div
              className={`emp-card  ${linkCardThree && "emp-card-active"}`}
              onClick={onThirdCard}
              style={{
                cursor: onThirdCard && "pointer",
                visibility: showCardThree,
              }}
            >
              <p
                style={{ color: titleCardThree ? "#0000FF" : "#FCA549" }}
                className="card-name"
              >
                {cardTitleThree || titleCardThree}
              </p>
              {loading ? (
                <Skeleton width={"70%"} height={17} />
              ) : (
                <p className="card-value">{cardBodyThree}</p>
              )}
            </div>
          )}
          {fifthCard && (
            <div
              className={`emp-card  ${linkCardFive && "emp-card-active"}`}
              onClick={onFifthCard}
              style={{
                cursor: onFifthCard && "pointer",
                visibility: showCardFive,
              }}
            >
              <p
                style={{ color: titleCardThree ? "#0000FF" : "#FCA549" }}
                className="card-name"
              >
                {cardTitleFive || cardTitleFive}
              </p>
              {loading ? (
                <Skeleton width={"70%"} height={17} />
              ) : (
                <p className="card-value">{cardBodyFive}</p>
              )}
            </div>
          )}
          {fourthCard && (
            <div
              className={`emp-card  ${linkCardFour && "emp-card-active"}`}
              style={{
                cursor: onFourthCard && "pointer",
                visibility: showCardFour,
              }}
              onClick={onFourthCard}
            >
              <p
                style={{
                  color: titleCardFour ? "#0000FF" : "#CC0905",
                }}
                className="card-name"
              >
                {cardTitleFour || titleCardFour}
              </p>
              {loading ? (
                <Skeleton width={"70%"} height={17} />
              ) : (
                <p className="card-value">{cardBodyFour}</p>
              )}
            </div>
          )}
          {sixthCard && (
            <div
              className={`emp-card  ${linkCardSix && "emp-card-active"}`}
              style={{
                cursor: onSixthCard && "pointer",
                visibility: showCardSix,
              }}
              onClick={onSixthCard}
            >
              <p
                style={{
                  color: "#0000FF",
                }}
                className="card-name"
              >
                {cardTitleSix || titleCardSix}
              </p>
              {loading ? (
                <Skeleton width={"70%"} height={17} />
              ) : (
                <p className="card-value">{cardBodySix}</p>
              )}
            </div>
          )}
        </div>
        {/* card wrap end */}
        {/* search, sort and filter container start */}
        <div
          className="emp-reuseable-table-search"
          // style={{ marginLeft: "2rem" }}
        >
          {showSearch && (
            <div className="emp-search-box">
              <input
                type="text"
                placeholder="Search"
                id="search"
                className="input"
                onChange={(e) => onSearchTable(e.target.value)}
              />
              <label htmlFor="search" className="label">
                <FaSearch className="icon" />
              </label>
            </div>
          )}

          {/* select start */}
          {/* <div className="emp-select-container"> */}
          {perPage && (
            <div className="type-select-box">
              <Select
                // isSearchable={true}
                // isMulti
                // defaultValue={optionSortVal[0]}
                // placeholder={`Per Page`}
                className="select-filter-by"
                styles={reactSelectStyleTable}
                value={perPageVal}
                onChange={(e) => {
                  setPerPage(e);
                }}
                options={optionPerPage}
              />
            </div>
          )}
          {/* filter modal start */}

          <FilterModal
            visible={showFilter}
            downloading={downloading}
            downloadBorderColor={
              (statusVal ||
                deptVal ||
                verticalVal ||
                productVal ||
                chartAcctVal ||
                optionFilterVal) &&
              exportVal
                ? ".1rem solid var(--blue-color)"
                : ".1rem solid #cccc"
            }
            downloadColor={
              (statusVal ||
                deptVal ||
                verticalVal ||
                productVal ||
                chartAcctVal ||
                optionFilterVal) &&
              exportVal
                ? "var(--blue-color)"
                : "#cccc"
            }
            onDownload={
              (statusVal ||
                deptVal ||
                verticalVal ||
                productVal ||
                chartAcctVal ||
                optionFilterVal) &&
              exportVal &&
              onDownload
            }
            onCancel={onCloseFilter}
            onSubmit={
              (statusVal ||
                deptVal ||
                verticalVal ||
                productVal ||
                chartAcctVal ||
                // onSearchTable ||
                optionFilterVal) &&
              onFilterSubmit
            }
            subLoading={filterLoading}
            bgColor={
              statusVal ||
              deptVal ||
              verticalVal ||
              productVal ||
              chartAcctVal ||
              // onSearchTable ||
              optionFilterVal
                ? "var(--blue-color)"
                : "#cccc"
            }
          >
            {/* {showSearch && (
              <div className="emp-search-box" style={{ width: "100%" }}>
                <input
                  type="text"
                  placeholder="Search"
                  id="search"
                  className="input"
                  onChange={(e) => onSearchTable(e.target.value)}
                />
                <label htmlFor="search" className="label">
                  <FaSearch className="icon" />
                </label>
              </div>
            )} */}
            {statusDrop && (
              <div
                className="type-select-box"
                style={{
                  zIndex: "1000",
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: ".5fr 2fr",
                  alignItems: "center",
                }}
              >
                <label>Status</label>
                <Select
                  // isSearchable={true}
                  // isMulti
                  // defaultValue={optionSortVal[0]}
                  placeholder={`Select Status...`}
                  className="select-filter-by"
                  styles={reactSelectStyleTable}
                  value={statusVal}
                  onChange={(e) => {
                    setStatusVal(e);
                  }}
                  options={statusDropdown}
                />
              </div>
            )}

            {dept && (
              <div
                className="type-select-box"
                style={{
                  zIndex: "900",
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: ".5fr 2fr",
                  alignItems: "center",
                }}
              >
                <label>Department</label>
                <Select
                  // isSearchable={true}
                  // isMulti
                  // defaultValue={optionSortVal[0]}
                  placeholder={`Select Department..`}
                  className="select-filter-by"
                  styles={reactSelectStyleTable}
                  value={deptVal}
                  isLoading={loading}
                  onChange={(e) => {
                    setDepartmentVal(e);
                  }}
                  options={addAllToList(departmentDrop, "Departments")}
                />
              </div>
            )}
            {vertical && (
              <div
                className="type-select-box"
                style={{
                  zIndex: "800",
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: ".5fr 2fr",
                  alignItems: "center",
                }}
              >
                <label>Vertical</label>
                <Select
                  // isSearchable={true}
                  // isMulti
                  // defaultValue={optionSortVal[0]}
                  placeholder={`Select Vertical..`}
                  className="select-filter-by"
                  styles={reactSelectStyleTable}
                  value={verticalVal}
                  isLoading={loading}
                  onChange={(e) => {
                    onVerticalChange(e);
                  }}
                  options={addAllToList(verticalDrop, "Verticals")}
                />
              </div>
            )}
            {chartAcct && (
              <div
                className="type-select-box"
                style={{
                  zIndex: "800",
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: ".5fr 2fr",
                  alignItems: "center",
                }}
              >
                <label>Chart Of Acct.</label>
                <Select
                  // isSearchable={true}
                  // isMulti
                  // defaultValue={optionSortVal[0]}
                  placeholder={`Select Chart Of Acct...`}
                  className="select-filter-by"
                  styles={reactSelectStyleTable}
                  value={chartAcctVal}
                  isLoading={loading}
                  onChange={(e) => {
                    onChartAcctChange(e);
                  }}
                  options={addAllToList(chartAcctDrop, "Chart of Account")}
                />
              </div>
            )}
            {product && (
              <div
                className="type-select-box"
                style={{
                  zIndex: "700",
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: ".5fr 2fr",
                  alignItems: "center",
                }}
              >
                <label>Product</label>
                <Select
                  // isSearchable={true}
                  // isMulti
                  // defaultValue={optionSortVal[0]}
                  placeholder={`Select Product..`}
                  className="select-filter-by"
                  styles={reactSelectStyleTable}
                  value={productVal}
                  isLoading={loading}
                  onChange={(e) => {
                    onProductChange(e);
                  }}
                  options={addAllToList(productDrop, "Products")}
                />
              </div>
            )}

            {sort && (
              <div className="type-select-box">
                <Select
                  // isSearchable={true}
                  // isMulti
                  // defaultValue={optionSortVal[0]}
                  placeholder={`Sort By`}
                  className="select-filter-by"
                  styles={reactSelectStyleTable}
                  value={optionSortVal}
                  onChange={(e) => {
                    setOptionSortVal(e);
                  }}
                  options={optionSort}
                />
              </div>
            )}

            {filter && (
              <div
                className="type-select-box"
                style={{
                  zIndex: "600",
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: ".5fr 2fr",
                  alignItems: "center",
                }}
              >
                <label>Search by</label>
                <Select
                  // isSearchable={true}
                  // isMulti
                  // defaultValue={`filter by`}
                  placeholder={`Search By`}
                  className="select-filter-by"
                  styles={reactSelectStyleTable}
                  value={optionFilterVal}
                  onChange={(e) => {
                    setOptionFilterVal(e);
                  }}
                  options={optionFilter}
                />
              </div>
            )}
            {exportDrop && (
              <div
                className="type-select-box"
                style={{
                  zIndex: "500",
                  display: "grid",
                  gap: "1rem",
                  gridTemplateColumns: ".5fr 2fr",
                  alignItems: "center",
                }}
              >
                <label>Export</label>
                <Select
                  styles={reactSelectStyle}
                  className="select-filter-by"
                  options={options}
                  placeholder="Select File Type"
                  value={exportVal}
                  onChange={(e) => onExport(e)}
                />
              </div>
            )}
          </FilterModal>
          {/* filter modal end */}
          <div />
          <div />
          <div />
          <div />
          {filterIcon && (
            <CiFilter
              className="filter-icon"
              size={22}
              onClick={onOpenFilter}
            />
          )}
          {ApprovalBell && (
            <div>
              {notifyLoader ? (
                <div style={{ alignSelf: "center", display: "flex" }}>
                  <Dna
                    visible={true}
                    height="40"
                    width="30"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <div className="emp-notify-bell-box">
                  <figure
                    className="img-box"
                    onClick={!disableBell && onNotify}
                  >
                    {/* <img
                    className="emp-approval-notify"
                    
                    style={{
                      width: "3rem",
                      marginTop: disableBell && ".3rem",
                      cursor: !disableBell && "pointer",
                    }}
                    src={disableBell ? DisableApprovalBell : ActiveApprovalBell}
                    alt=""
                  /> */}
                    {bellIconTwo}
                  </figure>
                </div>
              )}
            </div>
          )}

          {/* {recallAllBtn && ( */}

          {allActionBtn && (
            <AllACtionWrapper>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {recallAllBtn && (
                  <>
                    {recallLoader ? (
                      <div style={{ alignSelf: "center", display: "flex" }}>
                        <Dna
                          visible={true}
                          height="40"
                          width="30"
                          ariaLabel="dna-loading"
                          // wrapperStyle={{color: "red", backgroundColor : "red"}}
                          wrapperClass="dna-wrapper"
                        />
                      </div>
                    ) : (
                      <ButtonBlue
                        style={{ width: "100%" }}
                        text={"Recall All"}
                        bgColor={recallBgColor}
                        onClick={onRecallAll}
                      />
                    )}
                  </>
                )}
                {rejectAll && (
                  <ButtonBlue
                    style={{ width: "100%" }}
                    bgColor={`#CC0905`}
                    // style={{ marginRight: "1.5rem" }}
                    // onClick={() => {
                    //   setShowModal((prev) => {
                    //     return { ...prev, approve_all: true };
                    //   });
                    //   setAllApprovalStatus("rejected");
                    //   handleApproveAll();
                    // }}
                    onClick={onRejectAll}
                    text={`Reject all`}
                  />
                )}
                {approveAll && (
                  <ButtonBlue
                    style={{ width: "100%" }}
                    // onClick={() => {
                    //   setShowModal((prev) => {
                    //     return { ...prev, approve_all: true };
                    //   });
                    //   setAllApprovalStatus("approved");
                    //   handleApproveAll();
                    // }}
                    onClick={onApproveAll}
                    text={`Approve all`}
                  />
                )}
                {fileDownload && (
                  <>
                    {downloadLoader ? (
                      <div style={{ alignSelf: "center", display: "flex" }}>
                        <Dna
                          visible={true}
                          height="40"
                          width="30"
                          ariaLabel="dna-loading"
                          // wrapperStyle={{color: "red", backgroundColor : "red"}}
                          wrapperClass="dna-wrapper"
                        />
                      </div>
                    ) : (
                      <ButtonBlue
                        onClick={onFileDownload}
                        text={`Download ${exportSelect}`}
                        bgColor={exportSelect === "File" && `#cccccc`}
                        style={{ width: "100%" }}
                      />
                    )}
                  </>
                )}

                {addButton && (
                  <div>
                    <ButtonBlue
                      onClick={onPage}
                      // className="emp-select emp-card-btn"
                      // text={BtnTitle}
                      style={{ width: "100%" }}
                    >
                      {/* <div className="icon"> */}
                      <FaPlus style={{ marginRight: ".5rem" }} />
                      {BtnTitle}
                      {/* <span>{BtnTitle}</span> */}
                      {/* </div> */}
                    </ButtonBlue>
                  </div>
                )}
                {delBtn && (
                  <div>
                    {delLoader ? (
                      <div style={{ alignSelf: "center", display: "flex" }}>
                        <Dna
                          visible={true}
                          height="40"
                          width="30"
                          ariaLabel="dna-loading"
                          // wrapperStyle={{color: "red", backgroundColor : "red"}}
                          wrapperClass="dna-wrapper"
                        />
                      </div>
                    ) : (
                      <ButtonBlue
                        onClick={onDel}
                        // className="emp-select emp-card-btn"
                        style={{
                          backgroundColor: delBgColor ? "#CC0905" : "#cccccc",
                          width: "100%",
                        }}
                      >
                        {/* <div> */}
                        {/* <FaPlus /> */}
                        {/* <p style={{ display: "flex", alignItems: "center" }}> */}
                        {delBtnTitle}
                        {/* </p> */}
                        {/* </div> */}
                      </ButtonBlue>
                    )}
                  </div>
                )}

                {submitAll && (
                  <div
                    className="submit-all-draft-actual-box"
                    style={{ padding: "1rem 3rem" }}
                  >
                    {submitLoader ? (
                      <div style={{ display: "flex", alignSelf: "center" }}>
                        <Dna
                          visible={true}
                          // height="80"
                          // width="100"
                          height="40"
                          width="80"
                          ariaLabel="dna-loading"
                          // wrapperStyle={{color: "red", backgroundColor : "red"}}
                          wrapperClass="dna-wrapper"
                        />
                      </div>
                    ) : (
                      <div className="emp-submit-all-bt">
                        <ButtonBlue
                          style={{ width: "100%" }}
                          // onClick={() => {
                          //   selectedSubmit?.length > 0 && handleSubmitDrafts();
                          // }}
                          onClick={onSubmitAll}
                          text="Submit for approval"
                          // bgColor={submitVal?.length < 1 && "#cccccc"}
                          bgColor={submitBgColor}
                          //
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </AllACtionWrapper>
          )}
          <div />
          <div />
          {/* {delBtn && (
            <div>
              {loading ? (
                <div style={{ alignSelf: "center", display: "flex" }}>
                  <Dna
                    visible={true}
                    height="40"
                    width="30"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <button
                  onClick={onDel}
                  className="emp-select emp-card-btn"
                  style={{
                    backgroundColor: delBgColor ? "#CC0905" : "#cccccc",
                  }}
                >
                  <div className="icon">
                  
                    <span>{delBtnTitle}</span>
                  </div>
                </button>
              )}
            </div>
          )} */}

          {/* )} */}
          {/* </div> */}
          {/* <button  className="btn btn-bulk-upload">
              <FaPlus className="icon" />
              {`Bulk Upload`}
            </button> */}
          {/* select end */}
        </div>

        {children}
        {/* </div> */}
        {/* search, sort and filter container end */}
        {/* modal start */}
        {/* <Modal visible={showModal}>
          <div
            style={{
              padding: "3rem",
              width: "30rem",
              height: "30rem",
              position:"relative",
              overflow:"hidden"
            }}
          >
            {dept && (
              <div className="type-select-box">
                <Select
                  // isSearchable={true}
                  // isMulti
                  // defaultValue={optionSortVal[0]}
                  placeholder={`Select Dept..`}
                  className="select-filter-by"
                  styles={reactSelectStyleTable}
                  value={deptVal}
                  onChange={(e) => {
                    setDepartmentVal(e);
                  }}
                  options={addAllToList(departmentDrop, "Departments")}
                />
              </div>
            )}
            {dept}
          </div>
        </Modal> */}
        {/* modal end */}
      </>
    </>
  );
};

export default EmployeeReusableTable;
