import React from "react";
import { useState } from "react";
import { FaAngleRight, FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import Select from "react-select";
import "../../../../../../../employee-folder/styles/dashboard/reports/consolidatedBudget/index.scss";
// import { FiArrowUp } from "react-icons/fi";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
// import EmpTableBodyRow from "../../../../../layout/dashboard/EmpTableBodyRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnyEmployeeReports,
  getEmployeeProjectionDownload,
  getUserDepartment,
} from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { useEffect } from "react";
import {
  geProjectionActualRevenueGpPagination,
  getProjectionActualRevenueGpReport,
} from "../../../../../../../redux/employee/report/projection-actual-rev-and-gp";
import {
  addAllToList,
  checkCurrencyFormat,
  formatNumber,
  perPageOptions,
  // FormatNumWithCurrency,
  reactSelectStyle,
  reactSelectStyleTable,
} from "../../../../../../../helper/Helpers";
import TableBodyRow from "../../../../../../../component/tables/TableBodyRow";
// import Flatpickr from "react-flatpickr";
// import moment from "moment";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
// import { useReactToPrint } from "react-to-print";
// import { CSVLink } from "react-csv";
import { Dna } from "react-loader-spinner";
import useDebounce from "../../../../../../../helper/UseDebounce";
import { useContext } from "react";
import ActionContext from "../../../../../../../context/ActionContext";
import { getDepartments } from "../../../../../../../redux/department/deparment";
import { toast } from "react-toastify";

const EmployeeActualRevenueVsActualGpReportIndex = () => {
  const history = useHistory();

  const [details, setDetails] = useState({
    period: "",
    status: "",
  });
  const actionCtx = useContext(ActionContext);

  const { user } = useSelector((state) => state.user);
  const { userDepartmentDrop, loading } = useSelector(
    (state) => state.expenditure_projection
  );
  const { departmentDrop } = useSelector((state) => state.department);
  const { projectionActualRevenueGpReports, loadingPage } = useSelector(
    (state) => state.projection_actual_revenue_gp_report
  );
  // const { subsidiariesDrop } = useSelector((state) => state.subsidiary);

  // const [startDate, setStartDate] = useState("");

  // const [singleDept, setSingleDept] = useState({});
  const [search, setSearch] = useState("");
  // const [singleUserDrop, setSingleUserDrop] = useState({});
  const [userDrop, setUserDrop] = useState({});
  const [loader, setLoader] = useState({
    one: false,
    two: false,
    three: false,
  });

  const debouncedSearchTerm = useDebounce(search, 1000);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    dispatch(getDepartments(user?.organizations[0]?.id));
    handleGetRevenueReport();
    handleGetUser();
    // let isMount = true;
    // return () => {
    //   isMount = false;
    // };
    //eslint-disable-next-line
  }, [
    details,
    //eslint-disable-next-line
    user?.organizations[0]?.id,
    actionCtx?.currencyValue?.id,
    actionCtx?.perPage?.value,
    debouncedSearchTerm,
  ]);

  const handleGetRevenueReport = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      user_id: details?.user_id?.value,
      status: details?.status?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    // setLoader((prev) => {
    //   return { ...prev, three: param === "" && true };
    // });
    const data = await dispatch(getProjectionActualRevenueGpReport(obj));
    if (data?.payload?.success) {
      // setDetails((prev) => {
      //   return { ...prev, department_id: "" };
      // });
      setLoader((prev) => {
        return { ...prev, three: false };
      });
    } else
      setLoader((prev) => {
        return { ...prev, three: false };
      });
  };

  // console.log(projectionActualRevenueGpReports, "----");
  // pagination
  const handlePagination = async (param) => {
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      page: linkTwo,
      user_id: details?.user_id?.value,
      search: search,
      status: details?.status?.value,
      currency: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(geProjectionActualRevenueGpPagination(obj));
  };
  const handlePaginationNum = async (num) => {
    // const splitLink = param.split("?page=");
    // const linkTwo = splitLink[1];
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      page: num,
      user_id: details?.user_id?.value,
      search: search,
      status: details?.status?.value,
      currency: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(geProjectionActualRevenueGpPagination(obj));
  };

  const handleGetUser = () => {
    const userDropVal = details?.department_id?.users?.map((chi) => {
      return {
        label: chi?.full_name,
        value: chi?.id,
      };
    });
    setUserDrop(userDropVal);
  };
  // const range = {
  //   mode: "range",
  // };

  const tableHeadList = [
    "Revenue Item",
    "Vertical",
    `Projected Revenue (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Actual Revenue (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Actual Gross Profit (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    "Status",
  ];

  const statusDrop = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const handleFileDownload = async (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      type: "projection_vs_actual_revenue_and_actual_GP",
      format: param,
      user_id: user?.id,
      currency: actionCtx?.currencyValue?.id || "",
      status: details?.status?.value,
    };
    setLoader((prev) => {
      return {
        ...prev,
        one: param === "excel" && true,
        two: param === "pdf" && true,
      };
    });
    const data = await dispatch(getAnyEmployeeReports(obj));
    if (data?.payload?.status === 200) {
      window.open(data?.payload?.data?.url, "_blank").focus();
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    } else {
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    }
  };

  return (
    <>
      {/* <EmployeeDashboardLayout
        goBack
        pageTitle={`View Projections Vs. Actual Revenue & Actual GP Report`}
        organization
      > */}
      <div className="admin-org-settings-wrap">
        {/* left box start */}
        <div
          className="title-btn-bo "
          style={{ borderBottom: "1px solid var(--border-color)" }}
        >
          <div className="emp-consolidate-wra">
            {/* <div className="consolidate-left-box"> */}
            <div className="subtitle-box ">
              <p className="text">Report</p>
              <FaAngleRight className="icon" />
              <p
                className="text"
                onClick={() =>
                  history.push("/employee-dashboard-general-report")
                }
              >
                General Reports
              </p>
              <FaAngleRight className="icon" />
              <p className="active text">
                Projections Vs. Actual Revenue & Actual GP
              </p>
            </div>
            {/* </div> */}
            {/* left box end */}

            {/* select wrap start */}

            <div
              className=" consolidate-right-box"
              style={{ marginTop: "-5rem" }}
            >
              <div className="filter-label">
                <label className="label">Filter By</label>
              </div>
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  styles={reactSelectStyle}
                  placeholder="Status"
                  className={`select-wrap ${
                    details?.status && "select-wrap-active"
                  }`}
                  value={details?.status}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, status: e };
                    });
                  }}
                  options={statusDrop}
                />
              </div>
              {/* <div className="form-group">
                <Flatpickr
                  id="date"
                  name="date"
                  // multiple
                  options={range}
                  className={`input ${startDate && "input-active"}`}
                  autoComplete="off"
                  value={startDate}
                  onClose={(date) => {
                    setStartDate(
                      moment(date[0]).format("YYYY-MM-DD") +
                        " to " +
                        moment(date[1]).format("YYYY-MM-DD")
                    );
                    // console.log(, "date-date");
                  }}
                  // onClose={handleDateChange}
                  placeholder="YYYY/MM/DD"
                />
              </div> */}
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti

                  placeholder={"Department"}
                  className={`select-wrap ${
                    details?.department_id && "select-wrap-active"
                  }`}
                  value={details?.department_id}
                  styles={reactSelectStyle}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, department_id: e };
                    });
                    // setSingleDept((prev) => {
                    //   return { ...prev, department: e };
                    // });
                  }}
                  options={addAllToList(
                    user?.user_type === "ADMIN"
                      ? departmentDrop
                      : userDepartmentDrop,
                    "Departments"
                  )}
                />
              </div>
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  styles={reactSelectStyle}
                  placeholder="User"
                  className={`select-wrap ${
                    details?.user_id && "select-wrap-active"
                  }`}
                  isLoading={loading}
                  value={details?.user_id}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, user_id: e };
                    });
                    // console.log(e, "e");
                  }}
                  options={userDrop}
                />
              </div>
            </div>
          </div>
        </div>
        {/* select wrap end */}

        {/* sub-title start */}
        <div className="title-btn-bo">
          {/* text title box start */}
          <div className=" sub-right-box">
            <div className="emp-sub-report">
              <p className="title">
                {`${
                  details?.department_id?.label || "All"
                } Actual Revenue Vs Actual Gp`}
              </p>
              <Select
                className="per-page-select"
                styles={reactSelectStyleTable}
                value={actionCtx?.perPage}
                onChange={(e) => {
                  actionCtx?.setPerpage(e);
                }}
                options={perPageOptions}
              />
            </div>
            <div className="emp-btn-wrap">
              {/* <div
                style={{ height: "5rem", marginTop: "1.3rem" }}
                className="emp-reuseable-table-search emp-adjust-repor"
              > */}
              {/* <div className="emp-search-box">
                <input
                  type="text"
                  placeholder="Search user"
                  id="search"
                  className="input"
                  onChange={(e) => setSearch(e?.target?.value)}
                />
                <label htmlFor="search" className="label">
                  <FaSearch className="icon" />
                </label>
              </div> */}
              {loader?.one ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    !details?.department_id ||
                    details?.department_id?.label === "All Departments"
                      ? toast.error("Select a Department", {
                          theme: "colored",
                        })
                      : handleFileDownload("excel");
                  }}
                  text={`Export`}
                  bgColor={
                    !details?.department_id ||
                    details?.department_id?.label === "All Departments"
                      ? "#ccc"
                      : "var(--blue-color)"
                  }
                />
              )}
              {loader?.two ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    !details?.department_id ||
                    details?.department_id?.label === "All Departments"
                      ? toast.error("Select a Department", {
                          theme: "colored",
                        })
                      : handleFileDownload("pdf");
                  }}
                  text={`Print`}
                  bgColor={
                    !details?.department_id ||
                    details?.department_id?.label === "All Departments"
                      ? "#ccc"
                      : "var(--blue-color)"
                  }
                />
              )}

              {/* {loader?.three ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleGetRevenueReport("");
                  }}
                  text={`All Department`}
                />
              )} */}
            </div>
          </div>

          {/* sub-title end */}
          {/* </div> */}
        </div>
        {/* table start */}
        <ActualReuseableTable
          useNumPagination
          currentPage={projectionActualRevenueGpReports?.current_page}
          totalTableLength={projectionActualRevenueGpReports?.total}
          onNumPage={(e) => {
            handlePaginationNum(e);
          }}
          dontShowBnSearch={true}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          six={tableHeadList[5]}
          dontShowPagination={
            projectionActualRevenueGpReports?.data?.length < 1
          }
          fromPage={projectionActualRevenueGpReports?.from}
          toPage={projectionActualRevenueGpReports?.to}
          totalPage={projectionActualRevenueGpReports?.total}
          nextPage={projectionActualRevenueGpReports?.next_page_url}
          prevPage={projectionActualRevenueGpReports?.prev_page_url}
          onNextPage={() =>
            handlePagination(projectionActualRevenueGpReports?.next_page_url)
          }
          onPrevPage={() =>
            handlePagination(projectionActualRevenueGpReports?.prev_page_url)
          }
        >
          {projectionActualRevenueGpReports?.data?.map((chi, idx) => {
            const {
              revenue_item,
              vertical,
              projected_revenue,
              actual_revenue,
              actual_gross_profit,
              status,
            } = chi;
            return (
              <TableBodyRow
                key={idx}
                one={revenue_item}
                two={vertical}
                three={formatNumber(String(projected_revenue || 0))}
                four={formatNumber(String(actual_revenue || 0))}
                five={formatNumber(String(actual_gross_profit || 0))}
                rowNo={idx}
                loading={loadingPage}
                progressType={status}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default EmployeeActualRevenueVsActualGpReportIndex;
