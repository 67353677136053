import React, { useState } from "react";
import { NumericFormat } from "react-number-format";

const EmployeeRevenueProjectionSpreadForm = ({
  onInputChange,
  price_unit,
  units,
  total_rev_pro,
  gross_profit_per,
  total_gross_pro,
  totalDisabled,
  gpDisable,
}) => {
  const [details] = useState({
    unit_price: "",
    units: "",
    total_revenue_projection: "",
    gross_profit_percentage: "",
    total_gp: "",
  });

  return (
    <div className="form-wrap" style={{ marginTop: "3rem" }}>
      <div className="form-group">
        {/* unit-price input start */}
        <label htmlFor="unit price" className="label">
          Unit Price <span>*</span>
        </label>

        <NumericFormat
      
          className={`input ${price_unit && "input-active"}`}
          decimalScale={3}
          decimalSeparator="."
          type="text"
          name="unit_price"
          allowNegative
          onChange={onInputChange}
          thousandSeparator={","}
          value={details.unit_price || price_unit}
          id="unit_price"
          placeholder="specify unit value"
        />
      </div>
      {/* unit-price input start */}
      {/* unit input start */}
      <div className="form-group">
        <label htmlFor="units" className="label">
          Units <span>*</span>
        </label>
        <NumericFormat
          className={`input ${units && "input-active"}`}
          decimalScale={3}
          decimalSeparator="."
          type="text"
          name="unit"
          allowNegative
          onChange={onInputChange}
          thousandSeparator={","}
          value={details.units || units}
          id="unit"
          placeholder="Specify quantity"
        />
      </div>
      {/* unit input end */}
      {/* total revenue projection input start */}
      <div className="form-group">
        <label
          style={{ width: "100%" }}
          htmlFor="total_revenue_projection"
          className="label"
        >
          Total Revenue Projection
        </label>
        <NumericFormat
          disabled
          className={`input`}
          decimalScale={3}
          decimalSeparator="."
          allowNegative
          thousandSeparator={","}
          type="text"
          name="total_revenue_project"
          style={{
            background: "#ECEDED",
          }}
          onChange={onInputChange}
          value={details.total_revenue_projection || total_rev_pro}
          id="total_revenue_project"
          placeholder="Shows total value"
        />
      </div>

      {/* gp container start */}
      {/* gp percentage start */}
      <div
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "3rem" }}
      >
        <div className="form-group">
          <label
            // style={{ width: "150%" }}
            htmlFor="gross_profit_percentage"
            className="label"
          >
            GP %
          </label>
          <NumericFormat
            disabled={totalDisabled}
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            type="text"
            placeholder="Input value"
            id="gross_profit_percentage"
            name="gpp"
            style={{
              background: totalDisabled && "#ECEDED",
            }}
            className={`input ${
              details?.gross_profit_percentage &&
              !totalDisabled &&
              "input-active"
            }`}
            value={details.gross_profit_percentage || gross_profit_per}
            onChange={onInputChange}
          />
        </div>
        {/* gp percentage end */}
        {/* total gp start */}
        <div className="form-group">
          <label htmlFor="total_gp" className="label">
            Estimated GP
          </label>
          <NumericFormat
            disabled
            decimalScale={3}
            decimalSeparator="."
            allowNegative
            thousandSeparator={","}
            type="text"
            placeholder="Input Value"
            id="total_gp"
            name="total_gross_profit"
            style={{
              background: "#ECEDED",
            }}
            className={`input`}
            value={details.total_gp || total_gross_pro}
            onChange={onInputChange}
          />
        </div>
      </div>
      {/* total gp end */}
      {/* gp container end */}
    </div>
  );
};
export default EmployeeRevenueProjectionSpreadForm;
