import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getSubsidiaries = createAsyncThunk(
  "admin/get-subsidiaries",
  async (formData, thunkAPI) => {
    // const actionCtx = useContext(ActionContext);
    // console.log("yes");
    try {
      let url = "";
      if (formData?.reason) {
        url = `/dropdowns/${formData?.id}/subsidiaries`;
      } else {
        url = `/dropdowns/${formData}/subsidiaries`;
      }
      //   console.log(formData);
      //   return;
      const { data } = await axios.get(url);
      // console.log("currency", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setSubsidiaries(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const subInfo = data.data.susidiaries;
        await thunkAPI.dispatch(setSubsidiariewDrop(subInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubsidiariesWithPagination = createAsyncThunk(
  "admin/get-subsidiaries",
  async (formData, thunkAPI) => {
    // const actionCtx = useContext(ActionContext);
    // console.log("yes");
    try {
      //   console.log(formData);
      //   return;
      const { data } = await axios.get(
        `admin/subsidiaries/organization/${
          formData?.org_id
        }?paginated=true&per_page=${formData?.per_page || "5"}`
      );
      //   console.log("currency", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        await thunkAPI.dispatch(setSubsidiaries(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const subInfo = data.data.subsidiaries;
        await thunkAPI.dispatch(setSubsidiaries(subInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubsidiariesByFilter = createAsyncThunk(
  "admin/get-subsidiaries",
  async (formData, thunkAPI) => {
    // const actionCtx = useContext(ActionContext);
    // console.log("yes");
    try {
      //   console.log(formData);
      //   return;
      const { data } = await axios.get(
        `admin/subsidiaries/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
      );
      //   console.log("currency", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        await thunkAPI.dispatch(setSubsidiaries(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        const subInfo = data.data.subsidiaries;
        await thunkAPI.dispatch(setSubsidiariesSort(subInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubsidiariesPagination = createAsyncThunk(
  "admin/get-subsidiaries-pagination",
  async (formData, thunkAPI) => {
    // const actionCtx = useContext(ActionContext);
    // console.log("yes");
    try {
      //   console.log(formData);
      // const splitLink = formData.split("?page=");
      //   console.log(splitLink[0]);
      //   console.log(splitLink[1]);
      //   return;
      const { data } = await axios.get(`${formData}`);
      //   console.log("currency", data);
      if (!data?.success) {
        await thunkAPI.dispatch(setSubsidiaries(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        const subInfo = data.data.subsidiaries;
        await thunkAPI.dispatch(setSubsidiaries(subInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getSubsidiary = createAsyncThunk(
  "admin/get-subsidiary",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`admin/subsidiaries/${formData}`);
      //   console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        //   console.log(data);
        // await thunkAPI.dispatch(setCountries(data.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createSubsidiary = createAsyncThunk(
  "admin/create-subsidiary",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/subsidiaries", formData);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteSubsidiary = createAsyncThunk(
  "admin/delete-subsidiary",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/subsidiaries/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateSubsidiary = createAsyncThunk(
  "admin/update-subsidiary",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/subsidiaries/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
        if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const subsidary = createSlice({
  name: "subsidiary",
  initialState: {
    subsidiariesDrop: [],
    subsidiaries: [],
    links: {},
    meta: {},
    subsidiary: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setSubsidiaries: (state, action) => {
      state.isAuth = true;
      state.subsidiaries = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setSubsidiariesSort: (state, action) => {
      state.isAuth = true;
      state.subsidiaries = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setSubsidiary: (state, action) => {
      state.isAuth = true;
      state.subsidiary = action.payload;
    },
    setSubsidiariewDrop: (state, action) => {
      state.isAuth = true;
      state.subsidiariesDrop = addLabelValueFunc(action.payload);
    },
  },
  extraReducers: {
    [createSubsidiary.pending]: (state) => {
      state.loading = true;
    },
    [createSubsidiary.fulfilled]: (state) => {
      state.loading = false;
    },
    [createSubsidiary.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateSubsidiary.pending]: (state) => {
      state.loading = true;
    },
    [updateSubsidiary.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateSubsidiary.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSubsidiaries.pending]: (state) => {
      state.loading = true;
    },
    [getSubsidiaries.fulfilled]: (state) => {
      state.loading = false;
    },
    [getSubsidiaries.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getSubsidiariesPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSubsidiariesPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSubsidiariesPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getSubsidiariesWithPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSubsidiariesWithPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSubsidiariesWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getSubsidiariesByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getSubsidiariesByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getSubsidiariesByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteSubsidiary.pending]: (state) => {
      state.loading = true;
    },
    [deleteSubsidiary.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteSubsidiary.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSubsidiary,
  setSubsidiaries,
  setSubsidiariewDrop,
  setSubsidiariesSort,
} = subsidary.actions;

export default subsidary.reducer;
