import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const createEmployeePreference = createAsyncThunk(
  "employee/create-employee-preference",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(`/employee/preferences`, formData);
      // console.log("branch", data.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(setPreference(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getEmployeePreference = createAsyncThunk(
  "employee/get-employee-peference",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const data = await axios.get(`employee/get_preference/${formData}`);
      //  console.log(data);
      if (!data?.data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.data?.success) {
        // console.log("branch", data?.data?.data?.Preference);
        await thunkAPI.dispatch(setEmpPreference(data?.data?.data));
        // toast.success(data.message, {
        //     theme: "colored",
        //   });
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const employee_preference = createSlice({
  name: "employee_preference",
  initialState: {
    userPreference: {},
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
  },
  reducers: {
    setEmpPreference: (state, action) => {
      // console.log(action);
      state.isAuth = true;
      state.userPreference = action;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [createEmployeePreference.pending]: (state) => {
      state.loading = true;
    },
    [createEmployeePreference.fulfilled]: (state) => {
      state.loading = false;
    },
    [createEmployeePreference.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEmpPreference } = employee_preference.actions;

export default employee_preference.reducer;
