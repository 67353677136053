
import { Dashboard_Actual_route_group } from "./actuals";
import { Dashboard_admin_setting_route_group } from "./admin-setting";
import { Dashboard_general_route_group } from "./general";
import { Dashboard_home_route_group } from "./home";
import { Dashboard_home_all_users_route_group } from "./home/allUsers";
import { Dashboard_projection_route_group } from "./projection";
import { Dashboard_admin_support_route_group } from "./support";

export const Dashboard_route_group = [
  ...Dashboard_home_route_group,
  ...Dashboard_home_all_users_route_group,
  ...Dashboard_admin_setting_route_group,
  ...Dashboard_projection_route_group,
  ...Dashboard_Actual_route_group,
  ...Dashboard_general_route_group,
  ...Dashboard_admin_support_route_group,
];
