import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../utils/axios";
import setAuthToken from "../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getUserPreference = createAsyncThunk(
  "admin/get-user-peference",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const data = await axios.get(`/admin/get_preference/${formData}`);
      //  console.log(data);
      if (!data?.data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setDepartments(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.data?.success) {
        //  console.log("branch", data.data.Preference[0]);
         await thunkAPI.dispatch(setPreference(data.data.data));
        // toast.success(data.message, {
        //     theme: "colored",
        //   });
        return data;
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateUserPreference = createAsyncThunk(
  "admin/update-user-peference",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(`/admin/preferences`, formData);
        // console.log("branch", data.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // await thunkAPI.dispatch(setPreference(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        toast.success(data.message, {
            theme: "colored",
          });
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const preference = createSlice({
  name: "preference",
  initialState: {
    preference: {},   
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
  },
  reducers: {
    setPreference: (state, action) => {
      // console.log(action);
      state.isAuth = true;
      state.preference = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [updateUserPreference.pending]: (state) => {
      state.loading = true;
    },
    [updateUserPreference.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateUserPreference.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPreference } = preference.actions;

export default preference.reducer;
