import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
import { addLabelValueFunc } from "../../../helper/Helpers";
// import { addLabelValueFunc } from "../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getClasses = createAsyncThunk(
  "admin/get-roles",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `dropdowns/${formData}/get-class`
      );
      // console.log("branch", data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setClasses(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // const departmentsInfo = data.data.classes;
        await thunkAPI.dispatch(setClassesDrop(data.data.projectionClasses
          ));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getClassesWithPagination = createAsyncThunk(
  "admin/get-roles-with-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/admin/classes/organization/${formData?.org_id}?paginated=true&per_page=${formData?.per_page || 5}`
      );
    //   console.log("branch", data);  
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // await thunkAPI.dispatch(setClasses(data.data[0]));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setClasses(data.data.classes));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getClassesByFilter = createAsyncThunk(
    "admin/get-roles-filter",
    async (formData, thunkAPI) => {
      try {
        // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
        const { data } = await axios.get(
          `/admin/classes/organization/${formData?.org_id}?q=${formData?.q}&filter_by=${formData?.filter_by}`
        );
        // console.log("branch", data.data.branches.data);
        if (!data?.success) {
          // toast.error(data.message, {
          //   theme: "colored",
          // });
          // await thunkAPI.dispatch(setClasses(data.data.subsidiaries));
          return thunkAPI.rejectWithValue(data);
        }
        if (data?.success) {
            // console.log(data);
          const classesInfo = data.data.classes;
          await thunkAPI.dispatch(setClassesFilter(classesInfo));
          return thunkAPI.rejectWithValue(data);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 500) {
          toast.error(err.response?.data?.message, {
            theme: "colored",
          });
        }
        if (err) {
          return thunkAPI.rejectWithValue(err);
        }
      }
    }
  );

export const getClassesPagination = createAsyncThunk(
  "admin/get-classes-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`${formData}`);
      if (!data?.success) {
        // await thunkAPI.dispatch(setClasses(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data.data.branches.data);
        const classesInfo = data.data.classes;
        await thunkAPI.dispatch(setClasses(classesInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getClass = createAsyncThunk(
  "admin/get-class",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(`/admin/classes/${formData}`);
        console.log("currency", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createClass = createAsyncThunk(
  "admin/create-class",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post("/admin/classes", formData);
    //   console.log('class', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteClass = createAsyncThunk(
  "admin/delete-class",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(`/admin/classes/${formData}`);
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateClass = createAsyncThunk(
  "admin/update-class",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.put(
        `/admin/classes/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if(err?.response?.status === 422){
        toast.error(err?.response?.data?.errors.name[0], {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const classes = createSlice({
  name: "class",
  initialState: {
    classDrop: [],
    classes: [],
    links: {},
    meta: {},
    class: {},
    isAuth: false,
    loading: false,
    loadingPage: false,
  },
  reducers: {
    setClasses: (state, action) => {
      state.isAuth = true;
      state.classes = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setClassesFilter: (state, action) => {
        state.isAuth = true;
        state.classes = action.payload;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
      },
    setClassesDrop: (state, action) => {
      state.isAuth = true;
      state.classDrop = addLabelValueFunc(action.payload);
    },
    setClass: (state, action) => {
      state.isAuth = true;
      state.class = action.payload;
    },
  },
  extraReducers: {
    [createClass.pending]: (state) => {
      state.loading = true;
    },
    [createClass.fulfilled]: (state) => {
      state.loading = false;
    },
    [createClass.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [updateClass.pending]: (state) => {
      state.loading = true;
    },
    [updateClass.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateClass.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getClassesPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getClassesPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getClassesPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getClassesByFilter.pending]: (state) => {
      state.loadingPage = true;
    },
    [getClassesByFilter.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getClassesByFilter.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getClassesWithPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getClassesWithPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getClassesWithPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteClass.pending]: (state) => {
      state.loading = true;
    },
    [deleteClass.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteClass.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClass, setClasses, setClassesDrop, setClassesFilter } = classes.actions;

export default classes.reducer;
