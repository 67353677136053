import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/axios";
import setAuthToken from "../../../utils/auth";
import { toast } from "react-toastify";
import { addLabelValueFunc } from "../../../helper/Helpers";
toast.configure();

export const getDraftRevenueProjections = createAsyncThunk(
  "employee/get-draft-revenue-projection",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/revenue-projection/draft/${formData?.org_id}?perPage=${
          formData?.per_page || ""
        }
      `
      );
      // console.log(data);

      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(
        //   "revenue_draft_projection: ",
        //   data?.data?.draft_revenueprojection
        // );
        await thunkAPI.dispatch(
          setRevenueProjections(data?.data?.draft_revenueprojection)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);
export const getProductByPortfolio = createAsyncThunk(
  "employee/get-product-by-portfolio",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `admin/product-by-portfolio/${formData}
      `
      );
      // console.log(data);

      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        await thunkAPI.dispatch(setportfolioByProductDrop(data?.data));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjectioDraftPagination = createAsyncThunk(
  "admin/get-revenue-projection-draft-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(`/employee/revenue-projection/draft/${
        formData?.org_id
      }?perPage=${formData?.per_page || ""}&page=${formData?.page || ""}
    `);
      if (!data?.success) {
        // await thunkAPI.dispatch(setSharedServices(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        console.log("revenue-info", data);
        const revenueInfo = data?.data?.draft_revenueprojection;
        await thunkAPI.dispatch(setRevenueProjections(revenueInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const submitRevenueDraftForApproval = createAsyncThunk(
  "employee/submit-revenue-draft-for-approval",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/revenue-projection/multiple_submit",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const submitSingleRevenueDraftForApproval = createAsyncThunk(
  "employee/submit-revenue-draft-for-approval",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/revenue-projection/multiple_submit",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovedRevenueProjectionSubmittedBySearch = createAsyncThunk(
  "employee/get-revenue-projection-search",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/employee/revenue-projection/submitted/${
          formData?.org_id || ""
        }?sort_by=${formData?.sort_by || ""}&search=${
          formData?.search
        }&filter[is_submitted]=${formData?.is_submitted || ""}&filter_by=${
          formData?.filter_by || ""
        }&status=${formData?.status || ""}&filter[department_id]=${
          formData?.dept_id || ""
        }`
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data);
        await thunkAPI.dispatch(
          setSubmittedRevenueProjectionSearch(
            data?.data?.submitted_revenueprojection
          )
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getApprovedRevenueProjectionById = createAsyncThunk(
  "employee/get-approved-revenue-projection-by-id",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/revenue-projection/submitted/${
          formData?.org_id || ""
        }?filter[is_submitted]=${
          formData?.filter_submitted || ""
        }&filter[status]=${formData?.status || ""}&filter[department_id]=${
          formData?.dept_id || ""
        }
      `
      );
      // console.log(data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list", data?.data?.submitted_revenueprojection)
        await thunkAPI.dispatch(
          setRevenueProjectionById(data?.data?.submitted_revenueprojection)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const commentRevenueProjection = createAsyncThunk(
  "employee/comment-revenue-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `/employee/comments/revenue/add-comment?revenue_project_id=${formData?.revenue_id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getAllRevenueProjectionComment = createAsyncThunk(
  "employee/get-all-comment-revenue-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.get(
        `/employee/comments/revenue/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        await thunkAPI.dispatch(
          setRevenueProjectionComments(data?.data?.comments)
        );
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjectionBySearch = createAsyncThunk(
  "employee/get-revenue-projection-search",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/employee/revenue-projection/draft/${formData?.org_id || ""}?search=${
          formData?.search || ""
        }&filter_by=${formData?.filter_by || ""}&sort_by=${
          formData?.sort || ""
        }&perPage=${formData?.per_page || ""}
        `
      );
      // console.log("branch", data.data.branches.data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("revenue-info", data[0]);
        const revenueInfo = data?.data?.draft_revenueprojection;
        // console.log("revenueInfo", revenueInfo);
        await thunkAPI.dispatch(setRevenueProjectionSearch(revenueInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueSubmitProjectionBySearch = createAsyncThunk(
  "employee/get-revenue-submit-projection-search",
  async (formData, thunkAPI) => {
    try {
      // admin/departments/organization/f02083c4-9954-4860-82a3-7172b3b22e6b
      const { data } = await axios.get(
        `/employee/revenue-projection/submitted/${
          formData?.org_id || ""
        }?sort_by=${formData?.sort || ""}&search=${
          formData?.search || ""
        }&filter=${formData?.filter_by || ""}&filter[status]=${
          formData?.status || ""
        }&filter[department_id]=${
          formData?.dept_id || ""
        }&currency_conversion_id=${formData?.currency_id || ""}&perPage=${
          formData?.per_page || ""
        }`
      );
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("revenue-info", data[0]);
        const revenueInfo = data?.data?.submitted_revenueprojection;
        // console.log("revenueInfo", revenueInfo);
        await thunkAPI.dispatch(
          setSubmittedRevenueProjectionSearch(revenueInfo)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err);
      if (err.response.status === 500) {
        toast.error(err.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjectionSubmittedPagination = createAsyncThunk(
  "employee/get-revenue-projection-submitted-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } =
        await axios.get(`/employee/revenue-projection/submitted/${
          formData?.org_id || ""
        }?filter[department_id]=${formData?.dept_id || ""}&filter[status]=${
          formData?.status || ""
        }&page=${formData?.page || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}
    `);
      if (!data?.success) {
        // await thunkAPI.dispatch(setSharedServices(data.data.subsidiaries));
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("owfa", data.data.submitted_revenueprojection);
        const revenueInfo = data?.data?.submitted_revenueprojection;
        await thunkAPI.dispatch(setRevenueProjectionById(revenueInfo));
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const createRevenueProjection = createAsyncThunk(
  "employee/create-revenue-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        "/employee/revenue_projection",
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.data.message) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const RevenueProjectionDownload = createAsyncThunk(
  "employee/revenue-projection-download",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/revenue-projection/worksheet
      `,
        formData
      );
      // const file = new Blob([data.data], { type: "application/xlsx" }); //Build a URL from the file
      // const fileURL = URL.createObjectURL(file); //Open the URL on new Window
      // window.open(fileURL);
      // console.log("data--->", data);
      window.open(data?.url, "_self").focus();
      // const downloadCsv = (url) => {
      //   // console.log(templateDownload?.url, "url");
      //   // dispatch(ExpenditureProjectionDownload());
      // };

      await thunkAPI.dispatch(setRevTemplateDownload(data));
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log(data, "data data")
        toast.success(data.message, {
          theme: "colored",
        });

        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjectionById = createAsyncThunk(
  "employee/get-submitted-revenue-projection-by-id",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/revenue-projection/submitted/${
          formData?.org_id || ""
        }?filter[department_id]=${formData?.dept_id || ""}&filter[status]=${
          formData?.status || ""
        }&currency_conversion_id=${formData?.currency_id || ""}&perPage=${
          formData?.per_page || ""
        }&vertical_id=${formData?.vertical_id || ""}&product_id=${
          formData?.product_id || ""
        }
      `
      );
      // console.log(data);
      if (!data?.success) {
        // console.log("submitted-list", data);
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("submitted-list", data.data.submitted_revenueprojection);
        await thunkAPI.dispatch(
          setRevenueProjectionById(data?.data?.submitted_revenueprojection)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteRevenueProjection = createAsyncThunk(
  "employee/delete-revenue-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `employee/revenue_projection/${formData}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const deleteRevenueProjectionComment = createAsyncThunk(
  "employee/delete-revenue-projection-comment",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.delete(
        `employee/comments/revenue/${formData?.id}/${formData?.comment_id}`
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        // toast.error(data.message, {
        //   theme: "colored",
        // });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getRevenueProjection = createAsyncThunk(
  "employee/get-revenue-projection",
  async (formData, thunkAPI) => {
    //   console.log("yes");
    try {
      const { data } = await axios.get(
        `/employee/revenue_projection/${formData}`
      );
      // console.log("get-revenue-projection", data);
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const RevenueBulkUpload = createAsyncThunk(
  "employee/revenue-bulk-upload",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.post(
        `employee/revenue-projection/bulk_upload`,
        formData
      );
      // const { data } = await axios.post(
      //   `employee/expenditure_projection/bulk-update?organization_id=${
      //     formData?.org_id || ""
      //   }&file=${formData?.file || ""}`,
      //   formData
      // );
      // console.log(data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 422) {
        toast.error(err.response.data.message, {
          theme: "colored",
        });
      }
      // if (err.response.data.message) {
      //   toast.error(err.response.data.message, {
      //     theme: "colored",
      //   });
      // }
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const updateRevenueProjections = createAsyncThunk(
  "employee/update-revenue-projection",
  async (formData, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      await setAuthToken(token);
      const { data } = await axios.patch(
        `/employee/revenue_projection/${formData?.id}`,
        formData
      );
      // console.log('set-up-org', data);
      if (!data?.success) {
        toast.error(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }

      if (data?.success) {
        toast.success(data.message, {
          theme: "colored",
        });
        // console.log(data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error(err?.response?.data?.message, {
          theme: "colored",
        });
      }
      toast.error(err.response.data.message, {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const revenue_projection = createSlice({
  name: "revenue_projection",
  initialState: {
    revenueProjectionDrop: [],
    revenueProjections: [],
    revenueProjectionById: [],
    revenueProjectionComments: [],
    revTemplateDownload: {},
    isAuth: false,
    links: {},
    meta: {},
    loading: false,
    loadingTwo: false,
    loadingThree: false,
    loadingPage: false,
    revenueProjection: {},
  },
  reducers: {
    setRevenueProjections: (state, action) => {
      state.isAuth = true;
      state.revenueProjections = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setRevenueProjectionComments: (state, action) => {
      state.isAuth = true;
      state.revenueProjectionComments = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setRevenueProjectionDrop: (state, action) => {
      state.isAuth = true;
      state.revenueProjectionDrop = addLabelValueFunc(action.payload);
    },
    setportfolioByProductDrop: (state, action) => {
      state.isAuth = true;
      state.productByPort = addLabelValueFunc(action.payload);
    },
    setRevTemplateDownload: (state, action) => {
      state.isAuth = true;
      state.revTemplateDownload = action.payload;
    },

    setRevenueProjection: (state, action) => {
      state.isAuth = true;
      state.revenueProjection = action.payload;
    },
    setRevenueProjectionById: (state, action) => {
      state.isAuth = true;
      state.revenueProjectionById = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setRevenueProjectionSearch: (state, action) => {
      state.isAuth = true;
      state.revenueProjections = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    setSubmittedRevenueProjectionSearch: (state, action) => {
      state.isAuth = true;
      state.revenueProjectionById = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [createRevenueProjection.pending]: (state) => {
      state.loading = true;
    },
    [createRevenueProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [createRevenueProjection.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getProductByPortfolio.pending]: (state) => {
      state.portLoading = true;
    },
    [getProductByPortfolio.fulfilled]: (state) => {
      state.portLoading = false;
    },
    [getProductByPortfolio.rejected]: (state) => {
      state.portLoading = false;
      state.isAuth = false;
      state = null;
    },
    [getDraftRevenueProjections.pending]: (state) => {
      state.loading = true;
      state.loadingPage = true;
    },
    [getDraftRevenueProjections.fulfilled]: (state) => {
      state.loading = false;
      state.loadingPage = false;
    },
    [getDraftRevenueProjections.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjection.pending]: (state) => {
      state.loading = true;
    },
    [getRevenueProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [getRevenueProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectionById.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevenueProjectionById.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevenueProjectionById.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [commentRevenueProjection.pending]: (state) => {
      state.loading = true;
    },
    [commentRevenueProjection.fulfilled]: (state) => {
      state.loading = false;
    },
    [commentRevenueProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectionSubmittedPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevenueProjectionSubmittedPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevenueProjectionSubmittedPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovedRevenueProjectionSubmittedBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getApprovedRevenueProjectionSubmittedBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getApprovedRevenueProjectionSubmittedBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getApprovedRevenueProjectionById.pending]: (state) => {
      state.loadingPage = true;
    },
    [getApprovedRevenueProjectionById.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getApprovedRevenueProjectionById.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [updateRevenueProjections.pending]: (state) => {
      state.loading = true;
    },
    [updateRevenueProjections.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateRevenueProjections.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [RevenueProjectionDownload.pending]: (state) => {
      state.loading = true;
    },
    [RevenueProjectionDownload.fulfilled]: (state) => {
      state.loading = false;
    },
    [RevenueProjectionDownload.rejected]: (state) => {
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [submitRevenueDraftForApproval.pending]: (state) => {
      state.loadingTwo = true;
      state.loading = true;
    },
    [submitRevenueDraftForApproval.fulfilled]: (state) => {
      state.loadingTwo = false;
      state.loading = false;
    },
    [submitRevenueDraftForApproval.rejected]: (state) => {
      state.loadingTwo = false;
      state.loading = false;
      state.isAuth = false;
      state = null;
    },
    [RevenueBulkUpload.pending]: (state) => {
      state.loadingTwo = true;
    },
    [RevenueBulkUpload.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [RevenueBulkUpload.rejected]: (state) => {
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
    [submitSingleRevenueDraftForApproval.pending]: (state) => {
      state.loadingPage = true;
      state.loadingTwo = true;
      state.loadingThree = true;
    },
    [submitSingleRevenueDraftForApproval.fulfilled]: (state) => {
      state.loadingPage = false;
      state.loadingTwo = false;
      state.loadingThree = false;
    },
    [submitSingleRevenueDraftForApproval.rejected]: (state) => {
      state.loadingPage = false;
      state.loadingTwo = false;
      state.loadingThree = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectioDraftPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevenueProjectioDraftPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevenueProjectioDraftPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectioDraftPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevenueProjectioDraftPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevenueProjectioDraftPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueProjectionBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevenueProjectionBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevenueProjectionBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getRevenueSubmitProjectionBySearch.pending]: (state) => {
      state.loadingPage = true;
    },
    [getRevenueSubmitProjectionBySearch.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getRevenueSubmitProjectionBySearch.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [deleteRevenueProjection.pending]: (state) => {
      state.loadingTwo = true;
    },
    [deleteRevenueProjection.fulfilled]: (state) => {
      state.loadingTwo = false;
    },
    [deleteRevenueProjection.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingTwo = false;
      state.isAuth = false;
      state = null;
    },
  },
});

export const {
  setRevenueProjection,
  setRevenueProjections,
  setRevenueProjectionById,
  setRevenueProjectionSearch,
  setRevTemplateDownload,
  setSubmittedRevenueProjectionSearch,
  setRevenueProjectionComments,
  setportfolioByProductDrop,
} = revenue_projection.actions;

export default revenue_projection.reducer;
