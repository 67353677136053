import moment from "moment";
import React, { useContext } from "react";
// import { useEffect } from "react";
// import { useContext } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../context/ActionContext";
import {
  getNotifications,
  markAsRead,
} from "../../../redux/notifications/notifications";
// import ActionContext from "../../../context/ActionContext";
// import { getUserDetails } from "../../../redux/user";
// import { markAsRead } from "../../../redux/users/users";
import "./NotificationModal.css";

const NotificationModal = ({ onCancel }) => {
  const { notifications } = useSelector((state) => state.notifications);
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  // const { user } = useSelector((state) => state.user);

  // useEffect(() => {
  //   dispatch(getNotifications());
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const marKasReadNotify = async (id) => {
    const data = await dispatch(markAsRead(id));

    if (data?.payload?.success) {
      dispatch(getNotifications());
    }
  };

  const newList = (list) => {
    const val = list.filter((chi) => !chi?.read_at);
    return val;
  };

  return (
    <div className="notification-modal-wrap">
      <div className="trans-box"></div>
      <div className="content-wrap">
        <p className="page-title">All Notifications</p>
        <span
          onClick={() => {
            actionCtx?.setShowNotification(false);
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </span>
        {/* notify wrap start */}
        <div className="notify-wrap">
          {/* message box start */}
          {/* message box end */}

          {!notifications.length && (
            <div className="no-notification">
              <p>No new notifications! check back later</p>
            </div>
          )}
          {/* {newList(notifications)} */}
          {newList(notifications)
            ?.slice(0, 10)
            ?.map((chi, idx) => {
              // console.log(chi);
              return (
                <div
                  key={idx}
                  className={`message-box ${
                    chi?.read_at && "message-box-inactive"
                  }`}
                >
                  <p className="message-title">
                    {chi?.data?.title || chi?.data?.message?.title}
                  </p>{" "}
                  <p className="message-text">
                    {chi?.data?.message?.body || chi?.data?.message || "---"}
                  </p>
                  <div className="messagebox-footer">
                    <p className="date">{moment(chi?.created_at).fromNow()}</p>
                    {!chi?.read_at && (
                      <span
                        onClick={() => {
                          marKasReadNotify(chi?.id);
                        }}
                        className={`read ${!chi?.read_at && "read-active"}`}
                        // style={{backgroundColor: chi?.read_at && "red"}}
                      >
                        Mark as read
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        {/* notify wrap end */}
      </div>
    </div>
  );
};

export default NotificationModal;
