import React from "react";
import Select from "react-select";
import {
  addAllToList,
  reactSelectStyleCommonSort,
} from "../../../../../helper/Helpers";
import "../../../../../styles/dashboard/CommonSortBox.css";
// import Flatpickr from "react-flatpickr";

const EmployeeHomeSortBox = ({
  onSearch,
  onFiscalChange,
  fiscalOption,
  fiscal_year_value,
  isLoading,
  dateVal,
  onDateChange,
  // exportvalue,
  // onExportChange,
  // exportOption,
  deptValue,
  onDeptChange,
  userValue,
  onUserChange,
  deptOption,
  userOption,
  dateRange,
  // handleDateChange,
  // range,
}) => {
  // const range = {
  //   mode: "range",
  // };

  return (
    <>
      <div
        className="common-sort-box-wrap"
        style={{ display: "flex", justifyContent: "right", gap: "3rem" }}
      >
        {/* select fiscal year wrap start */}
        <div className="select-wrap">
          <label className="label" htmlFor="">
            Fiscal Year
          </label>
          <Select
            // isSearchable={true}
            // isMulti
            placeholder="Select Year"
            className="select"
            styles={reactSelectStyleCommonSort}
            // isLoading={isLoading}
            name="fiscal_year"
            value={fiscal_year_value}
            onChange={(e) => onFiscalChange(e)}
            options={fiscalOption}
            // isLoading={loading}
          />
        </div>
        {/* select Date wrap end */}
        {/* select fiscal year wrap start */}
        <div className="select-wrap">
          <label className="label" htmlFor="">
            Date
          </label>
          <Select
            // isSearchable={true}
            // isMulti
            placeholder="Select Period"
            styles={reactSelectStyleCommonSort}
            className="select"
            value={dateVal}
            onChange={(e) => onDateChange(e)}
            options={dateRange}
          />
        </div>

        {/* select Date wrap end */}

        {/* select fiscal year wrap start */}
        {/* <div className="select-wrap">
          <label className="label" htmlFor="">
            Export
          </label>
          <Select
            // isSearchable={true}
            // isMulti
            placeholder="Select Option"
            className="select"
            value={exportvalue}
            onChange={onExportChange}
            options={exportOption}
          />
        </div> */}
        <div className="select-wrap">
          <label className="label" htmlFor="">
            Department
          </label>
          <Select
            // isSearchable={true}
            // isMulti
            placeholder="Select Department"
            className="select"
            value={deptValue}
            // isLoading={isLoading}
            onChange={(e) => onDeptChange(e)}
            options={addAllToList(deptOption, "Departments")}
            styles={reactSelectStyleCommonSort}
          />
        </div>
        <div className="select-wrap">
          <label className="label" htmlFor="">
            User
          </label>
          <Select
            // isSearchable={true}
            // isMulti
            placeholder="Select User"
            className="select"
            value={userValue}
            isLoading={isLoading}
            styles={reactSelectStyleCommonSort}
            onChange={onUserChange}
            options={userOption}
          />
        </div>
        {/* Select Option wrap start */}
        {/* <div className="select-wrap">
          <label htmlFor="date" className="label">
            Date
          </label>
          <div>
            <Select
              id="date"
              name="date"
              // multiple
              options={range}
              styles={reactSelectStyleCommonSort}
              className={`select ${dateRange && "select-active"}`}
              autoComplete="off"
              value={dateRange}
              // onClose={(date) => {
              //   setStartDate(
              //     moment(date[0]).format("YYYY-MM-DD") +
              //       " to " +
              //       moment(date[1]).format("YYYY-MM-DD")
              //   );
              //   // console.log(, "date-date");
              // }}
              onChange={(e) => handleDateChange(e)}
              placeholder="Any time"
            />
          </div>
        </div> */}
        {/* <div
          className="select-wrap select-wrap-input"
          // onClick={onSearch}
        >
          <label className="label" htmlFor="">
            Search
          </label>
          <input
            // disabled
            className="input"
            placeholder="Advanced Search"
            // type="text"
          />
        </div> */}
        {/* Select Option wrap end */}
        {/* select Date wrap end */}
      </div>
    </>
  );
};

export default EmployeeHomeSortBox;
