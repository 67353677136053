import React from "react";
import { useState } from "react";
import { FaAngleRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import Select from "react-select";
import "../../../../../../../employee-folder/styles/dashboard/reports/consolidatedBudget/index.scss";
// import { FiArrowUp } from "react-icons/fi";
import ActualReuseableTable from "../../../../../../../component/tables/ActualReuseableTable";
// import EmpTableBodyRow from "../../../../../layout/dashboard/EmpTableBodyRow";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnyEmployeeReports,
  getEmployeeProjectionDownload,
  getUserDepartment,
} from "../../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import {
  getBudgetPerformanceReport,
  getBudgetPerformanceReportPagination,
} from "../../../../../../../redux/employee/report/budget-performance";
import { useEffect } from "react";
import {
  addAllToList,
  checkCurrencyFormat,
  formatNumber,
  perPageOptions,
  // FormatNumWithCurrency,
  reactSelectStyle,
  reactSelectStyleTable,
} from "../../../../../../../helper/Helpers";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
// import moment from "moment";
// import Flatpickr from "react-flatpickr";
// import { useReactToPrint } from "react-to-print";
import { Dna } from "react-loader-spinner";
// import { CSVLink } from "react-csv";
import TableBodyRow from "../../../../../../../component/tables/TableBodyRow";
import { useContext } from "react";
import ActionContext from "../../../../../../../context/ActionContext";
import { getDepartments } from "../../../../../../../redux/department/deparment";
import { toast } from "react-toastify";

const EmployeeBudgetPerformanceReportIndex = () => {
  const history = useHistory();

  const [details, setDetails] = useState({
    period: "",
    user: "",
    status: "",
  });
  // const { subsidiariesDrop } = useSelector((state) => state.subsidiary);

  // const range = {
  //   mode: "range",
  // };

  const actionCtx = useContext(ActionContext);

  const tableHeadList = [
    "Expense Item",
    "Expense Class",
    `Amount Projected (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Actual Spent (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    "Budget Performance",
    "Status",
  ];

  // const employeeTableBodyList = [
  //   {
  //     expense_item: "Cisco purchase",
  //     expense_class: "Asset",
  //     amount_projected: "100,000.00",
  //     actual_spent: "100,000.00",
  //     budget_performance: "10%",
  //   },
  // ];

  const { user } = useSelector((state) => state.user);
  const { userDepartmentDrop, loading } = useSelector(
    (state) => state.expenditure_projection
  );
  const { budgetPerformanceReports, loadingPage } = useSelector(
    (state) => state.budget_performance_report
  );
  const { departmentDrop } = useSelector((state) => state.department);

  // const [startDate, setStartDate] = useState("");

  const dispatch = useDispatch();
  // const [singleDept, setSingleDept] = useState({});
  const [userDrop, setUserDrop] = useState({});
  // const [singleUserDrop, setSingleUserDrop] = useState({});
  const [loader, setLoader] = useState({
    one: false,
    two: false,
    three: false,
  });

  useEffect(() => {
    dispatch(getUserDepartment(user?.organizations[0]?.id));
    dispatch(getDepartments(user?.organizations[0]?.id));
    handleGetBudgetPerformance();
    handleGetUser();
    //eslint-disable-next-line
  }, [
    //eslint-disable-next-line
    user?.organizations[0]?.id,
    details,
    actionCtx?.currencyValue?.id,
    actionCtx?.perPage?.value,
  ]);

  const handleGetBudgetPerformance = async () => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      user_id: details?.user_id?.value,
      status: details?.status?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    // setLoader((prev) => {
    //   return { ...prev, three: param === "" && true };
    // });
    const data = await dispatch(getBudgetPerformanceReport(obj));
    if (data?.payload?.success) {
      // setDetails((prev) => {
      //   return { ...prev, department_id: "" };
      // });
      setLoader((prev) => {
        return { ...prev, three: false };
      });
    } else
      setLoader((prev) => {
        return { ...prev, three: false };
      });
  };

  const handleGetUser = () => {
    const userDropVal = details?.department_id?.users?.map((chi) => {
      return {
        label: chi?.full_name,
        value: chi?.id,
      };
    });
    setUserDrop(userDropVal);
  };

  // pagination
  const handlePagination = async (param) => {
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,

      page: linkTwo,
      user_id: details?.user,
      status: details?.status?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getBudgetPerformanceReportPagination(obj));
  };
  const handlePaginationNum = async (num) => {
    // const splitLink = param.split("?pag=");
    // const linkTwo = splitLink[1];
    // link: linkTwo,
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      page: num,
      user_id: details?.user,
      status: details?.status?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getBudgetPerformanceReportPagination(obj));
  };

  const statusDrop = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];
  const handleFileDownload = async (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: details?.department_id?.id,
      type: "budget_performance",
      format: param,
      user_id: user?.id,
      currency: actionCtx?.currencyValue?.id || "",
      status: details?.status?.value,
    };
    setLoader((prev) => {
      return {
        ...prev,
        one: param === "excel" && true,
        two: param === "pdf" && true,
      };
    });
    const data = await dispatch(getAnyEmployeeReports(obj));
    if (data?.payload?.status === 200) {
      window.open(data?.payload?.data?.url, "_blank").focus();
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    } else {
      setLoader((prev) => {
        return {
          ...prev,
          one: param === "excel" && false,
          two: param === "pdf" && false,
        };
      });
    }
  };
  return (
    <>
      {/* <EmployeeDashboardLayout
        goBack
        pageTitle={`View Budget Performance Report`}
        organization
      > */}
      <div className="admin-org-settings-wrap">
        {/* left box start */}
        <div
          className="title-btn-bo"
          style={{ borderBottom: "1px solid var(--border-color)" }}
        >
          <div className="emp-consolidate-wra ">
            {/* <div className="consolidate-left-box"> */}
            <div className="subtitle-box ">
              <p className="text">Report</p>
              <FaAngleRight className="icon" />
              <p
                className="text"
                onClick={() =>
                  history.push("/employee-dashboard-general-report")
                }
              >
                General Reports
              </p>
              <FaAngleRight className="icon" />
              <p className="active text">Budget Performance</p>
            </div>
            {/* </div> */}
            {/* left box end */}

            {/* select wrap start */}

            <div
              className="consolidate-right-box"
              style={{ marginTop: "-5rem" }}
            >
              <div className="filter-label">
                <label className="label">Filter By</label>
              </div>
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  styles={reactSelectStyle}
                  placeholder="Status"
                  className={`select-wrap ${
                    details?.status && "select-wrap-active"
                  }`}
                  value={details?.status}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, status: e };
                    });
                  }}
                  options={statusDrop}
                />
              </div>
              {/* <div className="form-group">
                <Flatpickr
                  id="date"
                  name="date"
                  // multiple
                  options={range}
                  className={`input ${startDate && "input-active"}`}
                  autoComplete="off"
                  value={startDate}
                  onClose={(date) => {
                    setStartDate(
                      moment(date[0]).format("YYYY-MM-DD") +
                        " to " +
                        moment(date[1]).format("YYYY-MM-DD")
                    );
                    // console.log(, "date-date");
                  }}
                  // onClose={handleDateChange}
                  placeholder="YYYY/MM/DD"
                />
              </div> */}
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti

                  placeholder={"Department"}
                  className={`select-wrap ${
                    details?.department_id?.id && "select-wrap-active"
                  }`}
                  value={details?.department_id}
                  styles={reactSelectStyle}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, department_id: e };
                    });
                  }}
                  options={addAllToList(
                    user?.user_type === "ADMIN"
                      ? departmentDrop
                      : userDepartmentDrop,
                    "Departments"
                  )}
                />
              </div>
              <div>
                <Select
                  // isSearchable={true}
                  // isMulti
                  styles={reactSelectStyle}
                  placeholder="User"
                  className={`select-wrap ${
                    details?.user_id && "select-wrap-active"
                  }`}
                  isLoading={loading}
                  value={details?.user_id}
                  onChange={(e) => {
                    setDetails((prev) => {
                      return { ...prev, user_id: e };
                    });
                    // console.log(e, "e");
                  }}
                  options={userDrop}
                />
              </div>
            </div>
          </div>
        </div>
        {/* select wrap end */}

        {/* sub-title start */}
        <div className="title-btn-bo">
          {/* text title box start */}
          <div className=" sub-right-box">
            <div className="emp-sub-report">
              <p className="title">
                {`${details?.department_id?.label || "All"} Budget Performance`}
              </p>
              <Select
                className="per-page-select"
                styles={reactSelectStyleTable}
                value={actionCtx?.perPage}
                onChange={(e) => {
                  actionCtx?.setPerpage(e);
                }}
                options={perPageOptions}
              />
            </div>
            <div className="emp-btn-wrap">
              {/* <button className="emp-export-btn">
                <FiArrowUp className="export-icon" />
                Export
              </button> */}
              {loader?.one ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    !details?.department_id ||
                    details?.department_id?.label === "All Departments"
                      ? toast.error("Select a Department", {
                          theme: "colored",
                        })
                      : handleFileDownload("excel");
                  }}
                  // onClick={() => {
                  //   details?.department_id && handleFileDownload("excel");
                  // }}
                  text={`Export`}
                  bgColor={
                    !details?.department_id ||
                    details?.department_id?.label === "All Departments"
                      ? "#ccc"
                      : "var(--blue-color)"
                  }
                />
              )}
              {loader?.two ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="50"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    !details?.department_id ||
                    details?.department_id?.label === "All Departments"
                      ? toast.error("Select a Department", {
                          theme: "colored",
                        })
                      : handleFileDownload("pdf");
                  }}
                  // onClick={() => {
                  //   details?.department_id && handleFileDownload("excel");
                  // }}
                  text={`Print`}
                  bgColor={
                    !details?.department_id ||
                    details?.department_id?.label === "All Departments"
                      ? "#ccc"
                      : "var(--blue-color)"
                  }
                />
              )}

              {/* {loader?.three ? (
                <div style={{ alignSelf: "center", marginTop: "0rem" }}>
                  <Dna
                    visible={true}
                    height="50"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <ButtonBlue
                  onClick={() => {
                    handleGetBudgetPerformance("");
                  }}
                  text={`All Department`}
                />
              )} */}
              {/* <button className="emp-print-btn">
                Print
                <FaAngleDown className="print-icon" />
              </button> */}
            </div>
            {/* sub-title end */}
          </div>
        </div>
        {/* table start */}
        <ActualReuseableTable
          useNumPagination
          currentPage={budgetPerformanceReports?.current_page}
          totalTableLength={budgetPerformanceReports?.total}
          onNumPage={(e) => {
            handlePaginationNum(e);
          }}
          dontShowBnSearch={true}
          one={tableHeadList[0]}
          two={tableHeadList[1]}
          three={tableHeadList[2]}
          four={tableHeadList[3]}
          five={tableHeadList[4]}
          six={tableHeadList[5]}
          dontShowPagination={budgetPerformanceReports?.data?.length < 1}
          fromPage={budgetPerformanceReports?.from}
          toPage={budgetPerformanceReports?.to}
          totalPage={budgetPerformanceReports?.total}
          nextPage={budgetPerformanceReports?.next_page_url}
          prevPage={budgetPerformanceReports?.prev_page_url}
          onNextPage={() =>
            handlePagination(budgetPerformanceReports?.next_page_url)
          }
          onPrevPage={() =>
            handlePagination(budgetPerformanceReports?.prev_page_url)
          }
        >
          {budgetPerformanceReports?.data?.map((chi, idx) => {
            const {
              expense_item,
              expense_class,
              amount_projected,
              actual_spent,
              budget_performance,
              status,
            } = chi;
            return (
              <TableBodyRow
                key={idx}
                one={expense_item}
                two={expense_class}
                three={formatNumber(String(amount_projected || 0))}
                four={formatNumber(String(actual_spent || 0))}
                five={formatNumber(String(budget_performance || 0))}
                loading={loadingPage}
                rowNo={idx}
                progressType={status}
              />
            );
          })}
        </ActualReuseableTable>
        {/* table end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};
export default EmployeeBudgetPerformanceReportIndex;
