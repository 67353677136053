import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
// import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { addLabelValueFunc } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getBudgetVarianceReport = createAsyncThunk(
  "employee/get-budget-variance-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/expense-budget-variance-report?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&date=${
          formData?.date || ""
        }&user=${formData?.user_id || ""}&status=${
          formData?.status || ""
        }&currency_conversion_id=${formData?.currency_id || ""}&perPage=${
          formData?.per_page || ""
        }`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", )
        await thunkAPI.dispatch(
          setBudgetVarianceReports(data?.data?.expense_budget_variance)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      toast.error("Bad Network, check your network settings", {
        theme: "colored",
      });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getBudgetVarianceReportPagination = createAsyncThunk(
  "employee/get-budget-variance-report-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/expense-budget-variance-report?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&user=${
          formData?.user_id || ""
        }&status=${formData?.status || ""}&page=${
          formData?.page
        }&currency_conversion_id=${formData?.currency_id || ""}&perPage=${
          formData?.per_page || ""
        }`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", )
        await thunkAPI.dispatch(
          setBudgetVarianceReports(data?.data?.expense_budget_variance)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Bad Network, check your network settings", {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const budget_variance_report = createSlice({
  name: "budget_variance_report",
  initialState: {
    budgetVarianceReports: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
  },

  reducers: {
    setBudgetVarianceReports: (state, action) => {
      state.isAuth = true;
      state.budgetVarianceReports = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getBudgetVarianceReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBudgetVarianceReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBudgetVarianceReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getBudgetVarianceReportPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getBudgetVarianceReportPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getBudgetVarianceReportPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBudgetVarianceReports } = budget_variance_report.actions;

export default budget_variance_report.reducer;
