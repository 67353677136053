import React, { useEffect, useState } from "react";
import { FaCheck, FaPlus } from "react-icons/fa";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeSpreadForm from "./SpreadForm";
// import "aos/dist/aos.css";
// import Aos from "aos";
import Modal from "react-awesome-modal";
import EmployeeMonthlyForm from "./MonthlyForm";
import { useHistory } from "react-router-dom";
import EmployeeBulkModal from "./modals/upload";
import Select from "react-select";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
// import { useContext } from "react";
// import ActionContext from "../../../../../../context/ActionContext";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import {
  createExpenditureProjection,
  ExpenditureProjectionDownload,
  getDeptBySubsidiary,
  getSubsidiariesByOrg,
  getUserDepartment,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import { Dna } from "react-loader-spinner";
import moment from "moment";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import { NumericFormat } from "react-number-format";
import { getSubsidiaries } from "../../../../../../redux/subsidiary/subsidiary";
import {
  getDepartment,
  getDepartments,
  getDepartmentsBySubsidiary,
} from "../../../../../../redux/department/deparment";
import { toast } from "react-toastify";
// import { handleNumberFormatInput } from "../../../../../../helper/Helpers";
// import { useThemeDetector } from "../../../../../../utils/getThemeColor";
// import { getUser } from "../../../../../../redux/users/users";

const EmployeeNewExpenditureProjection = () => {
  const [spreadForm, setSpreadForm] = useState(false);
  // const [btnChange, setBtnChange] = useState(false);
  const [monthlyForm, setMonthlyForm] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { fiscalYearDrop } = useSelector((state) => state.fiscal_year);

  const [changeLabel, setChangeLabel] = useState({
    inputLabel: "Quantity",
    totalLabel: "Total Quantity",
  });
  const { departmentDrop } = useSelector((state) => state.department);

  const [details, setDetails] = useState({
    projection_type: "",
    date: "",
    user_id: "",
    department_id: "",
    chart_of_account_id: "",
    organization_id: "",
    description: "",
    unit_price: "",
    units: "",
    subsidiary_id: "",
    fiscal_year_id: "",
    total_value: "",
    // monthly_breakdown: [{ month_name: { label: "" }, value: "" }],
    monthly_breakdown: [{ name: "", value: "" }],
    is_submitted: "",
    entry_type: "",
    total_quantity: "",
    is_shared_service: "",
    departments_aportions: [
      { department_id: "", subsidiary_id: "", apportions: "" },
    ],
    // total_amount: "",
  });
  const [startDate, setstartDate] = useState("");
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);
  const { user } = useSelector((state) => state.user);
  const [refreshTable] = useState(false);
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  // const actionCtx = useContext(ActionContext);
  const [totalQuantity, setTotalQuatity] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [spreadTotalVal, setSpreadTotalVal] = useState("");
  const { loading, userDepartmentDrop } = useSelector(
    (state) => state.expenditure_projection
  );
  const [draftLoader, setDraftLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);

  // // console.log("user-dept-drop", userDepartmentDrop);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  const entryType = [
    {
      label: "Quantity",
      value: 1,
    },
    {
      label: "Amount",
      value: 2,
    },
  ];

  const enableSubmit = () => {
    let allowSubmit = false;

    allowSubmit =
      startDate &&
      details?.fiscal_year_id &&
      details?.chart_of_account_id &&
      details?.subsidiary_id &&
      // details?.departments_aportions &&
      details?.department_id &&
      details?.description &&
      details?.unit_price;

    if (details.projection_type === "spread") {
      allowSubmit = allowSubmit && details?.units;
    } else {
      allowSubmit =
        totalValue &&
        totalQuantity &&
        details?.monthly_breakdown &&
        details?.monthly_breakdown[0] &&
        details?.entry_type &&
        allowSubmit;
    }

    return allowSubmit;
  };

  useEffect(() => {
    // dispatch(getCountries())
    const chartAccObj = {
      id: user?.organizations[0]?.id,
      reason: true,
    };
    if (user?.organizations[0]?.id) {
      dispatch(getChatAccounts(chartAccObj));
      dispatch(getFiscalYears(chartAccObj));
      dispatch(getSubsidiaries(chartAccObj));
      dispatch(getDepartments(user?.organizations[0]?.id));
      dispatch(getUserDepartment(user?.organizations[0]?.id));
    }

    // eslint-disable-next-line
  }, [refreshTable, user?.organizations[0]?.id]);

  const history = useHistory();
  // setSpreadForm(!spreadForm);

  const handleAddToMonthList = () => {
    const id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const obj = {
      id: id,
      name: { label: "", value: "" },
      value: "",
    };
    const newList = {
      ...details,
      monthly_breakdown: [...details?.monthly_breakdown, obj],
    };
    setDetails(newList);
  };

  // const enableSubmit = () => {

  // }

  const handleSelectOptionToMonthList = (e, item) => {
    // // console.log(e);
    // // console.log(item);
    const newList = details?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id ? { ...chi, name: e } : chi
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };

  const handleInputOptionToMonthList = (e, item) => {
    const newList = details?.monthly_breakdown?.map((chi) =>
      chi?.id === item?.id
        ? {
            ...chi,
            value: e.target.value,
          }
        : chi
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };

  useEffect(() => {
    if (
      details?.projection_type === "monthly" &&
      details?.entry_type?.label === "Quantity"
    ) {
      const totalQua = details?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      setTotalValue(totalQuaAddList);

      const totalQuaVal =
        totalQuaAddList *
        formatNumberWithoutComma(String(details?.unit_price || 0));
      setTotalQuatity(totalQuaVal);
      // setTotalValue(totalQuaVal);
      // const totalQuaAddListVal = totalQuaAddList;
    }
    if (details?.entry_type?.label === "Amount" && details?.unit_price) {
      const totalQua = details?.monthly_breakdown?.map((chi) =>
        parseFloat(formatNumberWithoutComma(String(chi?.value || 0)))
      );
      const totalQuaAddList = totalQua.reduce((a, b) => a + b, 0);
      setTotalValue(totalQuaAddList);
      const totalQuaAddListVal = totalQuaAddList;

      setTotalQuatity(totalQuaAddListVal);
      const totalAmtVal =
        totalQuaAddListVal /
        formatNumberWithoutComma(String(details?.unit_price));
      const round = totalAmtVal || 0;
      setTotalValue(round);
    }
  }, [details]);

  // added changes to the calculation

  // spread total value
  useEffect(() => {
    if (details?.projection_type === "spread") {
      const totalSpreadVal =
        formatNumberWithoutComma(String(details?.unit_price)) *
        formatNumberWithoutComma(String(details?.units));
      // const totalSpreadValString = totalSpreadVal;
      setSpreadTotalVal(totalSpreadVal);
    }
  }, [details]);

  const handleRemoveOptionMonthList = (item) => {
    const newList = details?.monthly_breakdown?.filter(
      (chi) => chi?.id !== item?.id
    );
    setDetails((prev) => {
      return { ...prev, monthly_breakdown: newList };
    });
  };

  const [isApportion, setIsApportion] = useState([]);

  const editApportionToSubmit = (list, dept) => {
    if (list?.length > 0) {
      const newList = list?.map((chi) => {
        return {
          ...chi,
          subsidiary_id: chi?.subsidiary?.id,
          department_id: dept,
          apportion: chi?.budget_percentage,
        };
      });
      // console.log(newList, "newList");
      setIsApportion(newList);
    }
  };

  const formatApportionToSubmit = (obj, e) => {
    const newList = isApportion?.map((chi) =>
      obj?.id === chi?.id ? { ...chi, apportion: e } : chi
    );
    setIsApportion(newList);
  };

  useEffect(() => {
    handleApportionSum();
  }, [isApportion]);

  const [apportionSum, setApportionSum] = useState("");
  const handleApportionSum = () => {
    const totalApportion = isApportion
      ?.map((chi) => chi.apportion)
      .reduce((a, b) => Number(a) + Number(b), 0);
    setApportionSum(totalApportion);
  };

  // const [apportionAlert, setApportionAlert] = useState("");

  // const handleApportionAlert = () => {
  //   const alertText = "The sharing percentage is above 100%";
  //   // setApportionAlert(alertText);
  //   toast.error(alertText, {
  //     theme: "colored",
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newList = details.monthly_breakdown.map((chi) => {
      return {
        value: formatNumberWithoutComma(String(chi.value)),
        name: chi?.name?.label,
      };
    });
    const newApportionList = isApportion?.map((chi) => {
      return {
        department_id: chi?.department_id,
        subsidiary_id: chi?.subsidiary_id,
        apportions: chi?.apportion,
      };
    });
    const obj = {
      ...details,
      chart_of_account_id: details?.chart_of_account_id?.value,
      monthly_breakdown: newList,
      date: startDate,
      organization_id: user?.organizations[0]?.id,
      user_id: user?.id,
      apportion_length: apportionSum,
      departments_aportions: newApportionList,
      is_submitted: details?.is_submitted === 1 ? 1 : 0,
      total_quantity: totalValue,
      department_id: details?.department_id?.value,
      subsidiary_id: details?.subsidiary_id?.value,
      fiscal_year_id: details?.fiscal_year_id?.value,
      total_value: formatNumberWithoutComma(String(totalQuantity)),
      entry_type: details.entry_type?.label,
      is_shared_service: isApportion?.length > 0 ? true : false,
      projection_type: details?.projection_type,
      unit_price: formatNumberWithoutComma(String(details?.unit_price)),
      units:
        details?.projection_type === "monthly"
          ? totalValue
          : formatNumberWithoutComma(String(details?.units)),
    };
    // console.log("objective", obj);
    // return
    if (details?.projection_type === "spread") {
      delete obj["monthly_breakdown"];
      obj["total_value"] = formatNumberWithoutComma(String(spreadTotalVal));
    }

    if (obj?.is_submitted === 0) {
      setDraftLoader(true);
    }
    if (obj?.is_submitted === 1) {
      setApproveLoader(true);
    }
    const alertText = "The sharing percentage is above 100%";
    if (isApportion && apportionSum > 100) {
      toast.error(alertText, {
        theme: "colored",
      });
      setDraftLoader(false);
      setApproveLoader(false);
      return;
    }
    const data = await dispatch(createExpenditureProjection(obj));
    if (data?.payload?.success && obj?.is_submitted === 0) {
      setDraftLoader(false);
      history.push("/employee-dashboard-projection-expenditure");
    } else if (data?.payload?.success && obj?.is_submitted === 1) {
      setApproveLoader(false);
      history.push("/employee-dashboard-projection-expenditure-submitted");
    } else {
      setDraftLoader(false);
      setApproveLoader(false);
    }
  };

  const handleSpreadForm = () => {
    setSpreadForm(true);
    setMonthlyForm(false);
    // setBtnChange(true);
  };

  const handleMonthlyForm = () => {
    setMonthlyForm(true);
    setSpreadForm(false);
    // setBtnChange(true);
  };

  // const { preference } = useSelector((state) => state.preference);
  // const isDarkTheme = useThemeDetector();
  // require(`flatpickr/dist/themes/${
  //   preference?.display === "Light"
  //               ? "airbnb"
  //               : preference?.display === "Dark"
  //               ? "dark"
  //               : isDarkTheme
  //               ? "dark"
  //               : "airbnb"
  // }.css`);

  const apportionData =
    details?.department_id?.branch?.subsidiary?.shared_service;

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Expenditure Projection`}> */}
      <div className="admin-org-settings-wrap subtitle-box">
        {/* title and btn container */}
        <div className="title-btn-box">
          {/* title start */}
          <div className="title-text-box">
            <p className="title">New Expenditure Projection</p>
          </div>
          {/* title end */}

          {/* bulk upload btn start */}
          <div className="btn-download-box ">
            <button className={`btn-bulk `} onClick={() => setShowModal(true)}>
              <FaPlus className="icon" /> Bulk Upload
            </button>
            {loading ? (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginTop: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="40"
                  width="40"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            ) : (
              <p
                className="text"
                onClick={() => dispatch(ExpenditureProjectionDownload())}
              >
                See and download template
              </p>
            )}
          </div>
          {/* bulk upload btn start */}
        </div>
        {/* title and btn container */}

        <div className="form-box" style={{ width: "80%" }}>
          <form onSubmit={handleSubmit} action="" className="form">
            <div
              className="wrapper"
              style={{ borderBottom: "unset", paddingBottom: "unset" }}
            >
              <p className="box-title">1.Projection Information</p>
              <div className="form-wrap">
                {/* group box start */}

                <div className="form-group">
                  <label htmlFor="date" className="label">
                    Date <span>*</span>
                  </label>
                  <Flatpickr
                    id="date"
                    name="date"
                    className={`input ${startDate && "input-active"}`}
                    autoComplete="off"
                    value={startDate}
                    onChange={(date) => {
                      setstartDate(moment(date[0]).format("YYYY-MM-DD"));
                    }}
                    placeholder="DD/MM/YYYY"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Chart of account <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.chart_of_account_id && "select-wrap-active"
                    }`}
                    placeholder="Select chart of account"
                    value={details?.chart_of_account_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, chart_of_account_id: e };
                      });
                    }}
                    // isLoading={loading}
                    styles={reactSelectStyle}
                    options={chatAccountDrop}
                  />
                </div>
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="subsidiary" className="label">
                    Subsidiary <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.subsidiary_id && "select-wrap-active"
                    }`}
                    placeholder="Select Subsidiary"
                    value={details.subsidiary_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, subsidiary_id: e };
                      });
                      const obj = {
                        reason: true,
                        id: e?.value,
                      };
                      dispatch(getDepartmentsBySubsidiary(obj));
                    }}
                    styles={reactSelectStyle}
                    options={subsidiariesDrop}
                    // isLoading={isLoading}
                  />
                </div>
                {/* group box end */}
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Department <span>*</span>
                  </label>
                  <Select
                    className={`select-wrap ${
                      details.department_id && "select-wrap-active"
                    }`}
                    placeholder="Select Department"
                    value={details?.department_id}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, department_id: e };
                      });
                      editApportionToSubmit(
                        e?.branch?.subsidiary?.shared_service[0]?.apportions,
                        e?.id
                      );
                    }}
                    // isLoading={loading}
                    styles={reactSelectStyle}
                    options={
                      user?.user_type === "ADMIN" && details?.subsidiary_id
                        ? departmentDrop
                        : userDepartmentDrop
                    }
                    isLoading={loading}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Chart of account" className="label">
                    Fiscal Year <span>*</span>
                  </label>
                  <Select
                    placeholder="Select fiscal year"
                    className={`select-wrap ${
                      details.fiscal_year_id && "select-wrap-active"
                    }`}
                    styles={reactSelectStyle}
                    value={details?.fiscal_year_id}
                    // isLoading={loading}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, fiscal_year_id: e };
                      });
                    }}
                    options={fiscalYearDrop}
                  />
                </div>
                {/* group box end */}

                {/* group box start */}
                <br />
                <div className="form-group">
                  <label htmlFor="description" className="label">
                    Description <span>*</span>
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    onChange={handleChange}
                    style={{
                      width: "207%",
                      padding: "3rem",
                      // height:"25vh",
                      resize: "none",
                    }}
                    cols="20"
                    rows="8"
                    placeholder="Enter your description here"
                    className={`input ${
                      details?.description && "input-active"
                    }`}
                    value={details?.description}
                  />
                </div>
                {/* group box end */}
              </div>
              <div className="">
                {/* second wrap start */}
                {/* <div className="wrapper"></div> */}
                <div>
                  <div className="wrapper"></div>
                  <p className="box-title">2. Projection Format</p>
                </div>
                {/* form check bos start */}
                <div className="form-check-box" style={{ width: "60rem" }}>
                  {/* check group start */}

                  <div className="check-group" onChange={handleSpreadForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-spread-evenly"
                      value="spread"
                      checked={details?.projection_type === "spread"}
                      onChange={(e) => {
                        setDetails((prev) => {
                          return {
                            ...prev,
                            projection_type: e.target.value,
                            unit_price: "",
                            units: "",
                          };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-spread-evenly"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Spread Evenly</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (share units and value accross all items)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                  {/* check group start */}
                  {/* <input type="radio"/> */}

                  <div className="check-group" onChange={handleMonthlyForm}>
                    <input
                      type="radio"
                      name="radio-btn"
                      className="check-input"
                      id="check-monthly-entry-one"
                      value="monthly"
                      checked={details?.projection_type === "monthly"}
                      onChange={(e) => {
                        setDetails((prev) => {
                          setTotalQuatity("");
                          setTotalValue("");
                          return {
                            ...prev,
                            projection_type: e.target.value,
                            unit_price: "",
                            units: "",
                            entry_type: "",
                            monthly_breakdown: [
                              { name: { label: "" }, value: "" },
                            ],
                          };
                        });
                      }}
                    />
                    <label
                      htmlFor="check-monthly-entry-one"
                      className="check-label"
                    >
                      <div className="check-circle">
                        <FaCheck className="icon" />
                      </div>
                      <p className="text-one">Monthly Entry</p>
                      <span>{""}</span>
                      <p className="text-two">
                        (specify type and unit value for each item)
                      </p>
                    </label>
                  </div>
                  {/* check group end */}
                </div>
                {/* spread-evenly form start */}
                {spreadForm && (
                  <EmployeeSpreadForm
                    onInputChange={handleChange}
                    unit_price={details?.unit_price}
                    units={details?.units}
                    total_value={spreadTotalVal || ""}
                    // total_value={formatNumber(String(spreadTotalVal || 0))}
                    // total_value={totalValue}
                  />
                )}
                {/* spread-evenly form end */}
                {monthlyForm && (
                  <EmployeeMonthlyForm
                    onInputChange={handleChange}
                    entry_type_value={details?.entry_type}
                    // unit_price={details?.unit_price}
                    unit_price={
                      details?.projection_type === "monthly" &&
                      details?.unit_price
                    }
                    units={details?.units}
                    list={details?.monthly_breakdown}
                    handleEntryTypeChange={(e) => {
                      // // console.log("check", e);
                      // // console.log(e.value);
                      e.value === 2
                        ? setChangeLabel((prev) => {
                            return {
                              ...prev,
                              inputLabel: "Amount",
                              totalLabel: "Total Quantity",
                            };
                          })
                        : setChangeLabel(
                            !changeLabel.inputLabel,
                            !changeLabel.totalLabel
                          );
                      setDetails({ ...details, entry_type: e });
                    }}
                    // entryType={details?.entry_type}
                    Quantity={changeLabel.inputLabel}
                    // total_quantity={details.units}
                    total_quantity={totalValue || ""}
                    total_value={totalQuantity || ""}
                    handleAddMonthList={handleAddToMonthList}
                    onSelectvalueChange={handleSelectOptionToMonthList}
                    onInputValueChange={handleInputOptionToMonthList}
                    handleRemoveMonthList={handleRemoveOptionMonthList}
                    Total_quantity_label={changeLabel?.totalLabel}
                    entryType={entryType}
                    total_value_label={
                      details?.entry_type?.label === "Amount" && "Total Amount"
                    }
                  />
                )}

                {/* form check bos end */}
              </div>
              <div>
                {apportionData?.length > 0 && (
                  <>
                    <div className="wrapper" />
                    <p className="box-title">3. Set Sharing Percentage</p>
                    <p>
                      Set cost sharing percentage or retain default shared
                      service settings.
                    </p>

                    <p style={{ marginTop: ".5rem" }}>
                      Note: Percentage for all subsidiaries add up to 100%
                    </p>
                  </>
                )}

                {apportionData?.length > 0 &&
                  isApportion?.map((chi, idx) => {
                    const { apportion, subsidiary } = chi;

                    return (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: ".4fr 1fr 1fr",
                          marginTop: "3rem",
                          gridTemplateRows: "none",
                          alignItems: "center",
                          width: "100%",
                        }}
                        className="form-wrap"
                        key={idx}
                      >
                        <label htmlFor="subsidiary">{subsidiary?.name}</label>

                        <div className="form-group">
                          <input
                            key={idx}
                            className="input"
                            placeholder="Select Department"
                            value={details?.department_id?.label}
                            style={{ background: "#ECEDED" }}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <input
                            key={idx}
                            className={`input ${apportion && "input-active"}`}
                            value={apportion}
                            type="text"
                            style={{ width: "10rem" }}
                            name="apportions"
                            id="apportions"
                            // allowNegative
                            // thousandSeparator={","}
                            // id="unit_price"
                            // onChange={handleChange}
                            onChange={(e) => {
                              if (Number(e.target.value) <= 100) {
                                formatApportionToSubmit(chi, e.target.value);
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* second wrap end*/}
            <div className="wrapper"></div>
            {/* btn box start */}

            {/* button start */}
            <div style={{ display: "flex", gap: "3rem" }}>
              {!approveLoader ? (
                <input
                  onClick={() => {
                    setDetails({ ...details, is_submitted: 1 });
                  }}
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Submit For Approval"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  style={{ height: "5rem" }}
                />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="50"
                    width="100"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
              {draftLoader ? (
                <div>
                  <Dna
                    visible={true}
                    height="50"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              ) : (
                <input
                  onClick={() => {
                    setDetails({ ...details, is_submitted: 0 });
                  }}
                  disabled={!enableSubmit()}
                  type="submit"
                  value="Save To Draft"
                  className={`form-group form-submit ${
                    enableSubmit() && "form-submit-active"
                  }`}
                  // style={{ height: "5rem" }}
                  style={{
                    height: "5rem",
                    background: "#ffff",
                    color: !enableSubmit() ? "#cccc" : "var(--blue-color)",
                    border: !enableSubmit()
                      ? ".1rem solid #cccc"
                      : ".1rem solid var(--blue-color)",
                  }}
                />
              )}
              {/* button end */}
              {/* button start */}
            </div>
            {/* btn box end */}
          </form>
        </div>
      </div>
      {/* </EmployeeDashboardLayout> */}
      {/* modal start */}
      <Modal effect="fadeInUp" visible={showModal}>
        <EmployeeBulkModal onCancel={() => setShowModal(false)} />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeNewExpenditureProjection;
