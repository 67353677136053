import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH } from "react-icons/fa";
// import { useSelector } from "react-redux";
import EmployeeOverviewTableBodyRow from "../../../../employee-folder/component/pages/dashboard/home/modals/Reimbursement/overviewTableRow";
import {
  formatNumber,
  formatNumberWithComma,
} from "../../../../helper/Helpers";
import ButtonBlue from "../../../buttons/ButtonBlue";
// import DashboardLayout from "../../../layout/dashboard/DashboardLayout";
// import cardviewImg from "../../../../../../assets/view-card-img.png";
import ProfileImg from "../../../../assets/Unknown_person.jpeg";
// import "../../../../styles/dashboard/"
import Modal from "react-awesome-modal";
import FinalApprovalModal from "../projection/expense/modals/FinalApprovalModal";
import ApprovalModal from "../projection/expense/modals/ApprovalModal";
import ActionContext from "../../../../context/ActionContext";
import RejectModal from "../projection/expense/modals/RejectModal";
import ComentModal from "../projection/expense/modals/CommentModal";
import BigImgModal from "../actuals/expense/modals/BigImgModal";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSingleViewProjectionType } from "../../../../redux/projections/ExpenditureProjection";

const SingleApprovalPage = () => {
  const actionCtx = useContext(ActionContext);
  const [refreshTable, setRefreshTable] = useState(false);
  const [detail] = useState({});
  const [showActions, setShowActions] = useState(false);
  const [singleChild] = useState({});

  const [showModal, setShowModal] = useState({
    overview: false,
    approve: false,
    approve_all: false,
    reject: false,
    comment: false,
    big_img: false,
  });
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });
  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  const handleFisnish = () => {
    setShowModal({
      overview: false,
      approve: false,
      approve_all: false,
      reject: false,
      comment: false,
    });
    setRefreshTable(!refreshTable);
  };

  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    type: "",
    id: "",
  });

  useEffect(() => {
    getUrlInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUrlInfo = async () => {
    const urlInfo = window?.location.href?.split("?")[1];
    // console.log(urlInfo);
    const type = urlInfo?.split("=")[2];
    const id = urlInfo?.split("=")[1]?.split("&")[0];
    setDetails((prev) => {
      return { ...prev, type: type, id: id };
    });
    const obj = {
      id: id,
      type: type,
    };
    const data = await dispatch(getSingleViewProjectionType(obj));
    console.log(data);
  };

  return (
    <>
      {/* <DashboardLayout pageTitle={`Approve`}> */}
      <div className="overview-card-modal-wrap overview-card-modal-wrap-single ">
        <div className="top-box">
          <p className="title">Overview</p>
          <ButtonBlue
            onClick={() =>
              setShowModal((prev) => {
                return { ...prev, approve: true };
              })
            }
            text={`Approve`}
          />
          <div
            onClick={() => setShowActions(!showActions)}
            className="ellipse-box"
          >
            <FaEllipsisH className="icon" />
            {/* action box start */}
            <div className={`action-box ${showActions && "action-box-active"}`}>
              <p
                onClick={() =>
                  setShowModal((prev) => {
                    return { ...prev, reject: true };
                  })
                }
              >
                <span>Reject</span>
              </p>
              <p
                onClick={() =>
                  setShowModal((prev) => {
                    return { ...prev, comment: true };
                  })
                }
              >
                <span>View Comments</span>
              </p>
              <p>
                <span>Export as PDF</span>
              </p>
            </div>
            {/* action box end */}
          </div>
          {/* <div onClick={onCancel} className="cancel-box">
          <FaTimes className="icon" />
        </div> */}
        </div>
        {/* end of top box */}
        {/* content box start */}
        <div className="content-box">
          {/* title drop control box start */}
          <>
            <div
              onClick={() => handleShowBox("one")}
              className="title-drop-control-box"
            >
              <p className="title">Budget Owner Details</p>
              <div className="icon-box">
                {!showBox.one && <FaAngleDown className="icon" />}
                {showBox.one && <FaAngleUp className="icon" />}
              </div>
            </div>
            {showBox.one && (
              <div className="content-wrap">
                <div className="three-box">
                  {/* box start */}
                  <div className="box">
                    <p className="text">Chart of Account</p>
                    <p className="value">{detail?.chart_of_account?.name}</p>
                  </div>
                  {/* box end */}
                  {/* box start */}
                  <div className="box">
                    <p className="text">Class</p>
                    <p className="value">{detail?.chart_of_account?.class}</p>
                  </div>
                  {/* box end */}
                  {/* box start */}
                  {/* <div className="box">
                  <p className="text">Units</p>
                  <p className="value">1.00</p>
                </div> */}
                  {/* box end */}
                </div>
                <div className="two-box">
                  <div className="box">
                    <p className="text">Units</p>
                    <p className="value">
                      {formatNumberWithComma(String(detail?.units))}
                    </p>
                  </div>
                  {/* box start */}
                  <div className="box">
                    <p className="text">Unit Price</p>
                    <p className="value">
                      {formatNumberWithComma(String(detail?.unit_price))}
                    </p>
                  </div>
                  {/* box end */}
                  {/* box start */}
                  {/* <div className="box">
                  <p className="text">Total Value</p>
                  <p className="value">20,000.00</p>
                </div> */}
                  {/* box end */}
                </div>
                <div className="one-box">
                  {/* box start */}
                  <div className="box">
                    <p className="text">Description</p>
                    <p className="value">{detail?.description}</p>
                  </div>
                  {/* box end */}
                </div>
                <div className="two-box">
                  {/* box start */}
                  <div className="box">
                    <p className="text">Expense Date</p>
                    <p className="value">
                      {moment(detail?.expense_date).format("YYYY-MM-DD")}
                    </p>
                  </div>
                  {/* box end */}
                  {/* box start */}
                  {/* <div className="box">
                  <p className="text">Last Updated</p>
                  <p className="value">26 May, 2022 - 7:08pm</p>
                </div> */}
                  {/* box end */}
                </div>

                {/* created by wrap start */}
                <div className="one-box">
                  <div className="box">
                    <p className="text">Created by</p>
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="img-text-box"
                    >
                      {/* {detail?.createdBy?.profile_picture && ( */}
                      <figure
                        style={{
                          marginRight: "1rem",
                          width: "2.5rem",
                          height: "2.5rem",
                        }}
                        className="img-box"
                        // style={{width: "2.5rem", height: "2.5rem"}}
                      >
                        <img
                          src={detail?.createdBy?.profile_picture || ProfileImg}
                          alt=""
                          className="img"
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      </figure>
                      {/* )} */}
                      <div className="text-box">
                        <p className="value">{detail?.createdBy?.full_name}</p>
                        <p style={{ marginBottom: "unset" }} className="text">
                          {detail?.createdBy?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* created by wrap end */}
                {/* image wrap box start */}
                <div className="image-wrap-box">
                  {detail?.receipts?.map((chi, idx) => {
                    const { original_url, mime_type } = chi;
                    // console.log(original_url);
                    if (original_url && !mime_type?.includes("image/")) {
                      return (
                        <div className="box box-file" key={idx}>
                          {/* <p className="type">File type: <span>{formatApplicationType(chi?.type)}</span></p> */}
                          <p className="name">
                            File name: <span>{chi?.name}</span>
                          </p>
                        </div>
                      );
                    }
                    return (
                      <div
                        onClick={() =>
                          setShowModal((prev) => {
                            return { ...prev, big_img: true };
                          })
                        }
                        className="box-img"
                      >
                        <figure className="img-box">
                          <img src={original_url} alt="" className="img" />
                        </figure>
                      </div>
                      // <div
                      //   style={{
                      //     backgroundImage: original_url
                      //       ? `url(${original_url})`
                      //       : `url(${URL.createObjectURL(chi)})`,
                      //     width: "25rem",
                      //     //   backgroundColor: "red"
                      //   }}
                      //   key={idx}
                      //   className="box"
                      // ></div>
                    );
                  })}
                </div>
                {/* image wrap box end */}
              </div>
            )}
          </>
          {/* title drop control box end */}

          {/* title drop control box start */}
          <>
            <div
              onClick={() => handleShowBox("two")}
              className="title-drop-control-box"
              style={{ marginTop: "2rem" }}
            >
              <p className="title">Monthly Breakdown</p>
              <div className="icon-box">
                {!showBox.two && <FaAngleDown className="icon" />}
                {showBox.two && <FaAngleUp className="icon" />}
              </div>
            </div>
            {showBox.two && (
              <div className="content-wrap">
                {detail?.monthly_breakdown?.map((chi, idx) => {
                  const { month_name, value } = chi;
                  return (
                    <>
                      <EmployeeOverviewTableBodyRow
                        key={idx}
                        one={month_name}
                        two={formatNumber(String(value))}
                        three={formatNumber(String(detail?.total_value))}
                      />
                    </>
                  );
                })}
              </div>
            )}
          </>
          {/* title drop control box end */}
          {/* title drop control box start */}
          <>
            <div
              onClick={() => handleShowBox("three")}
              className="title-drop-control-box"
              style={{ marginTop: "2rem" }}
            >
              <p className="title">Approval Flow</p>
              <div className="icon-box">
                {!showBox.three && <FaAngleDown className="icon" />}
                {showBox.three && <FaAngleUp className="icon" />}
              </div>
            </div>
            {showBox.three && (
              <div className="content-wrap">
                {detail?.approval_flow?.map((chi, idx) => {
                  const { step, user, status } = chi;
                  return (
                    <>
                      <EmployeeOverviewTableBodyRow
                        key={idx}
                        one={step.toString().padStart(2, "0")}
                        userOne
                        userName={
                          user[0]?.first_name + " " + user[0]?.last_name
                        }
                        userEmail={user[0]?.email}
                        userImg={user[0]?.profile_picture}
                        status={
                          status === "approved"
                            ? "Approved"
                            : status === "pending"
                            ? "Pending"
                            : status === "rejected"
                            ? "Rejected"
                            : "pending"
                        }
                      />
                    </>
                  );
                })}
              </div>
            )}
          </>
          {/* title drop control box end */}
        </div>
        {/* content box end */}
        {/*  */}
      </div>
      {/* </DashboardLayout> */}
      {/* approve modal start */}
      <Modal effect="fadeInRight" visible={showModal.approve_all}>
        <FinalApprovalModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve_all: false };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}

      {/* approve modal start */}
      <Modal effect="fadeInUp" visible={showModal.approve}>
        <ApprovalModal
          onFinish={handleFisnish}
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false, overview: true };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}

      {/* approve modal start */}
      <Modal effect="fadeInLeft" visible={showModal.reject}>
        <RejectModal
          onFinish={handleFisnish}
          detail={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false, overview: true };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInLeft" visible={showModal.comment}>
        <ComentModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, comment: false, overview: true };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* approve modal start */}
      <Modal visible={showModal.big_img}>
        <BigImgModal
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, overview: true, big_img: false };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
    </>
  );
};

export default SingleApprovalPage;
