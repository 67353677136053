import React, { useContext } from "react";
import "../../../../styles/dashboard/HeaderNav.css";
import toggleImg from "../../../../assets/sidebar-reduce-toggle-icon.svg";
import ActionContext from "../../../../context/ActionContext";
import { FaAngleDown } from "react-icons/fa";
// import ProfileImg from "../../../../assets/profile-img.png";
import notifyImg from "../../../../assets/notification-icon.svg";
import settingImg from "../../../../assets/settings-icon-nav.svg";
import backIcon from "../../../../assets/backIcon.svg";
import userImg from "../../../../assets/Unknown_person.jpeg";
import nigImg from "../../../../assets/nig-img.svg";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import {
  formatNumberWithComma,
  reactSelectStyle,
} from "../../../../helper/Helpers";
// import EmployeeSupportDropDown from "../../pages/dashboard/dropdown/supportdropdown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  // getOrganizations,
  getUserDetails,
  LogoutUser,
} from "../../../../redux/user";
import { Dna } from "react-loader-spinner";
import {
  getCurriencies,
  // getCurrienciesConversion,
  getEmpCurrienciesConversion,
} from "../../../../redux/currency/currency";
import usaImg from "../../../../assets/usa.png";
import kingdomImg from "../../../../assets/kigdom.png";
import { getNotifications } from "../../../../redux/notifications/notifications";

const EmployeeHeaderNav = ({ goBack, pageTitle, pageTitleSpan }) => {
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.user);
  const { notifications } = useSelector((state) => state.notifications);
  const { currencyConversion } = useSelector((state) => state.currency);

  // const { currencyDrop } = useSelector((state) => state.currency);

  const logoutUser = async () => {
    const data = await dispatch(LogoutUser());
    if (data?.payload?.success) {
      // // console.log(data);
    }
  };

  // // console.log(actionCtx)

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
    };
    if (user?.organizations?.length > 0) {
      getOrganizationFuc();
      dispatch(getCurriencies());
      // dispatch(getCurrienciesConversion(obj));
      dispatch(getEmpCurrienciesConversion(obj));
    }

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getNotifications());
    if (user?.organizations[0]?.id) {
      // handleGetData();
      // handleGetBaseCurrency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id]);

  const getOrganizationFuc = () => {
    const org = user?.organizations;
    actionCtx.activeOrgFunc(addLabelValueFunc(org)[0]);
    // console.log(org, "org-check");
  };
  // const getOrganizationFuc = async () => {
  //   const data = await dispatch(getOrganizations(true));
  //   // // console.log(actionCtx.activeOrg);
  //   if (data?.payload?.success) {
  //     const org = data?.payload?.data?.organizations;
  //     actionCtx.activeOrgFunc(addLabelValueFunc(org)[0]);
  //   }
  // };

  // const { currencyConversion } = useSelector((state) => state.currency);

  // // console.log('Currency: ',actionCtx.currencyValue)

  const addLabelValueFunc = (list) => {
    // // console.log(list);
    const newList = list?.map((chi) => {
      return { ...chi, label: chi?.name, value: chi?.id };
    });
    // // console.log(newList);
    return newList;
  };

  const checkIfNotify = (list) => {
    // // console.log(list);
    const reason = list?.some((chi) => !chi?.read_at);
    // // console.log(reason);
    return reason;
  };

  const formatCurrencySelect = (list) => {
    // console.log(list);
    const newList = list.map((chi) => {
      return {
        ...chi,
        label: `${chi?.currency?.name} - ${formatNumberWithComma(chi?.rate)}`,
        value: chi?.currency?.id,
      };
    });
    return newList;
  };

  // const [currencySelect, setCurrencySelect] = useState({});

  // const handleGetBaseCurrency = () => {
  //   const currencyVal =
  //     actionCtx?.activeOrg?.fiscal_year[0]?.currency_conversion;
  //   const getCurrencyVal = {
  //     label: currencyVal?.base_currency?.name,
  //     value: currencyVal?.base_currency?.id,
  //   };
  //   // console.log(getCurrencyVal, "val");
  //   actionCtx?.setCurrencyValue(getCurrencyVal);
  //   // console.log(getCurrencyVal, "val");
  // };

  // const handleChange = (e) => {
  //   if (currency) {
  //     setCurrency(e);
  //   }
  // };

  // console.log(user, "user");

  // const handleGetData = () => {
  //   const currencyData =
  //     actionCtx?.activeOrg?.fiscal_year[0]?.currency_conversion;
  //   const currencyVal = Array(currencyData)?.map((chi) => {
  //     return {
  //       ...chi,
  //       label: chi?.base_currency?.name,
  //       value: chi?.base_currency?.id,
  //     };
  //   });
  //   const currencyDropVal =
  //     user?.organizations[0]?.fiscal_year[0]?.currency_conversion;

  //   const currencyDropDown = Array(currencyDropVal)?.map((chi) => {
  //     return [
  //       // ...currencyDropVal,
  //       {
  //         ...chi,
  //         label: chi?.base_currency?.name,
  //         value: chi?.base_currency?.id,
  //       },
  //       {
  //         ...chi,
  //         label: chi?.currency?.name,
  //         value: chi?.currency?.id,
  //       },
  //     ];
  //   });

  //   setCurrencySelect(currencyDropDown[0]);
  //   actionCtx?.setCurrencyValue(currencyVal[0]);
  // };

  //   console.log(
  //   actionCtx?.activeOrg?.fiscal_year[0]?.currency_conversion,
  //   "Active orf"
  // );

  // console.log(actionCtx?.currencyValue, "currenc");

  // console.log(user, "users..>");

  // console.log(user, "user");

  // console.log(actionCtx?.currencyValue, "currenecy value");
  // console.log(currencyConversion, "currency");
  // console.log(actionCtx?.currencyValue, "actionCtx.currencyValue");

  console.log(user, "user");

  return (
    <div className="header-nav-wrap">
      {/* sidebar toggle box start */}
      <div
        className={`sidebar-toggle-icon ${
          actionCtx.isSidebarReduce && "sidebar-toggle-icon-reduce"
        }`}
        onClick={() => actionCtx.sidebarReduceFunc()}
      >
        <figure className="img-box">
          <img src={toggleImg} alt="" className="img" />
        </figure>
      </div>
      {/* sidebar toggle box end */}
      {/* back box start */}
      {goBack && (
        <div className="back-box" onClick={() => history.goBack()}>
          <figure className="img-box">
            <img src={backIcon} alt="" className="img" />
          </figure>
        </div>
      )}
      {/* back box end */}
      {/* title box start */}
      {pageTitle && (
        <div className="page-title-box">
          <p className="title">
            {pageTitle
              ? pageTitle.length > 25
                ? `${pageTitle?.slice(0, 25)}...`
                : pageTitle
              : ""}{" "}
            <span>
              {pageTitleSpan
                ? pageTitleSpan.length > 40
                  ? `${pageTitleSpan?.slice(0, 40)}...`
                  : pageTitleSpan
                : ""}
            </span>
          </p>
        </div>
      )}
      {/* title box end */}
      {/* currency select box start */}
      <div className="currency-select-box">
        <figure className="img-box">
          <img
            src={
              actionCtx?.currencyValue?.label?.split(" - ")[0] === "Naira"
                ? nigImg
                : actionCtx?.currencyValue?.label?.split(" - ")[0] === "USD"
                ? usaImg
                : kingdomImg
            }
            alt=""
            className="img"
          />
        </figure>
        <Select
          styles={reactSelectStyle}
          className="select select-base-currency"
          value={actionCtx?.currencyValue}
          onChange={(e) => {
            actionCtx.setCurrencyValue(e);
            // setCurrencySelect(e);
            // console.log(e);
          }}
          options={formatCurrencySelect(currencyConversion)}
        />
      </div>

      {/* currency select box end */}
      {/* type select box start */}
      {/* {organization && (
        <div className="type-select-box">
          <select name="" id="" className="select-input">
            <option value="" className="option">
              IT Holdings
            </option>
          </select>
        </div>
      )} */}
      {/* {organization && (
        <div className="type-select-box">
          <Select
            // isSearchable={true}
            // isMulti
            // placeholder={user?.organizations[0]?.name}
            className="select"
            styles={reactSelectStyleHeaderNav}
            // value={actionCtx?.activeOrg}
            // onChange={(e) => {
            //   actionCtx.setActiveOrgManual(e);
            // }}
            options={organizations}
          />
        </div>
      )} */}
      {/* type select box end */}
      {/* notification box start */}
      <div
        onClick={() => {
          actionCtx?.setShowNotification(true);
        }}
        className="notification-box"
      >
        <figure className="img-box">
          <img src={notifyImg} alt="" className="img" />
        </figure>
        {checkIfNotify(notifications) && <span></span>}
      </div>
      {/* notification box end */}
      {/* setting box start */}
      <div
        className="settings-box"
        onClick={() =>
          history.push(
            "/employee-dashboard-administration-admin-settings-preference"
          )
        }
      >
        <figure className="img-box">
          <img src={settingImg} alt="" className="img" />
        </figure>
      </div>
      {/* setting box end */}
      {/* profile box start */}
      <div
        className="profile-box"
        // onClick={() => actionCtx.profileBoxOpenFunc("any")}
      >
        <figure
          onClick={() => actionCtx.profileBoxOpenFunc("any")}
          className="img-box"
        >
          <img src={user?.profile_picture || userImg} alt="" className="img" />
        </figure>
        <p
          onClick={() => actionCtx.profileBoxOpenFunc("any")}
          className="name"
        >{`${user?.full_name}`}</p>
        <FaAngleDown
          onClick={() => actionCtx.profileBoxOpenFunc("any")}
          className="icon"
        />

        {/* profile drop box start */}
        <div
          className={`profile-drop-box ${
            actionCtx.isProfileBoxOpen && "profile-drop-box-show"
          }`}
        >
          {/* arrow start */}
          <div className="arrow-box"></div>
          {/* arrow end */}
          <div className="img-text-box">
            <figure className="img-box">
              <img
                src={user?.profile_picture || userImg}
                alt=""
                className="img"
              />
            </figure>
            <p className="name">{`${user?.full_name}`} </p>
          </div>
          {/* links start */}
          <div
            className=""
            onClick={() =>
              history.push("/employee-dashboard-administration-admin-settings")
            }
          >
            <Link to="" className="link">
              <span>My Profile</span>
            </Link>
          </div>
          {/* links end */}
          {/* links start */}
          {user?.role === "creator administrator" && (
            <div className="">
              <Link to="" className="link">
                <span>Billing & Subscription</span>
              </Link>
            </div>
          )}
          {/* links end */}
          {/* logout start */}
          {!loading ? (
            <p
              onClick={logoutUser}
              style={{ color: "#CC0905" }}
              className="logout"
            >
              Logout
            </p>
          ) : (
            <div style={{ alignSelf: "flex-start" }}>
              <Dna
                visible={true}
                height="60"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
          {/* logout end */}

          {/* logout start */}
          {/* <p style={{ color: "#CC0905" }} className="logout">
            Logout
          </p> */}
          {/* logout end */}
        </div>
        {/* profile drop box end */}
      </div>
      {/* profile box end */}
      {/* <EmployeeSupportDropDown /> */}
    </div>
  );
};

export default EmployeeHeaderNav;
