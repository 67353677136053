import React, { useState, useEffect, useContext } from "react";
import { FaAngleRight, FaPlus } from "react-icons/fa";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import noteIcon from "../../../../../assets/note-icon.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubsidiary,
  updateSubsidiary,
} from "../../../../../redux/subsidiary/subsidiary";
import Select from "react-select";
import { Dna } from "react-loader-spinner";
import { reactSelectStyle } from "../../../../../helper/Helpers";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { getCountries } from "../../../../../redux/user";
import ActionContext from "../../../../../context/ActionContext";

const UpdateSubsidiary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { organizations, countries } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.subsidiary);
  const [refreshData, setRefreshData] = useState(false);
  const [subsidiaryObj, setSubsidiaryObj] = useState({});
  const [details, setDetails] = useState({
    name: "",
    country: "",
    address: "",
    organization_id: "",
    brand_color: "",
  });
  const [color, setColor] = useColor("hex", "#004BFF");

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const dataSub = { ...subsidiaryObj, [name]: value };
    const data = { ...details, [name]: value };
    setSubsidiaryObj(dataSub);
    setDetails(data);
  };
 
  const actionCtx = useContext(ActionContext)
  useEffect(() => {
    if(actionCtx?.activeOrg?.type !== "group"){
      history?.goBack()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getSubsidiaryFunc();
    dispatch(getCountries())
      // eslint-disable-next-line
  }, [refreshData]);

  const getSubsidiaryFunc = async () => {
    const id = localStorage.getItem("single-subsidiary-id");
    // console.log();
    const data = await dispatch(getSubsidiary(id));
    if (data?.payload?.success) {
      const subsidiary = data?.payload?.data.subsidiary;
      const obj = {
        ...subsidiary,
        label: subsidiary?.name,
        value: subsidiary?.id,
        country: { label: subsidiary.country, value: subsidiary.country },
        organization: {
          ...subsidiary?.organization,
          label: subsidiary?.organization.name,
          value: subsidiary?.organization.id,
        },
        brand_color: subsidiary?.brand_color,
      };
      // console.log(obj);
      // setColor("hex", subsidiary?.brand_color)
      setSubsidiaryObj(obj);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      name: details?.name || subsidiaryObj?.name,
      country: details?.country?.label || subsidiaryObj?.country?.label,
      address: details?.address || subsidiaryObj?.address,
      organization_id:
        details?.organization_id?.value || subsidiaryObj.organization?.value,
      id: subsidiaryObj?.id,
      brand_color: details?.brand_color || subsidiaryObj.brand_color,
    };
    // console.log(obj);
    // return;
    const data = await dispatch(updateSubsidiary(obj));
    if (data?.payload?.success) {
      setRefreshData(!refreshData);
    }
  };

  return (
    <>
      {/* <DashboardLayout
        goBack
        // pageTitleSpan={`Step 2 of 6`}
        pageTitle={`Add Subsidiary`}
      > */}
      <div className="admin-org-settings-wrap">
        <div className="subtitle-box">
          <p
            onClick={() =>
              history.push("/dashboard-administration-admin-settings")
            }
            className="text"
          >
            Organization
          </p>
          <FaAngleRight className="icon" />
          <p
            onClick={() =>
              history.push(
                "/dashboard-administration-admin-settings-subsidaries-main"
              )
            }
            className="text"
          >
            Subsidiary
          </p>
          <FaAngleRight className="icon" />
          <p className="active text">Edit Subsidiary</p>
        </div>
        {/* title btn box start */}
        <div className="title-btn-box">
          {/* text tititl box start */}
          <div className="title-text-box">
            <p className="title">Add Subsidiary</p>
            <p className="text">
              Set your organization subsidiary . Once added, you can easily
              switch between created subsidiaries of the organization.
            </p>
          </div>
          {/* text tititl box end */}
          {/* button box start */}
          <div className="btn-download-box">
            <button
              className={`btn-bulk ${
                details?.Subsidiary_Address ||
                details?.Subsidiary_Name ||
                details?.Subsidiary_of ||
                details?.country
                  ? "btn-bulk-inactive"
                  : ""
              }`}
            >
              {" "}
              <FaPlus className="icon" /> Bulk Upload
            </button>
            <p className="text">See and download template</p>
          </div>
          {/* button box end */}
        </div>
        {/* title btn box  end */}

        {/* form box start */}
        <div className="form-box" style={{ width: "80%" }}>
          {/* <p className="form-title">Invite via </p> */}
          <form onSubmit={handleSubmit} action="" className="form">
            {/* wrap start */}
            <div className="wrapper">
              <p className="box-title">1.Subsidiary Information</p>
              <div className="form-wrap">
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary Name" className="label">
                    Subsidiary Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    // placeholder="IT Horizon Holdings"
                    className={`input ${details?.name && "input-active"}`}
                    id="Subsidiary Name"
                    name="name"
                    onChange={handleChange}
                    value={subsidiaryObj?.name}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Country" className="label">
                    Country <span>*</span>
                  </label>
                  <Select
                    className="select-wrap"
                    // placeholder="Sync with system settings"
                    value={subsidiaryObj?.country}
                    onChange={(e) => {
                      setSubsidiaryObj((prev) => {
                        return { ...prev, country: e };
                      });
                      setDetails((prev) => {
                        return { ...prev, country: e };
                      });
                    }}
                    styles={reactSelectStyle}
                    options={countries}
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary Address" className="label">
                    Subsidiary Address <span>*</span>
                  </label>
                  <input
                    name="address"
                    onChange={handleChange}
                    value={subsidiaryObj?.address}
                    type="text"
                    // placeholder="9B, Akin-Ogunmade Davies Close, Gbagada Phase II, Lg"
                    className={`input ${details?.address && "input-active"}`}
                    id="Subsidiary Address"
                  />
                </div>
                {/* group box end */}
                {/* group box start */}
                <div className="form-group">
                  <label htmlFor="Subsidiary of" className="label">
                    Subsidiary of
                    <figure
                      className="img-box"
                      style={{
                        filter: "saturate(0%)",
                        transform: "translateX(.5rem) translateY(.3rem)",
                      }}
                    >
                      <img src={noteIcon} alt="" className="img" />
                    </figure>
                  </label>
                  <Select
                    className="select-wrap"
                    // placeholder={"hello"}
                    value={subsidiaryObj?.organization}
                    onChange={(e) => {
                      setSubsidiaryObj((prev) => {
                        return { ...prev, organization_id: e };
                      });
                      setDetails((prev) => {
                        return { ...prev, organization_id: e };
                      });
                      
                    }}
                    styles={reactSelectStyle}
                    options={organizations}
                  />
                </div>
                {/* group box end */}
                {/* color group start */}
                <div className="color-group-box">
                  <div className="wrap">
                    <div className="text-box">
                      <p className="color-text">Brand Color</p>
                      <p className="color-text">
                        This color would be use for this subsidiary action chart
                      </p>
                    </div>
                    <div className="color-box">
                      <div className="form-group">
                        <input
                          onChange={handleChange}
                          type="text"
                          value={subsidiaryObj?.brand_color}
                          className="input"
                          name="brand_color"
                          placeholder="input color"
                        />
                        <div
                          style={{
                            backgroundColor: subsidiaryObj?.brand_color,
                          }}
                          className="display-color"
                        ></div>
                      </div>
                      <ColorPicker
                        width={300}
                        height={200}
                        color={color}
                        onChange={(e) => {
                          setColor(e);
                          setDetails((prev) => {
                            return { ...prev, brand_color: e?.hex };
                          });
                          setSubsidiaryObj((prev) => {
                            return { ...prev, brand_color: e?.hex };
                          });
                        }}
                        hideHEX
                        hideHSV
                        hideRGB
                        // dark
                      />
                    </div>
                  </div>
                </div>
                {/* color group end */}
              </div>
            </div>
            {/* wrap end */}
            {/* btn box start */}
            {/* <div className="btn-box-wrap"> */}
            {/* submit form start */}
            {!loading ? (
              <input
                style={{ marginLeft: "0rem" }}
                disabled={
                  !details?.name &&
                  !details?.address &&
                  !details?.country &&
                  !details?.organization_id &&
                  !details?.brand_color
                }
                type="submit"
                value="Save & Continue"
                className={`form-group btn-reuse form-submit ${
                  details?.name ||
                  details?.address ||
                  details?.country ||
                  details?.organization_id ||
                  details?.brand_color
                    ? "form-submit-active"
                    : ""
                }`}
              />
            ) : (
              <div
                className="dna-wrapper-box"
                style={{ alignSelf: "center", marginLeft: "0rem" }}
              >
                <Dna
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="dna-loading"
                  // wrapperStyle={{color: "red", backgroundColor : "red"}}
                  wrapperClass="dna-wrapper"
                />
              </div>
            )}
            {/* submit form  end */}
            {/* <button className="btn-reuse btn-two">Save & Continue</button> */}
            {/* </div> */}
            {/* btn box end */}
          </form>
        </div>
        {/* form box end */}
      </div>
      {/* </DashboardLayout> */}
    </>
  );
};

export default UpdateSubsidiary;
