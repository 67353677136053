import React, { useContext } from "react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaCheck,
  FaPlus,
  FaSearch,
} from "react-icons/fa";
// import { useSelector } from "react-redux";
import ActionContext from "../../context/ActionContext";
import "../../styles/dashboard/tables/ActualReuseableTable.css";
import Select from "react-select";
import { reactSelectStyleTable } from "../../helper/Helpers";
import Skeleton from "react-loading-skeleton";

const ActualReuseableTable = ({
  tableTitle,
  tableTitleSpan,
  addBtntext,
  onCreateNew,
  children,
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirtheeen,
  action,
  dontShowBnSearch,
  dontShowSearch,
  dontShowSelect,
  dontShowBtn,
  showBulkUpload,
  onBulkUpload,
  dontShowPagination,
  onNextPage,
  fromPage,
  currentPage,
  totalPage,
  toPage,
  onPrevPage,
  prevPage,
  nextPage,
  perPageValue,
  setPerPageValue,
  optionFilter,
  optionFilterVal,
  setOptionFilterVal,
  onSearchTable,
  plusIcon,
  searchText,
  selectedChildren,
  massSelectable,
  onMassSelect,
  showBtnTwo,
  btnTwoText,
  onAddBtnTwo,
  useNumPagination,
  onNumPage,
  totalTableLength,
  onMassCheck,
  checkValue,
  checkId,
  loading,
  loadingNum,
}) => {
  // const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);

  const options = [
    { label: "3", value: "3" },
    { label: "5", value: "5" },
    { label: "10", value: "10" },
    { label: "15", value: "15" },
    { label: "20", value: "20" },
  ];

  function HandlepaginationHelper(c, m) {
    var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

    for (let i = 1; i <= last; i++) {
      // eslint-disable-next-line eqeqeq, no-mixed-operators
      if (i == 1 || i == last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }
    // console.log(rangeWithDots);
    return rangeWithDots;
  }

  return (
    <div className="actual-table-wrap">
      {/* title btn box start */}
      <div className="title-btn-box">
        {tableTitle && (
          <div className="title">
            {tableTitle || "title"}{" "}
            {perPageValue ? (
              <div className="type-select-box">
                <Select
                  // isSearchable={true}
                  // isMulti
                  // placeholder={user?.organizations[0]?.name}
                  className="select"
                  styles={reactSelectStyleTable}
                  value={actionCtx.perPage}
                  onChange={(e) => {
                    actionCtx.setPerpage(e);
                    setPerPageValue(e);
                  }}
                  options={options}
                />
              </div>
            ) : (
              <span style={{ marginLeft: ".4rem" }}>
                {" "}
                {`(${tableTitleSpan})`}
              </span>
            )}
          </div>
        )}
        {!dontShowBnSearch && (
          <div className="btn-input-box">
            {/* search box start */}
            {!dontShowSearch && (
              <div className="search-box">
                <input
                  type="text"
                  placeholder={searchText || "Search"}
                  id="search"
                  className="input"
                  onChange={(e) => onSearchTable(e.target.value)}
                />
                <label htmlFor="search" className="label">
                  <FaSearch className="icon" />
                </label>
              </div>
            )}
            {/* search box end */}
            {/* selcet box start */}
            {!dontShowSelect && (
              <div className="type-select-box">
                <Select
                  // isSearchable={true}
                  // isMulti
                  // defaultValue={`filter by`}
                  placeholder={`Filter By`}
                  className="select-filter-by"
                  styles={reactSelectStyleTable}
                  value={optionFilterVal}
                  onChange={(e) => {
                    setOptionFilterVal(e);
                  }}
                  options={optionFilter}
                />
              </div>
            )}
            {/* selcet box end */}
            {/* btn box start */}
            {showBtnTwo && (
              <button
                style={{
                  backgroundColor: "var(--blue-color)",
                }}
                onClick={onAddBtnTwo}
                className="btn"
              >
                {/* {!plusIcon && <FaPlus className="icon" />} */}
                {btnTwoText || "new action"}
              </button>
            )}
            {/* btn box end */}

            {/* btn box start */}
            {!dontShowBtn && (
              <button
                style={{
                  backgroundColor: "var(--blue-color)",
                }}
                onClick={onCreateNew}
                className="btn"
              >
                {!plusIcon && <FaPlus className="icon" />}
                {addBtntext || "new action"}
              </button>
            )}
            {/* btn box end */}
          </div>
        )}
      </div>
      {/* title btn box end */}
      <table className="table" cellSpacing="0" cellPadding="0">
        {/* table head start  */}
        <thead className="table-head">
          <tr>
            <th>
              {massSelectable ? (
                <div className="table-check-wrap-box">
                  <input
                    type="checkbox"
                    name={`table-check-${checkId}`}
                    id={`table-check-${checkId}`}
                    className="table-check-input-row"
                    value={checkValue}
                    onChange={(e) => {
                      onMassCheck(e);
                    }}
                    checked={checkValue}
                  />
                  <label
                    className="table-check-label-input-row"
                    htmlFor={`table-check-${checkId}`}
                  >
                    <FaCheck className="icon" />
                  </label>
                </div>
              ) : (
                <span></span>
              )}
            </th>
            <th>S/N</th>
            {one && <th>{one}</th>}
            {two && <th>{two}</th>}
            {three && <th>{three}</th>}
            {four && <th>{four}</th>}
            {five && <th>{five}</th>}
            {six && <th>{six}</th>}
            {seven && <th>{seven}</th>}
            {eight && <th>{eight}</th>}
            {nine && <th>{nine}</th>}
            {ten && <th>{ten}</th>}
            {eleven && <th>{eleven}</th>}
            {twelve && <th>{twelve}</th>}
            {thirtheeen && <th>{thirtheeen}</th>}
            {action && <th className="action-td">action</th>}
            {/* {onNextPage && <th>&nbsp;</th>} */}
          </tr>
        </thead>
        {/* table head end */}
        {/* table body start */}
        <tbody className="table-body">
          {loading ? (
            <>
              <tr>
                {loadingNum >= 1 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 2 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 3 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 4 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 5 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 6 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 7 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 8 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 9 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 10 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 11 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 12 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
              </tr>
              <tr>
                {loadingNum >= 1 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 2 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 3 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 4 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 5 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 6 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 7 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 8 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 9 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 10 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                 {loadingNum >= 11 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
                {loadingNum >= 12 && (
                  <td>
                    <Skeleton width={"70%"} height={17} />
                  </td>
                )}
              </tr>
            </>
          ) : (
            <>{children}</>
          )}
        </tbody>
        {/* table body end */}
      </table>

      {/* table pagination start */}
      {!dontShowPagination && (
        <div className="pagination-box">
          <p className="text">{fromPage || "1"}</p>
          <span>-</span>
          <p className="text">{toPage || "1"}</p>
          <span>of</span>
          <p className="text">{totalPage || "1"}</p>

          <div className="next-prev-box">
            <FaAngleLeft
              onClick={() => prevPage && onPrevPage()}
              className={`icon ${prevPage && "icon-active"}`}
            />
            {useNumPagination &&
              currentPage &&
              totalTableLength &&
              HandlepaginationHelper(
                currentPage,
                Math.ceil(totalTableLength / actionCtx?.perPage?.value)
              ).map((chi, idx) => {
                return (
                  <div
                    onClick={() => {
                      if (chi !== "..." && currentPage !== idx + 1) {
                        onNumPage(chi);
                      }
                    }}
                    key={idx}
                    className={`icon icon-active ${
                      currentPage === idx + 1 && "current current-chi"
                    } ${chi === "..." && "current"}`}
                  >
                    {chi}
                  </div>
                );
              })}
            <FaAngleRight
              onClick={() => nextPage && onNextPage()}
              className={`icon ${nextPage && "icon-active"}`}
            />
          </div>
        </div>
      )}
      {/* table pagination end */}
    </div>
  );
};

export default ActualReuseableTable;
