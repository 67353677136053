import React, { useContext, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp, FaEllipsisH, FaTimes } from "react-icons/fa";
import "../../../../../../styles/dashboard/projection/expense/OverviewCardExpenseModal.css";
import ButtonBlue from "../../../../../buttons/ButtonBlue";
// import imgOne from "../../../../../../assets/hod-two-img.svg";
import {
  formatNumberWithComma,
  FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
// import moment from "moment";
import EmployeeOverviewTableBodyRow from "../../../../../../employee-folder/component/pages/dashboard/home/modals/Reimbursement/overviewTableRow";
import ActionContext from "../../../../../../context/ActionContext";
import html2canvas from "html2canvas";
import { useDispatch } from "react-redux";
import { getRevProjectionPdf } from "../../../../../../redux/projections/RevenueProjection";
import {
  approvedIcon,
  rejectIcon,
} from "../../../projection/expense/activityLogIcons";
import { getSupplementaryBudgetActivityLog } from "../../../../../../redux/actuals/suplementaryBudget";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

const OverviewCardExpenseModal = ({
  onCancel,
  onApprove,
  onReject,
  onComment,
  detail,
}) => {
  const [detailToShow, setDetailToShow] = useState("overview");
  const [showBox, setShowBox] = useState({
    one: true,
    two: false,
    three: false,
  });
  const [showActions, setShowActions] = useState(false);
  const actionCtx = useContext(ActionContext);
  const handleShowBox = (param) => {
    if (param === "one") {
      setShowBox((prev) => {
        return { ...prev, one: !showBox.one };
      });
    }
    if (param === "two") {
      setShowBox((prev) => {
        return { ...prev, two: !showBox.two };
      });
    }
    if (param === "three") {
      setShowBox((prev) => {
        return { ...prev, three: !showBox.three };
      });
    }
  };

  const screenShotRef = useRef();
  const dispatch = useDispatch();
  const handleDowload = async () => {
    // html2canvas(screenShotRef.current).then((canvas) => {
    //   const link = document?.createElement("a");
    //   link.download = "expenditure_projection.png";
    //   link.href = canvas.toDataURL("image/png");
    //   link.click();
    // });

    const obj = {
      id: detail?.id,
      type: "supplementary",
      cur_id: actionCtx?.currencyValue?.id || "",
    };
    const data = await dispatch(getRevProjectionPdf(obj));
    downloadCsv(data.payload.url);
    if (data?.payload?.status === 200) {
      downloadCsv(data.payload.data.url);
    }
  };

  const downloadCsv = (url) => {
    window.open(url, "_blank").focus();
  };

  const [logList, setLogList] = useState([]);
  const [loadingLog, setLoadingLog] = useState(false);
  const handleGetActivityLog = async (id) => {
    setLoadingLog(true);
    const obj = {
      id: id,
    };
    const data = await dispatch(getSupplementaryBudgetActivityLog(obj));
    if (data?.payload?.success) {
      setLoadingLog(false);
      setLogList(data?.payload?.data);
    }
  };

  return (
    <div className="overview-card-modal-wrap">
      <div className="top-box">
        <div className="title-box">
          <p
            onClick={() => {
              setDetailToShow("overview");
            }}
            className={`title ${detailToShow === "overview" && "title-active"}`}
          >
            Overview
          </p>
          <p
            onClick={() => {
              setDetailToShow("activity");
              handleGetActivityLog(detail?.id);
            }}
            className={`title ${detailToShow === "activity" && "title-active"}`}
          >
            Activity Log
          </p>
        </div>
        {detail?.status !== "approved" && detail?.current_approver && (
          <ButtonBlue onClick={onApprove} text={`Approve`} />
        )}
        <div
          onClick={() => setShowActions(!showActions)}
          className="ellipse-box"
        >
          <FaEllipsisH className="icon" />
          {/* action box start */}
          <div className={`action-box ${showActions && "action-box-active"}`}>
            {detail?.status !== "rejected" && (
              <p onClick={onReject}>
                <span>Reject</span>
              </p>
            )}
            <p onClick={onComment}>
              <span>View Comments</span>
            </p>
            <p
              onClick={() => {
                handleDowload();
                setShowActions(!showActions);
              }}
            >
              <span>Export as PDF</span>
            </p>
          </div>
          {/* action box end */}
        </div>
        <div
          onClick={() => {
            setShowActions(false);
            onCancel();
          }}
          className="cancel-box"
        >
          <FaTimes className="icon" />
        </div>
      </div>
      {/* end of top box */}
      {/* content box start */}
      <div ref={screenShotRef} className="content-box">
        {detailToShow === "overview" && (
          <>
            {" "}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("one")}
                className="title-drop-control-box"
              >
                <p className="title">Budget Owner Details</p>
                <div className="icon-box">
                  {!showBox.one && <FaAngleDown className="icon" />}
                  {showBox.one && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.one && (
                <div className="content-wrap">
                  <div className="three-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Chart of Account</p>
                      <p className="value">{detail?.chartofaccount?.name}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Class</p>
                      <p className="value">{detail?.chartofaccount?.class}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Units</p>
                      <p className="value">
                        {formatNumberWithComma(String(detail?.units))}
                      </p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="two-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Unit Price</p>
                      <p className="value">
                        {formatNumberWithComma(
                          String(
                            FormatNumWithCurrency(
                              detail?.unit_price,
                              actionCtx?.activeOrg?.active_fiscal_year
                                ?.base_currency?.name,
                              actionCtx?.currencyValue
                            )
                          )
                        )}
                      </p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Total Value</p>
                      <p className="value">
                        {formatNumberWithComma(
                          String(
                            FormatNumWithCurrency(
                              detail?.total_supplementary,
                              actionCtx?.activeOrg?.active_fiscal_year
                                ?.base_currency?.name,
                              actionCtx?.currencyValue
                            )
                          )
                        )}
                      </p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="one-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Description</p>
                      <p className="value">{detail?.description}</p>
                    </div>
                    {/* box end */}
                  </div>
                  <div className="two-box">
                    {/* box start */}
                    <div className="box">
                      <p className="text">Date Created</p>
                      <p className="value">{detail?.created_at}</p>
                    </div>
                    {/* box end */}
                    {/* box start */}
                    <div className="box">
                      <p className="text">Last Updated</p>
                      <p className="value">{detail?.date}</p>
                    </div>
                    {/* box end */}
                  </div>
                  {/* created by wrap start */}
                  <div className="one-box">
                    <div className="box">
                      <p className="text">Created by</p>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="img-text-box"
                      >
                        {detail?.createdby?.profile_picture && (
                          <figure
                            style={{
                              marginRight: "1rem",
                              width: "2.5rem",
                              height: "2.5rem",
                            }}
                            className="img-box"
                            // style={{width: "2.5rem", height: "2.5rem"}}
                          >
                            <img
                              src={detail?.createdby?.profile_picture}
                              alt=""
                              className="img"
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </figure>
                        )}
                        <div className="text-box">
                          <p className="value">
                            {detail?.createdby?.full_name}
                          </p>
                          <p style={{ marginBottom: "unset" }} className="text">
                            {detail?.createdby?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* created by wrap end */}
                </div>
              )}
            </>
            {/* title drop control box end */}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("two")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem" }}
              >
                <p className="title">Monthly Breakdown</p>
                <div className="icon-box">
                  {!showBox.two && <FaAngleDown className="icon" />}
                  {showBox.two && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.two && <div className="content-wrap">
              {detail?.monthly_breakdown?.map((chi, idx) => {
                    const { month_name, value, id } = chi;
                    return (
                      <div
                        className=""
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                        }}
                        key={id || idx}
                      >
                        <div className="box">
                          <p className="text">Month Name</p>
                          <p className="value">{month_name}</p>
                        </div>
                        <div className="box">
                          <p className="text">Value</p>
                          <p className="value">
                            {formatNumberWithComma(
                              String(
                                value
                              )
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>}
            </>
            {/* title drop control box end */}
            {/* title drop control box start */}
            <>
              <div
                onClick={() => handleShowBox("three")}
                className="title-drop-control-box"
                style={{ marginTop: "2rem" }}
              >
                <p className="title">Approval Flow</p>
                <div className="icon-box">
                  {!showBox.three && <FaAngleDown className="icon" />}
                  {showBox.three && <FaAngleUp className="icon" />}
                </div>
              </div>
              {showBox.three && (
                <div className="content-wrap">
                  {detail?.approval_flow?.map((chi, idx) => {
                    const { step, user, status } = chi;
                    return (
                      <>
                        <EmployeeOverviewTableBodyRow
                          key={idx}
                          one={step.toString().padStart(2, "0")}
                          userOne
                          userName={
                            user[0]?.first_name + " " + user[0]?.last_name
                          }
                          userEmail={user[0]?.email}
                          userImg={user[0]?.profile_picture}
                          status={
                            status === "approved"
                              ? "Approved"
                              : status === "pending"
                              ? "Pending"
                              : status === "rejected"
                              ? "Rejected"
                              : "pending"
                          }
                        />
                      </>
                    );
                  })}
                </div>
              )}
            </>
            {/* title drop control box end */}
          </>
        )}
        {/* activity log start -------- */}
        {detailToShow === "activity" && (
          <>
            <div className="activity-log-wrap-box">
               {/* box start */}
               {detail?.activities?.map((chi, idx) => {
                const { causer, title, type, created_at, icon } = chi;
                if (logList?.length < 1 ) {
                  return(
                    <div key={idx} className="log-box">
                    <>
                      <Skeleton width={35} height={35} circle />
                    </>
                    <div className="img-wrap">
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                    </div>
                    <div className="dash"></div>
                    {/* detail wrap start */}
                    <div className="log-detail-wrap">
                      <>
                        <Skeleton width={100} height={15} />
                      </>
                      <>
                        <Skeleton width={170} height={12} />
                      </>
                      <>
                        <Skeleton width={100} height={12} />
                      </>
                      <>
                        <Skeleton width={100} height={12} />
                      </>
                    </div>
                    {/* detail wrap end */}
                  </div>
                  )
                }
                return (
                  <div key={idx} className="log-box">
                    {loadingLog ? (
                      <>
                        <Skeleton width={35} height={35} circle />
                      </>
                    ) : (
                      <div className="num">
                        <span>
                          {logList.length - idx < 10
                            ? `0${logList.length - idx}`
                            : logList.length - idx}
                        </span>
                      </div>
                    )}
                    <div className="img-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={35} height={35} circle />
                        </>
                      ) : (
                        <figure className="img-box">
                          <img src={icon} alt="" className="img" />
                        </figure>
                      )}
                    </div>
                    <div className="dash"></div>
                    {/* detail wrap start */}
                    <div className="log-detail-wrap">
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={15} />
                        </>
                      ) : (
                        <p className="title">{title || "---"}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={170} height={12} />
                        </>
                      ) : (
                        <p className="initiator">{`${type || ""} by: ${
                          causer?.full_name
                        }`}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="date">{moment(created_at).format("MM-DD-YYYY HH:mm:ss A")}</p>
                      )}
                      {loadingLog ? (
                        <>
                          <Skeleton width={100} height={12} />
                        </>
                      ) : (
                        <p className="time">{created_at?.split(" ")[1]}</p>
                      )}
                    </div>
                    {/* detail wrap end */}
                  </div>
                );
              })}
              {/* box end */}
            </div>
          </>
        )}
        {/* activity log end ----------- */}
      </div>
      {/* content box end */}
      {/*  */}
    </div>
  );
};

export default OverviewCardExpenseModal;
