import React from "react";

const EmployeeOverviewTableBodyRow = ({
  one,
  two,
  three,
  userOne,
  userImg,
  userName,
  userEmail,
  status,
}) => {
  return (
    <>
      <tr
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 2fr 1fr",
          marginTop: "1rem",
        }}
        // className="top-box"
      >
        {one && <td>{one}</td>}
        {two && <td>{two}</td>}
        {userOne && (
          <td>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div>
                <figure>
                  <img
                    style={{
                      borderRadius: "20%",
                      width: "4rem",
                      height: "4rem",
                    }}
                    alt=""
                    className="img"
                    src={userImg}
                  />
                </figure>
              </div>
              <div>
                <p style={{ color: "#1D1D18", fontWeight: "600" }}>
                  {userName}
                </p>
                <p className="text" style={{ fontSize: "10px" }}>
                  {userEmail}
                </p>
              </div>
            </div>
          </td>
        )}
        {three && <td>{three}</td>}
        {status && (
          <td
            // className="overview-content-status"
            // note that both approved and rejected text are case sensitive...
            style={{
              color:
                status === "Approved"
                  ? "#07A287"
                  : status === "Rejected"
                  ? "#CC0905"
                  : "#FCA549",
            }}
          >
            {status}
          </td>
        )}
      </tr>
      <div
        style={{
          //   border: "0.1px solid #DDE2E4",
          borderBottom: ".1rem solid #DDE2E4",
          backgroundColor: "var(--white-color)",
          height: "px",
          marginTop: "2rem",
        }}
      />
    </>
  );
};
export default EmployeeOverviewTableBodyRow;
