import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../utils/axios";
// import setAuthToken from "../../../utils/auth";
import "react-toastify/dist/ReactToastify.css";
// import { addLabelValueFunc } from "../../../helper/Helpers";
// import { useContext } from "react";
// import ActionContext from "../../context/ActionContext";
toast.configure();

export const getProjectionActualGpReport = createAsyncThunk(
  "employee/get-projection-actual-gp-report",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/revenue-projection-vs-gp-actual?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&date=${
          formData?.date || ""
        }&search=${formData?.search || ""}&user_id=${
          formData?.user_id || ""
        }&status=${formData?.status || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", )
        await thunkAPI.dispatch(
          setProjectionActualGpReports(data?.data?.projection_vs_actualGP)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      // console.log(err, "error")
      toast.error(err.response?.data?.message, {
        theme: "colored",
      });
      // toast.error("Bad Network, check your network settings", {
      //   theme: "colored",
      // });
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const getProjectionActualGpReportPagination = createAsyncThunk(
  "employee/get-projection-actual-gp-pagination",
  async (formData, thunkAPI) => {
    try {
      const { data } = await axios.get(
        `/employee/reports/revenue-projection-vs-gp-actual?organization_id=${
          formData?.org_id || ""
        }&department_id=${formData?.dept_id || ""}&search=${
          formData?.search || ""
        }&user_id=${formData?.user_id || ""}&status=${
          formData?.status || ""
        }&page=${formData?.page || ""}&currency_conversion_id=${
          formData?.currency_id || ""
        }&perPage=${formData?.per_page || ""}`
      );
      if (!data?.success) {
        return thunkAPI.rejectWithValue(data);
      }
      if (data?.success) {
        // console.log("data-check", )
        await thunkAPI.dispatch(
          setProjectionActualGpReports(data?.data?.projection_vs_actualGP)
        );
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      if (err.response.status === 500) {
        toast.error("Bad Network, check your network settings", {
          theme: "colored",
        });
      }
      if (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  }
);

export const projection_actual_gp_report = createSlice({
  name: "projection_actual_gp_report",
  initialState: {
    projectionActualGpReports: [],
    links: {},
    meta: {},
    isAuth: false,
    loading: false,
    loadingTwo: false,
    deleteLoading: false,
    loadingPage: false,
  },

  reducers: {
    setProjectionActualGpReports: (state, action) => {
      state.isAuth = true;
      state.projectionActualGpReports = action.payload;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
  },
  extraReducers: {
    [getProjectionActualGpReport.pending]: (state) => {
      state.loadingPage = true;
    },
    [getProjectionActualGpReport.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getProjectionActualGpReport.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
    [getProjectionActualGpReportPagination.pending]: (state) => {
      state.loadingPage = true;
    },
    [getProjectionActualGpReportPagination.fulfilled]: (state) => {
      state.loadingPage = false;
    },
    [getProjectionActualGpReportPagination.rejected]: (state) => {
      // localStorage.removeItem('token');
      state.loadingPage = false;
      state.isAuth = false;
      state = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProjectionActualGpReports } =
  projection_actual_gp_report.actions;

export default projection_actual_gp_report.reducer;
