import React, { useContext, useEffect, useState } from "react";
import BudgetMain from "..";
import ActualReuseableTable from "../../../../../tables/ActualReuseableTable";
import TableBodyRow from "../../../../../tables/TableBodyRow";
import CommonExpenditureLink from "../expenditure-rev/commonLink";
import Modal from "react-awesome-modal";
import NewAssumptionModal from "../modals/NewAssumptionModal";
import UpdateAssumptionModal from "../modals/UpdateAssumptionModal";
import DeleteBudgetAssumption from "../modals/DeleteAssumptionModal";
import ActionContext from "../../../../../../context/ActionContext";
import { useDispatch, useSelector } from "react-redux";
import { getFiscalYears } from "../../../../../../redux/fiscal-year/fiscalYear";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  getBudgetAssumptionPagination,
  getBudgetAssumptionsByFilter,
  getBudgetAssumptionWithPagination,
} from "../../../../../../redux/budget-setting/budget-assumption/budgetAssumptions";
import { getUserDetails } from "../../../../../../redux/user";

const tableHeadList = ["Fiscal Year", "Budget Assumption"];


const BudgetAssumption = () => {
  const [refreshTable, setRefreshTable] = useState(false);
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const { budgetAssumptions, loadingPage, meta, links } = useSelector(
    (state) => state.budgetAssumptions
  );
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [indexNo, setIndexNo] = useState("");
  // const history = useHistory();
  const [showModal, setShowModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  useEffect(() => {
    if (user?.organizations?.length > 0) {
      dispatch(getFiscalYears(actionCtx?.activeOrg?.id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: actionCtx.perPage.value,
    };
    if (user?.organizations?.length > 0) {
      dispatch(getBudgetAssumptionWithPagination(obj));
    }
    // eslint-disable-next-line
  }, [refreshTable]);

  useEffect(() => {}, [refreshTable]);

 

  const handleFinish = () => {
    dispatch(getUserDetails())
    actionCtx.setIsModalOut(false);
    setShowModal((prev) => {
      return { add: false, edit: false, delete: false };
    });
    setRefreshTable(!refreshTable);
  };


  const handleEditSingleChild = (e, param) => {
    if (param === "fiscal") {
      setSingleChild((prev) => {
        return { ...prev, fiscal_year_id: e };
      });
      return;
    }
    if (param === "attach") {
      setSingleChild((prev) => {
        return { ...prev, attachment: e };
      });
      return;
    }
    const { name, value } = e.target;
    const data = { ...singleChild, [name]: value };
    setSingleChild(data);
  };

  const handleAddSingleObjEdit = (param) => {
    const obj = {
      ...param,
      fiscal_year_id: {
        label: param.fiscal_year.title,
        value: param.fiscal_year.id,
      },
    };
    console.log(obj);
    setSingleChild(obj);
  };

  const handlePagination = async (param) => {
    // console.log(param);
    const splitLink = param.split("/api");
    const linkTwo = splitLink[1];
    dispatch(getBudgetAssumptionPagination(linkTwo));
  };

  const handleSortPerPage = (id) => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id,
      per_page: id,
    };
    // console.log(obj);
    user?.organizations?.length > 0 &&
      dispatch(getBudgetAssumptionWithPagination(obj));
  };

  // Debounce search term so that it only gives us latest value
  // If search has not been updated within last 500ms, query the Cosmic API
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length) {
      const obj = {
        q: search,
        filter_by: "",
        org_id: actionCtx?.activeOrg?.id,
      };
      // console.log(obj);
      dispatch(getBudgetAssumptionsByFilter(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <>
      <BudgetMain>
        <CommonExpenditureLink title={`Budget Assumption`} />
        <div className="table-box-budget">
          {/* table box start */}
          <ActualReuseableTable
            fromPage={meta?.from || 1}
            toPage={meta?.to || budgetAssumptions?.length}
            totalPage={meta?.total || budgetAssumptions?.length}
            nextPage={links?.next}
            prevPage={links?.prev}
            onNextPage={() => handlePagination(links?.next)}
            onPrevPage={() => handlePagination(links?.prev)}
            onSearchTable={(e) => {
              setSearch(e);
            }}
            perPageValue={actionCtx?.perPage?.value}
            setPerPageValue={(e) => {
              // setPerpageVal(e);
              handleSortPerPage(e.value);
            }}
            tableTitle={
              budgetAssumptions?.length > 1
                ? `Budget Assumptions`
                : "budget Assumption"
            }
            tableTitleSpan={`(${budgetAssumptions?.length})`}
            dontShowSelect
            // tableTitleSpan={`(2)`}
            addBtntext={`New Assumption`}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            action
            onCreateNew={() => {
              actionCtx.setIsModalOut(true);
              setShowModal((prev) => {
                return { ...prev, add: true };
              });
            }}
            loading={loadingPage && budgetAssumptions?.length < 1}
            loadingNum={4}
          >
            {budgetAssumptions?.map((chi, idx) => {
              const { fiscal_year, assumption } = chi;
              return (
                <TableBodyRow
                  loading={loadingPage}
                  key={idx}
                  one={fiscal_year?.title || "not set"}
                  two={assumption || "not set"}
                  action
                  rowNo={idx}
                  indexNo={indexNo}
                  setIndexNo={() => setIndexNo(idx)}
                  deleteText={`delete`}
                  updateText={` modify`}
                  onUpdate={() => {
                    actionCtx.setIsModalOut(true);
                    setShowModal((prev) => {
                      return { ...prev, edit: true };
                    });
                    handleAddSingleObjEdit(chi);
                  }}
                  onDelete={() => {
                    setSingleChild(chi);
                    setShowModal((prev) => {
                      return { ...prev, delete: true };
                    });
                  }}
                />
              );
            })}
          </ActualReuseableTable>
          {/* table box end */}
        </div>
      </BudgetMain>
      <Modal effect="fadeInUp" visible={showModal.add}>
        <NewAssumptionModal
          onFinish={handleFinish}
          onCancel={() =>
            setShowModal((prev) => {
              return { ...prev, add: false };
            })
          }
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.edit}>
        <UpdateAssumptionModal
          onDelete={() => {
            setSingleChild((prev) => {
              return { ...prev, media: "" };
            });
          }}
          onCancel={() => {
            actionCtx.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, edit: false };
            });
            setSingleChild({});
          }}
          onFinish={handleFinish}
          detail={singleChild}
          onEdit={handleEditSingleChild}
        />
      </Modal>
      <Modal effect="fadeInUp" visible={showModal.delete}>
        <DeleteBudgetAssumption
          onFinish={handleFinish}
          detail={singleChild}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
            setSingleChild({});
          }}
        />
      </Modal>
    </>
  );
};

export default BudgetAssumption;
