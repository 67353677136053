import React, { useState } from "react";
import EmployeeCommonSettingsLayout from "../links/commonLayout";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Select from "react-select";
import lockIcon from "../../../../../../../assets/password-icon.svg";
import { FaCheck } from "react-icons/fa";
// import { useHistory } from "react-router-dom";
import Modal from "react-awesome-modal";
import EmployeeSettingUpdatePasswordModal from "../modals/updatePassword";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";
import { reactSelectStyle } from "../../../../../../../helper/Helpers";
import {
  createEmployeePreference,
  getEmployeePreference,
} from "../../../../../../../redux/employee/preference/preference";
import { useEffect } from "react";

const EmployeeSettingsPreferenceIndex = () => {
  const { loading } = useSelector((state) => state.employee_preference);
  const selectListOne = [
    { label: "Sync with system settings", value: "Sync with system settings" },
    { label: "Light ", value: "Light" },
    { label: "Dark", value: "Dark" },
  ];

  const selectLisTwo = [
    { label: "English (UK)", value: "English" },
    { label: "English (American)", value: "English (American)" },
    { label: "Chinese (Mandarin)", value: "Chinese (Mandarin)" },
  ];

  const [selectOne, setSelectOne] = useState(null);
  const [selectTwo, setSelectTwo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // const [display, setDisplay] = useState(null)
  // const history = useHistory();
  const [showPassword, setShwoPassord] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [details, setDetails] = useState({
    organization_id: "",
    display: "",
    language: "",
    email_preferences: [{ projections: "", modified_password: "" }],
  });
  const dispatch = useDispatch();
  // console.log(userPreference,"preference")
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
  };

  useEffect(() => {
    if (user?.organizations[0]?.id) {
      getPreferences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id]);

  const getPreferences = async () => {
    const data = await dispatch(
      getEmployeePreference(user?.organizations[0]?.id)
    );
    if (data?.payload?.data?.success) {
      const preferenceVal = data?.payload?.data?.data?.Preference;
      const emailPref = JSON.parse(preferenceVal?.email_preferences)[0];
      const obj = {
        ...details,
        display: {
          label: preferenceVal?.display,
          value: preferenceVal?.display,
        },
        language: {
          label: preferenceVal?.language,
          value: preferenceVal?.language,
        },
        projections: emailPref?.projections,
        modified_password: emailPref?.modified_password,
      };
      setDetails(obj);
      setSelectOne(obj?.display);
      setSelectTwo(obj?.language);
    }
  };

  // console.log(userPreference, "preference");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      organization_id: user?.organizations[0]?.id,
      display: selectOne?.value,
      language: selectTwo?.value,
      email_preferences: [
        {
          projections: details?.projections,
          modified_password: details?.modified_password,
        },
      ],
    };
    const data = await dispatch(createEmployeePreference(obj));
    if (data?.payload?.success) {
      getPreferences();
    }
  };

  return (
    <>
      <EmployeeCommonSettingsLayout settingsLink>
        <div className="profile-setting-wrap">
          {/* form box start */}
          {/* <ButtonBlue /> */}
          <form action="" className="form" autoComplete="off">
            {/* form submit button start */}
            <div className="submit-box">
              {!loading ? (
                <ButtonBlue onClick={handleSubmit} text={`Update Preference`} />
              ) : (
                <div
                  className="dna-wrapper-box"
                  style={{ alignSelf: "center", marginTop: "0rem" }}
                >
                  <Dna
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="dna-loading"
                    // wrapperStyle={{color: "red", backgroundColor : "red"}}
                    wrapperClass="dna-wrapper"
                  />
                </div>
              )}
            </div>
            {/* form submit button end */}
            <div className="form-box-preference">
              <p className="title">Display</p>
              <div className="wrap">
                {/* form group start */}
                {/* select box */}
                <div className="box">
                  <label style={{ marginBottom: ".5rem" }} htmlFor="">
                    Theme
                  </label>
                  <Select
                    className="select"
                    styles={reactSelectStyle}
                    placeholder="Sync with system settings"
                    value={selectOne}
                    onChange={(e) => {
                      setSelectOne(e);
                    }}
                    options={selectListOne}
                  />
                </div>
                {/* select end */}
                {/* form group end */}
                <div className="underline"></div>
                {/* form group start */}
                {/* select box */}
                <div className="box">
                  <label style={{ marginBottom: ".5rem" }} htmlFor="">
                    Language
                  </label>
                  <Select
                    styles={reactSelectStyle}
                    className="select"
                    placeholder="English"
                    value={selectTwo}
                    onChange={(e) => {
                      setSelectTwo(e);
                      // console.log(e?.value)
                    }}
                    options={selectLisTwo}
                  />
                </div>
                {/* select end */}
                {/* form group end */}
              </div>
            </div>
            {/* form box end */}
            {/* form box start */}
            <div className="form-box-preference">
              <p className="title">Notifications</p>

              <div className="wrap">
                <p className="subtitle">Email Preferences</p>
                <div className="underline"></div>
                {/* check start */}
                <div className="check-label-box">
                  <input
                    type="checkbox"
                    className="check"
                    id="check-budget-approved"
                    checked={details?.projections}
                    value={details?.projections}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, projections: e?.target?.checked };
                      });
                      // console.log(e.target.checked);
                    }}
                  />
                  <label htmlFor="check-budget-approved" className="label">
                    <span>
                      <FaCheck className="icon" />
                    </span>
                    Send me an email notifictation when budget is approved,
                    rejected and comment is made
                  </label>
                </div>
                {/* check end */}
                {/* check start */}
                {/* <div className="check-label-box">
                  <input
                    type="checkbox"
                    className="check"
                    id="check-budget-rejected"
                    value={details?.budget_rejected}
                    checked={details?.budget_rejected}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return { ...prev, budget_rejected: e?.target?.checked };
                      });
                    }}
                  />
                  <label htmlFor="check-budget-rejected" className="label">
                    <span>
                      <FaCheck className="icon" />
                    </span>
                    Send me an email notification when budget is rejected
                  </label>
                </div> */}
                {/* check end */}
                {/* check start */}
                {/* <div className="check-label-box">
                  <input
                    type="checkbox"
                    className="check"
                    id="check-budget-commented"
                    value={details?.budget_commented}
                    checked={details?.budget_commented}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          budget_commented: e?.target?.checked,
                        };
                      });
                    }}
                  />
                  <label htmlFor="check-budget-commented" className="label">
                    <span>
                      <FaCheck className="icon" />
                    </span>
                    Send me an email notification when a comment is made
                  </label>
                </div> */}
                {/* check end */}
                {/* check start */}
                <div className="check-label-box">
                  <input
                    type="checkbox"
                    className="check"
                    id="check-password-reset"
                    value={details?.modified_password}
                    checked={details?.modified_password}
                    onChange={(e) => {
                      setDetails((prev) => {
                        return {
                          ...prev,
                          modified_password: e?.target?.checked,
                        };
                      });
                    }}
                  />
                  <label htmlFor="check-password-reset" className="label">
                    <span>
                      <FaCheck className="icon" />
                    </span>
                    Send me an email notification when I modify/reset my
                    password
                  </label>
                </div>
                {/* check end */}
              </div>
            </div>
            {/* form box start */}
            <div className="form-box-preference">
              <p className="title">Security Settings</p>
              <div className="wrap">
                <p className="subtitle sub-two">Manage Password</p>
                <div className="underline"></div>
                {/* form group start */}
                <div className="form-group">
                  <label htmlFor="Password" className="form-label">
                    <figure className="img-box">
                      <img src={lockIcon} className="img" alt="" />
                    </figure>
                  </label>
                  <input
                    type={showPassword ? "text" : "Password"}
                    name="password"
                    id="Password"
                    className="form-input white-black-color"
                    placeholder="*************"
                    onChange={handleChange}
                    value={details.password}
                    disabled
                  />
                  <div
                    className={`status ${details.password && "status-active"}`}
                  ></div>
                  <p
                    className={`placeholder  white-black-bg white-black-color ${
                      details.password && "placeholder-active"
                    }`}
                  >
                    Password
                  </p>
                  <div
                    onClick={() => setShwoPassord(!showPassword)}
                    className="show-hide"
                  >
                    {showPassword ? (
                      <FaEyeSlash className="icon" />
                    ) : (
                      <FaEye className="icon" />
                    )}
                  </div>
                </div>
                {/* form group end */}
                {/* update modat text start */}
                <p onClick={() => setShowModal(true)} className="update-text">
                  Update Password
                </p>
                {/* update modat text end */}
              </div>
            </div>
          </form>
          {/* form box end */}
        </div>
      </EmployeeCommonSettingsLayout>
      {/* update password modal start */}
      <Modal effect="fadeInUp" visible={showModal}>
        <EmployeeSettingUpdatePasswordModal
          onCancel={() => setShowModal(false)}
        />
      </Modal>
      {/* update password modal end */}
    </>
  );
};

export default EmployeeSettingsPreferenceIndex;
