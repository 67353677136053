import React, { useContext, useEffect, useState } from "react";
import CommonInputModal from "./CommonInputModal";
import Select from "react-select";
// import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ActionContext from "../../../../../../context/ActionContext";
import {
  formatNumberWithoutComma,
  reactSelectStyle,
} from "../../../../../../helper/Helpers";
import { updateProduct } from "../../../../../../redux/budget-setting/revenue-projection/product";
import { NumericFormat } from "react-number-format";
import { FaCheck } from "react-icons/fa";

const UpdateBudgetRevProducts = ({ onCancel, onFinish, onEdit, detail }) => {
  const { subsidiariesDrop } = useSelector((state) => state.subsidiary);
  // const { verticalDrop } = useSelector((state) => state.vertical);
  const { loading } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);
  const [details, setDetails] = useState({
    subsidiary_id: "",
    is_allowed: false,
    name: "",
    description: "",
    gp_percentage: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    setDetails(data);
    onEdit(e);
  };

  const handleSubmit = async () => {
    const obj = {
      id: detail?.id,
      name: details?.name || detail?.name,
      description: details?.description || detail?.description,
      subsidiary_id:
        details?.subsidiary_id?.value || detail?.subsidiary_id?.value,
      is_allowed: detail?.is_allowed === 1 ? true : false,
      organization_id: actionCtx?.activeOrg?.id,
      gp_percentage:
        formatNumberWithoutComma(details?.gp_percentage) ||
        detail?.gp_percentage,
    };
    // console.log(obj);
    // return
    const data = await dispatch(updateProduct(obj));
    if (data?.payload?.success) {
      onFinish();
      setDetails({
        subsidiary_id: "",
        is_allowed: false,
        name: "",
        description: "",
        gp_percentage: "",
      });
    }
  };

  useEffect(() => {
    if (details?.subsidiary_id) {
      setDetails((prev) => {
        return { ...prev, subsidiary_id: {}, vertical_id: {} };
      });
    }
    // eslint-disable-next-line
  }, [actionCtx?.activeOrg]);

  return (
    <>
      <CommonInputModal
        loading={loading}
        btnActive={
          details?.description ||
          details?.name ||
          details?.gp_percentage ||
          details?.subsidiary_id 
            ? true
            : false
        }
        onBtnClick={handleSubmit}
        btnDisable={
          !details?.description &&
          !details?.name &&
          !details?.gp_percentage &&
          details?.vertical_id
        }
        longContent
        onCancel={onCancel}
        title={`Update Product`}
        btnText={`Update`}
      >
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Name <span>*</span>
          </label>
          <input
            name="name"
            onChange={handleChange}
            value={detail?.name}
            type="text"
            placeholder="Name products"
            className={`input ${details.name && "input-active"}`}
            id="Department Code"
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Description
          </label>
          <textarea
            className={`input ${details.description && "input-active"}`}
            name="description"
            id=""
            cols="30"
            rows="10"
            onChange={handleChange}
            value={detail?.description}
            type="text"
            placeholder="describe your product"
          ></textarea>
        </div>
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            Subsidiary <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="Select expense class"
            className="select "
            value={detail?.subsidiary_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, subsidiary_id: e };
              });
              onEdit(e, "subsidiary");
            }}
            options={subsidiariesDrop}
          />
        </div>
        {/* group box end */}
        {/* group box start */}
        {/* <div className="form-group">
          <label htmlFor="Branch" className="label">
            Vertical <span>*</span>
          </label>
          <Select
            styles={reactSelectStyle}
            placeholder="Select expense class"
            className="select "
            value={detail?.vertical_id}
            onChange={(e) => {
              setDetails((prev) => {
                return { ...prev, vertical_id: e };
              });
              onEdit(e, "vertical");
            }}
            options={verticalDrop}
          />
        </div> */}
        {/* group box end */}
        {/* group box start */}
        <div className="form-group">
          <label htmlFor="Branch" className="label">
            GP (%)
          </label>
          {/* <input
            name="gp_percentage"
            onChange={(e) => {
              !e.target.value.match(/[A-Z]+/g) &&
                !e.target.value.match(/[!@#$.%^&*_=+-]/g) &&
                !e.target.value.match(/[a-z]+/g) &&
                handleChange(e)
            }}
            value={formatNumberWithComma(String(detail?.gp_percentage))}
            type="text"
            maxLength={3}
            placeholder="enter account code"
            className={`input ${details.gp_percentage && "input-active"}`}
            id="Department Code"
          /> */}
          <NumericFormat
            decimalScale={3}
            decimalSeparator="."
            type="text"
            name="gp_percentage"
            maxLength={3}
            thousandsGroupStyle={"lakh"}
            allowNegative
            onChange={handleChange}
            thousandSeparator={","}
            value={detail.gp_percentage}
            className={`input ${detail.gp_percentage && "input-active"}`}
            id="Department Code"
            placeholder="enter gp percentage"
          />
        </div>
        {/* group box end */}
        {/* group start */}
        <div className="form-group form-group-check">
          <input
            value={detail?.is_allowed}
            checked={detail?.is_allowed === 1 ? true : false}
            onChange={(e) => {
              setDetails((prev) => {
                return {
                  ...prev,
                  is_allowed: detail?.is_allowed === 1 ? 0 : 1,
                };
              });
              // console.log(e.target.checked);
              // const obj = {target: {value: detail?.is_allowed === 1 ? 0 : 1 , name: "is_allowed"}}
              onEdit(detail?.is_allowed === 1 ? 0 : 1, "is_allowed");
            }}
            id="input-check-percentage-jj"
            type="checkbox"
            className="input-check-box"
          />
          <label
            htmlFor="input-check-percentage-jj"
            className="label-check-box"
          >
            <div className="new-check">
              <FaCheck className="icon" />
            </div>
            <p className="text">Allow edit of Gp percentage</p>
          </label>
        </div>
        {/* group end */}
      </CommonInputModal>
    </>
  );
};

export default UpdateBudgetRevProducts;
