import React, { useState, useEffect, useContext } from "react";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
// import EmployeeDraftProjection from "../../projection/expense/EmpDraftProjection";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
// import EmployeeCommonRevenueLayout from "./layout/CommonLayout";
import EmployeeSupplementaryLayout from "./links/CommonLayout";
import EmployeeActualSupplementaryBudgetDraft from "./draftProjectionIndex";
import { useDispatch, useSelector } from "react-redux";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import {
  getAllSupplementaryBudgetComment,
  getSubmittedSupplementaryBudget,
  getSubmittedSupplementaryBudgetBySearch,
  getSubmittedSupplementaryBudgetPagination,
} from "../../../../../../redux/employee/actual-supplementary-budget/actual-supplementary-budget";
import EmployeeActualSupplementaryBudgetPendingOverview from "./modals/pendingOverview";
import EmployeeActualSupplementaryBudgetApproveOverview from "./modals/approveOverviewModal";
import EmployeeActualSupplementaryBudgetRejectOverview from "./modals/rejectOverview";
import Modal from "react-awesome-modal";
import {
  checkCurrencyFormat,
  formatNumber,
  perPageOptions,
} from "../../../../../../helper/Helpers";
import EmployeeSupplementaryComentModal from "./modals/comment";
import EmployeeSupplementaryApprovalModal from "./modals/approve";
import EmployeeSupplementaryRejectModal from "./modals/reject";
import ActionContext from "../../../../../../context/ActionContext";
import useDebounce from "../../../../../../helper/UseDebounce";
import {
  getEmployeeProjectionDownload,
  getExportToPdf,
  getUserDepartment,
  recallProjections,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import moment from "moment";
import { getDepartments } from "../../../../../../redux/department/deparment";
import { getVerticals } from "../../../../../../redux/budget-setting/revenue-projection/vertical";
import { getProducts } from "../../../../../../redux/budget-setting/revenue-projection/product";
import { notifyNextApprover } from "../../../../../../redux/projections/ExpenditureProjection";
import FinalApprovalModal from "./modals/FinalApprovalModal";
import SupBudgetRecallModal from "./modals/recallModal";
import { getChatAccounts } from "../../../../../../redux/budget-setting/expenditure-projection/chartOfAccount";
// import Papa from "papaparse";
// import { useRef } from "react";
// import { useReactToPrint } from "react-to-print";

const EmployeeActualSupplementarySubmit = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const actionCtx = useContext(ActionContext);

  const { loadingPage, submittedactualSupplementaryBudgets, links } =
    useSelector((state) => state.actual_supplementary_budget);
  const [singleChild, setSingleChild] = useState({});
  const [selectStat, setSelectStat] = useState("");
  const [dept, setDept] = useState("");
  const { userDepartmentDrop, loading, recallLoading } = useSelector(
    (state) => state.expenditure_projection
  );
  const { departmentDrop } = useSelector((state) => state.department);
  const { productDrop } = useSelector((state) => state.product);
  const { verticalDrop } = useSelector((state) => state.vertical);
  // const [perPageVal, setPerPageVal] = useState({ label: "Per Page" });
  const [chartOfAcctVal, setChartOfAcct] = useState("");
  const { chatAccountDrop } = useSelector((state) => state.chatOfAccount);

  const { user } = useSelector((state) => state.user);
  const [refreshTable] = useState(false);
  const [showModal, setShowModal] = useState({
    approve: false,
    comment: false,
    reject: false,
    overview: false,
    approveOverview: false,
    rejectOverview: false,
  });
  const [activeStat, setActiveStat] = useState({
    one: true,
    two: false,
    three: false,
    four: false,
  });
  const [indexNo, setIndexNo] = useState("");
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    const obj = {
      reason: true,
      id: user?.organizations[0]?.id,
    };
    if (user?.organizations[0]?.id) {
      dispatch(getUserDepartment(user?.organizations[0]?.id));
      dispatch(getDepartments(user?.organizations[0]?.id));
      dispatch(getVerticals(obj));
      dispatch(getChatAccounts(obj));
      dispatch(getProducts(obj));
    }
  }, [user?.organizations[0]?.id]);

  useEffect(() => {
    if (actionCtx?.perPage || actionCtx?.currencyValue) {
      handleGetSupplemetaryData();
    }
  }, [actionCtx?.perPage, actionCtx?.currencyValue]);

  const [filterByVal, setFilterByVal] = useState("");
  // const [sortByVal, setSortByVal] = useState({ label: "Sort by" });

  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart of Account", value: "chart_of_account_id" },
    { label: "Class", value: "class" },
  ];
  const statusDropdown = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Pending My Approval",
      value: "pending_my_current_approver",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  const statusDropDownTwo = [
    {
      label: "All Projection",
      value: "",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];
  // const SortByOptions = [
  //   { label: "Ascending", value: "ASC" },
  //   { label: "Descending", value: "DESC" },
  // ];
  const [search, setSearch] = useState("");
  const [productVal, setProductVal] = useState("");
  const [verticalVal, setVerticalVal] = useState("");
  const [modalView, setModalView] = useState(false);

  const handlePagination = async (param, statusParam) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: linkTwo,
      dept_id: dept?.id,
      status: statusParam || selectStat?.value,
      filter: filterByVal?.value,
      search: search,
      // sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
    };
    setIndexNo(false);
    dispatch(getSubmittedSupplementaryBudgetPagination(obj));
  };
  const handlePaginationNum = async (num, statusParam) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: true,
      page: num,
      dept_id: dept?.id,
      status: statusParam || selectStat?.value,
      filter: filterByVal?.value,
      search: search,
      // sort: sortByVal?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
    };
    setIndexNo(false);
    dispatch(getSubmittedSupplementaryBudgetPagination(obj));
  };

  const debouncedSearchTerm = useDebounce(search, 1000);
  const handleSearch = (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      search: search,
      dept_id: dept?.id,
      filter_by: filterByVal?.value,
      status: selectStat?.value || param,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
      // sort_by: sortByVal?.value,
    };
    setIndexNo(false);
    dispatch(getSubmittedSupplementaryBudgetBySearch(obj));
  };

  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      (activeStat?.one && handleSearch("")) ||
        (activeStat?.two && handleSearch("approved")) ||
        (activeStat?.three && handleSearch("pending")) ||
        (activeStat?.four && handleSearch("rejected"));

      setIndexNo(false);
    } else {
      // handleGetSupplemetaryData();
      (activeStat?.one && handleGetSupplemetaryData("")) ||
        (activeStat?.two && handleGetSupplemetaryData("approved")) ||
        (activeStat?.three && handleGetSupplemetaryData("pending")) ||
        (activeStat?.four && handleGetSupplemetaryData("rejected"));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const tableHeadList = [
    "Chart of Account",
    "Class",
    `Proposed Price (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Approved Price (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    `Total Value (${checkCurrencyFormat(
      actionCtx?.currencyValue?.label?.split(" - ")[0]
    )})`,
    "Date",
    "Status",

    // "Action",
  ];

  const cardHeader = [
    "Total Supplementary",
    "Approved",
    "Pending",
    "Rejected",
    "Pending My Approval",
  ];
  // const cardValue = ["00.00", "0", "0", "0"];

  const handleGetCommentData = (param) => {
    // setSingleChild(param);
    dispatch(getAllSupplementaryBudgetComment(param));
  };

  // console.log("user", user);

  const [singleItem, setSingleItem] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterLoad, setFilterLoad] = useState(false);
  const [allApprovalStatus, setAllApprovalStatus] = useState("");
  const [headerCheck, setHeaderCheck] = useState(true);
  const handleGetSupplemetaryData = async (param, deptParam) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: deptParam || dept?.id,
      status: param || selectStat?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
    };
    const data = await dispatch(getSubmittedSupplementaryBudget(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
    }
  };
  const [activeBell, setActiveBell] = useState(true);
  const handleFilterSubmit = async (param, deptParam) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: deptParam || dept?.id,
      status: param || selectStat?.value,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      chart_of_account_id: chartOfAcctVal?.value,
    };
    setFilterLoad(true);
    const data = await dispatch(getSubmittedSupplementaryBudget(obj));
    if (data?.payload?.success) {
      setSingleItem(data?.payload?.data);
      setFilterLoad(false);
      setShowFilterModal(false);
      actionCtx?.setIsModalOut(false);
      // setSelectStat("");
      localStorage.setItem("supplementary-notify-dept", dept?.id);
      // setVerticalVal("");
      // setProductVal("");
      // setChartOfAcct("");
      // setPdfExport("");
      // setFilterByVal("");
      dept?.id && setActiveBell(false);
      // setDept("");
      setActiveStat((prev) => {
        return {
          ...prev,
          one: obj?.status === "",
          two: obj?.status === "approved" && true,
          three: obj?.status === "pending" && true,
          five: obj?.status === "pending_my_current_approver" && true,
          four: obj?.status === "rejected" && true,
        };
      });
    } else {
      setFilterLoad(false);
    }
  };

  const handleEditListCheck = (id) => {
    const newList = singleItem?.subsidiary?.data?.map((chi) =>
      chi?.id === id
        ? { ...chi, current_approver: !chi?.current_approver }
        : chi
    );
    const newObj = {
      ...singleItem,
      subsidiary: { ...singleItem?.subsidiary, data: newList },
    };
    setSingleItem(newObj);
  };

  // single item card value
  const cardValue = [
    singleItem?.total_supplementary || 0,
    singleItem?.approved || 0,
    singleItem?.awaiting_approval || 0,
    singleItem?.rejected || 0,
    singleItem?.total_gp || 0,
    singleItem?.pending_my_approver || 0,
  ];

  const route = [
    "/employee-dashboard-actual-supplementary-budget-form",
    "/employee-dashboard-actual-supplementary-budget-submitted",
    "/employee-dashboard-actual-supplementary-budget-approved",
    "/employee-dashboard-actual-supplementary-budget-pending",
    "/employee-dashboard-actual-supplementary-budget-rejected",
  ];
  const handleActualSupplementaryUpdate = (param) => {
    actionCtx?.setIsModalOut(false);
    localStorage.setItem("single-actual-supplementary-budget-id", param.id);
    history.push("/employee-dashboard-actual-supplementary-budget-form-update");
  };
  // export to pdf

  // export dropdown
  const [pdfExport, setPdfExport] = useState("");
  const exportOption = [
    { label: "CSV", value: "excel" },
    { label: "PDF", value: "pdf" },
  ];

  const handleFileDownload = async (param) => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      dept_id: dept?.id,
      type: "supplimentary_budget",
      format: pdfExport?.value,
      user_id: user?.id,
      currency: actionCtx?.currencyValue?.id || "",
      status: param || selectStat?.value,
    };
    const data = await dispatch(getEmployeeProjectionDownload(obj));
    if (data?.payload?.status === 200) {
      setIndexNo(false);
      window.open(data?.payload?.data?.url, "_blank").focus();
      setShowFilterModal(false);
      setSelectStat("");
      setDept("");
      setVerticalVal("");
      setProductVal("");
      setPdfExport("");
      setFilterByVal("");
    }
  };

  const [exportLoader, setExportLoader] = useState(false);
  const handleExport = async (param) => {
    const obj = {
      id: param?.id,
      type: "supplimentary_budget",
      currency_id: actionCtx?.currencyValue?.id,
    };
    setExportLoader(true);
    const data = await dispatch(getExportToPdf(obj));
    if (data?.payload?.url) {
      setIndexNo(false);
      window.open(data?.payload?.url, "_blank").focus();
      setShowModal((prev) => {
        return {
          ...prev,
          approveOverview: false,
          overview: false,
          rejectOverview: false,
        };
      });
      setExportLoader(false);
    } else {
      setExportLoader(false);
    }
  };

  // recall all
  const [selectedRecall, setSelectedRecall] = useState([]);
  const [checkHeader, setCheckHeader] = useState(!true);

  const [allApproveId, setAllAppproveId] = useState([]);
  const handleApproveAll = () => {
    const list = singleItem?.subsidiary?.data?.filter(
      (chi) => chi?.status === "pending" && chi?.current_approver
    );
    const newList = list.map((chi) => chi?.id);
    setAllAppproveId(newList);
  };

  const [recallLoader, setRecallLoader] = useState(false);

  const handleRecallProjection = async (param, singleParam) => {
    const obj = {
      id: param || [singleParam],
      type: "supplementary_budget",
    };
    setRecallLoader(true);
    const data = await dispatch(recallProjections(obj));
    // console.log(obj, "obj-check");
    if (data?.payload?.success) {
      setRecallLoader(false);
      setIndexNo(false);
      history.push("/employee-dashboard-actual-supplementary-budget");
    } else {
      setRecallLoader(false);
    }
  };

  const handleCheckRecall = (id) => {
    const checkedList = selectedRecall.indexOf(id);
    if (checkedList > -1) {
      setSelectedRecall(selectedRecall.filter((chi) => chi !== id));
    } else {
      setSelectedRecall([...selectedRecall, id]);
    }
  };

  const handleAllRecallCheck = (e) => {
    if (e.target.checked) {
      setSelectedRecall(singleItem?.subsidiary?.data?.map((chi) => chi.id));
    } else {
      setSelectedRecall([]);
    }
    setCheckHeader(e.target.checked);
  };

  const deptId = localStorage.getItem("supplementary-notify-dept");
  const [notifyLoader, setNotifyLoader] = useState(false);
  const handleNotifyApprover = async () => {
    const obj = {
      department_id: deptId,
      projection_type: "supplimentary_budget",
    };
    setNotifyLoader(true);
    const data = await dispatch(notifyNextApprover(obj));
    if (data.payload?.success) {
      setNotifyLoader(false);
      setDept("");
      setActiveBell(true);
    } else {
      setNotifyLoader(false);
    }
  };
  return (
    <>
      <EmployeeSupplementaryLayout
        supplementaryLink={true}
        exportDrop
        exportVal={pdfExport}
        options={exportOption}
        onExport={(e) => {
          setIndexNo(false);
          setPdfExport(e);
        }}
      >
        <EmployeeReusableTable
          downloading={downloading}
          onDownload={() => {
            handleFileDownload(
              (activeStat?.one && "") ||
                (activeStat?.two && "approved") ||
                (activeStat?.three && "pending") ||
                (activeStat?.four && "rejected")
            );
          }}
          exportDrop
          exportVal={pdfExport}
          options={exportOption}
          onExport={(e) => {
            setPdfExport(e);
            setIndexNo(false);
          }}
          onOpenFilter={() => {
            setIndexNo(false);
            setShowFilterModal(true);
            actionCtx?.setIsModalOut(true);
          }}
          showFilter={showFilterModal}
          onCloseFilter={() => {
            actionCtx?.setIsModalOut(false);
            setShowFilterModal(false);
            setSelectStat("");
            setVerticalVal("");
            setProductVal("");
            setPdfExport("");
            setFilterByVal("");
            setDept("");
          }}
          filterLoading={filterLoad}
          onFilterSubmit={() => {
            handleFilterSubmit();
          }}
          filterIcon
          // vertical
          verticalVal={verticalVal}
          onVerticalChange={(e) => {
            setVerticalVal(e);
          }}
          verticalDrop={verticalDrop}
          chartAcct
          chartAcctVal={chartOfAcctVal}
          chartAcctDrop={chatAccountDrop}
          onChartAcctChange={(e) => {
            setChartOfAcct(e);
          }}
          // product
          productVal={productVal}
          onProductChange={(e) => {
            setProductVal(e);
          }}
          productDrop={productDrop}
          fileDownload
          onRejectAll={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, approve_all: true };
            });
            setAllApprovalStatus("rejected");
            handleApproveAll();
          }}
          onApproveAll={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, approve_all: true };
            });
            setAllApprovalStatus("approved");
            handleApproveAll();
          }}
          rejectAll={activeStat?.five}
          approveAll={activeStat?.five}
          allActionBtn
          downloadLoader={downloading}
          onFileDownload={() => {
            pdfExport?.value &&
              handleFileDownload(
                (activeStat?.one && "") ||
                  (activeStat?.two && "approved") ||
                  (activeStat?.three && "pending") ||
                  (activeStat?.four && "rejected")
              );
            setIndexNo(false);
          }}
          exportSelect={pdfExport?.label || "File"}
          ApprovalBell={!activeStat?.four}
          disableBell={activeBell}
          onNotify={handleNotifyApprover}
          totalTitle={"Total Supplementary"}
          totalValue={formatNumber(String(cardValue[0]))}
          totalTitleTwo={"Total GP"}
          totalValueTwo={formatNumber(String(cardValue[5] || 0))}
          perPage
          optionPerPage={perPageOptions}
          setPerPage={(e) => {
            actionCtx?.setPerpage(e);
          }}
          recallAllBtn={
            activeStat?.three ||
            activeStat?.one ||
            activeStat?.five ||
            !activeStat?.one
          }
          loading={loadingPage}
          notifyLoader={notifyLoader}
          recallLoader={recallLoading || loadingPage}
          onRecallAll={() => {
            selectedRecall?.length > 0 &&
              handleRecallProjection(selectedRecall, "");
          }}
          recallBgColor={selectedRecall?.length < 1 && "#cccccc"}
          perPageVal={actionCtx?.perPage}
          statusDrop
          statusDropdown={
            user?.role !== "budget rep" ? statusDropdown : statusDropDownTwo
          }
          setStatusVal={(e) => {
            setIndexNo(false);
            setSelectStat(e);
          }}
          statusVal={selectStat}
          dept
          departmentDrop={
            user?.user_type === "ADMIN" ? departmentDrop : userDepartmentDrop
          }
          deptVal={dept}
          setDepartmentVal={(e) => {
            setIndexNo(false);
            setDept(e);
          }}
          filter
          addButton
          BtnTitle={"new budget"}
          onPage={() => history.push(route[0])}
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => {
            setIndexNo(false);
            setFilterByVal(e);
            // handleFil
          }}
          onSearchTable={(e) => {
            setIndexNo(false);
            setSearch(e);
            // console.log("e", e)
          }}
          // firstCard
          secondCard
          thirdCard
          fifthCard={user?.role !== "budget rep"}
          // loading={loadingPage}
          fourthCard
          // cardTitleOne={cardHeader[0]}
          cardTitleTwo={cardHeader[1]}
          cardTitleThree={cardHeader[2]}
          cardTitleFour={cardHeader[3]}
          cardTitleFive={cardHeader[4]}
          showSearch
          // cardBodyOne={formatNumber(String(cardValue[0] || 0))}
          cardBodyTwo={formatNumber(String(cardValue[1] || 0))}
          cardBodyThree={formatNumber(String(cardValue[2] || 0))}
          cardBodyFour={formatNumber(String(cardValue[3] || 0))}
          cardBodyFive={formatNumber(String(cardValue[5] || 0))}
          onFirstCard={() => {
            setIndexNo(false);
            handleGetSupplemetaryData("");
            setActiveStat((prev) => {
              return {
                ...prev,
                one: true,
                two: false,
                three: false,
                four: false,
                five: false,
              };
            });
          }}
          onSecondCard={() => {
            setIndexNo(false);
            handleGetSupplemetaryData("approved");
            setSelectStat({ label: "Approved", value: "approved" });
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: true,
                three: false,
                four: false,
                five: false,
              };
            });
          }}
          onThirdCard={() => {
            setIndexNo(false);
            handleGetSupplemetaryData("pending");
            setSelectStat({
              label: "Pending",
              value: "pending",
            });
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: false,
                three: true,
                four: false,
                five: false,
              };
            });
          }}
          onFourthCard={() => {
            setIndexNo(false);
            setSelectStat({ label: "Rejected", value: "rejected" });
            handleGetSupplemetaryData("rejected");
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: false,
                three: false,
                four: true,
                five: false,
              };
            });
          }}
          onFifthCard={() => {
            setIndexNo(false);
            setSelectStat({
              label: "Pending My Approval",
              value: "pending_my_current_approver",
            });
            handleGetSupplemetaryData("pending_my_current_approver");
            setActiveStat((prev) => {
              return {
                ...prev,
                one: false,
                two: false,
                three: false,
                four: false,
                five: true,
              };
            });
          }}
          linkCardOne={activeStat?.one}
          linkCardTwo={activeStat?.two}
          linkCardThree={activeStat?.three}
          linkCardFour={activeStat?.four}
          linkCardFive={activeStat?.five}
        />

        {/* table start  */}
        {children}
        <div className="table-box">
          {/* table box start */}
          <ActualReuseableTable
            key={`dffgjsh`}
            massSelectable={
              (activeStat?.five && singleItem?.subsidiary?.data?.length > 0) ||
              !activeStat?.one ||
              (activeStat?.three && singleItem?.subsidiary?.data?.length > 0) ||
              !activeStat?.one
            }
            checkValue={
              (activeStat?.five && headerCheck) ||
              (activeStat?.three && checkHeader)
            }
            onMassCheck={(e) => {
              setHeaderCheck(!headerCheck);
              const newList = singleItem?.subsidiary?.data?.map((chi) => {
                return { ...chi, current_approver: e.target.checked };
              });
              const newObj = {
                ...singleItem,
                subsidiary: { ...singleItem?.subsidiary, data: newList },
              };
              setSingleItem(newObj);
              // pending
              activeStat?.three && handleAllRecallCheck(e);
            }}
            useNumPagination
            currentPage={links?.current_page}
            totalTableLength={links?.total}
            onNumPage={(e) => {
              handlePaginationNum(
                e,
                (activeStat?.one && "") ||
                  (activeStat?.two && "approved") ||
                  (activeStat?.three && "pending") ||
                  (activeStat?.four && "rejected")
              );
            }}
            dontShowBnSearch
            dontShowPagination={singleItem?.subsidiary?.data?.length < 1}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            action
            totalPage={links?.total}
            fromPage={links?.from}
            toPage={links?.to}
            nextPage={links?.next_page_url}
            prevPage={links?.prev_page_url}
            // onNextPage={() => handlePagination(links?.next)}
            // onPrevPage={() => handlePagination(links?.prev)}
            onNextPage={() => {
              (activeStat?.one && handlePagination(links?.next_page_url, "")) ||
                (activeStat?.two &&
                  handlePagination(links?.next_page_url, "approved")) ||
                (activeStat?.three &&
                  handlePagination(links?.next_page_url, "pending")) ||
                (activeStat?.one &&
                  handlePagination(links?.next_page_url, "rejected"));
            }}
            onPrevPage={() => {
              (activeStat?.one && handlePagination(links?.prev_page_url, "")) ||
                (activeStat?.two &&
                  handlePagination(links?.prev_page_url, "approved")) ||
                (activeStat?.three &&
                  handlePagination(links?.prev_page_url, "pending")) ||
                (activeStat?.one &&
                  handlePagination(links?.prev_page_url, "rejected"));
            }}
          >
            {" "}
            {singleItem?.subsidiary?.data?.length > 0 ? (
              <>
                {singleItem?.subsidiary?.data?.map((chi, idx) => {
                  const {
                    chartofaccount,
                    // classes,
                    new_price,
                    approved_price,
                    total_supplementary,
                    date,
                    status,
                    current_approver,
                    id,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      checkBoxType={
                        activeStat?.five || activeStat?.three || activeStat?.one
                      }
                      onCheck={(e) => {
                        handleEditListCheck(id);
                        handleCheckRecall(id);
                      }}
                      checkValue={
                        // ((activeStat?.three || activeStat?.one) &&
                        (activeStat?.five && current_approver) ||
                        (status === "pending" && selectedRecall.includes(id))
                      }
                      checkId={`${id}-${idx}`}
                      item={chi}
                      key={idx}
                      one={chartofaccount?.name}
                      two={chartofaccount?.class}
                      three={formatNumber(String(new_price || 0))}
                      four={formatNumber(String(approved_price || 0))}
                      five={formatNumber(String(total_supplementary || 0))}
                      six={moment(date).format("YYYY-MM-DD")}
                      action
                      progressType={status}
                      loading={loadingPage}
                      rowNo={idx}
                      indexNo={indexNo}
                      setIndexNo={() => setIndexNo(idx)}
                      updateText={
                        status === "pending" &&
                        user?.permissions?.includes(
                          "edit supplementary budget"
                        ) &&
                        "update"
                      }
                      onUpdate={() => handleActualSupplementaryUpdate(chi)}
                      approveText={
                        status === "pending" &&
                        current_approver &&
                        user?.permissions?.includes(
                          "approve/reject supplementary budget"
                        )
                          ? "Approve"
                          : null
                      }
                      viewText={"View Comments"}
                      // rejectText={"reject"}
                      deleteText={
                        user?.permissions?.includes(
                          "approve/reject supplementary budget"
                        )
                          ? `Reject`
                          : null
                      }
                      exportText={`Export as PDF`}
                      onExport={() => handleExport(chi)}
                      exportLoader={exportLoader}
                      // rejectedText={
                      //   user?.permissions?.includes(
                      //     "approve/reject supplementary budget"
                      //   )
                      //     ? `Reject`
                      //     : null
                      // }
                      onRejected={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, reject: true };
                        });
                      }}
                      // onUpdate={() => handleActualSupplementaryUpdate(chi)}
                      onApprove={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approve: true };
                        });
                      }}
                      recallText={"recall"}
                      onRecall={() => {
                        setIndexNo(false);
                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, recall: true };
                        });
                        actionCtx?.setIsModalOut(true);
                      }}
                      onView={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        handleGetCommentData(chi?.id);
                        setShowModal((prev) => {
                          return { ...prev, comment: true };
                        });
                      }}
                      onDelete={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, reject: true };
                        });
                      }}
                      onAcceptStatus={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, approveOverview: true };
                        });
                      }}
                      onOverview={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, overview: true };
                        });
                      }}
                      onRejectStatus={() => {
                        setIndexNo(false);
                        actionCtx?.setIsModalOut(true);

                        setSingleChild(chi);
                        setShowModal((prev) => {
                          return { ...prev, rejectOverview: true };
                        });
                      }}
                    />
                  );
                })}
              </>
            ) : (
              <EmployeeActualSupplementaryBudgetDraft>
                <ButtonBlue
                  onClick={() =>
                    history.push(
                      "/employee-dashboard-actual-supplementary-budget-form"
                    )
                  }
                  text={`Add custom budget`}
                >
                  <FaPlus style={{ marginRight: "15px" }} />
                </ButtonBlue>
              </EmployeeActualSupplementaryBudgetDraft>
            )}
          </ActualReuseableTable>
        </div>
        {/* table box end */}
      </EmployeeSupplementaryLayout>

      {/* modal start */}
      <Modal visible={showModal.comment}>
        <EmployeeSupplementaryComentModal
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                comment: false,
                overview:
                  singleChild?.status === "pending" && modalView && true,
                approveOverview:
                  singleChild?.status === "approved" && modalView && true,
                rejectOverview:
                  singleChild?.status === "rejected" && modalView && true,
              };
            });
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.approve}>
        <EmployeeSupplementaryApprovalModal
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                approve: false,
                overview: modalView && true,
              };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve: false };
            });
          }}
          // onRefreshCard={handleGetSupplemetaryData}
          // onDispatch={handleGetSupplementaryBudgetTable}
          onDispatch={() => {
            (activeStat?.one && handleGetSupplemetaryData("")) ||
              (activeStat?.two && handleGetSupplemetaryData("approved")) ||
              (activeStat?.three && handleGetSupplemetaryData("pending")) ||
              (activeStat?.four && handleGetSupplemetaryData("rejected"));
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.reject}>
        <EmployeeSupplementaryRejectModal
          details={singleChild}
          onCancel={() => {
            modalView
              ? actionCtx?.setIsModalOut(true)
              : actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return {
                ...prev,
                reject: false,
                overview: modalView && true,
              };
            });
          }}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, reject: false };
            });
          }}
          // onRefreshCard={handleGetSupplemetaryData}
          // onDispatch={handleGetSupplementaryBudgetTable}
          onDispatch={() => {
            (activeStat?.one && handleGetSupplemetaryData("")) ||
              (activeStat?.two && handleGetSupplemetaryData("approved")) ||
              (activeStat?.three && handleGetSupplemetaryData("pending")) ||
              (activeStat?.four && handleGetSupplemetaryData("rejected"));
          }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.overview}>
        <EmployeeActualSupplementaryBudgetPendingOverview
          details={singleChild}
          handleShowModal={setShowModal}
          updateText={
            user?.permissions?.includes("edit supplementary budget") && "update"
          }
          onUpdate={() => {
            handleActualSupplementaryUpdate(singleChild);
          }}
          onRecall={() => {
            setIndexNo(false);
            setModalView(true);
            actionCtx?.setIsModalOut(true);
            setSingleChild(singleChild);
            setShowModal((prev) => {
              return { ...prev, recall: true, overview: false };
            });
          }}
          rejectText={
            user?.permissions?.includes("approve/reject supplementary budget")
              ? true
              : false
          }
          onNextPage={() => history.push(links[0])}
          onReject={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, reject: true, overview: false };
            });
          }}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, overview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          approveBtn={
            user?.permissions?.includes("approve/reject supplementary budget")
              ? true
              : false
          }
          onApprove={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, approve: true, overview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, overview: false };
            });
          }}
          // onUpdate={() =>
          //   // history.push("/employee-dashboard-actual-revenue-form")
          //   {
          //     // console.log("chi", singleChild?.id);
          //     localStorage.setItem(
          //       "single-actual-supplementary-budget-id",
          //       singleChild.id
          //     );
          //     history.push(
          //       "/employee-dashboard-actual-supplementary-budget-form-update"
          //     );
          //   }
          // }
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmployeeActualSupplementaryBudgetApproveOverview
          details={singleChild}
          handleShowModal={setShowModal}
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, approveOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
          // rejectText={
          //   user?.permissions?.includes("approve/reject supplementary budget")
          //     ? true
          //     : false
          // }
          // onReject={() => {
          //   actionCtx?.setIsModalOut(true);
          //   setShowModal((prev) => {
          //     return { ...prev, reject: true, approveOverview: false };
          //   });
          // }}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.rejectOverview}>
        <EmployeeActualSupplementaryBudgetRejectOverview
          onComment={() => {
            actionCtx?.setIsModalOut(true);
            setModalView(true);
            setShowModal((prev) => {
              return { ...prev, comment: true, rejectOverview: false };
            });
            handleGetCommentData(singleChild?.id);
          }}
          handleShowModal={setShowModal}
          details={singleChild}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setModalView(false);
            setShowModal((prev) => {
              return { ...prev, rejectOverview: false };
            });
          }}
          // onApprove={() => {
          //   actionCtx?.setIsModalOut(true);
          //   setShowModal((prev) => {
          //     return { ...prev, approve: true, rejectOverview: false };
          //   });
          // }}
        />
      </Modal>
      {/* modal end */}
      {/* approve modal start */}
      <Modal effect="fadeInRight" visible={showModal.approve_all}>
        <FinalApprovalModal
          allApprovalStatus={allApprovalStatus}
          // detail={expenditure_projection_single?.expenditure?.data[0]}
          allId={allApproveId}
          onFinish={() => {
            actionCtx?.setIsModalOut(false);
            handleGetSupplemetaryData();
            setShowModal({
              pendingOverview: false,
              approveOverview: false,
              rejectOverview: false,
              approve: false,
              reject: false,
              comment: false,
              approve_all: false,
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approve_all: false };
            });
          }}
        />
      </Modal>
      {/* approve modal end */}
      {/* modal start */}
      <Modal effect="fadeInRight" visible={showModal.recall}>
        <SupBudgetRecallModal
          details={singleChild}
          loading={recallLoader}
          onClose={() => {
            setShowModal((prev) => {
              return { ...prev, recall: false };
            });
            actionCtx?.setIsModalOut(false);
          }}
          onSubmit={() => {
            handleRecallProjection("", singleChild?.id);
          }}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeActualSupplementarySubmit;
