import React, { useContext, useEffect } from "react";
import "../../../styles/dashboard/HeaderNav.css";
import toggleImg from "../../../assets/sidebar-reduce-toggle-icon.svg";
import ActionContext from "../../../context/ActionContext";
import { FaAngleDown } from "react-icons/fa";
import ProfileImg from "../../../assets/Unknown_person.jpeg";
import notifyImg from "../../../assets/notification-icon.svg";
import settingImg from "../../../assets/settings-icon-nav.svg";
import backIcon from "../../../assets/backIcon.svg";
import nigImg from "../../../assets/nig-img.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizations,
  getUserDetails,
  LogoutUser,
} from "../../../redux/user";
import { Dna } from "react-loader-spinner";
// import { toast } from "react-toastify";
import Select from "react-select";
import usaImg from "../../../assets/usa.png";
import kingdomImg from "../../../assets/kigdom.png";
import {
  formatNumberWithComma,
  // FormatNumWithCurrency,
  reactSelectStyle,
  reactSelectStyleHeaderNav,
} from "../../../helper/Helpers";
import {
  getSubsidiaries,
  getSubsidiariesWithPagination,
} from "../../../redux/subsidiary/subsidiary";
import {
  getBranches,
  getBranchesWithPagination,
} from "../../../redux/branch/branch";
import {
  getDepartments,
  getDepartmentsWithPagination,
} from "../../../redux/department/deparment";
import {
  getClasses,
  getClassesWithPagination,
} from "../../../redux/budget-setting/expenditure-projection/class";
import {
  getUserActivePlan,
  getUsers,
  getUsersWithPagination,
} from "../../../redux/users/users";
import {
  getCategories,
  getCategoriesWithPagination,
} from "../../../redux/budget-setting/expenditure-projection/categories";
import {
  getChatAccounts,
  getChatOfAccountWithPagination,
} from "../../../redux/budget-setting/expenditure-projection/chartOfAccount";
import {
  getCurriencies,
  getCurrienciesConversion,
} from "../../../redux/currency/currency";
import { useThemeDetector } from "../../../utils/getThemeColor";
import { getUserPreference } from "../../../redux/preference/preference";
import { getNotifications } from "../../../redux/notifications/notifications";
// import moment from "moment";

const HeaderNav = ({ goBack, pageTitle, pageTitleSpan }) => {
  const dispatch = useDispatch();
  const { organizations, user, loading } = useSelector((state) => state.user);
  const { currencyConversion } = useSelector((state) => state.currency);
  const history = useHistory();
  const actionCtx = useContext(ActionContext);
  // const [OrgOnce, SetOrgOnce] = useState(true);
  // const {  currencyConversion } = useSelector(
  //   (state) => state.currency
  // );

  const logoutUser = async () => {
    const data = await dispatch(LogoutUser());
    if (data?.payload?.success) {
      console.log(data);
    }
  };

  useEffect(() => {
    const obj = {
      org_id: actionCtx?.activeOrg?.id || user?.organizations[0]?.id,
    };
    if (user?.organizations?.length > 0) {
      dispatch(getUserActivePlan(obj));
      getOrganizationFuc();
      dispatch(getCurriencies());
      dispatch(getCurrienciesConversion(obj));
      // dispatch(getUserActivePlan(obj));
    }

    // eslint-disable-next-line
  }, [user]);

  // useEffect(() => {}, [actionCtx?.activeOrg])

  const getOrganizationFuc = async () => {
    const data = await dispatch(getOrganizations());
    // console.log(actionCtx.activeOrg);
    if (data?.payload?.success) {
      const org = data?.payload?.data?.organizations;
      actionCtx.activeOrgFunc(addLabelValueFunc(org)[0]);
    }
  };

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { preference } = useSelector((state) => state.preference);
  const addLabelValueFunc = (list) => {
    // console.log(list);
    const newList = list?.map((chi) => {
      return { ...chi, label: chi?.name, value: chi?.id };
    });
    // console.log(newList);
    return newList;
  };

  const handleSwitchOrganization = (id) => {
    const obj = {
      org_id: id,
      per_page: actionCtx?.perPage?.value,
    };
    actionCtx.setRemoveOptions();
    if (user?.organizations?.length > 0) {
      dispatch(getUserPreference(id));
      // dispatch(getUserActivePlan(obj));
      // dispatch(getUserPreference(id));
      // subsidiary change
      dispatch(getSubsidiaries(id));
      dispatch(getSubsidiariesWithPagination(obj));
      // subsidary change end
      // branch change start
      dispatch(getCurrienciesConversion(obj));
      dispatch(getBranches(id));
      dispatch(getBranchesWithPagination(obj));
      // branch change end
      // department start
      dispatch(getDepartments(id));
      dispatch(getDepartmentsWithPagination(obj));
      // department end
      // users start
      dispatch(getUsers(id));
      dispatch(getUsersWithPagination(obj));
      // users end
      // classes start
      dispatch(getClasses(id));
      dispatch(getClassesWithPagination(obj));
      // classes end
      // category start
      dispatch(getCategories(id));
      dispatch(getCategoriesWithPagination(obj));
      // classes end
      // chart start
      dispatch(getChatAccounts(id));
      dispatch(getChatOfAccountWithPagination(obj));
      // dispatch(getUserPreference(id))
    }
  };

  const formatCurrencySelect = (list) => {
    // console.log(list);
    const newList = list.map((chi) => {
      return {
        ...chi,
        label: `${chi?.currency?.name} - ${formatNumberWithComma(chi?.rate)}`,
        value: chi?.currency?.id,
      };
    });

    return newList;
  };

  const checkIfNotify = (list) => {
    // console.log(list);
    const reason = list?.slice(0, 10)?.some((chi) => !chi.read_at);
    // console.log(reason);
    return reason;
  };

  const isDarkTheme = useThemeDetector();

  return (
    <div className="header-nav-wrap">
      {/* sidebar toggle box start */}
      <div
        className={`sidebar-toggle-icon ${
          actionCtx.isSidebarReduce && "sidebar-toggle-icon-reduce"
        } ${
          preference?.display === "Dark" && "sidebar-toggle-icon-reduce-dark"
        } ${isDarkTheme && "sidebar-toggle-icon-reduce-dark"}`}
        onClick={() => actionCtx.sidebarReduceFunc()}
      >
        <figure className="img-box">
          <img src={toggleImg} alt="" className="img" />
        </figure>
      </div>
      {/* sidebar toggle box end */}
      {/* back box start */}
      {goBack && (
        <div className="back-box" onClick={() => history.goBack()}>
          <figure className="img-box">
            <img src={backIcon} alt="" className="img" />
          </figure>
        </div>
      )}
      {/* back box end */}
      {/* title box start */}
      {pageTitle && (
        <div
          onClick={() => {
            console.log(currencyConversion);
          }}
          className="page-title-box"
        >
          <p className="title">
            {pageTitle
              ? pageTitle.length > 25
                ? `${pageTitle?.slice(0, 25)}...`
                : pageTitle
              : ""}{" "}
            <span>
              {pageTitleSpan
                ? pageTitleSpan.length > 40
                  ? `${pageTitleSpan?.slice(0, 40)}...`
                  : pageTitleSpan
                : ""}
            </span>
          </p>
        </div>
      )}
      {/* title box end */}
      {/* currency select box start */}
      <div className="currency-select-box">
        <figure className="img-box">
          <img
            src={
              actionCtx?.currencyValue?.label?.split(" - ")[0] === "Naira"
                ? nigImg
                : actionCtx?.currencyValue?.label?.split(" - ")[0] === "USD"
                ? usaImg
                : kingdomImg
            }
            alt=""
            className="img"
          />
        </figure>
        <form autoComplete="off" action="">
          <Select
            styles={reactSelectStyle}
            className="select select-base-currency"
            value={actionCtx?.currencyValue}
            onChange={(e) => {
              actionCtx.setCurrencyValue(e);
              // console.log(e);
            }}
            options={formatCurrencySelect(currencyConversion)}
          />
        </form>
      </div>
      {/* currency select box end */}
      {/* type select box start */}
      <div className="type-select-box">
        <form autoComplete="off" action="">
          <Select
            className="select"
            styles={reactSelectStyleHeaderNav}
            value={actionCtx?.activeOrg}
            onChange={(e) => {
              actionCtx.setActiveOrgManual(e);
              handleSwitchOrganization(e?.id);
              actionCtx?.setShowCheckList(true);
              // console.log(e);
            }}
            options={organizations}
            // autoComplete="off"
          />
        </form>
      </div>
      {/* type select box end */}
      {/* notification box start */}
      <div
        onClick={() => {
          actionCtx?.setShowNotification(true);
        }}
        className="notification-box"
      >
        <figure className="img-box">
          <img src={notifyImg} alt="" className="img" />
        </figure>
        {checkIfNotify(user?.notifications) && <span></span>}
      </div>
      {/* notification box end */}
      {/* setting box start */}
      <div
        onClick={() => {
          history.push("/dashboard-administration-admin-settings");
        }}
        className="settings-box"
      >
        <figure className="img-box">
          <img src={settingImg} alt="" className="img" />
        </figure>
      </div>
      {/* setting box end */}
      {/* profile box start */}
      <div
        className="profile-box"
        // onClick={() => actionCtx.profileBoxOpenFunc("any")}
      >
        <figure
          onClick={() => actionCtx.profileBoxOpenFunc("any")}
          className="img-box"
        >
          <img
            src={user?.profile_picture || ProfileImg}
            alt=""
            className="img"
          />
        </figure>
        <p onClick={() => actionCtx.profileBoxOpenFunc("any")} className="name">
          {user?.full_name.length > 12
            ? `${user?.full_name.slice(0, 12)}....`
            : `${user?.full_name}`}
        </p>
        <FaAngleDown
          onClick={() => actionCtx.profileBoxOpenFunc("any")}
          className="icon"
        />

        {/* profile drop box start */}
        <div
          className={`profile-drop-box ${
            actionCtx.isProfileBoxOpen && "profile-drop-box-show"
          }`}
        >
          {/* arrow start */}
          <div className="arrow-box"></div>
          {/* arrow end */}
          <div className="img-text-box">
            <figure className="img-box">
              <img src={ProfileImg} alt="" className="img" />
            </figure>
            <p className="name">
              {user?.full_name.length > 12
                ? `${user?.full_name.slice(0, 12)}....`
                : `${user?.full_name}`}
            </p>
          </div>
          {/* links start */}
          <div className="">
            {/* <Link to="" className="link">
              <span>My Profile</span>
            </Link> */}
            <div
              onClick={() => {
                actionCtx.profileBoxOpenFunc("close");
                history.push(
                  "/dashboard-administration-admin-settings-user-profile"
                );
              }}
              className="link"
            >
              <span>My Profile</span>
            </div>
          </div>
          {/* links end */}
          {/* links start */}
          {user?.role === "creator administrator" && (
            <div className="">
              {/* <Link to="" className="link">
              <span>Billing & Subscription</span>
            </Link> */}
              <div
                onClick={() => {
                  actionCtx.profileBoxOpenFunc("close");
                  history.push(
                    "dashboard-administration-admin-settings-billing"
                  );
                }}
                className="link"
              >
                <span>Billing & Subscription</span>
              </div>
            </div>
          )}
          {/* links end */}
          {/* logout start */}
          {!loading ? (
            <p
              onClick={logoutUser}
              style={{ color: "#CC0905" }}
              className="logout"
            >
              Logout
            </p>
          ) : (
            <div style={{ alignSelf: "flex-start" }}>
              <Dna
                visible={true}
                height="60"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
          {/* logout end */}
        </div>
        {/* profile drop box end */}
      </div>
      {/* profile box end */}
    </div>
  );
};

export default HeaderNav;
