import React, { useState } from "react";
import AuthLayout from "../../layout/auth";
import { useHistory } from "react-router-dom";
// import nameIcon from "../../../assets/name-input-icon.svg";
import "../../../styles/auth/AuthLayout.css";
import "../../../styles/auth/AuthForm.css";
import workMailIcon from "../../../assets/work-mail-icon.svg";
import lockIcon from "../../../assets/password-icon.svg";
import { FaCheck, FaEye, FaEyeSlash } from "react-icons/fa";
import { Dna } from "react-loader-spinner";
// import Aos from "aos";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../redux/user";
import { validateEmail } from "../../../helper/Helpers";
import { toast } from "react-toastify";
import {
  validatePasswordLowercase,
  validatePasswordNumber,
  validatePasswordSpecialCharacter,
  validatePasswordUpperCase,
} from "../../../helper/ValidateInput";
// import "aos/dist/aos.css";

const Login = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);
  const history = useHistory();
  const [showPassword, setShwoPassord] = useState(false);
  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...details, [name]: value };
    const newError = { ...errors, [name]: "" };
    setErrors(newError);
    setDetails(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // history.push("/dashboard-home");
    // return;

    //  validations start ----------------
    if (!validateEmail(details?.email)) {
      toast.error("Invalid email address!", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (
      !validatePasswordLowercase(details?.password) &&
      !validatePasswordUpperCase(details?.password) &&
      !validatePasswordSpecialCharacter(details?.password) &&
      !validatePasswordNumber(details?.password)
    ) {
      toast.error(
        "Password should at least be 8 characters long,contain 1 capital letter, 1 lowercase letter,  1 special character,  1 numeric character.",
        {
          theme: "colored",
          position: "top-right",
        }
      );
      return;
    }
    if (!validatePasswordLowercase(details?.password)) {
      toast.error("Password should have at least 1 lowercase letter", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordUpperCase(details?.password)) {
      toast.error("Password should have at least 1 capital letter", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordSpecialCharacter(details?.password)) {
      toast.error("Password should have at least 1 special character", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    if (!validatePasswordNumber(details?.password)) {
      toast.error("Password should have at least  1 numeric character.", {
        theme: "colored",
        position: "top-right",
      });
      return;
    }
    // validations end -----------------

    if (validateEmail(details?.email)) {
      const data = await dispatch(loginUser(details));
      const errors = data?.payload?.response?.data?.errors;
      // const msgError = data?.payload?.response?.data?.message;
      // if (msgError) {
      //   toast.error(msgError, {
      //     theme: "colored",
      //   });
      // }
      if (errors) {
        console.log(errors);
        const email = errors?.email;
        const password = errors?.password;
        if (email) {
          setErrors((prev) => {
            return { ...prev, email: email[0] };
          });
        }
        if (password) {
          setErrors((prev) => {
            return { ...prev, password: password[0] };
          });
        }
      }
      if (data?.payload?.success) {
        const name = `${data?.payload?.data?.user?.first_name} ${data?.payload?.data?.user?.last_name}`;
        const msg = data?.payload?.message;
        const token = data?.payload?.data?.token;
        const user = data?.payload?.data?.user;
        const user_type = data?.payload?.data?.user?.user_type;
        localStorage.setItem("token", token);
        localStorage.setItem("login", true);

        if (user?.organizations?.length > 0) {
          // console.log('Beans!: ', user);
          const brand_color = user?.organizations[0]?.brand_color;
          localStorage.setItem("brand_color", brand_color);
        }

        // localStorage.setItem("user", JSON.stringify(user));
        if (user?.is_verified) {
          toast.success(`User ${name} ${msg} `, {
            theme: "colored",
          });
          if (user_type === "ADMIN") {
            const urlLink = window?.location.href.split("?")[1];
            if (urlLink) {
              history.push(`/${urlLink}`);
              // console.log(user);
            } else {
              history.push("/dashboard-home");
            }
          } else {
            history.push("/employee-dashboard-home");
          }
        } else {
          toast.info(`Please request for otp to verify your email`, {
            theme: "colored",
          });
          history.push("/verify-email");
        }
      }
    } else {
      toast.error("Please provide a valid email address", {
        theme: "colored",
      });
      setErrors((prev) => {
        return { ...prev, email: "Please provide a valid email address" };
      });
    }
  };

  return (
    <>
      <AuthLayout
        getHelp
        title="Sign in to your account"
        small_text={`Access with your registered credentials`}
      >
        {/* get started */}
        {/* auth form start */}
        <form
          onSubmit={handleSubmit}
          action=""
          className="auth-form-wrap"
          style={{ marginTop: "4rem" }}
        >
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="work_email" className="form-label">
              <figure className="img-box">
                <img src={workMailIcon} className="img" alt="" />
              </figure>
            </label>
            <input
              type="work_email"
              name="email"
              id="work_email"
              className="form-input white-black-color"
              placeholder="Email"
              onChange={handleChange}
              value={details.email}
            />
            <div className={`status ${details.email && "status-active"}`}></div>
            {validateEmail(details?.email) && (
              <FaCheck style={{ color: "green" }} />
            )}
            <p
              className={`placeholder white-black-bg white-black-color ${
                details.email && "placeholder-active"
              }`}
            >
              Email
            </p>
            {errors.email && <p className="error">{errors?.email}</p>}
          </div>
          {/* form group end */}
          {/* form group start */}
          <div className="form-group">
            <label htmlFor="Password" className="form-label">
              <figure className="img-box">
                <img src={lockIcon} className="img" alt="" />
              </figure>
            </label>
            <input
              type={showPassword ? "text" : "Password"}
              name="password"
              id="Password"
              className="form-input white-black-color"
              placeholder="password"
              onChange={handleChange}
              value={details.password}
            />
            <div
              className={`status ${details.password && "status-active"}`}
            ></div>
            <p
              className={`placeholder  white-black-bg white-black-color ${
                details.password && "placeholder-active"
              }`}
            >
              Password
            </p>
            <div
              onClick={() => setShwoPassord(!showPassword)}
              className="show-hide"
            >
              {showPassword ? (
                <FaEyeSlash className="icon" />
              ) : (
                <FaEye className="icon" />
              )}
            </div>
            {errors.password && <p className="error">{errors?.password}</p>}
          </div>
          {/* form group end */}
          {/* already text start */}
          <div className="already-box already-box-login">
            <p
              onClick={() => history.push("/forget-password")}
              className="text text-login"
            >
              <span>Forgot Password</span>
            </p>
          </div>
          {/* already text end */}
          {/* submit form start */}
          {!loading ? (
            <input
              disabled={!details.email || !details.password}
              type="submit"
              value="Continue"
              className={`form-group btn-reuse form-submit ${
                details.email && details.password && "form-submit-active"
              }`}
            />
          ) : (
            <div style={{ alignSelf: "center" }}>
              <Dna
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
          {/* submit form  end */}
          {/* have an account start */}
          <p className="route-to-sign-up">
            Don't have an account ?{" "}
            <span onClick={() => history.push("/sign-up")}>Sign Up</span>
          </p>
          {/* have an account end */}
        </form>
        {/* auth form end */}
      </AuthLayout>
    </>
  );
};

export default Login;
