import React, { useContext, useState } from "react";
// import DashboardLayout from "../../../../layout/dashboard/DashboardLayout";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import CommonSortBox from "../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
// import CommonExpenseLayout from "./CommonLayout";
// import EmployeeCommonExpenseLayout from "../expense/CommonLayout";

// import Select from "react-select";
import ActionContext from "../../../../../../context/ActionContext";
import { useHistory } from "react-router-dom";
// import ExpenseChart from "../../../../../../component/pages/dashboard/actuals/expense/ExpenseChart";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
// import TableBodyRow from "../../../../../../component/tables/TableBodyRow";

import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
// import illustrationIcon from "../../../../assets/Illustration-icon.svg";
// import illustrationIcon from "../../../../../../assets/Illustration-icon.svg";
import EmployeeDraftProjection from "../expense/EmpDraftProjection";
import EmployeeCommonRevenueLayout from "./CommonLayout";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deleteRevenueProjection,
  getDraftRevenueProjections,
  getRevenueProjectioDraftPagination,
  // getRevenueProjection,
  getRevenueProjectionBySearch,
  // revenue_projection,
  submitRevenueDraftForApproval,
  submitSingleRevenueDraftForApproval,
} from "../../../../../../redux/employee/revenue-projection/revenue-projection";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import Modal from "react-awesome-modal";
import EmpRevenueProjectionDeleteModal from "./modals/delete";
import EmpRevenueOverview from "./modals/overviewModal";
import {
  formatNumber,
  // FormatNumWithCurrency,
  perPageOptions,
  trimLongString,
} from "../../../../../../helper/Helpers";
import useDebounce from "../../../../../../helper/UseDebounce";
import { Dna } from "react-loader-spinner";
import EmpRevenueSubmitForApproval from "./modals/submit";
import { deleteExpenditueProjection } from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";

const EmployeeRevenueProjectionIndex = ({ children }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  // const [sortByVal, setSortByVal] = useState({ label: "Sort by" });
  const [search, setSearch] = useState("");
  const { loadingPage, revenueProjections, loadingTwo } = useSelector(
    (state) => state.revenue_projection
  );
  const [showModal, setShowModal] = useState({
    delete: false,
    approveOverview: false,
    submit: false,
  });
  // const [perPageVal, setPerPageVal] = useState({ label: "Per Page" });

  // const SortByOptions = [
  //   // { label: "All", value: "" },
  //   { label: "Ascending", value: "ASC" },
  //   { label: "Descending", value: "DESC" },
  // ];
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Product", value: "product_id" },
    // { label: "Units ", value: "units" },
    // { label: "Units Price", value: "unit_price" },
    // { label: "Revenue", value: "revenue" },
    // { label: "GP", value: "gp_percentage" },
    { label: "Vertical", value: "vertical_id" },
  ];

  const dispatch = useDispatch();

  // const handlePagination = async (param) => {
  //   const splitLink = param.split("/api");
  //   const linkTwo = splitLink[1];
  //   // console.log("linkTwo", linkTwo);
  //   setIndexNo(false);
  //   dispatch(getRevenueProjectioDraftPagination(linkTwo));
  // };

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: false,
      filter: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: linkTwo,
    };
    setIndexNo(false);
    dispatch(getRevenueProjectioDraftPagination(obj));
  };
  const handlePaginationNum = async (param) => {
    // const splitLink = param.split("/api");
    // const splitLink = param.split("?page=");
    // const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: false,
      filter: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: param,
    };
    setIndexNo(false);
    dispatch(getRevenueProjectioDraftPagination(obj));
  };

  const debouncedSearchTerm = useDebounce(search, 1000);

  useEffect(() => {
    const obj = {
      org_id: user?.organizations[0]?.id,
      per_page: actionCtx?.perPage?.value,
    };
    dispatch(getDraftRevenueProjections(obj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, user?.organizations[0]?.id, actionCtx?.perPage?.value]);

  const handleRevnueProjectionUpdate = (param) => {
    actionCtx?.setIsModalOut(false);
    localStorage.setItem("single-revenue-projection-id", param.id);
    history.push("/employee-dashboard-projection-revenue-update");
  };

  // const handleSubmitDrafts = async () => {
  //   const val = revenueProjections?.data?.map((chi) => String(chi?.id));
  //   const obj = {
  //     revenue_projection_ids: val,
  //   };
  //   const data = await dispatch(submitRevenueDraftForApproval(obj));
  //   if (data?.payload?.success) {
  //     handleFinish();
  //   }
  // };

  const handleFinish = (del = false) => {
    setSingleChild({});
    setIndexNo(false);
    actionCtx?.setIsModalOut(false);
    setRefreshTable(!refreshTable);
    setShowModal((prev) => {
      return { ...prev, delete: false, submit: false };
    });
    if (del) {
      setShowModal((prev) => {
        return {
          ...prev,
          approveOverview: false,
        };
      });
    }
  };

  // search, sortby, and filter by
  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        org_id: user?.organizations[0]?.id,
        search: search,
        is_submitted: 0,
        // user_id: user?.id,
        // sort:
        filter_by: filterByVal?.value,
        per_page: actionCtx?.perPage?.value,
        // sort_by: sortByVal?.value,
      };
      dispatch(getRevenueProjectionBySearch(obj));
      setIndexNo(false);
    } else {
      const obj = {
        org_id: user?.organizations[0]?.id,
        per_page: actionCtx?.perPage?.value,
      };
      dispatch(getDraftRevenueProjections(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line
  }, [debouncedSearchTerm]);
  // const handleSortChange = (param) => {
  //   const obj = {
  //     // q: search,
  //     sort: param?.value,
  //     is_submitted: 0,
  //     user_id: user?.id,
  //     per_page: actionCtx?.perPage?.value,

  //     // sort_by: sortByVal?.value,
  //     org_id: user?.organizations[0]?.id,
  //   };
  //   // console.log("obj", obj)
  //   setIndexNo(false);
  //   dispatch(getRevenueProjectionBySearch(obj));
  // };

  const tableHeadList = [
    "Product",
    "Description",
    "Portfolio",
    // "Portfolio",
    "Units",
    "Unit Price",
    "Revenue",
    "GP",
    "GP %",
  ];

  const [selectedSubmit, setSelectedSubmit] = useState([]);
  const [checkHeader, setCheckHeader] = useState(!true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitDrafts = async () => {
    // const val = expenditureProjctions?.data?.map((chi) => String(chi?.id));
    const obj = {
      revenue_projection_ids: selectedSubmit,
    };
    setSubmitLoading(true);
    const data = await dispatch(submitRevenueDraftForApproval(obj));
    if (data?.payload?.success) {
      handleFinish();
      setSubmitLoading(false);
      history.push("/employee-dashboard-projection-revenue-submitted");
    } else {
      setSubmitLoading(false);
    }
  };
  const handleCheckSubmit = (id) => {
    const checkedList = selectedSubmit.indexOf(id);
    if (checkedList > -1) {
      setSelectedSubmit(selectedSubmit.filter((chi) => chi !== id));
    } else {
      setSelectedSubmit([...selectedSubmit, id]);
    }
  };
  const handleAllSubmitCheck = (e) => {
    if (e.target.checked) {
      setSelectedSubmit(revenueProjections?.data?.map((chi) => chi.id));
    } else {
      setSelectedSubmit([]);
    }
    setCheckHeader(e.target.checked);
  };

  // handleDeleteAll
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async () => {
    const obj = {
      multi: true,
      ids: selectedSubmit,
      projection_type: "revenue_projection",
    };
    setDeleteLoading(true);
    const data = await dispatch(deleteExpenditueProjection(obj));
    if (data?.payload?.success) {
      setDeleteLoading(false);
      handleFinish();
    } else {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <EmployeeCommonRevenueLayout>
        <EmployeeReusableTable
          submitAll
          onSubmitAll={() => {
            selectedSubmit?.length > 0 && handleSubmitDrafts();
          }}
          submitBgColor={selectedSubmit?.length < 1 && "#cccccc"}
          submitLoader={submitLoading}
          allActionBtn={revenueProjections?.data?.length > 0}
          filter={revenueProjections?.data?.length > 0}
          perPage={revenueProjections?.data?.length > 0}
          optionPerPage={perPageOptions}
          setPerPage={(e) => {
            actionCtx?.setPerpage(e);
          }}
          perPageVal={actionCtx?.perPage}
          // sort={true}
          // optionSortVal={sortByVal}
          // optionSort={SortByOptions}
          // setOptionSortVal={(e) => {
          //   setSortByVal(e);
          //   handleSortChange(e);
          // }}
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => {
            setFilterByVal(e);
            // handleFil
          }}
          onSearchTable={(e) => {
            setSearch(e);
            // console.log("e", e)
          }}
          showSearch={revenueProjections?.data?.length > 0}
          addButton={revenueProjections?.data?.length > 0}
          BtnTitle={`Create Revenue`}
          onPage={() =>
            history.push("/employee-dashboard-projection-revenue-new")
          }
          delBtn={revenueProjections?.data?.length > 0}
          onDel={() => {
            selectedSubmit?.length > 0 && handleDelete();
          }}
          delLoader={deleteLoading}
          loading={loadingTwo}
          delBtnTitle={"Delete Revenue"}
          delBgColor={selectedSubmit?.length > 0}
        />
        {children}
        <div className="table-bo">
          {/* table box start */}
          <ActualReuseableTable
            key={`dffgjsh`}
            massSelectable={revenueProjections?.data?.length > 0}
            checkValue={checkHeader}
            onMassCheck={(e) => {
              handleAllSubmitCheck(e);
            }}
            useNumPagination
            currentPage={revenueProjections?.current_page}
            totalTableLength={revenueProjections?.total}
            onNumPage={(e) => {
              handlePaginationNum(e);
              // console.log(e, "e");
            }}
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            eight={tableHeadList[7]}
            dontShowPagination={revenueProjections?.data?.length < 1}
            dontShowBnSearch
            fromPage={revenueProjections?.from}
            toPage={revenueProjections?.to}
            // loading={loading}
            // selectedChildren={selectedExpenditure.length}
            nextPage={revenueProjections?.next_page_url}
            prevPage={revenueProjections?.prev_page_url}
            onNextPage={() =>
              handlePagination(revenueProjections?.next_page_url)
            }
            onPrevPage={() =>
              handlePagination(revenueProjections?.prev_page_url)
            }
            totalPage={
              revenueProjections?.total || revenueProjections?.data?.length
            }
            action
          >
            {revenueProjections?.data?.length > 0 ? (
              <>
                {revenueProjections?.data?.map((chi, idx) => {
                  const {
                    product,
                    unit,
                    unit_price,
                    total_revenue_project,
                    total_gross_profit,
                    gpp,
                    portfolio,
                    description,
                    id,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      checkBoxType
                      onCheck={(e) => {
                        handleCheckSubmit(id);
                      }}
                      checkValue={selectedSubmit.includes(id)}
                      checkId={`${id}-${idx}`}
                      item={chi}
                      key={idx}
                      one={product?.name}
                      two={trimLongString(description, "25")}
                      three={portfolio?.name || <></>}
                      four={unit || 0}
                      five={formatNumber(String(unit_price || 0))}
                      six={formatNumber(String(total_revenue_project || 0))}
                      seven={formatNumber(String(total_gross_profit || 0))}
                      eight={formatNumber(String(gpp))}
                      // six={date}
                      loading={loadingPage}
                      action
                      rowNo={idx}
                      indexNo={indexNo}
                      setIndexNo={() => setIndexNo(idx)}
                      updateText={`Update`}
                      deleteText={`Delete`}
                      updateTextTwo={"Submit for Approval"}
                      // onUpdateTwo={async (e) => {
                      //   e.preventDefault();
                      //   // dispatch submit projection action
                      //   // then redirect to the submission page

                      //   const dat = { revenue_projection_ids: [chi?.id] };

                      //   const data = await dispatch(
                      //     submitSingleRevenueDraftForApproval(dat)
                      //   );

                      //   if (data?.payload?.success) {
                      //     handleFinish(true);

                      //     // setstartDate(startDate);
                      //   }
                      // }}
                      onUpdateTwo={() => {
                        //eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, submit: true };
                          });
                        }
                        setIndexNo(false);
                      }}
                      onDelete={() => {
                        //eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, delete: true };
                          });
                        }
                        setIndexNo(false);
                      }}
                      onUpdate={() => handleRevnueProjectionUpdate(chi)}
                      // onUpdate={() => {
                      //   localStorage.setItem("expenditure-projection-id", id);
                      //   history.push(
                      //     "/employee-dashboard-projection-expenditure-update"
                      //   );
                      // }}
                      onOverview={() => {
                        //eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, approveOverview: true };
                          });
                          setIndexNo(false);
                        }
                      }}
                    />
                  );
                })}
              </>
            ) : (
              <EmployeeDraftProjection projectionText={`revenue`}>
                <ButtonBlue
                  onClick={() =>
                    history.push("/employee-dashboard-projection-revenue-new")
                  }
                  text={`Create Revenue`}
                >
                  <FaPlus style={{ marginRight: "15px" }} />
                </ButtonBlue>
              </EmployeeDraftProjection>
            )}
          </ActualReuseableTable>
        </div>
      </EmployeeCommonRevenueLayout>

      {/* {revenueProjections?.data?.length > 0 && (
        <div
          className="submit-all-draft-actual-box"
          style={{ padding: "1rem 3rem" }}
        >
          {!loadingTwo ? (
            <ButtonBlue
              onClick={() => {
                selectedSubmit?.length > 0 && handleSubmitDrafts();
              }}
              text="Submit for approval"
              bgColor={selectedSubmit?.length < 1 && "#cccccc"}
              //
            />
          ) : (
            <div style={{ alignSelf: "center", marginLeft: "5rem" }}>
              <Dna
                visible={true}
                height="40"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          )}
        </div>
      )} */}

      {/* modal start */}
      <Modal visible={showModal.delete}>
        <EmpRevenueProjectionDeleteModal
          data={singleChild}
          onFinish={handleFinish}
          onClose={() => {
            actionCtx?.setIsModalOut(false);

            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
          }}
        />
      </Modal>
      {/* modal end */}

      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmpRevenueOverview
          data={singleChild}
          handleSubmit={async () => {
            // e.preventDefault();
            // dispatch submit projection action
            // then redirect to the submission page

            const dat = { revenue_projection_ids: [singleChild?.id] };

            const data = await dispatch(
              submitSingleRevenueDraftForApproval(dat)
            );

            if (data?.payload?.success) {
              handleFinish(true);
              history.push("/employee-dashboard-projection-revenue-submitted");
              // setstartDate(startDate);
            }
          }}
          onUpdate={() => {
            actionCtx?.setIsModalOut(false);
            localStorage.setItem(
              "single-revenue-projection-id",
              singleChild?.id
            );
            history.push("/employee-dashboard-projection-revenue-update");
          }}
          onDelete={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, delete: true, approveOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
          // onUpdate={handleExpenditureProjectionUpdate()}
          details={singleChild}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.submit}>
        <EmpRevenueSubmitForApproval
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, submit: false };
            });
          }}
          details={singleChild}
          onFinish={handleFinish}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeRevenueProjectionIndex;
