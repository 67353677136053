import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";

const EmpSharedServiceModal = ({
  deptVal,
  onInputChange,
  onSubmit,
  bgColor,
  onCancel,
  list,
  listData,
  formatApportionToSubmit,
}) => {
  return (
    <div className="emp-shared-wrap">
      <div className="emp-shared-service-modal">
        <p className="title">Set Percentage</p>
        <p>Set cost sharing percentage or retain default service settings</p>
        <p className="note">
          Note: Percentage for all subsidiaries add up to 100%
        </p>
        {/* form-wrap start */}
        <div className="form-wrap">
          {/* form group start */}
          {listData?.length > 0 ? (
            list?.map((chi, idx) => {
              const { apportion, subsidiary } = chi;
              return (
                <div className="form-group">
                  <label>{subsidiary?.name}</label>
                  <input
                    className="input"
                    placeholder="Select Department"
                    value={deptVal}
                    style={{ background: "#ECEDED" }}
                    disabled
                  />
                  <input
                    className={`input ${apportion && "input-active"}`}
                    value={apportion}
                    type="text"
                    style={{ width: "10rem" }}
                    name="apportions"
                    id="apportions"
                    onChange={(e) => {
                      if (Number(e.target.value) <= 100) {
                        formatApportionToSubmit(chi, e.target.value);
                      }
                    }}
                  />
                </div>
              );
            })
          ) : (
            <p style={{ fontWeight: "400", fontSize: "1.5rem" }}>
              Select a Shared Service Department to set percentage
            </p>
          )}
          {/* form-group end */}
          {/* form-group start */}
          {/* <div className="form-group">
            <label>Zojatech</label>
            <input
              className="input"
              placeholder="Select Department"
              value={deptVal}
              style={{ background: "#ECEDED" }}
              disabled
            />
            <input
              className={`input ${percent && "input-active"}`}
              value={percent}
              type="text"
              style={{ width: "10rem" }}
              name="apportions"
              id="apportions"
              onChange={onInputChange}
            />
          </div> */}
          {/* form-group end */}
        </div>
        {/* form-wrap end */}
      </div>
      <div className="btn-wrap">
        <ButtonBlue
          style={{
            border: ".1rem solid var(--blue-color)",
            color: "var(--blue-color)",
          }}
          text={"cancel"}
          bgColor={"var(--white-color)"}
          onClick={onCancel}
        />
        <ButtonBlue text={"set"} onClick={onSubmit} />
      </div>
    </div>
  );
};
export default EmpSharedServiceModal;
