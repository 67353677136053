import React from "react";
import { reactSelectStyle } from "../../../../../../../helper/Helpers";
// import CommonSortBox from "../../../../../../component/fragments/CommonSortBox";
// import CommonNavLink from "../../../../../../component/pages/dashboard/actuals/expense/CommonNavLink";
// import ActionContext from "../../../../../../context/ActionContext";
import "../../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
// import EmployeeDashboardLayout from "../../../../../layout/dashboard/EmpDashboardLayout";
import EmployeeSupplementaryApprovedNavLink from "./approveLink";
import EmployeeSupplementaryNavLink from "./CommonNavLink";
import EmployeeDraftTableNavLink from "./draftTableLink";
import EmployeeSupplementaryPendingNavLink from "./pendingLink";
import EmployeeSupplementaryRejectNavLink from "./rejectLink";
import Select from "react-select";
import ButtonBlue from "../../../../../../../component/buttons/ButtonBlue";
import { Dna } from "react-loader-spinner";
// import CommonSortBox from "../../../../../fragments/CommonSortBox";
// import CommonNavLink from "./CommonNavLink";
// import ActionContext from "../../../../../context/ActionContext";
// import EmployeeDashboardLayout from "../../../../layout/dashboard/EmpDashboardLayout";
// import EmployeeSupplementaryNavLink from "../supplementary budget/links/CommonNavLink";
// import EmployeeActualSubmitReject from "./links/rejectLink";
// import EmployeeActualDraftExpenseNavLink from "./links/draftExpenseLink";
// import EmployeeCommonNavLink from "./CommonNavLink";

const EmployeeSupplementaryLayout = ({
  children,
  supplementaryLink,
  draftLink,
  approvedLink,
  pendingLink,
  rejectLink,
  options,
  exportVal,
  onExport,
  exportDrop,
  onGetDept,
  exportSelect,
  onFileDownload,
  loading,
}) => {
  //   const actionCtx = useContext(ActionContext);

  return (
    <>
      {/* <EmployeeDashboardLayout goBack pageTitle={`Supplementary Budget`}> */}
      <div className="expense-projection-inde-wrap">
        {exportDrop && (
          <div className="header-submit-filter">
            <label>Export</label>
            <Select
              styles={reactSelectStyle}
              className="select-filter-by"
              options={options}
              placeholder="File Type"
              value={exportVal}
              onChange={(e) => onExport(e)}
            />
          </div>
        )}
        <>
          {supplementaryLink && (
            <EmployeeSupplementaryNavLink link={"/employee-dashboard-home"} />
          )}
          {draftLink && (
            <EmployeeDraftTableNavLink link={"/employee-dashboard-home"} />
          )}
          {approvedLink && (
            <EmployeeSupplementaryApprovedNavLink
              link={"/employee-dashboard-home"}
            />
          )}
          {pendingLink && (
            <EmployeeSupplementaryPendingNavLink
              link={"/employee-dashboard-home"}
            />
          )}
          {rejectLink && (
            <EmployeeSupplementaryRejectNavLink
              link={"/employee-dashboard-home"}
            />
          )}
        </>
        {/* common links end */}
        {/* chart and table box start */}
        {/* <>{chartBox}</> */}
        {/* chart  box end */}
        {/* children box start */}
        <>{children}</>
        {/* children box end */}
      </div>
      {/* </EmployeeDashboardLayout> */}
    </>
  );
};

export default EmployeeSupplementaryLayout;
