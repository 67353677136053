import React, { useContext, useState } from "react";
import "../../../../../../styles/dashboard/projection/expense/ExpenseProjectionIndex.css";
import EmployeeCommonExpenseLayout from "../expense/CommonLayout";
import ActionContext from "../../../../../../context/ActionContext";
import { useHistory } from "react-router-dom";
import ActualReuseableTable from "../../../../../../component/tables/ActualReuseableTable";
import EmployeeDraftProjection from "./EmpDraftProjection";
import ButtonBlue from "../../../../../../component/buttons/ButtonBlue";
import { FaPlus } from "react-icons/fa";
import EmployeeReusableTable from "../../../../table/EmployeeReusableTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deleteExpenditueProjection,
  getExpenditureDraftProjectionPagination,
  getExpenditureProjectionBySearch,
  getExpenditureProjections,
  // getUserDepartment,
  submitDraftForApproval,
  submitSingleDraftForApproval,
} from "../../../../../../redux/employee/expenditure-projection/expenditure-projection";
import EmpTableBodyRow from "../../../../layout/dashboard/EmpTableBodyRow";
import Modal from "react-awesome-modal";
import EmpExpenseProjectionDeleteModal from "./modals/deleteModal";
import EmpExpenditureOverview from "./modals/overview";
import {
  formatNumber,
  perPageOptions,
  trimLongString,
  // perPageOptions,
  // reactSelectStyleTable,
  // FormatNumWithCurrency,
} from "../../../../../../helper/Helpers";
import { Dna } from "react-loader-spinner";
import useDebounce from "../../../../../../helper/UseDebounce";
import moment from "moment";
import EmpSubmitForApproval from "./modals/submit";
// import Select from "react-select";

const EmployeeExpenseProjectionIndex = ({ children }) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const { loadingPage, expenditureProjctions, loadingTwo } = useSelector(
    (state) => state.expenditure_projection
  );
  const actionCtx = useContext(ActionContext);
  const [singleChild, setSingleChild] = useState({});
  const [refreshTable, setRefreshTable] = useState(false);
  const [indexNo, setIndexNo] = useState("");
  const [search, setSearch] = useState("");
  // const [sortByVal, setSortByVal] = useState({ label: "Sort by" });
  const [filterByVal, setFilterByVal] = useState({ label: "Filter by" });

  // const SortByOptions = [
  //   { label: "Ascending", value: "ASC" },
  //   { label: "Descending", value: "DESC" },
  // ];
  const filterByOptions = [
    { label: "All", value: "" },
    { label: "Chart Of Account", value: "chart_of_account" },
    { label: "Class", value: "class" },
    { label: "Description", value: "description" },
    { label: "Department", value: "department_id" },
  ];
  // const [perPageVal, setPerPageVal] = useState({ label: "Per Page" });

  // const perPage

  useEffect(() => {
    // dispatch(getUserDepartment(user?.organizations[0]?.id));
    if (user?.organizations[0]?.id) {
      const obj = {
        org_id: user?.organizations[0]?.id,
        per_page: actionCtx?.perPage?.value,
      };
      dispatch(getExpenditureProjections(obj));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizations[0]?.id, actionCtx?.perPage?.value]);

  const debouncedSearchTerm = useDebounce(search, 1000);

  useEffect(() => {
    let isMount = true;

    if (isMount && debouncedSearchTerm?.length > 0) {
      const obj = {
        org_id: user?.organizations[0]?.id,
        search: search,
        filter: filterByVal?.value,
        per_page: actionCtx?.perPage?.value,
        currency_id: actionCtx?.currencyValue?.id,
      };
      dispatch(getExpenditureProjectionBySearch(obj));
      setIndexNo(false);
    } else if (isMount && debouncedSearchTerm?.length < 1) {
      const obj = {
        org_id: user?.organizations[0]?.id,
        per_page: actionCtx?.perPage?.value,
      };
      dispatch(getExpenditureProjections(obj));
    }

    return () => {
      isMount = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  // per page
  // const perPageOption = [
  //   { label: "All Projections", value: "" },
  //   { label: "05", value: 5 },
  //   { label: "10", value: 10 },
  //   { label: "20", value: 20 },
  //   { label: "30", value: 30 },
  //   { label: "40", value: 40 },
  //   { label: "50", value: 50 },
  // ];

  // handle sort

  // const handleSortChange = (param) => {
  //   const obj = {
  //     // q: search,
  //     sort: param?.value,
  //     is_submitted: false,
  //     user_id: user?.id,
  //     // sort_by: sortByVal?.value,
  //     org_id: user?.organizations[0]?.id,
  //     currency_id: actionCtx?.currencyValue?.id,
  //   };
  //   setIndexNo(false);
  //   dispatch(getExpenditureProjectionBySearch(obj));
  // };

  const [showModal, setShowModal] = useState({
    delete: false,
    approveOverview: false,
    submit: false,
  });
  // const []
  const dispatch = useDispatch();

  const handleExpenditureProjectionUpdate = (param, update = false) => {
    actionCtx?.setIsModalOut(false);
    localStorage.setItem("single-expenditure-projection-id", param.id);
    history.push("/employee-dashboard-projection-expenditure-update");
    if (update) {
      history.push("/employee-dashboard-projection-expenditure-update");
    }
  };

  const handleFinish = async (del = false) => {
    setSingleChild({});
    setIndexNo(false);
    actionCtx?.setIsModalOut(false);
    setSelectedSubmit([]);
    setRefreshTable(!refreshTable);
    await dispatch(
      getExpenditureProjections({
        org_id: user?.organizations[0]?.id,
        per_page: actionCtx?.perPage?.value,
      })
    );
    setShowModal((prev) => {
      return { ...prev, delete: false, submit: false };
    });
    if (del) {
      setShowModal((prev) => {
        return {
          ...prev,
          approveOverview: false,
        };
      });
    }
  };

  // const handlePagination = async (param) => {
  //   const splitLink = param.split("/api");
  //   const linkTwo = splitLink[1];
  //   // console.log("linkTwo", linkTwo);
  //   setIndexNo(false);
  //   dispatch(getExpenditureDraftProjectionPagination(linkTwo));
  // };

  const handlePagination = async (param) => {
    // const splitLink = param.split("/api");
    const splitLink = param.split("?page=");
    const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: false,
      filter: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: linkTwo,
    };
    setIndexNo(false);
    dispatch(getExpenditureDraftProjectionPagination(obj));
  };

  const handlePaginationNum = async (num) => {
    // const splitLink = param.split("/api");
    // const splitLink = param.split("?page=");
    // const linkTwo = splitLink[1];
    const obj = {
      org_id: user?.organizations[0]?.id,
      filter_submitted: false,
      filter: filterByVal?.value,
      search: search,
      currency_id: actionCtx?.currencyValue?.id,
      per_page: actionCtx?.perPage?.value,
      page: num,
    };
    setIndexNo(false);
    dispatch(getExpenditureDraftProjectionPagination(obj));
  };

  // multi select checkbox
  const [selectedSubmit, setSelectedSubmit] = useState([]);
  const [checkHeader, setCheckHeader] = useState(!true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitDrafts = async () => {
    // const val = expenditureProjctions?.data?.map((chi) => String(chi?.id));
    const obj = {
      expenditure_projection_ids: selectedSubmit,
    };
    setSubmitLoading(true);
    const data = await dispatch(submitDraftForApproval(obj));
    if (data?.payload?.success) {
      setSubmitLoading(false);
      handleFinish();
      history.push("/employee-dashboard-projection-expenditure-submitted");
    } else {
      setSubmitLoading(false);
    }
  };
  const handleCheckSubmit = (id) => {
    const checkedList = selectedSubmit.indexOf(id);
    if (checkedList > -1) {
      setSelectedSubmit(selectedSubmit.filter((chi) => chi !== id));
    } else {
      setSelectedSubmit([...selectedSubmit, id]);
    }
  };
  const handleAllSubmitCheck = (e) => {
    if (e.target.checked) {
      setSelectedSubmit(expenditureProjctions?.data?.map((chi) => chi.id));
    } else {
      setSelectedSubmit([]);
    }
    setCheckHeader(e.target.checked);
  };

  // handleDeleteAll
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleDelete = async () => {
    // const id = selectedSubmit;
    const obj = {
      multi: true,
      ids: selectedSubmit,
      projection_type: "expense_projection",
    };
    // console.log(obj, "obj");
    setDeleteLoading(true);
    const data = await dispatch(deleteExpenditueProjection(obj));
    if (data?.payload?.success) {
      setDeleteLoading(false);
      handleFinish();
    } else {
      setDeleteLoading(false);
    }
  };

  const tableHeadList = [
    "Chart of Account",
    "Class",
    "Description",
    "Unit",
    "Unit Price",
    "Total Value",
    "Date",
  ];

  // console.log(selectedSubmit > 1, "check")

  // console.log(expenditureProjctions, "exefs")

  return (
    <>
      <EmployeeCommonExpenseLayout commonlink={true}>
        <EmployeeReusableTable
          submitAll
          onSubmitAll={() => {
            selectedSubmit?.length > 0 && handleSubmitDrafts();
          }}
          submitBgColor={selectedSubmit?.length < 1 && "#cccccc"}
          submitLoader={submitLoading}
          allActionBtn={expenditureProjctions?.data?.length > 0}
          filter={expenditureProjctions?.data?.length > 0}
          perPage={expenditureProjctions?.data?.length > 0}
          optionPerPage={perPageOptions}
          setPerPage={(e) => {
            actionCtx?.setPerpage(e);
          }}
          perPageVal={actionCtx?.perPage}
          // dept
          // departmentDrop={userDepartmentDrop}
          // deptVal={dept}
          // setDepartmentVal={(e) => {
          //   setDept(e);
          // }}
          //   sort={true}
          // optionSort={SortByOptions}
          // optionSortVal={sortByVal}
          // setOptionSortVal={(e) => {
          //   setSortByVal(e);
          //   handleSortChange(e);
          // }}
          optionFilter={filterByOptions}
          optionFilterVal={filterByVal}
          setOptionFilterVal={(e) => setFilterByVal(e)}
          onSearchTable={(e) => {
            setSearch(e);
          }}
          showSearch={expenditureProjctions?.data?.length > 0}
          addButton={expenditureProjctions?.data?.length > 0}
          BtnTitle={`New Expenditure`}
          onPage={() =>
            history.push("/employee-dashboard-projection-expenditure-new")
          }
          delBtn={expenditureProjctions?.data?.length > 0}
          onDel={() => {
            selectedSubmit?.length > 0 && handleDelete();
          }}
          delLoader={deleteLoading}
          loading={loadingTwo}
          delBtnTitle={"Delete Expenditure"}
          delBgColor={selectedSubmit?.length > 0}
        />
        {/* table start  */}
        {/* {children} */}
        <div className="table-bo">
          {/* table box start */}
          <ActualReuseableTable
            key={`dffgjsh`}
            massSelectable={expenditureProjctions?.data?.length > 0}
            checkValue={checkHeader}
            onMassCheck={(e) => {
              handleAllSubmitCheck(e);
            }}
            useNumPagination
            currentPage={expenditureProjctions?.current_page}
            totalTableLength={expenditureProjctions?.total}
            onNumPage={(e) => {
              handlePaginationNum(e);
            }}
            dontShowPagination={expenditureProjctions?.data?.length < 1}
            fromPage={expenditureProjctions?.from}
            toPage={expenditureProjctions?.to}
            nextPage={expenditureProjctions?.next_page_url}
            prevPage={expenditureProjctions?.prev_page_url}
            onNextPage={() =>
              handlePagination(expenditureProjctions?.next_page_url)
            }
            onPrevPage={() =>
              handlePagination(expenditureProjctions?.prev_page_url)
            }
            totalPage={expenditureProjctions?.total}
            dontShowBnSearch
            one={tableHeadList[0]}
            two={tableHeadList[1]}
            three={tableHeadList[2]}
            four={tableHeadList[3]}
            five={tableHeadList[4]}
            six={tableHeadList[5]}
            seven={tableHeadList[6]}
            eight={tableHeadList[7]}
            action
            // onNextPage
          >
            {expenditureProjctions?.data?.length > 0 ? (
              <>
                {expenditureProjctions?.data?.map((chi, idx) => {
                  const {
                    chart_of_account,
                    // classes,
                    description,
                    units,
                    unit_price,
                    total_value,
                    date,
                    id,
                  } = chi;
                  return (
                    <EmpTableBodyRow
                      checkBoxType
                      onCheck={(e) => {
                        handleCheckSubmit(id);
                      }}
                      checkValue={selectedSubmit.includes(id)}
                      checkId={`${id}-${idx}`}
                      item={chi}
                      key={idx}
                      one={chart_of_account?.name}
                      // two={chart_of_account?.projection_class?.name}
                      two={chart_of_account?.class}
                      three={trimLongString(description, "25")}
                      four={units}
                      five={formatNumber(String(unit_price || 0))}
                      six={formatNumber(String(total_value || 0))}
                      seven={moment(date).format("YYYY-MM-DD")}
                      loading={loadingPage}
                      action
                      rowNo={idx}
                      indexNo={indexNo}
                      setIndexNo={() => setIndexNo(idx)}
                      updateText={`Update`}
                      updateTextTwo={"Submit for Approval"}
                      // onUpdateTwo={async (e) => {
                      //   e.preventDefault();
                      //   // dispatch submit projection action
                      //   // then redirect to the submission page

                      //   const dat = {
                      //     expenditure_projection_ids: [chi?.id],
                      //   };

                      //   const data = await dispatch(
                      //     submitSingleDraftForApproval(dat)
                      //   );
                      //   if (data?.payload?.success) {
                      //     handleFinish(true);
                      //   }
                      // }}

                      deleteText={`Delete`}
                      onDelete={() => {
                        //eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, delete: true };
                          });
                        }
                        setIndexNo(false);
                      }}
                      onUpdateTwo={() => {
                        //eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, submit: true };
                          });
                        }
                        setIndexNo(false);
                      }}
                      onUpdate={() => handleExpenditureProjectionUpdate(chi)}
                      onOverview={() => {
                        //eslint-disable-next-line
                        {
                          actionCtx?.setIsModalOut(true);
                          setSingleChild(chi);
                          setShowModal((prev) => {
                            return { ...prev, approveOverview: true };
                          });
                        }
                        setIndexNo(false);
                      }}
                    />
                  );
                })}
              </>
            ) : (
              (
                <EmployeeDraftProjection>
                  <ButtonBlue
                    onClick={() =>
                      history.push(
                        "/employee-dashboard-projection-expenditure-new"
                      )
                    }
                    text={`Create Expenditure`}
                  >
                    <FaPlus style={{ marginRight: "15px" }} />
                  </ButtonBlue>
                </EmployeeDraftProjection>
              ) || <p>loading...</p>
            )}
            {/* table box end */}
          </ActualReuseableTable>
        </div>
      </EmployeeCommonExpenseLayout>
      {/* {expenditureProjctions?.data?.length > 0 && (
        <div
          className="submit-all-draft-actual-box"
          style={{ padding: "1rem 3rem" }}
        >
          {loadingTwo ? (
            <div style={{ alignSelf: "center", marginLeft: "5rem" }}>
              <Dna
                visible={true}
                // height="80"
                // width="100"
                height="40"
                width="80"
                ariaLabel="dna-loading"
                // wrapperStyle={{color: "red", backgroundColor : "red"}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <div className="emp-submit-all-btn">
              <ButtonBlue
                onClick={() => {
                  selectedSubmit?.length > 0 && handleSubmitDrafts();
                }}
                text="Submit for approval"
                bgColor={selectedSubmit?.length < 1 && "#cccccc"}
                //
              />
            </div>
          )}
        </div>
      )} */}
      {/* table end */}

      {/* draft projection current status start*/}
      {/* <figure>
            <img src={illustrationIcon} alt=""/>
          </figure> */}

      {/* draft projection current status end*/}

      {/* modal start */}
      <Modal visible={showModal.delete}>
        <EmpExpenseProjectionDeleteModal
          details={singleChild}
          onFinish={handleFinish}
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, delete: false };
            });
          }}
        />
      </Modal>

      {/* modal start */}
      <Modal effect="fadeInDown" visible={showModal.approveOverview}>
        <EmpExpenditureOverview
          data={singleChild}
          handleSubmit={async () => {
            // e.preventDefault();
            // dispatch submit projection action
            // then redirect to the submission page

            const dat = { expenditure_projection_ids: [singleChild?.id] };

            const data = await dispatch(submitSingleDraftForApproval(dat));
            if (data?.payload?.success) {
              handleFinish(true);
              dispatch(getExpenditureProjections(user?.organizations[0]?.id));
              history.push(
                "/employee-dashboard-projection-expenditure-submitted"
              );
            }
          }}
          onDelete={() => {
            actionCtx?.setIsModalOut(true);
            setShowModal((prev) => {
              return { ...prev, delete: true, approveOverview: false };
            });
          }}
          onCancel={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, approveOverview: false };
            });
          }}
          onUpdate={() => {
            localStorage.setItem(
              "single-expenditure-projection-id",
              singleChild?.id
            );
            history.push("/employee-dashboard-projection-expenditure-update");
            actionCtx.setIsModalOut(false);
          }}
          // onUpdate={handleExpenditureProjectionUpdate()}
          details={singleChild}
        />
      </Modal>
      {/* modal end */}
      {/* modal start */}
      <Modal visible={showModal.submit}>
        <EmpSubmitForApproval
          onClose={() => {
            actionCtx?.setIsModalOut(false);
            setShowModal((prev) => {
              return { ...prev, submit: false };
            });
          }}
          details={singleChild}
          onFinish={handleFinish}
        />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default EmployeeExpenseProjectionIndex;
